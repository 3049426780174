import React from 'react';
//Variables needed for some text
let currentYear = new Date().getFullYear();
let pastYear = new Date().getFullYear() - 1

const lang = {
    en: {
        //General
        inclBen: "Incl. benefits",
        exclBen: "Excl. benefits",
        womenDiff: "Difference women",
        save: "Save",
        saved: "Saved",
        cancel: "Cancel",
        continue: "Continue",
        moreInfo: "More info",
        name: "Name",
        averageSal: "Average salary",
        avgSal: "Avg. salary",
        avgBen: "Avg. benefits",
        avgAge: "Avg. age",
        avgExp: "Avg. exp.",
        avgLocation: "Avg. location",
        salDiff: "Salary difference",
        salDiffShort: "Salary diff.",
        men: "Men",
        women: "Women",
        total: "Total",
        spread: "Spread",
        spreadRatio: "Spread ratio",
        ratioDiff: "Ratio diff.",
        salarySpread: "Salary spread",
        position: "Position",
        location: "Location",
        adjust: "Adjust",
        salary: "Salary",
        newSalary: "New salary",
        hideEmp: "Hide employee",
        changeGroup: "The adjustment is temporary and resets on group change.",
        personHasBenefit: "The employee has benefits of ",
        personBenefitQuestion: "Should the benefits be included or add to the sum?",
        include: "Include",
        personBenefitAdd: "Add",
        marketSalaries: "Market salaries",
        sector: "Sector",
        all: "All",
        public: "Public",
        private: "Private",
        hide: "Hide",
        note: "Add note",
        textField: "Aa",
        notable: "Notable",
        moreFields: "More fields ",
        reason: "Reason",
        action: "Action",
        actions: "Actions",
        cost: "Cost",
        responsible: "Responsible",
        notes: "Notes",
        selectedGroup: "Selected group",
        commentPast: "Past year comments",
        reuseAll: "Reuse all",
        reuseAllPop: "Do you want to reuse all comments from the selected year for all groups?",
        evaluates: "Under evaluation",
        reuse: "Reuse",
        reuseAgain: "Reuse again",
        employees: "Employees",
        noCommentsPastYear: "No comments from past year",
        create: "Create",
        equal: "Equal",
        reset: "Reset",
        no: "No",

        // Left menu
        s1: 'Import',
        s2: 'Group & Evaluate',
        s21: '2.1 Group Equal Work',
        s22: '2.2 Job Evaluation',
        s3: 'Analyze',
        s31: '3.1 Analyze Equal Work',
        s32: '3.2 Analyze Equivalent Work',
        s33: '3.3 Evaluate',
        s4: 'Document',

        // Top menu
        overview: "Overview",
        reporting: "Reporting",
        introduction: "Introduction",
        account: "Account",
        settings: "Settings",
        logout: "Log out",
        selectYear: "Select Year",
        yearInfo: "Specify the year for which the Pay Equity Analysis applies. Notes are retrieved and saved for this year, as well as the documentation.",
        deleteData: "Delete data",
        deleteInfo: "Delete individual data. Note that only individual data is deleted (data from the uploaded Excel file). Comments etc. is not deleted.",
        moreSettings: "More settings",
        thisYear: "Current year",
        deleteDataPop: "Do you want to delete personal data?",
        reImport: "You can restore deleted data by re-importing the Excel file at any time.",
        deleteForBolag: "Delete for company",
        deleteForKoncern: "Delete for corporate group",
        errorTryAgainToLoad: "Something went wrong, please try reloading the page",
        dataRemoved: "Data deleted",


        //Settings
        report: "Documentation",
        other: "Other",
        back: "Back",
        settingTitle: "Settings",
        twoStep: "Two-Factor Authentication",
        twoStepInfo: "With two-factor authentication, you add an extra layer of protection in case your password falls into the wrong hands. During login, you will be required to enter an additional code tied to your mobile device through the 'Google Authenticator' app.",
        warningPop: "Warning message",
        warningInfo: "Displays a warning message if you attempt to switch pages without saving your work.",
        language: "Language",
        languageInfo: "Select the language to be used.",
        swedish: "Swedish",
        english: "English",
        scatterPlot: "Scatterplot in documentation",
        scatterPlotInfo: "Display scatterplots for equal work in the documentation.",
        hideYSalary: "Hide salaries on the y-axis.",
        hideYSalaryInfo: "The y-axis is typically displayed as currency. This is the clearest way to illustrate salary differences. If this is perceived as sensitive information, you can choose to display the y-axis in percentage instead. The percentage indicates what proportion of the total salary in the group each individual possesses. This provides the same visual representation of where salary differences exist but does not reveal the specific salary amounts.",
        showAll: "Show all",
        showAge: "Show age",
        showOrg: "Show years in org.",
        diagramRec: "We recommend scatterplots in the documentation for a clearer presentation.",
        hideAvgSalaries: "Hide average salaries in documentation",
        hideAvgSalariesInfo: "Hide average salaries for all groups in the documentation. Differences are instead shown in percentages.",
        jobEvaluation: "Job evaluation",
        jobEvaluationInfo: "Select what job evaluation to be used.",
        standardInfo6Plus: "Ready-to-use factor plan. Descriptions, weighting, and sensitivity can be adjusted if needed.",
        standardInfo6: "Ready-to-use factor plan. Descriptions and weighting can be adjusted if needed.",
        easiest: "Easiest",
        customized: "Customized",
        customizedInfo: "For organizations seeking maximum flexibility. Ability to create and delete factors, modify levels, and more.",
        extern: "External",
        externInfo: "Use the result from a job evaluation that is not created in Lönelys.",
        clarifyBenefits: "Clarify benefits in documentation",
        benefitsInfo: "Separate and present benefits more clearly in the documentation.",
        benefitsInfoTwo: "Instead of only displaying salary including benefits, more rows are displayed with salary including benefits, salary excluding benefits, only benefits, and with or without additional charts. Provides a clearer presentation of benefits, with the drawback of a slightly more complex documentation. ",
        benefitsExample: "See example",
        additionalCharts: "Additional charts:",
        noExtraChart: "No additional charts",
        salExclChart: "Salaries excl. benefits",
        onlyBenefitsChart: "Only benefits",
        nrOfGroups: "Number of groups ",
        nrOfGroupsInfo: "Adjust the number of groups for equivalent works. More groups means more sensitive job evaluation.",
        nrOfGroupsDetails: "Increasing the number of groups means that fewer works will be classified as equivalent. For large organizations with many equal works, a larger number of groups may provide a more fair analysis. It's important to be consistent and not adjust from year to year.",
        nrOfGroupsDetailsBox: "Weighting and descriptions can be adjusted directly in the job evaluation if needed during step 2.1.",
        factorName: "Factor name",
        levels: "Levels",
        description: "Description",
        weightning: "Weight",
        knowledgeAndExp: "Skills & Competencies",
        setResp: "Responsibilites",
        setEffort: "Effort & Working Conditions",
        importPlanInfo: "If you have created a customized factor plan in Lönelys for another company within the same company group and want to use that same factor plan, you can import it here. Choose the company you want to copy from and then click import.",
        enableTwoStep: "Enable two-step verification",
        verify: "Verify",
        followSteps: "Follow these steps:",
        addCode: "Add code",
        downloadApp: `Download the app "Google Authenticator" on your mobile phone and scan the QR code above.`,
        enterCode: "Enter the code that you see on the app.",
        addDescription: "Add description.",
        addConsid: "Add what to take into consideration.",



        // Introduction
        welcome: "Welcome!",
        welcomeInfo: "Watch the introduction video below, then feel free to get started.",
        welcomePopTitle: "Happy new year",
        welcomePopTextOne: "The system has automatically started the Pay Equity Analysis for ",
        welcomePopTextTwo: `Have you started your Pay Equity Analysis in ${pastYear} but haven't completed it before the new year? If so, you can continue the Pay Equity Analysis for ${pastYear} below. If you prefer to begin a new Pay Equity Analysis for ${currentYear}, select to start the work for ${currentYear} instead.`,
        welcomePopTextThree: `If you choose the year ${currentYear}, all the comments and actions you created in ${pastYear} will be displayed as comments/actions from the previous year.`,
        welcomeContinue: `Continue ${pastYear}`,
        welcomeStartNew: `Start ${currentYear}`,
        welcomeNote: "You can always change the year whenever you want under settings via the green button at the top right.",

        // Information
        // Info job evaluation, standard
        infoJobEval: "Job Evaluation",
        infoJobEvalDetails: `Job evaluation is an evaluation of job requirements. This is used for the analysis of equivalent works in step 3.2. In total there is 8 factors, where you for for each job should assign a score from 1-4. Click on the information symbol next to each factor to see more details about its evaluation criteria.
        Please note that you can also weight factors that are particularly relevant to your organization. Click on the video for more information.`,
        infoLowReq: "low reqs",
        infoMedReq: "medium reqs",
        infoHighReq: "high reqs",
        infoHighestReq: "very high reqs",
        // Info job evaluation, edited
        infoJobEvalDetailsEdited: `Job evaluation is an evaluation of job requirements. This is used for the analysis of equivalent works in step 3.2. 
        Below, you will find several factors where you for each job should assign a score from 1-4 (assuming you haven't changed the number of levels).
        The higher the score, the higher the requirements. Click on the information symbol next to each factor to see more details about its evaluation criteria.
        Please note that you can also weight factors that are particularly relevant to your organization. Click on the video for more information. Provided that you have not changed the number of levels, the following applies:`,
        // Info job evaluation, extern
        infoJobEvalDetailsExtern: `This section is for you who already have a job evaluation. If you instead want to create a new job evaluation in Lönelys, go to settings and choose this option.`,
        infoJobEvalDetailsExternTwo: `To use an existing job evaluation, first specify the name of the job evaluation system that has been used. Then, add the level for each work based on your results. The levels are represented by numbers in hierarchical order, where works at the same level share similar requirements. One indicates the lowest requirements, two has higher requirements than one, three has higher requirements than two and so on. When all works are categorized into a level, you are finished.`,

        // Info import data
        infoImportTitle: "Import salary data",
        infoImportDetails: `Start by importing employee data. Obtain this by creating a report from your payroll system. For the import to be correct, it's important that the data is structured correctly. Therefore, download our template and fill it with your information, then import it. Ensure that the file ends with '.xlsx'.`,
        infoImportDetailsTwo: `At the beginning of a new year or for making adjustments, simply upload a new file. This will replace the old one. It is recommended that you save your import file each year, as this allows you to easily restore old employee data if needed.`,
        infoImportDownload: "Download template",

        // Info group equal work
        infoGroupSimTitle: "Group equal work",
        infoGroupSimDetails: `Below, you will find all job titles in your organization. Now, you need to group those that are performing almost the same tasks, known as equal work. You do this by clicking on 'Add Group' and entering a name for the group. If you have different job titles with very similar tasks, you should assign a common group for them. If there are job titles whose work cannot be grouped together with others, you can simply enter the job title as the group.`,
        infoGroupSimDetailsTwo: `Under each job title, you also see a gray row where you can choose a statistical code. Click to search and select the job that best fits the position. The code is used to retrieve market salary data and does not affect your grouping of equal work.`,

        // Info analyse equalWork
        infoAnalyseEqualTitle: "Analyze equal work",
        infoAnalyseEqualDetails: `Analyze of equal work means analyze of jobs that are considered to perform similar types of work tasks (grouped in step 2.1). Go through each group in the list on the left and analyze pay differences between men and women. Is there legitimate reasons for the salary differences? The scatterplot diagram helps you compare. When you find something that require analysis, please provide it as a comment below the chart. Then, specify the cause and add an action if necessary. Watch the video for more information.`,
        infoAnalyseEqualComments: "Note that your comments will be visible in the documentation (step 4). If you wish to hide info in the documentation, such as names, you can enclose it within parentheses. ",
        infoAnalyseEqualParan: "In step 4, you can automatically generate a complete documentation. This shows your comments. If there's anything you don't want to be shown in the documentation, you can enclose those parts in parentheses. All text within parentheses will be hidden in the documentation. This is useful, for example, when using names in the analysis but maintaining anonymity in the documentation.",
        infoAnalyseEqualParanTwo: "Example of how you can use names in the analysis but hide them in the documentation:",
        infoAnalyseEqualExample: <p><u>Text in comment:</u> <em>A man <b>(Martin Stensson)</b> earns 5000 sek more than a woman <b>(Sofia Tåström)</b> who has worked two years longer in the organization.</em></p>,
        infoAnalyseEqualExampleTwo: <p><u>Becomes in documentation:</u><em> A man earns 5000 sek more than a woman who has worked two year longer in the organisation.</em></p>,
        infoAnaluseEqualInStepOne: `In this step, you can also view market salaries by clicking the "`,
        infoAnaluseEqualInStepTwo: `" icon in the scatterplot diagram. This provides insight on how your salaries compare to the market.`,

        // Info analyse equivalent work
        infoAnalyseEqTitle: "Analyze equivalent work & hierarchical differences",
        infoAnalyseEqDetailsOne: "Analyze of equivalent work means analyze jobs with similar requirements. Below, you will find several groups. The higher the group, the higher the requirements of the job. The group in which the job falls is based on the job evaluation you conducted in step 2.2. Now, analyze the jobs within each group. Are there differences between female-dominated jobs and none-female-dominated? Note down any differences.",
        infoAnalyseEqDetailsTwo: "Analyze of hierarchical differences means analyze salary differences in jobs between the groups. Are there female-dominated jobs that have higher requirements but lower pay? Watch the video for more information and tips on performing your analysis.",

        // Info create report
        infoReportTitle: "Create documentation",
        infoReportDetails: "According to Swedish law, you are required to document your Pay Equity Analysis each year. This is easily done by creating a documentation with the press off a button. All data is automatically compiled, but before you generate the documentation, you need to fill in some relevant information below. Once all fields are filled in, click on ”Generate PDF/Wordfile”, and then you are finished with your Pay Equity Analysis! The documentation does not need to be submitted anywhere but should be downloaded and saved in an appropriate place. Note that you should also save the import file (Excel file) so that you can easily recreate data for previous years if needed in the future.",

        // Info Evaluate
        infoEvaluateTitle: "Evaluate previous year",
        infoEvaluateDetailsOne: "From year 2 onward, you need to evaluate the actions you created the previous year. Below, you will find a list of these actions. Mark whether they are finished or not by clicking on the 'x' icon. If an action is not finished, you need to add a comment explaining why.",
        infoEvaluateDetailsTwo: "An overview of current year's actions can also be viewed, and here you can mark actions as completed at any time or alternatively you can do it during next year's evaluation. Here you can also add new overarching actions if needed.",

        // Documentation
        docoTitle: 'Create documentation',
        docoDescription: `You are required to document your Pay Equity Analysis. 
    This is easily done with a simple click. All data is automatically compiled, but before you create the documentation, 
    you need to fill in the relevant information below. 
    Once all fields are filled in, just click on 'Generate PDF/Word File', and then you are finished with your Pay Equity Analysis! 
    Don't forget to save the documentation somewhere appropriate where you will find it easily. 
    Note that you should also save the import file (excel file) so that you can easily recreate data for previous years when needed in the future.`,
        createPDF: "Create PDF",
        createWord: "Create Wordfile",
        logo: "Logo",
        cooperation: "Cooperation",
        hideInfo: "Hide information",
        hideName: "Hide name: ",
        hideSalary: "Hide Salaries: ",
        showInPercent: "Shown in %",

        // Import
        import: "Upload",
        importSettingTitle: "Calculate years in the organization and age based on:",
        importToday: "Current date - Default",
        importTodayDetails: "Years in the organization and age are calculated based on the current date. Suitable in most cases.",
        importEndOfYear: "When you have selected a previous year, years in the organization and age are calculated based on the end of that year.",
        importPrevious: "Previous import date",
        importPreviousDetails: "Select the same date as one of your previous imports. Suitable if you want the data to reflect the exact same year and age as it was on the chosen import date.",
        importChooseDate: "Choose date",
        importCustom: "Custom date",
        importCustomDetails: "Choose a custom date for calculating years in the organization and age.",
        importNote: "Note that the above setting is temporary and will be reset when you switch steps in the menu on the left.",
        endOfYear: "End of the year ",
        dataImported: "Data imported successfully!",
        nowYouAreReady: "Now you are ready to get started. Proceed to step 2 in the menu.",
        importError: "An error occurred.",
        tryAgain: "Try again",
        containingErrors: "Fields containing errors",
        importPersonsErr: " employees have incorrect formatting or empty fields. See more information below.",
        importNotThisComp: "You cannot perform a Pay Equity Analysis for this organization. Choose another company in the menu at the top right under account.",
        importWrongFormat: "Incorrect format. Upload the file as '.xlsx' format. When saving your Excel file, use 'Save As' and choose '.xlsx' or 'Excel Workbook'.",
        tooBigFile: "File too large. The file can be a maximum of 20MB.",
        noName: "One or more employees do not have a name filled in the Excel file. Open your Excel file and ensure that all employees have their names filled in.",
        errorFoundFor: "Error found for",
        socialSecurityError: `The social security number is empty or in the wrong format. The social security number can be left blank, but in that case, gender and age must be provided. Open your Excel file and ensure that the social security number follows the format: "YYYYMMDD-NNNN" (including hyphen), or alternatively, provide age and gender instead. If gender and age are provided, make sure that gender is written as "Man" or "Woman" and that age consists only of digits.`,
        salaryError: "Salary is empty or in the wrong format. Open your Excel file and ensure that the salary is specified using only numbers.",
        positionError: "Position is not filled in for",
        startDateError: `Start date is empty or in the wrong format. Open your Excel file and make sure the start date is provided in the format "YYYY-MM-DD".`,
        extraFieldError: `The "extra" field can only contain numbers. Open your Excel file and adjust it accordingly. If an extension like "SEK" or "kr" is automatically added to the Excel file, it's because it's in currency format. If so then change the format to "general" instead.`,
        cityError: `The "city" field is empty. Open your Excel file and fill it in.`,
        additionalFieldError: `The field "additional" contains too many characters. The maximum number of characters is 80.`,

        //Group Equal Works
        groupTitle: "Group Positions",
        groupAll: "All groups",
        addGroup: "Add group",
        groupStatistic: "Select statistical code",
        groupCode: "Code: ",
        groupGet: "Import groupings",
        importFrom: "Import from..",
        importWhat: "What to import?",
        everything: "Everything",
        onlyEmpty: "Empty only",
        specificPos: "Selected positions",
        importChoosePos: "Select positions",
        markAll: "Select all",
        groupImport: "Import",
        groupImportInfo: "If you have made job groupings for another company within your company group, you can import and reuse these groupings for this company as well. The changes you make in one company do not affect the others.",
        howToImportGroup: <ul>
            <li><b>1)</b> Click the "Import groupings" button.</li>
            <li><b>2)</b> Select which company you want to copy/import from.</li>
            <li><b>3)</b> Select what you want to import. All, only empty, or individual positions.</li>
            <li><b>4)</b> Click on import.</li>
        </ul>,

        //Job Evaluation External
        enterName: "Enter the name of the job evaluation system",
        addLevel: "Add level",

        //Analyize equal
        equalChooseGroup: "Select group to analyze",
        equalNrOfWorkers: "Number of employees",
        equalDiff: "Diff. women",
        lowestSaL: "Lowest salary",
        highestSal: "Highest salary",
        lowestBen: "Lowest benefit",
        highestBen: "Highest benefit",
        benefits: "Benefits",
        equalInfo: "Scatterplots are an excellent way to visualize salary spread while highlighting individual differences. To quickly visualize salary spread, one can also examine the difference between the highest and lowest salaries. If there is a significant spread for one gender but not for the other, it may indicate that one gender has greater opportunities for wage progression, and it should be looked into more to determine if this is the case and the reasons behind it.",
        equalInfoTwo: "The spread ratio shows how big the salary distribution is between the highest and lowest salary. The higher the ratio, the greater the spread. A ratio of 2.0 means that the highest salary is twice as large as the lowest.",
        equalInfoThree: "Note that if one gender consists of 20 people or more, you will also see percentile 10 (P10) and percentile 90 (P90). This to provide a more accurate picture of the distribution and avoid extreme values. This means that instead of the highest and lowest salary, P10 shows the salary level where 10% earn less, and P90 shows the salary level where 10% earn more.",
        equalBigGroups: "For big groups",
        equalInfoMarket: "About the market salaries",
        aboutMarket: "About the data",
        aboutMarketInfo: "The salaries are based on data from Statistiska centralbyrån (SCB). All salaries displayed are gross monthly salaries. If '0' is shown, it means that data is missing or not reliable enough, or there is no statistical code linked to the job (can be added in step 2.1 or in the importfile).",
        whatMarket: "What you are seeing",
        whatMarketInfo: "The data shows market salaries in the form of several 'P' values, which represent percentiles. Percentile indicates what percentage has a lower salary. If P10 is 30,000, it means 10% have a lower salary than 30,000, and 90% have a higher salary. If P90 is 50,000, it means 90% have a lower salary than 50,000, and 10% have higher. So, 80% have a salary between 30,000 - 50,000. This means that the percentiles illustrates the market's salary range.",
        whatMarketInfoTwo: `P50 is the same as the median and means that 50% earn less and 50% earn more. If you were to use one figure, this would be the 'market salary'.`,
        multiplePositions: "Multiple positions in one group",
        multiplePositionsOne: "The market salary data displayed is an aggregation for all positions in the selected group. If there are multiple positions in the group with different market salaries, an average for these will be shown. To display market salaries for a specific position, use the filter in the chart (icon: ",
        multiPositionsTwo: " and select only the specific position.",
        displayInChart: "Display in chart",

        //Analyize equivalent
        detailedAnalysis: "Detailed and automatic analysis",
        detailedAnalysisInfo: "Here, you can perform a detailed analysis between two groups, see more details, visualize individual differences, and receive an automated analysis.",
        chooseGroups: "Select groups",
        changeGroups: "Change groups",
        chooseGroupInfo: `You select a group to analyze by first clicking on one of the boxes that says "Select group." The text will now change to "Click on group." Click on the first group you want to see. Then do the same for the second box where it says "Select group."`,
        changeGroupInfo: `To replace a group, simply click on the box where it previously said "Select group" (now it shows the group name). The text will now change to "Click on group." Click on the group you want to see instead.`,
        aiAnalysisInfo: "The analysis takes into account several factors such as:",
        aiAnalysisUl: <ul style={{ listStyleType: "circle", paddingLeft: 20, padding: "inherit" }}>
            <li>Salary levels</li>
            <li>Gender distribution</li>
            <li>Age</li>
            <li>Experience</li>
            <li>Group size</li>
            <li>Location</li>
            <li>Job requirements</li>
            <li style={{ display: "block" }}>... and more</li>
        </ul>,
        aiAnalyisInfoTwo: "Reality can be more complex, and other factors may come into play, such as the market, retained salaries, performance, etc. Therefore, you should always make your own assessment. Any inaccuracies are reserved.",
        selectGroup: "Select group",
        clickGroup: "Click on group",
        compare: "Compare",
        difference: "Difference",
        requirements: "Requirements",
        aiAnalysisTitle: "Analysis",
        aboutAge: "Age itself is not a justifiable cause, it needs to be relevant previous experience.",
        useAsComment: "Create as comment",
        new: "New",
        resetChange: `Click on 'Compare' to reset the change.`,
        equivalent: "Equivalent",
        hierarchical: "Hierarchical",
        group: "Group ",
        avgSalMinimal: "Avg Sal",
        empMinimal: "Emp",
        noCommentsFromThisYear: "No comments for this year.",
        groupMissing: "No group",

        // Evaluate
        noActionsPastYear: "No actions from past year.",
        addComment: "Add comment",
        noActionsThisYear: "No actions this year.",
        completed: "Finished",
        comment: "Comment",
        generalAction: "Create general action",
        infoGeneral: "A general action is an action that is not linked to a specific analysis. Analysis-specific actions should not be created here but under their respective section (step 3.1 and 3.2).",
        pastYear: "Previous year",
        notCreated: "Not created. The fields notable, action, and deadline must be specified. Add it and try again.",

        //Documentation
        uploadLogo: "Upload logo",
        changeSize: "Change size",
        cooperationInfo: "Describe how cooperation has taken place with the employees",
        cooperationInfoDetails: "According to the law, the Pay Equity Analysis should be done in cooperation with the employees, whether you have a collective agreement or not.",
        cooperationInfoTwo: "If you are unionized, cooperation is done with the union, if not, a group of employees can represent the workers instead.",
        cooperationExampleTitle: "Example of content:",
        cooperationInfoThree: `Begin with "The Pay Equity Analysis have been conducted in cooperation with the employees."`,
        cooperationInfoFour: "Describe how the cooperation has taken place. How was the work initiated, who has been involved, and in what way has the union participated, etc.",
        cooperationNote: "Note that collective agreements may contain information for how cooperation should be carried out.",
        cooperationDescribe: "Describe how the cooperation has been carried out.",
        payPolicy: "Analyze provisions & practices",
        payPolicyInfo: "Map and analyze your rules and practices for salaries and benefits.",
        payPInfo: "Describe your pay policy. Do you have a specific policy for this? In brief, what does it consist of?",
        payPInfoTwo: "Do you use individual salary setting?",
        payPInfoThree: "What is the criterias for salary setting? How do you ensure that it does not discriminate based on gender?",
        payPInfoFour: "If, during the analysis of provisions & practices, you find anything that may be discriminatory, it should be included here along with an action.",
        payPDesc: "Describe your analysis of provisions & practices.",
        whoAttended: "Specify who has been involved in the Pay Equity Analysis",
        whoAttendedInfo: "Provide first and last names, as well as job titles.",
        additionalInfo: "Add additional information",
        additionalInfoDetails: "If you want to add any additional information to the documentation, please add it here. This field is optional and will not be included in the documentation unless you add something.",
        title: "Title",
        payE: "Pay Equity Analysis",
        createPDF: "Create PDF",
        createWord: "Create Wordfile",
        preparing: "Preparing...",
        coop: "Cooperation",
        payPol: "Provisions & practices",
        participants: "Participants",
        freeText: "Free text",

        //Job evaluation
        hideArrowsInfo: "If you tend to accidentally click on the arrows in the input fields, you can choose to hide them. You will then only be able to change points using the keyboard.",
        hideArrows: "Hide arrows on input",
        jobEPop: "Are you sure you want to import?",
        jobEImported: "Data imported!",
        specificGroups: "Selected groups",
        onlyWeight: "Only weight",
        importEvaluation: "Import evaluations",
        sort: "Sort",
        filter: "Filter",
        hideAll: "Hide all",
        showAll: "Show all",
        hiddenWorks: "hidden works.",
        search: "Search",
        sortAlpha: "Alphabetical",
        sortLevel: "Requirements",
        describeFactor: "Describe the factor.",
        addLevelDesc: "Add level description.",
        demandsFor: "Requirements for the work in terms of",
        consider: "In consideration during assessment",
        levelDesc: "Level descriptions",
        sureCancel: "Are you sure you want to cancel? Your changes will not be saved.",
        resetFactor: "Resets the factor to the original text. All adjustments will be deleted. Do you want to continue?",
        importOther: "You can import and reuse job evaluations from other companies in your company group. The changes you make in one company do not affect the others.",
        howTo: "How to import:",
        howToSteps: <ul>
            <li><b>1)</b> Click on the "Import evaluations" button</li>
            <li><b>2)</b> Choose the company you want to import from.</li>
            <li><b>3)</b> Select what you want to import.</li>
            <li><b>4)</b> Click on import.</li>
        </ul>,
        backUpLevels: [
            {
                //   question: 'Erfarenhet & Utbildning',
                question: 'Education & Experience',
                level1: "No or low requirements for education and relevant work experience.",
                level2: "Requires post-secondary education or relevant experience.",
                level3: "Requires high knowledge acquired through high education or medium-level education with longer relevant work experience.",
                level4: "Requires highly specialized or broad knowledge acquired through high education and long relevant work experience."
            },
            {
                //   question: 'Problemlösning',
                question: 'Problem solving',
                level1: "Simple and often routine tasks with less emphasis on problem-solving. Solutions usually have known approaches.",
                level2: "Requires some analysis and creativity. Complicated problems arise but should be discussed with a superior before decisions are made.",
                level3: "Problem-solving is common. Independent analysis, innovative thinking, and evaluation of alternatives before decisions. Solutions are rarely given or predefined.",
                level4: "Problem-solving is a significant part of the work. Varied, complex problems. Requires creative solutions, often new approaches, qualified knowledge acquisition, and significant independence in decisions."
            },
            {
                //  question: 'Sociala färdigheter',
                question: 'Social skills',
                level1: "No requirements for specific social skills.",
                level2: "Skills in communication and collaboration both internally and externally. Ongoing dialogues. May involve conflicts of interest.",
                level3: "Communication is a significant part of the work. Often demanding situations and conflicts of interest. Ability to guide and argue.",
                level4: "Social skills are crucial for the work. Difficult and complex contacts with individuals/groups. Often significant conflicts of interest or challenging situations.",
            },
            {
                // question: 'Personal',
                question: 'Personnel',
                level1: "Has no personnel responsibility or very few employees where personnel management is a very small part of the work.",
                level2: "Responsible for a small group of employees. Personnel management is a certain part of the work.",
                level3: "Responsible for a significant number of employees. Management is a large part of the work.",
                level4: "Overall managerial responsibility for large groups of personnel or departments. Often management of other managers."
            },
            {
                // question: 'Verksamhet',
                question: 'Operations',
                level1: "Guidelines created by someone else are to be followed. Little or no responsibility for planning, development, and results. May participate in planning for own group.",
                level2: "Some responsibility for planning, development, or results. Not necessarily a managerial role. Independent responsibility for work methods, scheduling, or budget.",
                level3: "Overall and strategic responsibility for planning, development, and results. Can control and change processes within own operational area.",
                level4: "Overall business responsibility for one/multiple groups or operational areas. Shapes and influences goods/services as well as norms and guidelines that govern."
            },
            {
                //  question: 'Människor',
                question: 'People',
                level1: "Responsibility is limited to normal consideration and concern for others' well-being.",
                level2: "Some responsibility for others' health, development, or safety. Often involves influencing others by following rules and instructions within the framework. Handling of sensitive information may occur.",
                level3: "Significant responsibility/impact on others' health, development, or safety. Independent decisions affecting other people are common.",
                level4: "Very high demands to independently assess and make decisive decisions that affect others' health, development, or safety. Often entails significant impact on individuals/groups.",
            },
            {
                //  question: 'Fysiska',
                question: 'Physical',
                level1: "No specific requirements for physical exertion. Risk of injury is very low.",
                level2: "At times, physical exertion may be required. Risk of injury is low.",
                level3: "Physical exertion occurs daily. Long repetitive work or heavier tasks over shorter periods. Some risk of injury.",
                level4: "Physical exertion is a significant part of the job. Uncomfortable working positions occur. High risk of injury."
            },
            {
                //question: 'Psykiska',
                question: 'Psychological',
                level1: "Low demands on mental strain. Mentally challenging situations rarely occur.",
                level2: "Mentally challenging situations occur occasionally. High workload and time pressure occur periodically.",
                level3: "Mental and emotional strain occurs frequently.",
                level4: "Very high demands on mental and emotional strain over time. High demands on availability, concentration, time pressure, and challenging relationships.",
            },
        ],
        weight: "Weight:",
        edu: "Education",
        andExp: "& Experience",
        prob: "Problem solving",
        socSkills: "Social skills",
        staff: "Personnel",
        operation: "Operations",
        people: "People",
        physical: "Physical",
        psychological: "Psychological",
        originalFactors: [
            {
                question: "utbildning", // Important: Do not translate this, its not displayed and used for comparing
                category: "Knowledge",
                instructions: "- Education\n- Work Experience",
                focus: "Summarized assessment of the level of requirements the position places on education and work experience.",
                level1: "No or low requirements for education and relevant work experience.",
                level2: "Requires post-secondary education or relevant experience.",
                level3: "Requires high knowledge acquired through high education or medium-level education with longer relevant work experience.",
                level4: "Requires highly specialized or broad knowledge acquired through high education and long relevant work experience."
            },
            {
                question: "problem",
                category: "Knowledge",
                instructions: "- Handle emerging issues\n- Analysis\n- Creativity",
                focus: "- How often problems arise and need to be solved\n- Independence in work. Is there assistance available?\n- The complexity of tasks\n- Whether one needs to delve into new areas due to rapid development",
                level1: "Simple and often routine tasks with less emphasis on problem-solving. Solutions usually have known approaches.",
                level2: "Requires some analysis and creativity. Complicated problems arise but should be discussed with a superior before decisions are made.",
                level3: "Problem-solving is common. Independent analysis, innovative thinking, and evaluation of alternatives before decisions. Solutions are rarely given or predefined.",
                level4: "Problem-solving is a significant part of the work. Varied, complex problems. Requires creative solutions, often new approaches, qualified knowledge acquisition, and significant independence in decisions."
            },
            {
                question: "sociala",
                category: "Knowledge",
                instructions: "- Communication\n- Collaboration\n- Service",
                focus: "- How big proportion of the work requires social skills\n- If situations are often demanding\n- If there are frequent conflicts of interest",
                level1: "No requirements for specific social skills.",
                level2: "Skills in communication and collaboration both internally and externally. Ongoing dialogues. May involve conflicts of interest.",
                level3: "Communication is a significant part of the work. Often demanding situations and conflicts of interest. Ability to guide and argue.",
                level4: "Social skills are crucial for the work. Difficult and complex contacts with individuals/groups. Often significant conflicts of interest or challenging situations.",
            },
            {
                question: "personal",
                category: "Responsibility",
                instructions: "- Personnel responsibility\n- Management and leadership",
                focus: "- Number of employees\n- If a significant part of the work is devoted to management of personnel\n- Independence in the responsibility",
                level1: "Has no personnel responsibility or very few employees where personnel management is a very small part of the work.",
                level2: "Responsible for a small group of employees. Personnel management is a certain part of the work.",
                level3: "Responsible for a significant number of employees. Management is a large part of the work.",
                level4: "Overall managerial responsibility for large groups of personnel or departments. Often management of other managers."
            },
            {
                question: "verksamhet",
                category: "Responsibility",
                instructions: "- Planning\n- Development\n- Results",
                focus: "- If the work has long-term significance for the organization\n- How strongly the result affects the organization\n- Independence of the responsibility",
                level1: "Guidelines created by someone else are to be followed. Little or no responsibility for planning, development, and results. May participate in planning for own group.",
                level2: "Some responsibility for planning, development, or results. Not necessarily a managerial role. Independent responsibility for work methods, scheduling, or budget.",
                level3: "Overall and strategic responsibility for planning, development, and results. Can control and change processes within own operational area.",
                level4: "Overall business responsibility for one/multiple groups or operational areas. Shapes and influences goods/services as well as norms and guidelines that govern."
            },
            {
                question: "manniskor",
                category: "Responsibility",
                instructions: "- Customers\n- Patients\n- Responsibility for others' health or safety\n- Impact on people through decisions",
                focus: "- Independence in responsibility\n- How much responsibility for people dominates in the work\n- How strongly the work affects other people\n- If sensitive/confidential information about people is handled",
                level1: "Responsibility is limited to normal consideration and concern for others' well-being.",
                level2: "Some responsibility for others' health, development, or safety. Often involves influencing others by following rules and instructions within the framework. Handling of sensitive information may occur.",
                level3: "Significant responsibility/impact on others' health, development, or safety. Independent decisions affecting other people are common.",
                level4: "Very high demands to independently assess and make decisive decisions that affect others' health, development, or safety. Often entails significant impact on individuals/groups.",
            },
            {
                question: "fysiska",
                category: "Effort",
                instructions: "- Heavy work\n- Repetitive work tasks\n- Uncomfortable working positions\n- Risk of injury",
                focus: "- How demanding the physical work is\n- Combination effects, for example, if heavy work is done in combination with an uncomfortable working position\n- Long repetitive work can be as physically demanding as heavy tasks over shorter periods\n- Access to aids\n- Work pace, how quickly the physical work must be performed",
                level1: "No specific requirements for physical exertion. Risk of injury is very low.",
                level2: "At times, physical exertion may be required. Risk of injury is low.",
                level3: "Physical exertion occurs daily. Long repetitive work or heavier tasks over shorter periods. Some risk of injury.",
                level4: "Physical exertion is a significant part of the job. Uncomfortable working positions occur. High risk of injury."
            },
            {
                question: "psykiska",
                category: "Effort",
                instructions: "- Emotional strain \n- Monotony \n- Availability \n- Stress",
                focus: "- How demanding the mental strain is \n- Are there frequent encounters with angry, sick, or difficult-to-handle people? \n- Requirements for availability, such as outside regular working hours, or that other tasks are often being affected \n- Strict and monotonous work that requires careful concentration can be very demanding \n- If there is a high workload and stress",
                level1: "Low demands on mental strain. Mentally challenging situations rarely occur.",
                level2: "Mentally challenging situations occur occasionally. High workload and time pressure occur periodically.",
                level3: "Mental and emotional strain occurs frequently.",
                level4: "Very high demands on mental and emotional strain over time. High demands on availability, concentration, time pressure, and challenging relationships.",
            }
        ],
        infoDeailLevels: "Detailed level descriptions can help concretize the job evaluation and make it easier to understand what each level entails.",
        infoDetailLevelsTwo: `If you click on "Add," ready-made level descriptions will be retrieved, and these can also be adjusted if needed.`,
        infoDetailedLevelDesc: "Here you can add more detailed level descriptions.",
        originalFactorsForEdited: [
            {
                new: "Yes",
                id: 1,
                category: 'Knowledge',
                //  question: 'Erfarenhet & Utbildning',
                question: 'Education & Experience',
                levels: 4,
                weight: 20,
                instructions: "- Education\n- Work Experience",
                focus: "Summarized assessment of the level of requirements the position places on education and work experience.",
                level1: "No or low requirements for education and relevant work experience.",
                level2: "Requires post-secondary education or relevant experience.",
                level3: "Requires high knowledge acquired through high education or medium-level education with longer relevant work experience.",
                level4: "Requires highly specialized or broad knowledge acquired through high education and long relevant work experience.",
                helper: "education",
            },
            {
                new: "Yes",
                id: 2,
                category: 'Knowledge',
                // question: 'Problemlösning',
                question: 'Problem solving',
                levels: 4,
                weight: 15,
                instructions: "- Handle emerging issues\n- Analysis\n- Creativity",
                focus: "- How often problems arise and need to be solved\n- Independence in work. Is there assistance available?\n- The complexity of tasks\n- Whether one needs to delve into new areas due to rapid development",
                level1: "Simple and often routine tasks with less emphasis on problem-solving. Solutions usually have known approaches.",
                level2: "Requires some analysis and creativity. Complicated problems arise but should be discussed with a superior before decisions are made.",
                level3: "Problem-solving is common. Independent analysis, innovative thinking, and evaluation of alternatives before decisions. Solutions are rarely given or predefined.",
                level4: "Problem-solving is a significant part of the work. Varied, complex problems. Requires creative solutions, often new approaches, qualified knowledge acquisition, and significant independence in decisions.",
                helper: "problemsolving",
            },
            {
                new: "Yes",
                id: 3,
                category: 'Knowledge',
                // question: 'Sociala färdigheter',
                question: 'Social skills',
                levels: 4,
                weight: 10,
                instructions: "- Communication\n- Collaboration\n- Service",
                focus: "- How big proportion of the work requires social skills\n- If situations are often demanding\n- If there are frequent conflicts of interest",
                level1: "No requirements for specific social skills.",
                level2: "Skills in communication and collaboration both internally and externally. Ongoing dialogues. May involve conflicts of interest.",
                level3: "Communication is a significant part of the work. Often demanding situations and conflicts of interest. Ability to guide and argue.",
                level4: "Social skills are crucial for the work. Difficult and complex contacts with individuals/groups. Often significant conflicts of interest or challenging situations.",
                helper: "socialSkills"
            },
            {
                new: "Yes",
                id: 4,
                category: 'Responsibility',
                // question: 'Personal',
                question: 'Personnel',
                levels: 4,
                weight: 15,
                instructions: "- Personnel responsibility\n- Management and leadership",
                focus: "- Number of employees\n- If a significant part of the work is devoted to management of personnel\n- Independence in the responsibility",
                level1: "Has no personnel responsibility or very few employees where personnel management is a very small part of the work.",
                level2: "Responsible for a small group of employees. Personnel management is a certain part of the work.",
                level3: "Responsible for a significant number of employees. Management is a large part of the work.",
                level4: "Overall managerial responsibility for large groups of personnel or departments. Often management of other managers.",
                helper: "personnel"
            },
            {
                new: "Yes",
                id: 5,
                category: 'Responsibility',
                // question: 'Verksamhet',
                question: 'Operations',
                levels: 4,
                weight: 15,
                instructions: "- Planning\n- Development\n- Results",
                focus: "- If the work has long-term significance for the organization\n- How strongly the result affects the organization\n- Independence of the responsibility",
                level1: "Guidelines created by someone else are to be followed. Little or no responsibility for planning, development, and results. May participate in planning for own group.",
                level2: "Some responsibility for planning, development, or results. Not necessarily a managerial role. Independent responsibility for work methods, scheduling, or budget.",
                level3: "Overall and strategic responsibility for planning, development, and results. Can control and change processes within own operational area.",
                level4: "Overall business responsibility for one/multiple groups or operational areas. Shapes and influences goods/services as well as norms and guidelines that govern.",
                helper: "operation"
            },
            {
                new: "Yes",
                id: 6,
                category: 'Responsibility',
                // question: 'Människor',
                question: 'People',
                levels: 4,
                weight: 10,
                instructions: "- Customers\n- Patients\n- Responsibility for others' health or safety\n- Impact on people through decisions",
                focus: "- Independence in responsibility\n- How much responsibility for people dominates in the work\n- How strongly the work affects other people\n- If sensitive/confidential information about people is handled",
                level1: "Responsibility is limited to normal consideration and concern for others' well-being.",
                level2: "Some responsibility for others' health, development, or safety. Often involves influencing others by following rules and instructions within the framework. Handling of sensitive information may occur.",
                level3: "Significant responsibility/impact on others' health, development, or safety. Independent decisions affecting other people are common.",
                level4: "Very high demands to independently assess and make decisive decisions that affect others' health, development, or safety. Often entails significant impact on individuals/groups.",
                helper: "people"
            },
            {
                new: "Yes",
                id: 7,
                category: 'Effort',
                //  question: 'Fysiska',
                question: 'Physical',
                levels: 4,
                weight: 5,
                instructions: "- Heavy work\n- Repetitive work tasks\n- Uncomfortable working positions\n- Risk of injury",
                focus: "- How demanding the physical work is\n- Combination effects, for example, if heavy work is done in combination with an uncomfortable working position\n- Long repetitive work can be as physically demanding as heavy tasks over shorter periods\n- Access to aids\n- Work pace, how quickly the physical work must be performed",
                level1: "No specific requirements for physical exertion. Risk of injury is very low.",
                level2: "At times, physical exertion may be required. Risk of injury is low.",
                level3: "Physical exertion occurs daily. Long repetitive work or heavier tasks over shorter periods. Some risk of injury.",
                level4: "Physical exertion is a significant part of the job. Uncomfortable working positions occur. High risk of injury.",
                helper: "physical"
            },
            {
                new: "Yes",
                id: 8,
                category: 'Effort',
                //  question: 'Psykiska',
                question: 'Psychological',
                levels: 4,
                weight: 10,
                instructions: "- Emotional strain \n- Monotony \n- Availability \n- Stress",
                focus: "- How demanding the mental strain is \n- Are there frequent encounters with angry, sick, or difficult-to-handle people? \n- Requirements for availability, such as outside regular working hours, or that other tasks are often being affected \n- Strict and monotonous work that requires careful concentration can be very demanding \n- If there is a high workload and stress",
                level1: "Low demands on mental strain. Mentally challenging situations rarely occur.",
                level2: "Mentally challenging situations occur occasionally. High workload and time pressure occur periodically.",
                level3: "Mental and emotional strain occurs frequently.",
                level4: "Very high demands on mental and emotional strain over time. High demands on availability, concentration, time pressure, and challenging relationships.",
                helper: "psychological"
            },
        ],

        // Documentation
        //Tabke of contents
        tableOfCont: "Table of contents",
        tocIntro: "1. Introduction",
        tocMethod: "2. Method",
        tocSys: "2.1 System",
        tocWeight: "2.2 Weighting & Assessment",
        tocProcedure: "2.3 Procedure",
        tocCollab: "3. Cooperation",
        tocAnalyze: "4. Analysis of provisions & practices",
        tocResults: "5. Results & Analysis",
        tocEqualWork: "5.1 Equal Work",
        tocEquivalent: "5.2 Equivalent Works & Hierarchical Differences",
        tocAction: "6. Action Plan",
        tocEvaluation: "7. Evaluation",
        //Intro:
        intOne: "The purpose of this Pay Equity Analysis is to detect, address, and prevent unjustifiable pay gaps between genders.",
        intTwo: " employees are included in the Pay Equity Analysis. The total salary distribution is presented in the table below.",
        intSum: "Summary for all groups",
        intEmp: "Employees",
        intDist: "Salary distribution",
        intDiff: "Difference",
        intPart: "The following individuals have participated in the pay Equity Analysis:",
        //Method
        metOne: "The system Lönelys has been used for the Pay Equity Analysis. The system helps illustrating salary gaps within groups of employees performing equal work, between equivalent positions, and hierarchical differences.",
        metTwo: "To be able to compare equivalent works, a job evaluation has been conducted. The purpose is to make the most accurate and fair assessment of the job requirements possible. For the job evaluation we choosed to use the system ",
        metThree: `To be able to do comparisons between equivalent works, a job evaluation has been conducted. The purpose is to make the assessment of job requirements as accurate and fair as possible. The Swedish law "Diskrimineringslagen" specifies knowledge and skills, responsibility, effort, and working conditions as examples of criteria in the evaluation of a job.`,
        metFour: "We have chosen to use the weighting below for these criteria.",
        metFive: "The weighting above is the total weighting within each area. Each area is, in turn, divided into factors that are also weighted separately, contributing to the total. This is done to delve deeper and make as comprehensive and accurate an assessment of the job's requirements as possible.",
        metSix: "The factors and their weighting used for the job evaluation are described below.",
        metSeven: " The criteria and weighting below were used and assessed on a scale of 1-4, where 1 = low requirements, 2 = moderate requirements, 3 = high requirements, and 4 = very high requirements. The job evaluation has only focused on the job's requirements.",
        factor: "Factor",
        assessment: "Assessment",
        assessmentOf:"Assessment of",
        assessmentOfCons: "Consideration during assessment",
        //Procedure
        step: "Step ",
        proOne: "The salary information has been imported into the system.",
        proTwo: "The positions have been grouped into equal work. Positions that are considered to perform equal or nearly equal tasks have been assigned the same group for comparison of equal work.",
        proThree: `Grouping of equivalent works has been conducted. Equivalent works refer to works that do not perform the same tasks but still is considered to have equivalent requirements. The works have been assessed using a job evaluation, where they are divided into groups based on their job requirements. The design and basis for the assessment of the job evaluation are described in detail above under point 2.2, "Weighting & Assessment."`,
        proFour: "Gender-based salary differences has been analyzed for:",
        equalWorks: "Equal works",
        equivalentWorks: "equivalent works",
        proFive: "Which means women-dominated works that have higher job requirements but lower salaries than non-women-dominated works.",
        proSix: "Analysis of provisions & practices has also been conducted.",
        proSeven: "An actionplan, along with a cost calculation, has been created in cases where an action is necessary. Potential actions from previous year have also been evaluated.",
        //Equal
        equalOne: "Below are the results and analysis for equal work (works with equal or very similar tasks). Each group is presented with the total number of employees, gender distribution, salary spread, average salary for women and men, and salary difference. ",
        equalTwo: "Average salary is shown including and excluding benefits, and the difference for benefits only is also presented.",
        equalThree: "The spread ratio shows how large the salary spread is, where a ratio of 2 means that the highest salary is twice as high as the lowest. In cases where a gender consists of 20 employees or more, the ratio is based on the 10th and 90th percentiles, which means the salary level where 10% earn less and where 10% earn more. This is to provide a fair picture of the spread and avoid extreme values.",
        equalFour: "When conducting the analysis, in addition to the information presented below, scatterplot charts have been used to highlight individual differences. The charts have visualized all employees in each group based on salary, years in the organization",
        equalFive: "and age",
        equalSix: "When conducting the analysis, scatterplot charts have also been used to highlight individual differences. Below, these scatterplots are presented based on salary and the number of years in the organization, where blue dots represent men and green dots represent women.",
        equalSeven: "When conducting the analysis, scatterplot charts have also been used to highlight individual differences. Below, these scatterplots are presented based on salary and age, where blue dots represent men and green dots represent women.",
        equalEight: "When conducting the analysis, scatterplot charts have also been used to highlight individual differences. Below, these scatterplots are presented based on salary and age, and also salary and years in the organization, where blue dots represent men and green dots represent women.",
        equalNine: " The Y-axis shows the percentage of the total salary in the group that individuals possess.",
        //Equal Percent
        equalTen: "Below are the results and analysis for equal work (works with equal or very similar tasks). Each group is presented with the total number of employees, gender distribution, salary spread, and the percentage difference in salary for women. A negative percentage indicates how much less women earn based on men's salaries, while a positive indicates how much more.",
        equalEleven: "The salary difference is shown including and excluding benefits, and the difference for benefits only is also presented.",
        equalTwelve: "When conducting the analysis, scatterplot charts have also been used to highlight individual differences",
        equalThirteen: ". Below, scatterplots are presented based on salary and years in the organization, where blue dots represent men and green dots represent women.",
        equalFourteen: ". Below, scatterplots are presented based on salary and age, where blue dots represent men and green dots represent women.",
        equalFifteen: ". Below, scatterplots are presented based on salary and age, and also salary and years in the organization, where blue dots represent men and green dots represent women.",
        equalSixteen: "Positions that are considered to perform equal or nearly equal tasks have been grouped together to provide a fair and legal Pay Equity Analysis. The positions included in each group are listed under the group's heading.",
        equalSeventeen: "In cases where an action has been needed, these notes are also included in the action plan (section 6).",
        chartInfoOne: "Salary incl. benefits - Years in organisation",
        chartInfoTwo: "Salary incl. benefits - Age",
        chartInfoThree: "Salary excl. benefits - Years in organisation",
        chartInfoFour: "Salary excl. benefits - Age",
        chartInfoFive: "Benefits - Years in organisation",
        chartInfoSix: "Benefits - Age",
        yearInOrg: "Years in organisation",
        age: "Age",
        gapWomen: "Pay gap women",
        salInclBenefit: "Salary (incl. benefits)",
        avgSalInclBenefit: "Avg salary incl. benefits",
        avgSalExclBenefit: "Avg salary excl. benefits",
        salarySpreadInclBenefit: "Salary spread (incl. benefits)",
        equalFourFive: "When conducting the analysis, in addition to the information presented below, scatterplot charts have been used to highlight individual differences. The charts have visualized all employees in each group based on salary, years in the organization and age",
        basedOnIncluding: `* Based on average salary incl. benefits`,
        //Equivalent
        eqvOne: "Below are works with equivalent requirements. Each group contains works with equivalent requirements based on the performed job evaluation. The higher the group the higher the requirements. These groups have also been used for analyzing hierarchical differences. A yellow flag indicates that the work is female-dominated, and a blue flag indicates that the work is male-dominated. If there is no flag, it is considered gender-neutral. The limit for overrepresentation is 60%. The spread is based on percentile 10 and 90 in cases where there are 20 or more employees in the group, for smaller groups, it is based on the highest and lowest salaries.",
        eqvTwo: "Below the groups are female-dominated works with lower salaries but equal or higher requirements than non-female-dominated works presented. The percentage shows how much lower the salary is for the female-dominated work compared to the non-female-dominated work. Below this is the analysis. In cases where action has been needed, these notes are also included in the action plan (section 6).",
        eqvThree: "The analysis is presented below the groups. In cases where action has been needed, these notes are also included in the action plan (section 6).",
        eqvFour: "Below are presented the women-dominated works that have lower salaries but equal or higher requirements than non-women-dominated works. The percentage shows how much lower the salary is for the female-dominated work compared to the salary for the non-female-dominated work.",
        eqvFive: "No women-dominated works have had lower salaries than non-women-dominated works with equal or higher requirements.",
        eqvWorks: "Equivalent works",
        eqvHie: "Hierarchical differences",
        eqvOneWord: `Below are works with equivalent requirements. Each group contains works with equivalent requirements based on the performed job evaluation. The higher the group the higher the requirements. These groups have also been used for analyzing hierarchical differences. The letter "K" (yellow marked) indicates that the work is female-dominated, and the letter "M" (blue marked) indicates that the job is male-dominated. If there is no flag, it is considered gender-neutral. The limit for overrepresentation is 60%. The spread is based on percentile 10 and 90 in cases where there are 20 or more employees in the group, for smaller groups, it is based on the highest and lowest salaries.`,
      //Actionplan
        actOne: "This action plan shows actions needed to address gender-related salary differences. The actions are presented along with deadline and cost estimates.",
        actTwo: "A Pay Equity Analysis has been conducted. In the survey, no unjustified salary differences or conditions were found that had a direct or indirect connection to gender. This applies to both individual and group levels. Therefore, no actions have been considered necessary, and therefore no actions has been created for this year.",
        actThree: "Below, previous year's actions are presented along with an evaluation. The purpose is to ensure compliance with anti-discrimination laws and to ensure that the actions is implemented.",
        generalActions: "General actions",
        //Documentation other
        requirementsShort: "Req.",
        eduAndExp: "Education & Experience",
        dprob: "Problem solving",
        dsoc: "Social skills",
        dPer: "Personnel",
        dOpe: "Operations",
        dPeo: "People",
        dPhy: "Physical",
        dPsy: "Psychological",
        showMore: "Show more",
        showLess: "Show less",

        //Error messages
        textChangePop: "Please note that user-generated text is not translated.",
        errorOccuredTryAgain: "An error occurred. Please reload the page and try again.",
        wrongCode: "Wrong code",
        twoFactorActivated: "Two-factor authentication enabled!",
        errorTwoFactor: "An error occurred when trying to activate two-factor authentication.",
        somethingWrong: "Something went wrong. Please try again.",
        changesMadeSave: "You have made changes without saving. Are you sure you want to continue?",
        errorSave: "An error occurred when trying to save. Please try again.",
        noEmptyComment: `You cannot save an empty comment. Please fill in the "Notable" field.`,
        noSelectedGroupComment: "No group selected. Click on a group in the list to which the comment can be connected to. Then try again.",
        skillsMinimum: "Not allowed. At least 2 factors must exist within Skills & Competencies.",
        skillsResponsibilites: "Not allowed. At least 2 factors must exist within Responsibilities.",
        skillsEffort: "Not allowed. At least 1 factor must exist within Effort & Working Conditions.",
        cantSaveWeight: "Unable to save. The weighting must be 100%. Change the weighting and then save again.",
        cantSaveFactor: "Unable to save. The field for the factor name is empty. Enter text or remove the factor, then save again.",
        noOrgSelected: "No organization selected. First choose the organization whose factor plan should be imported, then click on import again.",
        obsImportVal: "OBS: Existing valuation scores will be deleted for factors that do not have the same name or level. Do you want to continue?",
        orgNoValPlan: "The selected organization did not have a customized factorplan.",
        weightNotH: "Unable to save. The total weighting must be 100%. Please adjust the weighting and save again.",
        selectWhatComp: "You need to select what to import and from which company.",
        noPosMissGroup: "None of the positions has en empty group.",
        noPosSelected: "You have not selected any positions.",
        noPosFound: "No corresponding groupings were found. Ensure that you have selected the correct company to import from.",
        cantImportFactor: "Cannot import. The selected organization does not use the same factor plan.",
        nothingImported: "Nothing was imported. There were no evaluations to import for the works.",
        missingInfoDoc: "Information is missing. You need to fill in the fields for Cooperation, Provisions & practices, and Participants.",
        wrongFormat: "Incorrect format. Please upload as .jpg, .jpeg, or .png.",
        maxFileSize: "The file is too large. The maximum file size is 1 MB.",
        logoError: "An error occurred. Please try to upload the logo again.",

        // AI analysis
        AIHigherReq: "have higher salary due to higher work requirements.",
        AINotNeeded: "Does not need to be analyzed.",
        AIwomenHigherSal: "is women-dominated and have a higher salary.",
        AIwomenHigherSalLowerReq: "is women-dominated and have higher salary despite lower work requirements.",
        AIwomehHigherSalHigherReq: "is women-dominated, have higher requirements, but also higher salary.",
        AIsameReqButHigherSal: "Have same requirements but higher salary than",
        AIisWomenDominated: "which is women-dominated.",
        AImoreSalaryThan: "have higher salary than",
        AIwomenDominatedHigherReq: "which is women-dominated and have higher requirements.",
        AINoReason: "Could not find a justified reason for the salary difference.",
        AIExplainedBy: "The salary difference is explained by the fact that the employees ",
        AIPartlyExplainedBy: "The salary difference is partly explained by the fact that the employees ",
        AILongerInOrg: "have worked longer in the organization.",
        AIBiggerCity: "work in a larger city where salaries are generally higher.",
        AIOlder: "are older and bring more experience from previous roles.",
        AIExplained: "The salary difference is explained by the fact that",
        AIhigherReq: "have higher requirements in their work.",
        AIExplainedPartly: "The salary difference is partly explained by the fact that",
        AILongerOrgAnd: "have worked longer in the organization and ",
        AICityAnd: "work in a larger city where salaries are generally higher and ",
        AIHigherReqAnd: "have higher job requirements, and that the employees",
        AICanBeExplained: "Higher salary can be explained by the fact that ",
        AICanBeExplainedPartly: "Higher salary can partly be explained by the fact that ",
        AINotAutoExplained: "Higher salary cannot be automatically explained. ",
        AIHowever: "However, some reasons were found, such as that ",
        AISalToBig: "It is, however, not enough to automatically explain the salary difference. Manual review is recommended.",
        AInothingFound: "Did not find enough differences to explain the cause of the salary difference. Review manually.",

        AIHasHigherReqs: "have higher requirements in their work. ",
        AIHasHigherReqsAnd: "have higher requirements in their work, and ",
        AIHasHigherReqsBut: "have higher job requirements and should thus have a higher salary, but the salary difference can likely be explained by the fact that ",
        AIEmployeesWorkedLonger: "the employees have been working longer in the organization",
        AItheGroup: "The group",
        AItheGroupSmall: "the group",
        AiExpOpposite: "have more experience, but the salary differences can be explained by the fact that ",

        AIbiggerCityOne: "the employees work in a larger city (",
        AIbiggerCityTwo: ") where salaries and living expenses are higher",
        AIbiggerCityOneVerTwo: ". They also work in a bigger city (",
        AIbiggerCityOneVerThree: "They also work in a bigger city (",
        AIolderVerTwo: "the employees are older, indicating that they bring more experience from previous roles.<age>",
        AIolderVerThree: " along with that they are older, indicating that they bring more experience from previous roles <age>",
        AIhighReqShouldBigger: "have higher job requirements and should have a higher salary than",
        AIreasonPart: "The reason can be partially explained by the fact that ",
        AIhaveHigherReq: "have higher job requirements and should be the group with a higher salary. Review manually.",
        AItoLarge: "There are some differences, but the salary difference is to large. Review manually",
        AInotExplained: " The work requirements, however, are substantial, and there are likely other factors influencing the difference. Review manually. ",
        AIalsoNote: "Also note that ",
        AIisOlderGeneral: "are older, indicating that they bring more experience from previous roles.<age>",
        AIageAlsoHigher: "The average age is also higher, indicating that they bring more experience from previous roles. <age>",
        AImajority: "the majority in the group",
        AIBCityOne: "work in a bigger city (",
        AIBCityHigher: ") where salaries are often higher.",
        AIBCityHigherAlso: "They also work in a bigger city (",
        AIEvenMore: "have more experience, which makes it even more difficult to find a valid reason for the salary difference.",
        AIEmore: "have more experience, which makes it more difficult to find a valid reason for the salary difference.",
        AIEmoreTwo: ", however, have more experience, which makes it more difficult to find a valid reason for the salary difference.",
        AIMainReason: "The main reason for the salary difference is likely that",
        AImainReasonInfo: "have higher job requirements. However, the salary difference is very big, therefore a manual review is recommended.",
        AinoteMaj: "However, note that the majority in the group ",
        AIolderFour: "Also, they are older, indicating they bring more experience from previous roles. <age>",
        AIolderFive: "is, however, older, indicating that they bring more experience from previous roles.<age>",
        AImoreExpLowSal: "has more experience and lower salary.",
        AIalsobiggerCity: "Also, the majority in the group work in a bigger city, which should result in slightly higher salaries.",
        AIbiggerAgeInfo: "has a higher average age, indicating that they collectively possess more experience. <age>",
        AIbiggerAgeInfoTwo: "Also the average age is higher, indicating they bring more experience from previous roles.<age>",
        AIHigReqLowSalHowever: ", however, have higher requirements but lower salary.",
        AihighShouldBiggerBut: "has significantly higher job requirements and should be the group with the higher salary.",
        AihighShouldBiggerButTwo: "however, has worked significantly longer in the organization, which may explaion the salary difference.",
        AihighShouldBiggerButThree: "has significantly higher job requirements and should be the group with the higher salary. There are differences but since the requirements differ significantly the reasons should be reviewed manually.",
        AihighShouldBiggerButFour: "has significantly higher job requirements and should be the group with the higher salary. Review manually.",
        AihighShouldBiggerButFive: "has significantly higher job requirements, so it's reasonable for the salary to be higher.",
        AIdetailsOne: "Salary differences exist, however,",
        AIdetailsTwo: "consists of only 3 employees, with 1 being male. This results in a high percentage of women, but the low number of individuals can make it challenging to justify gender as the cause.",
        AIdetailsThree: "is women-dominated and has a lower salary than",
        AIdetailsFour: ", which is not women-dominated.",
        Aibecause: "Because",
        AIdetailsOneTwo: "only consists of 3 employees, with 1 being male, this results in a high percentage of women. However, the low number of individuals can make it challenging to justify gender as the cause.",
        AIwomenHigherAll: "The women-dominated group has more experience, has higher age, and works in a bigger city where salaries are generally higher.<age>",
        AIwomenHigherTwo: "The women-dominated group has worked longer in the organization and has higher age.<age>",
        AIwomenHigherThree: "The women-dominated group has more experience and works in a larger city where salaries are usually higher.",
        AIwomenHigherFour: "The women-dominated group has a higher age and works in a larger city where salaries are usually higher.<age>",
        AIwomenHigherFive: "Note that the women-dominated group works in a larger city where salaries are usually higher.",
        AIwomenHigherSix: "Note that the women-dominated group has longer experience.",
        AIwomenHigherSeven: "Note that the women-dominated group has a higher average age. <age>",
        AInoNeedOne: "Both groups are dominated by women and do not need to be compared.",
        AInoNeedTwo: "Both groups are dominated by men and do not need to be compared.",
        AInoNeedThree: "No group is dominated by any gender and thus do not need to be compared.",
        AInoNeedFour: "No women-dominated group selected.",
        AImultiOne: "Also note that the majority in the group",
        AInotForBenOnly: "Auto-analysis can not be used for benefits only.",

        //Chart
        name: "Name",
        yInOrg: "Years in org",
        city: "City",
        positionShort: "Pos",
        excluded: "(excluded)",
        yearInOrgShorten: "Org. year",
        ageXinfo: "Set x-axis to age.",
        yearInOrgXinfo: "Set x-axis to years in organisation.",
        zoomOut: "Zoom out",
        fullscreen: "Fullscreen",
        iconInclSal: "Shows salary incl. benefits",
        iconExclSal: "Shows salary excl. benefits",
        iconOnlyBen: "Shows only benefits excl. salary",
        exclBenVeryShort: "Excl ben",
        onlyBenVeryShort: "Only ben",

        //CompanyView
        consultantNoAccess: "The consultant no longer has access to your company",
        adminNotDelete: "Admin users cannot be deleted.",
        cantDeleteOwn: "You cannot delete your own user.",
        userRemoved: "User deleted",
        sureDeleteUser: "Are you sure you want to delete this user?",
        deletedUserNotComp: "The user will be deleted, but data created by the user will be retained. Delete the company as well to remove all data.",
        removeConsultantAccess: "Do you want to remove the consultant's access?",
        consultantNoAccessSelect: "The consultant will no longer have access to your account. You cannot undo this action.",
        users: "Users",
        yourUser: "Your user",
        allUsers: "All users",
        company: "Company",
        email: "Email",
        role: "Role",
        newUser: "Do you need to create a new company or user?",
        contactUs: "Contact us",
        cantDeleteOwnComp: "You cannot delete the company you currently represent. First, select another company and then try again.",
        isDeleted: "is deleted.",
        deleteComp: "Are you sure you want to remove this company?",
        deleteCompData: "All data associated with this company will be deleted. You cannot undo this action.",
        youRepresent: "You represent",
        chooseCompRepresent: "Select what company you want to represent",
        showAllCompBelongingTo: "Showing all companies belonging to: ",
        removeAccesOwnComp: "You cannot remove access to your own company.",
        removeAccessToThisComp: "Remove access to this company?",
        consultRemoveOwnAccess: "Your consultant account, including all its users, will lose access to this company. No data will be deleted, and the company's own users can still access the company via its own account.",
        consultRemoveOwnAccessGroup: "This is a company group. Do you want to remove access for the entire group or just for the selected company?",
        chooseC: "The selected group",
        chooseG: "The entire group",

        //Login
        forgotRobotCheckbox: "You forgot to check the box 'I'm not a robot'. Check it and log in again.",
        notRobot: `Please check the box "I am not a robot".`,
        wrongRecaptcha: `Invalid reCaptcha. Please redo "I am not a robot".`,
        resetPass: "Reset password",
        addUsernameToReset: "Please fill in your username below to reset your password.",
        emailSent: "An email with a link has been sent to your user. Click on the link to set your new password.",
        checkFolder: "Check your spam folder if you don't see the email.",
        openGoogleAuth: `Open the "Google Authenticator" app on your mobile phone and enter the code you see in the app.`,
        contactIfNeeded: "If you have lost your mobile phone or encounter other issues when trying to login, please contact us and we will help you out.",
        forgotPass: "Forgot password?",
        login: "Login",
        wrongUserOrPass: "Wrong username or password.",
        username: "Username",
        password: "Password",
        openAuthAndScan: "Open the 'Google Authenticator' app on your mobile phone and scan the QR code above.",
        enterCodeFromApp: "Enter the code that shows up in the app.",
        ifNoApp: "If you don't have the app, you can download it ",
        dHere: "here.",

        //overview
        overviewOrg: "Organization overview",
        youNeedToImport: "You need to import data before you can view any data. You can do this in step 1 in the menu on the left.",
        showAllStatistics: "Show all statistics",
        internalReport: "Internal Report - EU Directive",
        authReport: "Report to Authorities - EU Directive",
        avgTimeInOrg: "Average length of service",
        avgAgeLong: "Average age",
        years: "yrs",
        genderDis: "Gender distribution",
        genderDominant: "Gender-dominant groups",
        beforeStatShow: "All equal works must be grouped before this statistics can be displayed. This is done in step 2.1.",
        proportionOfGender: "Proportion of genders in each quartile pay band",
        lowerQ: "Lower quartile",
        lowMidQ: "Lower middle quartile",
        higMidQ: "Higher middle quartile",
        highQ: "Higher quartile",
        payG: "Pay gap",
        average: "Average",
        median: "Median",
        benefitDiff: "Benefit difference",
        hasBenefits: "Receives benefits",
        nrOfMen: "Percent of men",
        nrOfWomen: "Percent of women",
        payGapCategory: "Show pay gap per category of workers",
        detailedAnaInfo: "Above shows the gender pay gap for works with equivalent requirements. Detailed analysis can be performed in the system.",
        genderEqual: "Equal",

        //Other
        importedFplan: "Factor plan imported.",
    },
};

export default lang;