import React from 'react';
import './Import.css';
import { Menu } from '../Menu.js';
import { TopNav } from '../TopNav.js';
import { Information } from '../Information/Information.js';
import uploadIcon from '../../assets/images/upload-icon.png';
import settingsIcon from '../../assets/images/Settings.png';
import { fetchAuthUpload } from '../../fetch-auth';
import { fetchAuth } from '../../fetch-auth';
import lang from '../Language/language.js';

export class Import extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadStatus: 'noUpload',
            errorMessage: '',
            allErrors: [],
            //For import settngs:
            settingsPop: false,
            selectedRadio: "none",
            importDateSelected: "standard",
            freeDate: "standard",
            importedDates: [],
            selectedYear: "now",
            dateToSend: "standard", //shows "standard", "" or specific date
            importedDatesStatus: "",
            showImportSetting: "off",
            //
            benchActive: false
        }
    }

    //Upload function
    upload = (file) => {
        //First, check if we import data for the current year or past years.
        const selectedYear = localStorage.getItem("year")
        const thisYear = new Date().getFullYear()
        const lastYear = thisYear - 1
        const twoYearsAgo = thisYear - 2
        const currentYearSelected = localStorage.getItem("currentYearSelected") //This is "Yes" or "No"
        let useYear = "Now"
        if (selectedYear !== null && currentYearSelected === "No") {
            if (selectedYear == lastYear) { useYear = lastYear }
            if (selectedYear == twoYearsAgo) { useYear = twoYearsAgo }
        }

        //Then send file and year info
        const data = new FormData()
        data.append('file', file)
        data.append('user', 'hubot')
        data.append('useYear', useYear)
        data.append('importDate', this.state.dateToSend) //This can say "standard" or a specific date

        fetchAuthUpload('api/uploadExcelData', 'POST', data)
            .then(
                response => response.json()
            ).then(
                message => {
                    let language = localStorage.getItem('language') ?? 'sv';
                    if (message === 'ConsultOnSelf') {
                        this.setState({
                            uploadStatus: 'failed',
                            errorMessage: language === "sv" ? 'Ni kan inte utföra lönekartläggning åt denna organisation. Välj ett annat bolag i menyn uppe till höger under konto. ' : lang[language].importNotThisComp
                        })
                    }
                    if (message === 'Invalid fileType') {
                        this.setState({
                            uploadStatus: 'failed',
                            errorMessage: language === "sv" ? 'Felaktigt format. Ladda upp filen i formatet ".xlsx". Använd spara som när ni sparar er excelfil och välj sedan ".xlsx" eller "Excel Workbook".' : lang[language].importWrongFormat 
                        })
                    }
                    if (message === 'File is too Large') {
                        this.setState({
                            uploadStatus: 'failed',
                            errorMessage: language === "sv" ? 'För stor fil. Filen kan max vara 20mb.' : lang[language].tooBigFile
                        })
                    }
                    if (message === 'Error occurred') {
                        this.setState({
                            uploadStatus: 'failed',
                        })
                    }
                    if (message[0] === 'No name') {
                        if (message[1].length > 1) {
                            this.setState({
                                uploadStatus: 'failed',
                                errorMessage: language === "sv" ? `${message[1].length} personer har fel format eller tomma fält. Se mer info nedan.` : `${message[1].length}${lang[language].importPersonsErr}`,
                                allErrors: message[1]
                            })
                        } else {
                            this.setState({
                                uploadStatus: 'failed',
                                errorMessage: language === "sv" ? 'En eller flera anställda har inte något namn ifyllt i excelfilen. Öppna er excelfil och säkerställ att alla anställda har namn ifyllt.' : lang[language].noName
                            })
                        }
                    }
                    if (message[0] === 'Wrong persNr') {
                        if (message[2].length > 1) {
                            this.setState({
                                uploadStatus: 'failed',
                                errorMessage: language === "sv" ? `${message[2].length} personer har fel format eller tomma fält. Se mer info nedan.` : `${message[2].length}${lang[language].importPersonsErr}`,
                                allErrors: message[2]
                            })
                        } else {
                            this.setState({
                                uploadStatus: 'failed',
                                errorMessage: language === "sv" ? `Fel hittades för ${message[1]}. Personnumret är tomt eller i felaktigt format. Personnummer kan lämnas tomt men då måste kön och ålder fyllas i. Öppna er excelfil och säkerställ att personnumret är enligt formatet: "YYYYMMDD-NNNN" (inkl bindestreck), alternativt ange ålder och kön istället. Om kön och ålder anges, säkerställ så att kön skrivs som "Man" eller "Woman" samt att ålder enbart består av siffror.` : `${lang[language].errorFoundFor} ${message[1]}. ${lang[language].socialSecurityError}`,
                                allErrors: message[2]
                            })
                        }
                    }
                    if (message[0] === 'No salary') {
                        if (message[2].length > 1) {
                            this.setState({
                                uploadStatus: 'failed',
                                errorMessage: language === "sv" ? `${message[2].length} personer har fel format eller tomma fält. Se mer info nedan.` : `${message[2].length}${lang[language].importPersonsErr}`,
                                allErrors: message[2]
                            })
                        } else {
                            this.setState({
                                uploadStatus: 'failed',
                                errorMessage: language === "sv" ? `Fel hittades för ${message[1]}. Lön är tomt eller i felaktigt format. Öppna er excelfil och säkerställ att lönen anges med bara siffror.` : `${lang[language].errorFoundFor} ${message[1]}. ${lang[language].salaryError}`,
                            })
                        }
                    }
                    if (message[0] === 'No position') {
                        if (message[2].length > 1) {
                            this.setState({
                                uploadStatus: 'failed',
                                errorMessage:language === "sv" ? `${message[2].length} personer har fel format eller tomma fält. Se mer info nedan.` : `${message[2].length}${lang[language].importPersonsErr}`,
                                allErrors: message[2]
                            })
                        } else {
                            this.setState({
                                uploadStatus: 'failed',
                                errorMessage: language === "sv" ? `Befattning är ej ifyllt för ${message[1]}. Öppna er excelfil och fyll i befattning under rubriken "position".` : `${lang[language].positionError} ${message[1]}.`
                            })
                        }
                    }
                    if (message[0] === 'No startDate') {
                        if (message[2].length > 1) {
                            this.setState({
                                uploadStatus: 'failed',
                                errorMessage: language === "sv" ? `${message[2].length} personer har fel format eller tomma fält. Se mer info nedan.` : `${message[2].length}${lang[language].importPersonsErr}`,
                                allErrors: message[2]
                            })
                        } else {
                            this.setState({
                                uploadStatus: 'failed',
                                errorMessage: language === "sv" ? `Fel hittades för ${message[1]}. Startdatum är tomt eller i felaktigt format. Öppna er excelfil och säkerställ att startdatum anges i formatet "YYYY-MM-DD".` : `${lang[language].errorFoundFor} ${message[1]}. ${lang[language].startDateError}`,
                            })
                        }
                    }
                    if (message[0] === 'wrong extrafield') {
                        if (message[2].length > 1) {
                            this.setState({
                                uploadStatus: 'failed',
                                errorMessage: language === "sv" ? `${message[2].length} personer har fel format eller tomma fält. Se mer info nedan.` : `${message[2].length}${lang[language].importPersonsErr}`,
                                allErrors: message[2]
                            })
                        } else {
                            this.setState({
                                uploadStatus: 'failed',
                                errorMessage: language === "sv" ? `Fel hittades för ${message[1]}. Fältet "extra" får enbart innehålla siffror. Öppna er excelfil och justera. Om "kr" läggs till automatiskt i excelfilen beror detta på att det är i formatet "valuta". Ändra då formatet till "allmänt" istället.` : `${lang[language].errorFoundFor} ${message[1]}. ${lang[language].extraFieldError}`,
                            })
                        }
                    }
                    if (message[0] === 'No city') {
                        if (message[2].length > 1) {
                            this.setState({
                                uploadStatus: 'failed',
                                errorMessage: language === "sv" ? `${message[2].length} personer har fel format eller tomma fält. Se mer info nedan.` : `${message[2].length}${lang[language].importPersonsErr}`,
                                allErrors: message[2]
                            })
                        } else {
                            this.setState({
                                uploadStatus: 'failed',
                                errorMessage: language === "sv" ? `Fel hittades för ${message[1]}. Fältet "stad" är tomt. Öppna er excelfil och justera.` : `${lang[language].errorFoundFor} ${message[1]}. ${lang[language].cityError}`,
                            })
                        }
                    }
                    if (message[0] === 'Maxed additional') {
                        if (message[2].length > 1) {
                            this.setState({
                                uploadStatus: 'failed',
                                errorMessage: language === "sv" ? `${message[2].length} personer har fel format eller tomma fält. Se mer info nedan.` : `${message[2].length}${lang[language].importPersonsErr}`,
                                allErrors: message[2]
                            })
                        } else {
                            this.setState({
                                uploadStatus: 'failed',
                                errorMessage: language === "sv" ? `Fel hittades för ${message[1]}. Fältet "additional" innehåller för många tecken. Max antal tecken är 80.` : `${lang[language].errorFoundFor} ${message[1]}. ${lang[language].additionalFieldError}`,
                            })
                        }
                    }
                    if (message === 'Upload success!') {
                        this.setState({
                            uploadStatus: 'success',
                        })
                    }
                }
            );
    };

    //send file to server
    fileToServer = event => {

        if (event.target.files[0]) {
            this.upload(event.target.files[0]) //Calls upload function above. Uploads file to server
        }
    }

    refreshPage = () => { window.location.reload() }

    //settingsPopUp and radio selection
    settingsPop = () => {
        this.state.settingsPop ? this.setState({ settingsPop: false }) : this.setState({ settingsPop: true }) //Toggle popUp
        //Get previous import dates
        if (this.state.importedDates.length < 1 && this.state.importedDatesStatus !== "noDates") {
            const getimportedDates = async () => {
                const response = await fetchAuth(`/api/loadImportedDates`, 'POST');
                let data = await response.json();
                let dates = data[0]
                if (dates.length < 1) { this.setState({ importedDates: [1, 2], importedDatesStatus: "noDates" }) } //makes sure we do not load this again
                else { //we set the dates if there is any
                    this.setState({ importedDates: dates, importedDatesStatus: "" })
                  //  for (let i = 0; i < dates.length; i++) { dates[i].importDateString =  dates[i].importDate.slice(0,10) } //Make date 10 digits (date without time)
                   // this.setState({ importedDates: dates, importedDatesStatus: "" })
                }
            }
            getimportedDates();
        }
    }
    radioSelected = event => {
        this.setState({ selectedRadio: event.target.id, dateToSend: "standard" })
        if (event.target.id === "importDate") { this.setState({ dateToSend: this.state.importDateSelected }) }
        if (event.target.id === "freeDate") { this.setState({ dateToSend: this.state.freeDate }) }
    }
    //import date selection
    changeImportDate = (event) => {
        let importDateSelected = event.target.value
        this.setState({ importDateSelected: importDateSelected, dateToSend: importDateSelected })
    }
    //Free date selection
    changeFreeDate = event => {
        let freeDate = event.target.value
        this.setState({ freeDate: freeDate, dateToSend: freeDate })
    }

    componentDidMount() {
        const selectedYear = localStorage.getItem("year")
        const currentYearSelected = localStorage.getItem("currentYearSelected")
        let useYear = "now"
        if (selectedYear !== null && currentYearSelected === "No") { useYear = selectedYear }
        this.setState({ selectedYear: useYear })
        //Get if should show import settings or not
        const getImportSetting = async () => {
            const response = await fetchAuth(`/api/loadImportSetting`, 'POST');
            let data = await response.json();
            let importSetting = data[0]
            if (importSetting != undefined) {
                if (importSetting.length > 0) {
                    let showImportSetting = importSetting[0].showImportSetting
                    if (showImportSetting === "on" || showImportSetting === "adminOn") { this.setState({ showImportSetting: "on" }) }
                    let benchActive = importSetting[0].showSalBench
                    if (benchActive === "on" || benchActive === "adminOn") { this.setState({ benchActive: true }) }
                }
            }
        }
        getImportSetting();
    }

    render() {
        let errorList = ""
        if (this.state.allErrors.length > 0) {
            errorList = this.state.allErrors.map((item) => {
                return <tr>
                    <td style={{ border: "1px solid", textAlign: "left" }}>{item.name}</td>
                    <td style={{ border: "1px solid", textAlign: "left" }}><ul>{item.errors.map((errors) => { return (<li>- {errors}</li>) })}</ul></td>
                </tr>
            })
        }

        let importedDates = <option key="123abc" value={""}>{""}</option>
        if (this.state.importedDates.length > 0 && this.state.importedDatesStatus !== "noDates") {
            importedDates = this.state.importedDates.map((item) => {
                return <option key={item.importDateString} value={item.importDateString}>{item.importDateString}</option>
            })
        }
        let language = localStorage.getItem('language') ?? 'sv';
        return (
            <>
                <TopNav />
                <Menu />
                <div className="container">

                    <Information step="import" benchActive={this.state.benchActive}/>

                    <div className="background-div" style={{ paddingTop: 100, paddingBottom: 100, marginBottom: 40 }}>
                        <div className={this.state.uploadStatus === "success" ? "displayImportInfo" : "hideImportInfo"}>
                            <div className="importOK">&#10004;</div>
                            <span className="spanOK">{language === "sv" ? "Data importerad!" : lang[language].dataImported}</span>
                            <span className="popUpSuccess" style={{ display: "block", marginBottom: 80 }}>{language === "sv" ? "Nu är ni redo att påbörja er kartläggning. Gå vidare till steg 2 i menyn." : lang[language].nowYouAreReady}</span>
                        </div>

                        <div className={this.state.uploadStatus === "failed" ? "displayImportInfo" : "hideImportInfo"}>
                            <div className="importOK" style={{ background: "#cb5656", boxShadow: "none" }}>&#10006;</div>
                            <span className="spanOK">{language === "sv" ? "Ett fel inträffade." : lang[language].importError}</span>
                            <span style={{ display: "block", margin: "auto", maxWidth: 700, marginBottom: 60 }}>{this.state.errorMessage}</span>
                            <span style={{ color: "#35b292", background: "#56cbad", color: "white", padding: 12, borderRadius: 8, fontWeight: 900, cursor: "pointer" }} onClick={this.refreshPage}>{language === "sv" ? "Prova igen" : lang[language].tryAgain}</span>
                            {/*Table with errors */}
                            <table className={this.state.allErrors.length > 0 ? "" : "hide"} style={{ margin: "auto", minWidth: 580, color: "#333333", marginTop: 80 }}>
                                <tr>
                                    <th style={{ border: "1px solid", width: 290, textAlign: "left" }}>{language === "sv" ? "Namn" : lang[language].name}</th>
                                    <th style={{ border: "1px solid", width: 290, textAlign: "left" }}>{language === "sv" ? "Fält som innehåller fel" : lang[language].containingErrors}</th>
                                </tr>
                                {errorList}
                            </table>
                        </div>

                        <img className={this.state.uploadStatus === "noUpload" ? "logoUpload-default" : "hideImportInfo"} style={{ paddingTop: 0 }} src={uploadIcon} />
                        <label htmlFor="files" className={this.state.uploadStatus === "noUpload" ? "upload-button upload-button-smallScreen" : "hideImportInfo"} style={{ display: "block", width: 110, margin: "auto" }}>{language === "sv" ? "IMPORTERA" : lang[language].import}</label>
                        <div onClick={this.settingsPop} className={this.state.uploadStatus !== "noUpload" || this.state.showImportSetting === "off" ? "hide" : "settingsPopUpload"}>
                            <img src={settingsIcon} style={{
                                filter: "brightness(0.48)",
                                width: 12,
                                verticalAlign: "top",
                                marginTop: 2.5,
                                marginRight: 4
                            }} />{language === "sv" ? "Inställningar" : lang[language].settings}</div>

                        <input name="logo" id="files" style={{ display: "none" }} type="file" onChange={this.fileToServer} />

                        <div className={this.state.settingsPop && this.state.uploadStatus === "noUpload" ? "settingsPopImport" : "hide"}>
                            <p className="importSettingsTitle">{language === "sv" ? "Beräkna år i organisationen och ålder baserat på:" : lang[language].importSettingTitle}</p>
                            <div>
                                <div><input type="radio" id="today" name="today" onChange={this.radioSelected} className={this.state.selectedRadio === "today" || this.state.selectedRadio === "none" ? "chartSelected" : "radioChart"} checked={this.state.selectedRadio === "today"} />
                                 {/*   <label className="checkBoxTypeLabel" for="today">{this.state.selectedYear === "now" ? "Dagens datum - Standard" : "Slutet av året " + "(" + this.state.selectedYear + ")" + " - Standard"}<p className={this.state.selectedRadio === "today" || this.state.selectedRadio === "none" ? "settingsInfoImport" : "hide"}>{this.state.selectedYear === "now" ? "År i organisationen och ålder beräknas på dagens datum. Lämpligt i de flesta fall." : "När du valt ett tidigare år beräknas år i organisationen och ålder baserat på slutet av året."}</p></label>*/}
                                 <label className="checkBoxTypeLabel" for="today">{this.state.selectedYear === "now" ? language === "sv" ? "Dagens datum - Standard" : lang[language].importToday : language === "sv" ? "Slutet av året " + "(" + this.state.selectedYear + ")" + " - Standard" : lang[language].endOfYear + "(" + this.state.selectedYear + ")" + " - Standard"}<p className={this.state.selectedRadio === "today" || this.state.selectedRadio === "none" ? "settingsInfoImport" : "hide"}>{this.state.selectedYear === "now" ? language === "sv" ?  "År i organisationen och ålder beräknas på dagens datum. Lämpligt i de flesta fall." : lang[language].importTodayDetails : language === "sv" ?  "När du valt ett tidigare år beräknas år i organisationen och ålder baserat på slutet av året." : lang[language].importEndOfYear}</p></label>
                                </div>
                                <div><input type="radio" id="importDate" name="importDate" onChange={this.radioSelected} className={this.state.selectedRadio === "importDate" ? "chartSelected" : "radioChart"} checked={this.state.selectedRadio === "importDate"} />
                                    <label className="checkBoxTypeLabel" for="importDate">{language === "sv" ? "Tidigare importdatum" : lang[language].importPrevious}<p className={this.state.selectedRadio === "importDate" ? "settingsInfoImport" : "hide"}>{language === "sv" ? "Välj samma datum som en av era tidigare importer. Lämpligt om ni vill att datan ska visa exakt samma år och ålder som vid det valda importdatumet." : lang[language].importPreviousDetails} <p>
                                        <select value={this.state.importDateSelected} onChange={this.changeImportDate} name="importDateSelected" id="importDateSelected">
                                            <option value="Test" hidden>{language === "sv" ? "Välj datum" : lang[language].importChooseDate}</option>
                                            {importedDates}
                                        </select>
                                    </p></p></label></div>
                                <div><input type="radio" id="freeDate" name="freeDate" onChange={this.radioSelected} className={this.state.selectedRadio === "freeDate" ? "chartSelected" : "radioChart"} checked={this.state.selectedRadio === "freeDate"} />
                                    <label className="checkBoxTypeLabel" for="freeDate">{language === "sv" ? "Eget datum" : lang[language].importCustom}<p className={this.state.selectedRadio === "freeDate" ? "settingsInfoImport" : "hide"}>{language === "sv" ? "Välj ett eget datum som år i organisationen och ålder ska beräknas på." : lang[language].importCustomDetails}<p>
                                        <input type="date" value={this.state.freeDate} onChange={this.changeFreeDate} name="freeDateSelection" id="freeDateSelection" />
                                    </p></p></label></div>
                                <p className="selectionInfoText">{language === "sv" ? "Notera att ovanstående inställning är tillfällig och återställs till standardinställningen när du byter steg i menyn till vänster." : lang[language].importNote}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>);
    }
}

