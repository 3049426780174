import React from 'react';
import './Likvardiga.css';
import { Menu } from '../Menu.js';
import { TopNav } from '../TopNav.js';
import { Information } from '../Information/Information.js';
import blueFlag from '../../assets/images/blue-flag.png';
import yellowFlag from '../../assets/images/yellow-flag.png';
import remove from '../../assets/images/x-mark.png';
import arrowDown from '../../assets/images/arrow-down-black.png';
import TextareaAutosize from 'react-textarea-autosize'; //For textareas to autosize
import { fetchAuth } from '../../fetch-auth';
import Chart from "react-apexcharts";
import { autoAnalysis } from './autoAnalysis';
import exitImg from '../../assets/images/exit.png';
import down from '../../assets/images/down.png';
import up from '../../assets/images/up.png';
import fullscreen from '../../assets/images/fullscreen.png';
import zoomOut from '../../assets/images/zoom-out.png';
import zoomIn from '../../assets/images/icon-zoom.png';
import { jitter } from '../AnalysLika/Jitter';
import ReactTooltip from 'react-tooltip';
import { Prompt } from 'react-router'
import percentile from "percentile";
import extraOn from '../../assets/images/extraOff.png';
import extraOff from '../../assets/images/extraOffKryss.png';
import bigText from '../../assets/images/bigText.png';
import eyeOn from '../../assets/images/eyeOn.png';
import eyeOff from '../../assets/images/eyeOff.png';
import lang from '../Language/language.js';

export class LikvardigaExt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataByGroup: [],
            group1: [],
            group2: [],
            comments: [],
            commentsLastYear: [],
            analysis: "all",
            deletedComments: [],
            allComments: [],
            selectedYear: 0,
            year: 0,
            showOldComments: true,
            saveButtonStatus: "Sparat",
            moreFields: false,
            clickedGroup: "",
            avgSalary: null,
            womenPercent: null,
            demands: null,
            showInfoIcon: false,
            nrOfEqualentGroups: 6,
            //Below is for AIComment
            showAIComment: false,
            AInotable: "",
            AIreason: "",
            AIAnalys: "",
            //Below is for adding completely new comment
            addComment: "",
            addReason: "",
            addTodo: "",
            addDeadline: "",
            addCost: "",
            addResponsible: "",
            addAnalysis: "likvärdiga",
            //Below is for autoAnalysis
            divOpened: "first",
            analyseGroup1: "Välj grupp",
            group1EqualGroup: 0,
            selectGroup1: false, //when true, we can click to select group 1
            analyseGroup2: "Välj grupp",
            group2EqualGroup: 0,
            selectGroup2: false,
            allEmp: [],
            tableInfo: [{ oneNrOfEmp: "" }],
            analysisText: "Inga grupper valda",
            //For popups:
            popUp: false,
            popUpComments: false,
            popUpInfo: false,
            popUpEditSalarySetting: false,
            //below is for showing salaries as percent
            showPercent: false,
            hideName: false,
            //If chart is edited
            groupOneEdited: [],
            groupTwoEdited: [],
            clickedPerson: "",
            clickedPersonIndex: "",
            serieIndex: "",
            popItUp: false,
            newSalary: 0,
            showChangesPrompt: "Yes", //<-- For changes PopUp
            showDiffPercent: false,
            //Below is for making text big or small
            bigTextIcon: true,
            textBig: [],
            //Below is for extra vs salary
            hideExtra: "includeExtra", //"includeExtra", "hideExtra", "onlyExtra"
            showHideExtraIcon: "off",
            //Below is for selecting external vs edited groups
            chosenEqualentGroup: "editedEqualentGroup",
            //below is for EU
            showWhat: "Original",
            analysisOpen: "false",
            infoBoxEU: false,
            filteredData: [],
            filteredOut: [],
            seriesBigFilteredOut: [],
            annotationsFilteredOut: [],
            gapPercent: false,
            payGapPerGroup: [],
            // Below is for EU version
            seriesBig: [{
                name: "",
                data: []
            },
            {
                name: "",
                data: []
            }
            ],
            optionsBig: {
                annotations: {
                    points: []
                },
                colors: ['#ffffff', 'transparent'],
                fill: {
                    colors: ['#ffffff', 'transparent']
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false
                },
                grid: {
                    show: true,
                    borderColor: '#e1e0e0',
                },
                tooltip: {
                    custom: function ({ seriesBig, seriesIndex, dataPointIndex, w }) {
                        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                        return `<ul>
                            <li style="color: #333333"><b>Namn</b>: ${data.fullName}</li>
                            <li style="color: #333333"><b>Lön</b>: ${data.y} kr</li>
                            <li style="color: #333333"><b>År i org</b>: ${data.experience}</li>
                            <li style="color: #333333"><b>Ålder</b>: ${data.age}</li>
                            <li style="color: #333333">${data.groupNameArbVardering}</li>
                            </ul>`
                    },
                },
                chart: {
                    height: 350,
                    type: 'scatter',
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false
                    },
                },
                markers: {
                    size: 6,
                    strokeWidth: 0,
                    hover: {
                        size: 6
                    },
                    fillOpacity: 0,
                },
                xaxis: {
                    tickAmount: 5,
                    type: 'numeric',
                    //max: longestExp,
                    //min: 0,
                    ticks: {
                        includeBounds: true,
                    },
                    labels: {
                        formatter: function (val) {
                            return parseFloat(val).toFixed(0)
                        }
                    },
                    //floating: true,
                    axisTicks: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    },
                    labels: {
                        show: true,
                        style: {
                            colors: '#44484a'
                        }
                    },
                },
                yaxis: {
                    tickAmount: 0,
                    labels: {
                        formatter: function (val) {
                            return parseFloat(val).toFixed(0)
                        },
                    },
                    //floating: true,
                    axisTicks: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    },
                    labels: {
                        show: true,
                        /*hover: {*/
                        style: {
                            colors: '#44484a',
                            /*    }*/
                        },
                    },
                },
            },
            //Below is for chart
            xAxisType: "experience",
            xSetting: false,
            chartWidth: "300",
            chartHeight: "255",
            apexPopUp: "",
            longestExp: 0,
            series: [{
                name: "",
                data: []
            },
            {
                name: "",
                data: []
            }
            ],
            options: {
                colors: ['#ffffff', 'transparent'],
                fill: {
                    colors: ['#ffffff', 'transparent']
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false
                },
                grid: {
                    show: false
                },
                /* toolbar: {
                     show: false,
                 },*/


                //fill:['white', 'blue'],
                tooltip: {
                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

                        return `<ul>
                            <li style="color: #333333"><b>Namn</b>: ${data.fullName}</li>
                            <li style="color: #333333"><b>Lön</b>: ${data.y} kr</li>
                            <li style="color: #333333"><b>År i org</b>: ${data.expNoJitter}</li>
                            <li style="color: #333333"><b>Ålder</b>: ${data.age}</li>
                            <li style="color: #333333">${data.equalGroup}</li>
                            </ul>`
                    },
                },
                chart: {
                    height: 350,
                    type: 'scatter',
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    tickAmount: 5,
                    type: 'numeric',
                    //max: longestExp,
                    //min: 0,
                    ticks: {
                        includeBounds: true,
                    },
                    labels: {
                        formatter: function (val) {
                            return parseFloat(val).toFixed(0)
                        }
                    },
                    //floating: true,
                    axisTicks: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    },
                    labels: {
                        show: true,
                        style: {
                            colors: '#a3eade'
                        }
                    },
                },
                yaxis: {
                    tickAmount: 0,
                    labels: {
                        formatter: function (val) {
                            return parseFloat(val).toFixed(0)
                        },
                    },
                    //floating: true,
                    axisTicks: {
                        show: false
                    },
                    axisBorder: {
                        show: false
                    },
                    labels: {
                        show: true,
                        /*hover: {*/
                        style: {
                            colors: '#a3eade',
                            /*    }*/
                        },
                    },
                },
            },
        }
    }


    //Toggle div
    toggleDiv = () => {
        if (this.state.divOpened === "first") this.setState({ divOpened: "yes" })
        else if (this.state.divOpened === "yes") this.setState({ divOpened: "no" })
        else if (this.state.divOpened === "no") this.setState({ divOpened: "yes" })
    }
    hideExtra = () => {
        return new Promise((resolve, reject) => {
            this.state.hideExtra === "includeExtra" ? this.setState({ hideExtra: "hideExtra" }) : this.state.hideExtra === "hideExtra" ? this.setState({ hideExtra: "onlyExtra" }) : this.setState({ hideExtra: "includeExtra" })
            this.state.hideExtra === "includeExtra" ? document.documentElement.style.setProperty('--widthExtraSalIcon', '20px') : document.documentElement.style.setProperty('--widthExtraSalIcon', '86px')
            this.state.hideExtra === "includeExtra" ? document.documentElement.style.setProperty('--margRightExtraSalIcon', '0px') : document.documentElement.style.setProperty('--margRightExtraSalIcon', '-5px')
            resolve();
        });
    }

    //Make text big/small for selected groups
    makeTextbig = (event) => {
        const equalentGroupId = event.target.id
        const equalentGroup = Number(equalentGroupId)
        let textBigCopy = this.state.textBig.slice();
        if (textBigCopy.includes(equalentGroup)) {
            const removeGroup = textBigCopy.filter(item => item !== equalentGroup);
            this.setState({ textBig: removeGroup })
        } else {
            textBigCopy.push(equalentGroup)
            this.setState({ textBig: textBigCopy })
        }
    }

    autoCompare = (filterOn) => {
        let language = localStorage.getItem('language') ?? 'sv';
        //Do nothing if no groups are compared
        if (
            (this.state.analyseGroup1 === "Klicka på grupp" || this.state.analyseGroup1 === "Välj grupp") ||
            (this.state.analyseGroup2 === "Klicka på grupp" || this.state.analyseGroup2 === "Välj grupp")
        ) { return; }

        else {
            let hideExtra = this.state.hideExtra //For showing/adding extra/tilagg button
            //If div is not opened, open it
            if (this.state.divOpened === "first") this.setState({ divOpened: "yes" })
            else if (this.state.divOpened === "no") this.setState({ divOpened: "yes" })

            //Make calculations
            const group1 = this.state.analyseGroup1
            const group2 = this.state.analyseGroup2

            let groupOne = []
            let groupTwo = []
            if (filterOn !== "edited") {
                groupOne = this.state.allEmp.filter(person => person.groupNameArbVardering === group1);
                groupTwo = this.state.allEmp.filter(person => person.groupNameArbVardering === group2);
                this.setState({ groupOneEdited: [], groupTwoEdited: [] })
            } else {
                if (this.state.groupOneEdited.length <= 0 || this.state.groupTwoEdited.length <= 0) { return; }
                groupOne = this.state.groupOneEdited
                groupTwo = this.state.groupTwoEdited
            }

            //1. First, get statistics for table
            let originalSalary = filterOn !== "edited" ? "totalSalary" : "salBeforeAddOn" // This saves the uses the original salary to each employee
            //group 1: 
            const oneNrOfEmp = groupOne.length
            const oneWomen = Math.floor((groupOne.filter(person => person.gender === "woman").length / oneNrOfEmp) * 100)
            let oneAvgSalary = 0
            if (hideExtra === "hideExtra") { oneAvgSalary = Math.round(groupOne.reduce((acc, person) => (acc + (person.salNoExtra === undefined ? (person[originalSalary] - person.addOn) : (person.salNoExtra))), 0) / oneNrOfEmp) }
            if (hideExtra === "onlyExtra") { oneAvgSalary = Math.round(groupOne.reduce((acc, person) => (acc + (person.addOn)), 0) / oneNrOfEmp) }
            if (hideExtra === "includeExtra") { oneAvgSalary = Math.round(groupOne.reduce((acc, person) => acc + (person.salNoExtra === undefined ? person[originalSalary] : (person.salNoExtra + person.addOn)), 0) / oneNrOfEmp) }
            const oneAvgAge = Math.round(groupOne.reduce((acc, person) => acc + person.age, 0) / oneNrOfEmp)
            const oneAvgExp = Math.round(groupOne.reduce((acc, person) => acc + person.experience, 0) / oneNrOfEmp)
            const oneEqualentGroup = groupOne[0][this.state.chosenEqualentGroup] //CHANGE THIS IF CHANGING EQUALENT GROUP
            const oneCityArray = groupOne.map(person => person.city)
            const oneAverageCity = oneCityArray.sort((a, b) =>
                oneCityArray.filter(v => v === a).length
                - oneCityArray.filter(v => v === b).length
            ).pop();
            //For spridning, get lowest and highest salary, also calculate spridningskvot (highest/lowest)
            let getOnlySalariesG1 = []
            if (hideExtra === "hideExtra") { for (let i = 0; i < groupOne.length; i++) { groupOne[i].salNoExtra === undefined ? getOnlySalariesG1.push(groupOne[i][originalSalary] - groupOne[i].addOn) : getOnlySalariesG1.push(groupOne[i].salNoExtra) } }
            if (hideExtra === "onlyExtra") { for (let i = 0; i < groupOne.length; i++) { getOnlySalariesG1.push(groupOne[i].addOn) } }
            if (hideExtra === "includeExtra") { for (let i = 0; i < groupOne.length; i++) { groupOne[i].salNoExtra === undefined ? getOnlySalariesG1.push(groupOne[i][originalSalary]) : getOnlySalariesG1.push(groupOne[i].salNoExtra + groupOne[i].addOn) } }
            let lowestSalaryG1 = Math.min(...getOnlySalariesG1)
            let highestSalaryG1 = Math.max(...getOnlySalariesG1)
            let spridningskvotG1 = Math.round((highestSalaryG1 / lowestSalaryG1) * 10) / 10

            //group 2:
            const twoNrOfEmp = groupTwo.length
            const twoWomen = Math.floor((groupTwo.filter(person => person.gender === "woman").length / twoNrOfEmp) * 100)
            let twoAvgSalary = 0
            if (hideExtra === "hideExtra") { twoAvgSalary = Math.round(groupTwo.reduce((acc, person) => (acc + (person.salNoExtra === undefined ? (person[originalSalary] - person.addOn) : (person.salNoExtra))), 0) / twoNrOfEmp) }
            if (hideExtra === "onlyExtra") { twoAvgSalary = Math.round(groupTwo.reduce((acc, person) => (acc + (person.addOn)), 0) / twoNrOfEmp) }
            if (hideExtra === "includeExtra") { twoAvgSalary = Math.round(groupTwo.reduce((acc, person) => acc + (person.salNoExtra === undefined ? person[originalSalary] : (person.salNoExtra + person.addOn)), 0) / twoNrOfEmp) }
            const twoAvgAge = Math.round(groupTwo.reduce((acc, person) => acc + person.age, 0) / twoNrOfEmp)
            const twoAvgExp = Math.round(groupTwo.reduce((acc, person) => acc + person.experience, 0) / twoNrOfEmp)
            const twoEqualentGroup = groupTwo[0][this.state.chosenEqualentGroup] // CHANGE THIS IF CHANGING EQUALENT GROUP
            const twoCityArray = groupTwo.map(person => person.city)
            const twoAverageCity = twoCityArray.sort((a, b) =>
                twoCityArray.filter(v => v === a).length
                - twoCityArray.filter(v => v === b).length
            ).pop();
            //For spridning, get lowest and highest salary, also calculate spridningskvot (highest/lowest)
            let getOnlySalariesG2 = []
            if (hideExtra === "hideExtra") { for (let i = 0; i < groupTwo.length; i++) { groupTwo[i].salNoExtra === undefined ? getOnlySalariesG2.push(groupTwo[i][originalSalary] - groupTwo[i].addOn) : getOnlySalariesG2.push(groupTwo[i].salNoExtra) } }
            if (hideExtra === "onlyExtra") { for (let i = 0; i < groupTwo.length; i++) { getOnlySalariesG2.push(groupTwo[i].addOn) } }
            if (hideExtra === "includeExtra") { for (let i = 0; i < groupTwo.length; i++) { groupTwo[i].salNoExtra === undefined ? getOnlySalariesG2.push(groupTwo[i][originalSalary]) : getOnlySalariesG2.push(groupTwo[i].salNoExtra + groupTwo[i].addOn) } }
            let lowestSalaryG2 = Math.min(...getOnlySalariesG2)
            let highestSalaryG2 = Math.max(...getOnlySalariesG2)
            let spridningskvotG2 = Math.round((highestSalaryG2 / lowestSalaryG2) * 10) / 10

            //Diffs
            const diffWomen = (oneWomen - twoWomen)

            //Calculate 10 and 90 percentiles if needed (if 20 or more employees)
            let moreOne10 = "-"
            let moreOne90 = "-"
            let moreTwo10 = "-"
            let moreTwo90 = "-"
            if (oneNrOfEmp > 19 || twoNrOfEmp > 19) {
                //If men bigger but not women
                if (oneNrOfEmp > 19 && twoNrOfEmp < 20) {
                    let g1SalariesInOrder = getOnlySalariesG1.sort((a, b) => a - b) //Sort lowest to highest
                    moreOne10 = percentile(10, g1SalariesInOrder)
                    moreOne90 = percentile(90, g1SalariesInOrder)
                    spridningskvotG1 = Math.round((moreOne90 / moreOne10) * 10) / 10
                }
                //If women bigger but not men
                if (twoNrOfEmp > 19 && oneNrOfEmp < 20) {
                    let g2SalariesInOrder = getOnlySalariesG2.sort((a, b) => a - b) //Sort lowest to highest
                    moreTwo10 = percentile(10, g2SalariesInOrder)
                    moreTwo90 = percentile(90, g2SalariesInOrder)
                    spridningskvotG2 = Math.round((moreTwo90 / moreTwo10) * 10) / 10
                }
                //If both bigger
                if (twoNrOfEmp > 19 && oneNrOfEmp > 19) {
                    let g1SalariesInOrder = getOnlySalariesG1.sort((a, b) => a - b) //Sort lowest to highest
                    moreOne10 = percentile(10, g1SalariesInOrder)
                    moreOne90 = percentile(90, g1SalariesInOrder)
                    spridningskvotG1 = Math.round((moreOne90 / moreOne10) * 10) / 10
                    let g2SalariesInOrder = getOnlySalariesG2.sort((a, b) => a - b) //Sort lowest to highest
                    moreTwo10 = percentile(10, g2SalariesInOrder)
                    moreTwo90 = percentile(90, g2SalariesInOrder)
                    spridningskvotG2 = Math.round((moreTwo90 / moreTwo10) * 10) / 10
                }
            }
            let spridningDiff = Math.round((spridningskvotG1 - spridningskvotG2) * 10) / 10

            this.setState({
                tableInfo: [{
                    oneNrOfEmp: oneNrOfEmp,
                    oneWomen: oneWomen,
                    oneAvgSalary: oneAvgSalary,
                    oneAvgAge: oneAvgAge,
                    oneAvgExp: oneAvgExp,
                    oneEqualentGroup: oneEqualentGroup,
                    oneAvgCity: oneAverageCity,
                    twoNrOfEmp: twoNrOfEmp,
                    twoWomen: twoWomen,
                    twoAvgSalary: twoAvgSalary,
                    twoAvgAge: twoAvgAge,
                    twoAvgExp: twoAvgExp,
                    twoEqualentGroup: twoEqualentGroup,
                    twoAvgCity: twoAverageCity,
                    diffWomen: diffWomen,
                    lowestSalaryG1: lowestSalaryG1,
                    highestSalaryG1: highestSalaryG1,
                    spridningskvotG1: spridningskvotG1,
                    lowestSalaryG2: lowestSalaryG2,
                    highestSalaryG2: highestSalaryG2,
                    spridningskvotG2: spridningskvotG2,
                    spridningDiff: spridningDiff,
                    moreOne10: moreOne10,
                    moreOne90: moreOne90,
                    moreTwo10: moreTwo10,
                    moreTwo90: moreTwo90,
                }]
            });

            let xAxisType = this.state.xAxisType
            //Get data to use with settings for chart x-axis
            const bothGroups = groupOne.concat(groupTwo);
            let allExperiences = []
            let longestExp = 0
            if (xAxisType === "experience") {
                allExperiences = bothGroups.map(person => person.experience)
                longestExp = Math.max(...allExperiences)
                if (longestExp < 10) longestExp = 10
                if (longestExp > 10 && longestExp < 16) longestExp = 16
                this.setState({ longestExp: longestExp }) //This is for buggfix with chart.
            }
            if (xAxisType === "age") {
                allExperiences = bothGroups.map(person => person.age)
                longestExp = Math.max(...allExperiences)
                this.setState({ longestExp: longestExp }) //This is for buggfix with chart.
            }

            //2. Then update chart
            //if should show in kr
            if (!this.state.showPercent) {
                let groupOneToChart = []
                let groupTwoToChart = []
                groupOneToChart = groupOne.map(function (el) {
                    // let salary = this.state.clickedNewGroup ? "totalSalary" : !this.state.clickedNewGroup && !this.state.showPercent ? "y" : "salInKr"
                    // let salBeforeAddOn = clickedNewGroup ? el[salary] : el.salBeforeAddOn // This saves the original salary to each employee
                    let salBeforeAddOn = filterOn !== "edited" ? el.totalSalary : el.salBeforeAddOn
                    return {
                        y: hideExtra === "hideExtra" && el.salNoExtra === undefined ? (salBeforeAddOn - el.addOn) : hideExtra === "hideExtra" && el.salNoExtra !== undefined ? el.salNoExtra : hideExtra === "onlyExtra" ? el.addOn : hideExtra === "includeExtra" && el.salNoExtra !== undefined ? (el.salNoExtra + el.addOn) : salBeforeAddOn,
                        x: xAxisType === "age" ? el.age : el.experience,
                        expNoJitter: el.experience,
                        salBeforeAddOn: salBeforeAddOn,
                        positionTitle: el.positionTitle,
                        gender: el.gender,
                        fullName: el.fullName,
                        age: el.age,
                        city: el.city,
                        additional: el.additional,
                        equalGroup: el.groupNameArbVardering,
                        addOn: el.addOn
                    }
                })
                groupTwoToChart = groupTwo.map(function (el) {
                    let salBeforeAddOn = filterOn !== "edited" ? el.totalSalary : el.salBeforeAddOn
                    return {
                        y: hideExtra === "hideExtra" && el.salNoExtra === undefined ? (salBeforeAddOn - el.addOn) : hideExtra === "hideExtra" && el.salNoExtra !== undefined ? (el.salNoExtra) : hideExtra === "onlyExtra" ? el.addOn : hideExtra === "includeExtra" && el.salNoExtra !== undefined ? (el.salNoExtra + el.addOn) : salBeforeAddOn,
                        x: xAxisType === "age" ? el.age : el.experience,
                        expNoJitter: el.experience,
                        salBeforeAddOn: salBeforeAddOn,
                        positionTitle: el.positionTitle,
                        gender: el.gender,
                        fullName: el.fullName,
                        age: el.age,
                        city: el.city,
                        additional: el.additional,
                        equalGroup: el.groupNameArbVardering,
                        addOn: el.addOn
                    }
                })

                //Create jitter if years and experience are exactly the same
                jitter(groupOneToChart, groupTwoToChart, 130)

                //Show or hide name
                const nameStatus = this.state.hideName ? "display: none" : "";

                //Set state for chart
                this.setState({
                    options: {
                        colors: ['#ffffff', 'transparent'],
                        fill: {
                            colors: ['#ffffff', 'transparent']
                        },
                        dataLabels: {
                            enabled: false
                        },
                        legend: {
                            show: false
                        },
                        grid: {
                            show: false
                        },
                        tooltip: {
                            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                                if (data.additional === null) { data.additional = "" }
                                let additionalToLong = data.additional.replace(/(.{40})/g, "$1<br>")
                                let showAdditional = ""
                                data.additional === "" ? showAdditional = "" : showAdditional = `<li style="color: #333333;"><b>${language === "sv" ? "Övrigt" : lang[language].other}</b>: ${additionalToLong}</li>`
                                if (data.addOn === 0 || data.addOn === null || data.addOn === undefined) { data.addOn = "" }
                                let showAddOn = ""
                                data.addOn === "" ? showAddOn = "" : hideExtra === "includeExtra" ? showAddOn = ` | <i style="font-size: 12px">${language === "sv" ? "Varav tillägg" : lang[language].inclBen}: ${data.addOn}</i>` : hideExtra === "hideExtra" ? showAddOn = ` | <i style="font-size: 12px">${language === "sv" ? (`Tillägg: ${data.addOn} (exkluderat)</li>`) : (`${lang[language].benefits}: ${data.addOn} ${lang[language].excluded}`)}</i>` : showAddOn = ``
                                let salaryName = hideExtra === "onlyExtra" ? (language === "sv" ? "Tillägg" : lang[language].benefits) : (language === "sv" ? "Lön" : lang[language].salary)
                                //let salaryExklExtra = data.salNoExtra === undefined ? data.y : data.salNoExtra
                                return `<ul>
                                    <li style="color: #333333 ${nameStatus}"><b>${language === "sv" ? "Namn" : lang[language].name}</b>: ${data.fullName}</li>
                                    <li style="color: #333333"><b>${salaryName}</b>: ${data.y} ${language === "sv" ? "kr" : ""}${showAddOn}</li>
                                    <li style="color: #333333"><b>${language === "sv" ? "År i org" : lang[language].yInOrg}</b>: ${data.expNoJitter}</li>
                                    <li style="color: #333333"><b>${language === "sv" ? "Ålder" : lang[language].age}</b>: ${data.age}</li>
                                    <li style="color: #333333"><b>${language === "sv" ? "Ort" : lang[language].city}</b>: ${data.city}</li>
                                    ${showAdditional}
                                    <li style="padding-top: 5px;color: #4caf9e; font-weight: bold">${data.equalGroup}</li>
                                    </ul>`
                            },
                        },
                        states: { active: { filter: { type: "none" } } },
                        chart: {
                            height: 350,
                            type: 'scatter',
                            zoom: {
                                enabled: true,
                                type: 'x',
                                zoomedArea: {
                                    fill: {
                                        color: '#ffffff',
                                    },
                                    stroke: {
                                        color: '#ffffff',
                                        opacity: 0.4,
                                        width: 1
                                    }
                                }
                            },
                            toolbar: {
                                show: true,
                                tools: {
                                    download: false,
                                    zoom: `<img src=${zoomIn} style="filter: brightness(0) invert(1);opacity: 0.40;width: 18px;margin-top: 2.3px;margin-left: 4px;">`,
                                    zoomin: false,
                                    zoomout: false,
                                    pan: false,
                                    reset: false,
                                    customIcons: [{
                                        icon: `<div>${language === "sv" ? "Ålder" : lang[language].age}</div>`,
                                        index: 4,
                                        title: `${language === "sv" ? "Sätt x-axel till ålder" : lang[language].ageXinfo}`,
                                        class: this.state.xSetting ? (this.state.xAxisType === "age" ? 'custom-icon-div custom-icon-div-white apexChartLikvAge' : 'custom-icon-div  custom-icon-div-transparent apexChartLikvAge') : 'hide',
                                        click: function (chart, options, e) {
                                            let activeItem = this.state.activeItem
                                            this.setState({ xAxisType: "age" })
                                            if (!this.state.groupOneEdited.length <= 0 && !this.state.groupTwoEdited.length <= 0) { this.autoCompare("edited"); }
                                            else { this.autoCompare(); }
                                        }.bind(this)
                                    }, {
                                        icon: hideExtra === "includeExtra" ? `
                                        <img width="16px" src=${extraOn} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px; opacity: 0.47;filter: brightness(10);">` : hideExtra === "hideExtra" ?
                                            `
                                          <img width="16px" src=${extraOff} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px; display: inline-block; margin-right: -6px;opacity: 0.47;filter: brightness(10);">
                                          <div style="
                                          font-size: 11px;
                        line-height: 8px;
                        width: 60px;
                        text-align: center;
                        padding: 2px;
                        border-radius: 4px;
                        margin-top: 4px;
                        display: inline-block;
                        padding-right: 0px;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        vertical-align: top;
                        border-bottom: 1.5px solid #94dbd0;
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 2px;
                        padding-left: 2px;
                        font-weight: 500;
                        color: #a8e6e1;
                        font-style: italic;
                        ">${language === "sv" ? "Exkl tillägg" : lang[language].exclBenVeryShort}</div>
                                          ` :
                                            `
                                          <img width="16px" src=${extraOff} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px; display: inline-block; margin-right: -6px;opacity: 0.47;filter: brightness(10);">
                                          <div style="
                                          font-size: 11px;
                        line-height: 8px;
                        width: 60px;
                        text-align: center;
                        padding: 2px;
                        border-radius: 4px;
                        margin-top: 4px;
                        display: inline-block;
                        padding-right: 0px;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        vertical-align: top;
                        border-bottom: 1.5px solid #94dbd0;
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 2px;
                        padding-left: 2px;
                        font-weight: 500;
                        color: #a8e6e1;
                        font-style: italic;
                        ">${language === "sv" ? "Bara tillägg" : lang[language].onlyBenVeryShort}</div>
                                          `
                                        ,
                                        index: 2,
                                        title: hideExtra === "includeExtra" ? (language === "sv" ? 'Visar lön inklusive tillägg' : lang[language].iconInclSal) : hideExtra === "hideExtra" ? (language === "sv" ? 'Visar lön exklusive tillägg' : lang[language].iconExclSal) : (language === "sv" ? "Visar tillägg exklusive lön" : lang[language].iconOnlyBen),
                                        class: this.state.showHideExtraIcon === "on" || this.state.showHideExtraIcon === "adminOn" ? 'custom-icon extraSalIcon' : "hide",
                                        click: function (chart, options, e) {
                                            this.hideExtra();
                                            if (!this.state.groupOneEdited.length <= 0 && !this.state.groupTwoEdited.length <= 0) { this.autoCompare("edited"); }
                                            else { this.autoCompare(); }
                                        }.bind(this)
                                    },
                                    {
                                        icon: `<img src=${zoomOut} width="15px" style="margin-left: 7px;filter: brightness(0) invert(1);
                                      opacity: 0.40;">`,
                                        index: 1,
                                        title: language === "sv" ? "Zooma ut" : lang[language].zoomOut,
                                        class: 'custom-icon',
                                        click: function (chart, options, e) {
                                            if (this.state.chartWidth === "300") {
                                                this.setState({
                                                    chartWidth: "300.1",
                                                })
                                            }
                                            if (this.state.chartWidth === "92%") {
                                                this.setState({
                                                    chartWidth: "92.01%",
                                                    options: {
                                                        xaxis: {
                                                            tickAmount: 5,
                                                            min: 0,
                                                            max: this.state.longestExp
                                                        }
                                                    }
                                                })
                                            }
                                            if (this.state.chartWidth === "92.01%") {
                                                this.setState({
                                                    chartWidth: "92%",
                                                    options: {
                                                        xaxis: {
                                                            tickAmount: 5,
                                                            min: 0,
                                                            max: this.state.longestExp
                                                        }
                                                    }
                                                })
                                            }
                                            else {
                                                this.setState({
                                                    chartWidth: "300",
                                                    options: {
                                                        xaxis: {
                                                            tickAmount: 5,
                                                            min: 0,
                                                            max: this.state.longestExp
                                                        }
                                                    }
                                                })
                                            }

                                        }.bind(this)
                                    },
                                    {
                                        icon: `<div>${language === "sv" ? "År i org." : lang[language].yearInOrgShorten}</div>`,
                                        index: 5,
                                        title: `${language === "sv" ? "Sätt x-axel till år i organisationen" : lang[language].yearInOrgXinfo}`,
                                        class: this.state.xSetting ? (this.state.xAxisType === "age" ? 'custom-icon-div custom-icon-div-mright custom-icon-div-transparent apexChartLikvExp' : 'custom-icon-div custom-icon-div-mright custom-icon-div-white apexChartLikvExp') : 'hide',
                                        click: function (chart, options, e) {
                                            this.setState({ xAxisType: "experience" })
                                            if (!this.state.groupOneEdited.length <= 0 && !this.state.groupTwoEdited.length <= 0) { this.autoCompare("edited"); }
                                            else { this.autoCompare(); }
                                        }.bind(this)
                                    },
                                    {
                                        icon: `<img src=${fullscreen} width="15px" style="margin-left: 10px;filter: brightness(0) invert(1);
                                      opacity: 0.40;">`,
                                        index: 3,
                                        title: language === "sv" ? "Fullskärm" : lang[language].fullscreen,
                                        class: 'custom-icon',
                                        click: function (chart, options, e) {
                                            if (this.state.chartWidth === "300") {
                                                this.setState({
                                                    chartWidth: "92%",
                                                    chartHeight: "auto",
                                                    apexPopUp: "apexPopUp",
                                                    options: {
                                                        grid: {
                                                            show: true,
                                                            borderColor: '#a3eade',
                                                        },
                                                    }

                                                })
                                            }
                                            else {
                                                this.setState({
                                                    chartWidth: "300",
                                                    chartHeight: "240",
                                                    apexPopUp: "",
                                                    options: {
                                                        grid: {
                                                            show: false,
                                                        },
                                                        xaxis: {
                                                            tickAmount: 5,
                                                            min: 0,
                                                            max: this.state.longestExp
                                                        }
                                                    }
                                                })
                                            }
                                        }.bind(this)
                                    },
                                    ]
                                },
                            },
                            events: {
                                beforeZoom: (e, { xaxis }) => {
                                    return {
                                        xaxis: {
                                            tickAmount: 1,
                                            max: xaxis.max,
                                            min: xaxis.min
                                        }
                                    }
                                },
                                dataPointSelection: (event, chartContext, config) => {
                                    let getGroupOne = this.state.series[0].data
                                    let getGroupTwo = this.state.series[1].data
                                    let clickedPerson = ""

                                    if (config.seriesIndex == 0) { //If mens group
                                        getGroupOne = this.state.series[0].data.slice(0) // Copy the array with all men without mutating
                                        clickedPerson = getGroupOne[config.dataPointIndex]
                                    }
                                    if (config.seriesIndex == 1) { //If womens group
                                        getGroupTwo = this.state.series[1].data.slice(0)
                                        clickedPerson = getGroupTwo[config.dataPointIndex]
                                    }
                                    //Edit groups so they include everything that is needed before setting state
                                    let prepareGroupOne = getGroupOne.map(function (el) {
                                        return { totalSalary: el.y, experience: el.expNoJitter, salBeforeAddOn: el.salBeforeAddOn, addOn: el.addOn, gender: el.gender, fullName: el.fullName, age: el.age, city: el.city, additional: el.additional, groupNameArbVardering: el.equalGroup, positionTitle: el.positionTitle, [this.state.chosenEqualentGroup]: groupOne[0][this.state.chosenEqualentGroup] }
                                    })
                                    let prepareGroupTwo = getGroupTwo.map(function (el) {
                                        return { totalSalary: el.y, experience: el.expNoJitter, salBeforeAddOn: el.salBeforeAddOn, addOn: el.addOn, gender: el.gender, fullName: el.fullName, age: el.age, city: el.city, additional: el.additional, groupNameArbVardering: el.equalGroup, positionTitle: el.positionTitle, [this.state.chosenEqualentGroup]: groupTwo[0][this.state.chosenEqualentGroup] }
                                    })

                                    this.setState({
                                        groupOneEdited: prepareGroupOne,
                                        groupTwoEdited: prepareGroupTwo,
                                        clickedPerson: clickedPerson,
                                        clickedPersonIndex: config.dataPointIndex,
                                        serieIndex: config.seriesIndex,
                                        popItUp: true,
                                    })
                                }
                            },
                        },
                        xaxis: {
                            tickAmount: 5,
                            type: 'numeric',
                            max: longestExp,
                            min: 0,
                            ticks: {
                                /*stepSize: 1,**/
                                /*maxTicksLimit: 10*/
                                /* count: 10*/
                                /*autoSkip: false*/
                                includeBounds: true,
                            },
                            labels: {
                                formatter: function (val) {
                                    return parseFloat(val).toFixed(0)
                                }
                            }
                        },
                        yaxis: {
                            tickAmount: 0,
                            labels: {
                                formatter: function (val) {
                                    return parseFloat(val).toFixed(0)
                                },
                            },
                            //floating: true,
                            axisTicks: {
                                show: false
                            },
                            axisBorder: {
                                show: false
                            },
                            labels: {
                                show: true,
                                /*hover: {*/
                                style: {
                                    colors: '#a3eade',
                                    /*    }*/
                                },
                            },
                        },
                    },
                    series: [{
                        name: group1,
                        data: groupOneToChart
                    },
                    {
                        name: group2,
                        data: groupTwoToChart
                    }],
                })
            }

            //if should show in PERCENT
            if (this.state.showPercent) {
                //1. Put togheter groupOne And Two
                const bothGroups = groupOne.concat(groupTwo);
                //2. calculate total salary for use when calculating percent
                // const salaryTotal = bothGroups.reduce((sum, cur) => sum + (filterOn !== "edited" ? cur.totalSalary : cur.salBeforeAddOn), 0) //<-- MÅSTE JUSTERA!
                let allSalaries = []
                for (let i = 0; i < bothGroups.length; i++) {
                    let el = bothGroups[i]
                    let salBeforeAddOn = filterOn !== "edited" ? el.totalSalary : el.salBeforeAddOn
                    allSalaries.push(hideExtra === "hideExtra" && el.salNoExtra === undefined ? (salBeforeAddOn - el.addOn) : hideExtra === "hideExtra" && el.salNoExtra !== undefined ? el.salNoExtra : hideExtra === "onlyExtra" ? el.addOn : hideExtra === "includeExtra" && el.salNoExtra !== undefined ? (el.salNoExtra + el.addOn) : salBeforeAddOn)
                }
                const salaryTotal = allSalaries.reduce((sum, cur) => sum + cur, 0)
                //3. Update properties
                let groupOneToChart = []
                let groupTwoToChart = []
                groupOneToChart = groupOne.map(function (el) {
                    //let theSalary = 0
                    //!hideExtra ? theSalary = el.totalSalary : theSalary = el.totalSalary - el.addOn
                    let salBeforeAddOn = filterOn !== "edited" ? el.totalSalary : el.salBeforeAddOn
                    let ySalary = hideExtra === "hideExtra" && el.salNoExtra === undefined ? (salBeforeAddOn - el.addOn) : hideExtra === "hideExtra" && el.salNoExtra !== undefined ? el.salNoExtra : hideExtra === "onlyExtra" ? el.addOn : hideExtra === "includeExtra" && el.salNoExtra !== undefined ? (el.salNoExtra + el.addOn) : salBeforeAddOn
                    return {
                        y: ((ySalary / salaryTotal) * 100).toFixed(1),
                        totSalinKr: el.totalSalary,
                        x: xAxisType === "age" ? el.age : el.experience,
                        expNoJitter: el.experience,
                        salBeforeAddOn: salBeforeAddOn,
                        positionTitle: el.positionTitle,
                        gender: el.gender,
                        fullName: el.fullName,
                        age: el.age,
                        city: el.city,
                        additional: el.additional,
                        equalGroup: el.groupNameArbVardering,
                        addOn: el.addOn
                    }
                })
                groupTwoToChart = groupTwo.map(function (el) {
                    // let theSalary = 0
                    // !hideExtra ? theSalary = el.totalSalary : theSalary = el.totalSalary - el.addOn
                    let salBeforeAddOn = filterOn !== "edited" ? el.totalSalary : el.salBeforeAddOn
                    let ySalary = hideExtra === "hideExtra" && el.salNoExtra === undefined ? (salBeforeAddOn - el.addOn) : hideExtra === "hideExtra" && el.salNoExtra !== undefined ? el.salNoExtra : hideExtra === "onlyExtra" ? el.addOn : hideExtra === "includeExtra" && el.salNoExtra !== undefined ? (el.salNoExtra + el.addOn) : salBeforeAddOn
                    return {
                        y: ((ySalary / salaryTotal) * 100).toFixed(1),
                        totSalinKr: el.totalSalary,
                        x: xAxisType === "age" ? el.age : el.experience,
                        expNoJitter: el.experience,
                        salBeforeAddOn: salBeforeAddOn,
                        positionTitle: el.positionTitle,
                        gender: el.gender,
                        fullName: el.fullName,
                        age: el.age,
                        city: el.city,
                        additional: el.additional,
                        equalGroup: el.groupNameArbVardering,
                        addOn: el.addOn
                    }
                })

                //Create jitter if years and experience are exactly the same
                jitter(groupOneToChart, groupTwoToChart, 130)

                //Show or hide name
                const nameStatus = this.state.hideName ? "display: none" : "";

                //Set state for chart
                this.setState({
                    options: {
                        colors: ['#ffffff', 'transparent'],
                        fill: {
                            colors: ['#ffffff', 'transparent']
                        },
                        dataLabels: {
                            enabled: false
                        },
                        legend: {
                            show: false
                        },
                        grid: {
                            show: false
                        },
                        tooltip: {
                            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                                if (data.additional === null) { data.additional = "" }
                                let additionalToLong = data.additional.replace(/(.{40})/g, "$1<br>")
                                let showAdditional = ""
                                data.additional === "" ? showAdditional = "" : showAdditional = `<li style="color: #333333"><b>${language === "sv" ? "Övrigt" : lang[language].other}</b>: ${additionalToLong}</li>`

                                return `<ul>
                                    <li style="color: #333333 ${nameStatus}"><b>${language === "sv" ? "Namn" : lang[language].name}</b>: ${data.fullName}</li>
                                    <li style="color: #333333"><b>${language === "sv" ? "Lön" : lang[language].salary}</b>: ${data.y} %</li>
                                    <li style="color: #333333"><b>${language === "sv" ? "År i org" : lang[language].yInOrg}</b>: ${data.expNoJitter}</li>
                                    <li style="color: #333333"><b>${language === "sv" ? "Ålder" : lang[language].age}</b>: ${data.age}</li>
                                    <li style="color: #333333"><b>${language === "sv" ? "Ort" : lang[language].city}</b>: ${data.city}</li>
                                    ${showAdditional}
                                    <li style="padding-top: 5px;color: #4caf9e; font-weight: bold">${data.equalGroup}</li>
                                    </ul>`
                            },
                        },
                        states: { active: { filter: { type: "none" } } },
                        chart: {
                            height: 350,
                            type: 'scatter',
                            zoom: {
                                enabled: true,
                                type: 'x',
                                zoomedArea: {
                                    fill: {
                                        color: '#ffffff',
                                    },
                                    stroke: {
                                        color: '#ffffff',
                                        opacity: 0.4,
                                        width: 1
                                    }
                                }
                            },
                            toolbar: {
                                show: true,
                                tools: {
                                    download: false,
                                    zoom: `<img src=${zoomIn} style="filter: brightness(0) invert(1);opacity: 0.40;width: 18px;margin-top: 2.3px;margin-left: 4px;">`,
                                    zoomin: false,
                                    zoomout: false,
                                    pan: false,
                                    reset: false,
                                    customIcons: [{
                                        icon: `<div>${language === "sv" ? "Ålder" : lang[language].age}</div>`,
                                        index: 4,
                                        title: `${language === "sv" ? "Sätt x-axel till ålder" : lang[language].ageXinfo}`,
                                        class: this.state.xSetting ? (this.state.xAxisType === "age" ? 'custom-icon-div custom-icon-div-white apexChartLikvAge' : 'custom-icon-div  custom-icon-div-transparent apexChartLikvAge') : 'hide',
                                        click: function (chart, options, e) {
                                            let activeItem = this.state.activeItem
                                            this.setState({ xAxisType: "age" })
                                            if (!this.state.groupOneEdited.length <= 0 && !this.state.groupTwoEdited.length <= 0) { this.autoCompare("edited"); }
                                            else { this.autoCompare(); }
                                        }.bind(this)
                                    }, {
                                        icon: hideExtra === "includeExtra" ? `
                                        <img width="16px" src=${extraOn} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px; opacity: 0.47;filter: brightness(10);">` : hideExtra === "hideExtra" ?
                                            `
                                          <img width="16px" src=${extraOff} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px; display: inline-block; margin-right: -6px;opacity: 0.47;filter: brightness(10);">
                                          <div style="
                                          font-size: 11px;
                        line-height: 8px;
                        width: 60px;
                        text-align: center;
                        padding: 2px;
                        border-radius: 4px;
                        margin-top: 4px;
                        display: inline-block;
                        padding-right: 0px;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        vertical-align: top;
                        border-bottom: 1.5px solid #94dbd0;
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 2px;
                        padding-left: 2px;
                        font-weight: 500;
                        color: #a8e6e1;
                        font-style: italic;
                        ">${language === "sv" ? "Exkl tillägg" : lang[language].exclBenVeryShort}</div>
                                          ` :
                                            `
                                          <img width="16px" src=${extraOff} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px; display: inline-block; margin-right: -6px;opacity: 0.47;filter: brightness(10);">
                                          <div style="
                                          font-size: 11px;
                        line-height: 8px;
                        width: 60px;
                        text-align: center;
                        padding: 2px;
                        border-radius: 4px;
                        margin-top: 4px;
                        display: inline-block;
                        padding-right: 0px;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        vertical-align: top;
                        border-bottom: 1.5px solid #94dbd0;
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 2px;
                        padding-left: 2px;
                        font-weight: 500;
                        color: #a8e6e1;
                        font-style: italic;
                        ">${language === "sv" ? "Bara tillägg" : lang[language].onlyBenVeryShort}</div>
                                          `,
                                        index: 2,
                                        title: hideExtra === "includeExtra" ? (language === "sv" ? 'Visar lön inklusive tillägg' : lang[language].iconInclSal) : hideExtra === "hideExtra" ? (language === "sv" ? 'Visar lön exklusive tillägg' : lang[language].iconExclSal) : (language === "sv" ? "Visar tillägg exklusive lön" : lang[language].iconOnlyBen),
                                        class: this.state.showHideExtraIcon === "on" || this.state.showHideExtraIcon === "adminOn" ? 'custom-icon extraSalIcon' : "hide",
                                        click: function (chart, options, e) {
                                            this.hideExtra();
                                            if (!this.state.groupOneEdited.length <= 0 && !this.state.groupTwoEdited.length <= 0) { this.autoCompare("edited"); }
                                            else { this.autoCompare(); }
                                        }.bind(this)
                                    },
                                    {
                                        icon: `<img src=${zoomOut} width="15px" style="margin-left: 7px;filter: brightness(0) invert(1);
                                    opacity: 0.40;">`,
                                        index: 1,
                                        title: language === "sv" ? "Zooma ut" : lang[language].zoomOut,
                                        class: 'custom-icon',
                                        click: function (chart, options, e) {
                                            if (this.state.chartWidth === "300") {
                                                this.setState({ chartWidth: "300.1" })
                                            }
                                            if (this.state.chartWidth === "92%") {
                                                this.setState({ chartWidth: "92.01%" })
                                            }
                                            if (this.state.chartWidth === "92.01%") {
                                                this.setState({ chartWidth: "92%" })
                                            }
                                            else {
                                                this.setState({ chartWidth: "300" })
                                            }

                                        }.bind(this)
                                    },
                                    {
                                        icon: `<div>${language === "sv" ? "År i org." : lang[language].yearInOrgShorten}</div>`,
                                        index: 5,
                                        title: `${language === "sv" ? "Sätt x-axel till år i organisationen" : lang[language].yearInOrgXinfo}`,
                                        class: this.state.xSetting ? (this.state.xAxisType === "age" ? 'custom-icon-div custom-icon-div-mright custom-icon-div-transparent apexChartLikvExp' : 'custom-icon-div custom-icon-div-mright custom-icon-div-white apexChartLikvExp') : 'hide',
                                        click: function (chart, options, e) {
                                            this.setState({ xAxisType: "experience" })
                                            if (!this.state.groupOneEdited.length <= 0 && !this.state.groupTwoEdited.length <= 0) { this.autoCompare("edited"); }
                                            else { this.autoCompare(); }
                                        }.bind(this)
                                    },
                                    {
                                        icon: `<img src=${fullscreen} width="15px" style="margin-left: 10px;filter: brightness(0) invert(1);
                                      opacity: 0.40;">`,
                                        index: 3,
                                        title: language === "sv" ? "Fullskärm" : lang[language].fullscreen,
                                        class: 'custom-icon',
                                        click: function (chart, options, e) {
                                            if (this.state.chartWidth === "300") {
                                                this.setState({
                                                    chartWidth: "92%",
                                                    chartHeight: "auto",
                                                    apexPopUp: "apexPopUp",
                                                    options: {
                                                        grid: {
                                                            show: true,
                                                            borderColor: '#a3eade',
                                                        },
                                                    }

                                                })
                                            }
                                            else {
                                                this.setState({
                                                    chartWidth: "300",
                                                    chartHeight: "240",
                                                    apexPopUp: "",
                                                    options: {
                                                        grid: {
                                                            show: false,
                                                        },
                                                    }
                                                })
                                            }
                                        }.bind(this)
                                    },
                                    ]
                                },
                            },
                            events: {
                                beforeZoom: (e, { xaxis }) => {
                                    return {
                                        xaxis: {
                                            tickAmount: 1,
                                            max: xaxis.max,
                                            min: xaxis.min
                                        }
                                    }
                                },
                                dataPointSelection: (event, chartContext, config) => {
                                    let getGroupOne = this.state.series[0].data
                                    let getGroupTwo = this.state.series[1].data
                                    let clickedPerson = ""

                                    if (config.seriesIndex == 0) { //If mens group
                                        getGroupOne = this.state.series[0].data.slice(0) // Copy the array with all men without mutating
                                        clickedPerson = getGroupOne[config.dataPointIndex]
                                    }
                                    if (config.seriesIndex == 1) { //If womens group
                                        getGroupTwo = this.state.series[1].data.slice(0)
                                        clickedPerson = getGroupTwo[config.dataPointIndex]
                                    }
                                    //Edit groups so they include everything that is needed before setting state
                                    let prepareGroupOne = getGroupOne.map(function (el) { return { totalSalary: el.totSalinKr, addOn: el.addOn, experience: el.expNoJitter, salBeforeAddOn: el.salBeforeAddOn, gender: el.gender, fullName: el.fullName, age: el.age, city: el.city, additional: el.additional, groupNameArbVardering: el.equalGroup, positionTitle: el.positionTitle, [this.state.chosenEqualentGroup]: groupOne[0][this.state.chosenEqualentGroup] } })
                                    let prepareGroupTwo = getGroupTwo.map(function (el) { return { totalSalary: el.totSalinKr, addOn: el.addOn, experience: el.expNoJitter, salBeforeAddOn: el.salBeforeAddOn, gender: el.gender, fullName: el.fullName, age: el.age, city: el.city, additional: el.additional, groupNameArbVardering: el.equalGroup, positionTitle: el.positionTitle, [this.state.chosenEqualentGroup]: groupTwo[0][this.state.chosenEqualentGroup] } })

                                    this.setState({
                                        groupOneEdited: prepareGroupOne,
                                        groupTwoEdited: prepareGroupTwo,
                                        clickedPerson: clickedPerson,
                                        clickedPersonIndex: config.dataPointIndex,
                                        serieIndex: config.seriesIndex,
                                        popItUp: true,
                                    })
                                }
                            },
                        },
                        xaxis: {
                            tickAmount: 5,
                            type: 'numeric',
                            max: longestExp,
                            min: 0,
                            ticks: {
                                includeBounds: true,
                            },
                            labels: {
                                formatter: function (val) {
                                    return parseFloat(val).toFixed(0)
                                }
                            }
                        },
                        yaxis: {
                            tickAmount: 0,
                            labels: {
                                formatter: function (val) {
                                    return parseFloat(val).toFixed(0)
                                },
                            },
                            //floating: true,
                            axisTicks: {
                                show: false
                            },
                            axisBorder: {
                                show: false
                            },
                            labels: {
                                show: true,
                                /*hover: {*/
                                style: {
                                    colors: '#a3eade',
                                    /*    }*/
                                },
                            },
                        },
                    },
                    series: [{
                        name: group1,
                        data: groupOneToChart
                    },
                    {
                        name: group2,
                        data: groupTwoToChart
                    }],
                })
            }

            //b. We change this based on some if statements below. So we dont have to copy our If statements. 
            //Initial state is the same as "if (oneWomen > 59 && twoWomen < 60)"
            let lessWomen = oneWomen //Percentage of women in group, ex 60 = 60% women
            let moreWomen = twoWomen //Percentage of women in the other group, below we switch it so that lessWomen always is the group with the smallest percantage of women.
            let firsAvgSalary = oneAvgSalary //Total average salary for group 1
            let secondAvgSalary = twoAvgSalary //Total average salary for group 2
            let nrOfEmp = oneNrOfEmp //Nr of employee for the women dominated group. If under 4 we do some things
            let nrOfEmpTwo = twoNrOfEmp //Nr of employee for the male dominated group
            let firstGroup = group1 //Employees in group 1
            let secondGroup = group2 //Employees in group 2
            let firstExp = oneAvgExp //Average Experience in group 1
            let secondExp = twoAvgExp //Average Experience in group 2
            let firstAge = oneAvgAge //Average Experience in group 1
            let secondAge = twoAvgAge //Average Experience in group 2
            let firstEqualent = this.state.group1EqualGroup //the equalent group the group belong to, (1-6). The first equalent group will always belong to the women dominated group
            let secondEqualent = this.state.group2EqualGroup
            let firstCity = oneAverageCity
            let secondCity = twoAverageCity

            //If it is the second group that is women dominated, that groups should be first. Therefore we swap all data
            if (twoWomen > 59 && oneWomen < 60) {
                lessWomen = twoWomen
                moreWomen = oneWomen
                firsAvgSalary = twoAvgSalary
                secondAvgSalary = oneAvgSalary
                nrOfEmp = twoNrOfEmp
                nrOfEmpTwo = oneNrOfEmp
                firstGroup = group2
                secondGroup = group1
                firstExp = twoAvgExp
                secondExp = oneAvgExp
                firstAge = twoAvgAge
                secondAge = oneAvgAge
                firstEqualent = this.state.group2EqualGroup
                secondEqualent = this.state.group1EqualGroup
                firstCity = twoAverageCity
                secondCity = oneAverageCity
            }

            //Run auto analysis (from separate file)
            //const autoAnalys = autoAnalysis(lessWomen, moreWomen, firsAvgSalary, secondAvgSalary, nrOfEmp, firstGroup, secondGroup, firstExp, secondExp, firstAge, secondAge, firstEqualent, secondEqualent, this.state.group1, this.state.group2, this.state.group3, this.state.group4, this.state.group5, this.state.group6);
            const autoAnalys = autoAnalysis(lessWomen, moreWomen, firsAvgSalary, secondAvgSalary, nrOfEmp, nrOfEmpTwo, firstGroup, secondGroup, firstExp, secondExp, firstAge, secondAge, firstEqualent, secondEqualent, firstCity, secondCity, "No");

            //Check if analysisText contains "<age>"
            if (autoAnalys[0].includes("<age>")) {
                this.setState({ showInfoIcon: true })
            } else {
                this.setState({ showInfoIcon: false })
            }
            //Remove
            let autoString = autoAnalys[0].replace("<age>", "")
            //Set state to result of auto analysis
            if (autoAnalys[1] === "ignore" || autoAnalys[1] == undefined) { autoAnalys[1] = "" }
            if (autoAnalys[2] === "ignore" || autoAnalys[1] == undefined || typeof autoAnalys[2] === 'object') { autoAnalys[2] = "" }
            if (hideExtra === "onlyExtra") {
                this.setState({ analysisText: language === "sv" ? "Autoanalys ej tillämpbar för enbart tillägg." : lang[language].AInotForBenOnly, AInotable: "", AIreason: "", AIAnalys: "" });
            }
            else {
                this.setState({ analysisText: autoString, AInotable: autoAnalys[1], AIreason: autoAnalys[2], AIAnalys: autoAnalys[3] });
            }
        }
    }

    selectGroup1 = () => {
        this.setState({
            analyseGroup1: "Klicka på grupp",
            selectGroup1: true,
        })
    }
    selectGroup2 = () => {
        this.setState({
            analyseGroup2: "Klicka på grupp",
            selectGroup2: true,
        })
    }

    updateApexChartWithThis = (men, women) => {
        return new Promise(resolve => {
            this.setState({
                series: [{
                    name: "Män",
                    data: men //filteredMale
                },
                {
                    name: "Kvinnor",
                    data: women //filteredWoman
                }],
            })
            resolve()
        });
    }

    showDiffPercent = () => { this.state.showDiffPercent ? this.setState({ showDiffPercent: false }) : this.setState({ showDiffPercent: true }) }

    componentDidMount() {
        let warningPrompt = localStorage.getItem('warningPrompt');
        if (warningPrompt === "off") { this.setState({ showChangesPrompt: "No" }) }
        let settingEU = localStorage.getItem('settingEU');
        if (settingEU === "on") { this.setState({ showWhat: "EU" }) }

        //Set what evaluation, external or edited
        if (this.props.evaluation === "extLevel" || this.props.evaluation === "extPoint") { this.setState({ chosenEqualentGroup: "extEqualentGroup" }) }
        if (this.props.evaluation === "edited") { this.setState({ chosenEqualentGroup: "editedEqualentGroup" }) }

        const getDataAndComments = async () => {
            const response = await fetchAuth(`/api/loadEqualent`, 'POST');
            let data = await response.json();

            //set nr of selected equalentgroups
            this.setState({ nrOfEqualentGroups: data[3] })

            //set percent to state
            let percentStatus = localStorage.getItem("settingPercent");
            percentStatus === "true" ? percentStatus = true : percentStatus = false
            this.setState({ showPercent: percentStatus })

            //set name status to state
            let hideName = localStorage.getItem("settingName");
            hideName === "true" ? hideName = true : hideName = false
            this.setState({ hideName: hideName })

            //set age status to state
            const ageChart = data[4]
            if (ageChart != undefined) {
                if (ageChart.length > 0) {
                    let showAgeChart = ageChart[0].showAgeChart
                    if (showAgeChart === "on" || showAgeChart === "adminOn") { this.setState({ xSetting: true }) }
                }
            }
            //set showExtraSetting to state
            const extraSetting = data[5]
            if (extraSetting != undefined) {
                if (extraSetting.length > 0) {
                    let showExtraSetting = extraSetting[0].showExtraSetting
                    if (showExtraSetting === "on" || showExtraSetting === "adminOn") { this.setState({ showHideExtraIcon: "on" }) }
                }
            }

            let employees = data[0]
            const comments = data[1]
            const commentsLastYear = data[2]
            const allEmployees = employees

            //*** GET DATA FOR EACH GROUP ***
            //Group all data that has the same equalent group
            let groupedData = Object.values(allEmployees.reduce((result, item) => {
                const g = item[this.state.chosenEqualentGroup]; // CHANGE THIS IF WE ARE USING ANOTHER EQUALENT GROUP
                const positionTitle = item.positionTitle;
                const salary = item.totalSalary;
                const gender = item.gender;
                const groupNameArbVardering = item.groupNameArbVardering
                if (!result[g]) {
                    result[g] = [];
                }
                const group = result[g].find(group => group.groupNameArbVardering === groupNameArbVardering);
                if (group) {
                    group.groups.push(item);
                    group.averageSalary += salary;
                    group.numberOfPersons++;
                    group.percentageOfWomen += (gender === 'woman' ? 1 : 0);
                } else {
                    result[g].push({
                        g,
                        positionTitle,
                        groupNameArbVardering, //added this
                        groups: [item],
                        averageSalary: salary,
                        numberOfPersons: 1,
                        percentageOfWomen: (gender === 'woman' ? 1 : 0)
                    });
                }
                return result;
            }, {}));

            // Calculate pay gap for each equivalent group
            if (this.state.showWhat === "EU") {
                let whatEqualentGroup = this.state.chosenEqualentGroup
                function calculatePayGap2(employees) {
                    // Group employees by editedEqualentGroup
                    const groupedEmployees = {};
                    employees.forEach(employee => {
                        const groupKey = employee[whatEqualentGroup]
                        if (!groupedEmployees[groupKey]) {
                            groupedEmployees[groupKey] = [];
                        }
                        groupedEmployees[groupKey].push(employee);
                    });
                    // Calculate pay gap for each group
                    const payGapResults = {};
                    Object.keys(groupedEmployees).forEach(groupKey => {
                        const group = groupedEmployees[groupKey];
                        const maleSalaries = group
                            .filter(employee => employee.gender === 'man')
                            .map(employee => employee.totalSalary);

                        const femaleSalaries = group
                            .filter(employee => employee.gender === 'woman')
                            .map(employee => employee.totalSalary);

                        if (maleSalaries.length === 0 || femaleSalaries.length === 0) {
                            // If only men or women, set pay gap to 0
                            payGapResults[groupKey] = 0;
                        } else {
                            const averageMaleSalary = maleSalaries.reduce((sum, salary) => sum + salary, 0) / maleSalaries.length;
                            const averageFemaleSalary = femaleSalaries.reduce((sum, salary) => sum + salary, 0) / femaleSalaries.length;

                            const payGapPercent = averageMaleSalary !== 0
                                ? ((averageMaleSalary - averageFemaleSalary) / averageMaleSalary) * 100
                                : (averageFemaleSalary !== 0 ? -100 : 0); // Handle division by zero

                            payGapResults[groupKey] = parseFloat(payGapPercent.toFixed(1));
                        }
                    });
                    return payGapResults
                }
                // 3. Run function and get pay gap results
                const payGapResults = calculatePayGap2(allEmployees);
                //console.log(payGapResults)
                // 4. Add the payGap  to groupData
                const resultA = groupedData.map((group) =>
                    group.map((item) => {
                        const payGap = payGapResults[item.g];
                        return payGap !== undefined ? { ...item, payGap } : item;
                    })
                );
                groupedData = resultA
            } // --- End of payGap calculation

            groupedData.forEach(gGroup => {
                gGroup.forEach(positionGroup => {
                    positionGroup.averageSalary /= positionGroup.numberOfPersons;
                    positionGroup.percentageOfWomen = (positionGroup.percentageOfWomen / positionGroup.numberOfPersons) * 100;
                });
            });
            this.setState({ dataByGroup: groupedData })

            //Sort comments by date 
            const commentsByDate = comments.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)
            const commentsByDateLastYear = commentsLastYear.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)

            //Set state for comments
            this.setState({
                comments: commentsByDate,
                commentsLastYear: commentsByDateLastYear
            })
            //Set alla employees to state
            this.setState({
                allEmp: allEmployees
            })
            //Set state for all comments (remove years for this year or after the selected year)
            let selectedYear = localStorage.getItem('year')
            if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
            if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
            const allComments = data[6]
            const allCommentsExceptNewer = allComments.filter(obj => { const objYear = new Date(obj.dateAdded).getFullYear(); return objYear < selectedYear; });
            // const allYears = [...new Set(allCommentsExceptNewer.map(obj => new Date(obj.dateAdded).getFullYear()))].sort((a, b) => a - b); 
            this.setState({
                allComments: allCommentsExceptNewer,
                selectedYear: selectedYear,
                year: selectedYear - 1,
            })
        }
        getDataAndComments();
    }

    //handle click function for hierarchical jobs. 
    //1. IF CLICKING ON WOMEN GROUP, MARK EVERY GROUP THAT IS NOT WOMEN AND HAS HIGHER PAY BUT LOWER DEMANDS
    //2. IF CLICKING ON MEN OR EQUAL GROUP, MARK EVERY GROUP THAT IS WOMEN AND HAS LOWER PAY AND HIGHER DEMANDS (LIKE IT IS NOW, BUT ONLY FOR MEN)
    handleClick = (event) => {

        const data = JSON.parse(event.currentTarget.id)

        //First, check if selectGroup1 and 2 is active, if so, the user is trying to select a group to compare
        if (this.state.selectGroup1) {
            this.setState({
                analyseGroup1: data.group,
                group1EqualGroup: data.demands,
                selectGroup1: false,
            })
            return;
        }
        if (this.state.selectGroup2) {
            this.setState({
                analyseGroup2: data.group,
                group2EqualGroup: data.demands,
                selectGroup2: false,
            })
            return;
        }

        //If user is not trying to select a group to compare, then show groups in green.
        if (this.state.clickedGroup === data.group) {

            this.setState({
                clickedGroup: "",
                avgSalary: null,
                womenPercent: null,
                demands: null
            })
        } else {
            this.setState({
                clickedGroup: data.group,
                avgSalary: data.avgSalary,
                womenPercent: data.womenPercent,
                demands: data.demands
            })
        }
    }

    changeYearComments = (event) => {
        let year = Number(event.target.value)
        let allComments = this.state.allComments
        let commentsForYear = allComments.filter(obj => new Date(obj.dateAdded).getFullYear() === year);
        //Below is probably wrong, borde tas bort från allcomments listan också.
        this.setState({ commentsLastYear: commentsForYear, year: year })
    }

    editComment = (event) => {
        //let comment = ""
        const column = event.target.name //what column should be saved
        const commentId_PK = event.target.id //For what comment ID
        const text = event.target.value //what text should be saved
        const editedComments = this.state.comments

        // if (text === "") { return; } //If no text changed, don't do anything

        //Look into state, if it is in the array, then merch the comments. If not in array, then add it 
        let updateComments = editedComments.map(el => {
            if (el.commentId_PK == commentId_PK) {
                return Object.assign({}, el, { [column]: text, updated: 'Yes' })
            } //Changes the correct column text. Updated Yes is there to know which comments have been updated when we are saving
            return el
        });

        this.setState({ comments: updateComments })
        //Update save button
        if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }
    }

    deleteComment = (event) => {
        const commentId_PK = event.target.id
        const removedComment = this.state.comments.filter(el => el.commentId_PK == commentId_PK);  //Get the deleted comment
        const listWithoutComment = this.state.comments.filter(el => el.commentId_PK != commentId_PK); //create new list without the deleted comment
        let del = this.state.deletedComments
        if (removedComment[0].reuseComment !== "Yes") { del = this.state.deletedComments.concat(removedComment) } //add removedComment to deletedComments array:

        this.setState({
            comments: listWithoutComment, //set state for new array without the deleted comment.
            deletedComments: del //set state for the comment/s that should be deleted, so it wont be deleted until we hit the save button
        })
        if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }
    }

    //Save changes in comments
    saveButton = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        //send only updatedComments to server for saving (and also the deleted comments)
        const updatedComments = this.state.comments.filter(el => el.updated)
        const deletedComments = this.state.deletedComments

        const saveData = async () => {
            const commentData = {
                changes: updatedComments,
                deleted: deletedComments
            }

            let response = await fetchAuth(`/api/saveEqualCommentChanges`, 'POST', JSON.stringify({ data: commentData }));
            let data = await response.json();
            if (data == 'Saved') { //it is a number because we returned the id for our last inserted item (the last id is in the data variable)

                this.setState({ saveButtonStatus: "Sparat" })

            } else { alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave) }
        };
        saveData();

        //Remove property updated if saved
        const removeUpdatedProperty = this.state.comments.map(el => {
            if (el.updated) {
                delete el.updated;
                return el;
            }
            return el;
        });
        this.setState({ comments: removeUpdatedProperty })
    }

    //Filter comments
    filterEqualent = () => {
        this.setState({ analysis: 'likvärdiga' })
    }
    filterHierarchy = () => {
        this.setState({ analysis: 'hierarkisk' })
    }
    filterAll = () => {
        this.setState({ analysis: 'all' })
    }

    //toggle more save fields
    toggleFields = () => {
        if (this.state.moreFields) {
            this.setState({
                moreFields: false
            })
        } else {
            this.setState({
                moreFields: true
            })
        }
    }

    //Save new comment
    //First add state for fields:
    addAnalysisEqualent = () => {
        this.setState({ addAnalysis: "likvärdiga" })
    }
    addAnalysisHierarchy = () => {
        this.setState({ addAnalysis: "hierarkisk" })
    }
    handleComment = (event) => {
        this.setState({ addComment: this.curSelComment.value })
    }
    handleReason = (event) => {
        this.setState({ addReason: this.curSelReason.value })
    }
    handleTodo = (event) => {
        this.setState({ addTodo: this.curSelTodo.value })
    }
    handleDeadline = (event) => {
        this.setState({ addDeadline: event.target.value })
    }
    handleCost = (event) => {
        this.setState({ addCost: this.curSelCost.value })
    }
    handleResponsible = (event) => {
        this.setState({ addResponsible: this.curSelResp.value })
    }

    //Then, save comment if clicking save button
    saveNewComment = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        if (this.state.addComment !== "") {
            const saveData = async () => {
                const commentData = {
                    notable: this.state.addComment,
                    reason: this.state.addReason,
                    todo: this.state.addTodo,
                    deadline: this.state.addDeadline,
                    cost: this.state.addCost,
                    responsible: this.state.addResponsible,
                    analysis: this.state.addAnalysis
                }

                let response = await fetchAuth(`/api/saveEqualentComment`, 'POST', JSON.stringify({ data: commentData }));
                let data = await response.json();

                //add todays date to temporary comment,or a previous date if we are doing it for a previous year
                let selectedYear = localStorage.getItem('year')
                if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
                if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
                let d = new Date()
                let month = d.getMonth();
                let day = d.getDate();
                const todaysDate = new Date(selectedYear, month, day);
                const dateNow = todaysDate.toISOString();

                //When saved, add the new comment to the comments array
                const toState = {
                    notable: this.state.addComment,
                    reason: this.state.addReason,
                    todo: this.state.addTodo,
                    deadline: this.state.addDeadline,
                    cost: this.state.addCost,
                    responsible: this.state.addResponsible,
                    equalGroup: this.state.groupTitle,
                    commentId_PK: data,
                    analysis: this.state.addAnalysis,
                    dateAdded: dateNow
                }
                const commentList = this.state.comments.concat(toState);

                //Arrange by descending date so the new comments goes to top:
                //commentList = [...comments].sort((a, b) => b.dateAdded > a.dateAdded ? 1: -1)
                const sortedComments = commentList.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)
                //Reset fields
                if (typeof data == 'number') { //it is a number because we returned the id for our last inserted item (the last id is in the data variable)
                    this.setState({
                        addComment: "",
                        addReason: "",
                        addTodo: "",
                        addDeadline: "",
                        addCost: "",
                        addResponsible: "",
                        comments: sortedComments //add the new comment to the comments array
                    })
                    this.curSelComment.value = ""
                    this.curSelReason.value = ""
                    this.curSelTodo.value = ""
                    this.curSelCost.value = ""
                    this.curSelResp.value = ""
                } else { alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave) }
            };
            saveData();
        } else {
            alert(language === "sv" ? 'Du kan inte spara en tom notering. Vänligen fyll i fältet "Noterbart".' : lang[language].noEmptyComment)
        }
    }

    togglePop = () => {
        this.state.popUp ? this.setState({ popUp: false }) : this.setState({ popUp: true })
    }
    popUpInfo = () => {
        this.state.popUpInfo ? this.setState({ popUpInfo: false }) : this.setState({ popUpInfo: true })
    }

    //----------------------------- For old comments // ----------------------------- 
    reuseComment = (event) => {
        const commentId_PK = event.target.id

        //get data for comment from state
        const commentData = this.state.commentsLastYear.filter((comment) => comment.commentId_PK == commentId_PK)
        //Get selected year from user
        let selectedYear = localStorage.getItem('year')
        if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
        if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
        let d = new Date()
        let month = d.getMonth();
        let day = d.getDate();
        const todaysDate = new Date(selectedYear, month, day);
        const dateNow = todaysDate.toISOString(); //add todays date to temporary comment

        //Add the new comment to the comments array
        const toState = {
            notable: commentData[0].notable,
            reason: commentData[0].reason,
            todo: commentData[0].todo,
            deadline: commentData[0].deadline,
            cost: commentData[0].cost,
            responsible: commentData[0].responsible,
            equalGroup: commentData[0].equalGroup,
            commentId_PK: commentId_PK,
            analysis: commentData[0].analysis,
            dateAdded: dateNow,
            updated: 'Yes',
            reuseComment: 'Yes',
        }
        const commentList = this.state.comments.concat(toState);

        //Sort comments by descending date
        const commentsByDate = commentList.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)

        //get all comments except this comment (to use for setting new state)
        const pastYearsComments = this.state.commentsLastYear.filter((comment) => comment.commentId_PK != commentId_PK)

          //also remove from allComments
          const allComments = this.state.allComments.filter((comment) => comment.commentId_PK != commentId_PK)

        //Add comment to new state and remove from the past
        this.setState({
            comments: commentsByDate, //add the new comment to the comments array
            commentsLastYear: pastYearsComments,
            allComments: allComments
        })
        if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }
    }

    reuseAll = () => {
        this.setState({ popUpComments: false }) //Hide popUp

        const comments = this.state.commentsLastYear.filter((comment) => (comment.todo == null || comment.todo == "")) //We filter out the ones with a todo, because these should be evaluated and can not be used for this year
        const commentsWithTodo = this.state.commentsLastYear.filter((comment) => (comment.todo != null && comment.todo !== "")) //We use this to temporary update the commentsLastYearState
          //Also filter out the all comments array and not only
          const commentsShouldBeRemoved = this.state.commentsLastYear.filter((comment) => (comment.todo === null || comment.todo == "")) //1. get what comments to be reused
          const removeFromAllComments = this.state.allComments.filter(obj2 => !commentsShouldBeRemoved.some(obj1 => obj1.commentId_PK === obj2.commentId_PK)); //2. remove those comments from all comments
  
        //Get selected year from user
        let selectedYear = localStorage.getItem('year')
        if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
        if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
        let d = new Date()
        let month = d.getMonth();
        let day = d.getDate();
        const todaysDate = new Date(selectedYear, month, day);
        const dateNow = todaysDate.toISOString(); //add todays date to temporary comment

        let addedYes = comments.map(el => {
            return Object.assign({}, el, { updated: 'Yes', dateAdded: dateNow, reuseComment: 'Yes' })
        }); //Changes the correct column text. Updated Yes is there to know which comments have been updated when we are saving

        const commentList = this.state.comments.concat(addedYes);
        const commentsByDate = commentList.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)

        this.setState({
            comments: commentsByDate, //add the new comment to the comments array
            commentsLastYear: commentsWithTodo, //Empty last years comments. 
            allComments: removeFromAllComments,
        })

        if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }
    }

    toggleOldComments = () => {
        this.state.showOldComments ? this.setState({ showOldComments: false }) : this.setState({ showOldComments: true })
    }

    toggleCommentPop = () => {
        this.state.popUpComments ? this.setState({ popUpComments: false }) : this.setState({ popUpComments: true })
    }

    AIComment = () => {
        //First, show popUp
        this.state.showAIComment ? this.setState({ showAIComment: false }) : this.setState({ showAIComment: true })

        //*** LOAD FROM COMMENTS HERE <------ DO LATER ***
        //1. KONTROLLERA DETTA ÅRS KOMMENTARER OM DEN FINNS DÄR, ISF HÄMTA OCH SKRIV DET (SKRIV UNDER "DU har redan förklarat med ovanstående")
        //2. KONTROLLERA FÖREGÅENDE ÅRS KOMMENTARER OM DEN FINNS DÄR, ISF SKRIV UNDER OCH BERÄTTA ATT FÖREGÅENDE FINNS OCH FRÅGA OM VILL HÄMTA
        //3. HÄMTA ANNARS FRÅN AUTOANALYS

        //ALT HÄMTA BARA FRÅN AUTOANALYS, IAF TILL EN BÖRJAN <--- BÖRJA MED DETTA! GÖRS GENOM ATT JAG SÄTTER AInotable och AIreason state när autonaalys har körts.

    }

    saveAIComment = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        // SAVE TO DB HERE <-------- GLÖM EJ!!!!!! ***/
        let comparedArray = [this.state.analyseGroup1.replace(/\s/g, '').toLowerCase(), this.state.analyseGroup2.replace(/\s/g, '').toLowerCase()].sort()
        let compared = comparedArray[0] + comparedArray[1]

        let notable = this.state.AInotable
        let reason = this.state.AIreason
        let analyseType = this.state.AIAnalys

        //Check if empty, if so return "must add notable"
        if (notable === "") {
            alert(language === "sv" ? 'Du kan inte spara en tom kommentar. Fyll i noterbart rutan.' : lang[language].noEmptyComment)
            return;
        }

        //Save to DB:
        const saveData = async () => {
            const commentData = {
                notable: notable,
                reason: reason,
                todo: "",
                deadline: "",
                cost: "",
                responsible: "",
                analysis: analyseType,
                comparedGroups: compared
            }

            let response = await fetchAuth(`/api/saveEqualentComment`, 'POST', JSON.stringify({ data: commentData }));
            let data = await response.json();

            //add todays date to temporary comment,or a previous date if we are doing it for a previous year
            let selectedYear = localStorage.getItem('year')
            if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
            if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
            let d = new Date()
            let month = d.getMonth();
            let day = d.getDate();
            const todaysDate = new Date(selectedYear, month, day);
            const dateNow = todaysDate.toISOString();

            //When saved, add the new comment to the comments array
            const toState = {
                notable: notable,
                reason: reason,
                todo: "",
                deadline: "",
                cost: "",
                responsible: "",
                commentId_PK: data,
                analysis: analyseType,
                dateAdded: dateNow,
                comparedGroups: compared
            }
            const commentList = this.state.comments.concat(toState);

            //Arrange by descending date so the new comments goes to top:
            //commentList = [...comments].sort((a, b) => b.dateAdded > a.dateAdded ? 1: -1)
            const sortedComments = commentList.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)

            if (typeof data == 'number') { //it is a number because we returned the id for our last inserted item (the last id is in the data variable)
                this.setState({
                    comments: sortedComments //add the new comment to the comments array
                })
            } else {
                alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave)
            }
        };
        saveData();

        //Then hide PopUp
        this.state.showAIComment ? this.setState({ showAIComment: false }) : this.setState({ showAIComment: true })
    }

    changeTextAINotable = (event) => {
        this.setState({ AInotable: event.target.value })
    }
    changeTextAIReason = (event) => {
        this.setState({ AIreason: event.target.value })
    }

    //For edit salary and hide person functions
    hideEditBox = () => { this.setState({ popItUp: false, popUpEditSalarySetting: false }) }
    addEditSalary = (event) => {
        let salary = Number(event.target.value)
        this.setState({ newSalary: salary })
    }
    forceUpdateApexChart = (serieZero, serieOne) => {
        return new Promise(resolve => {
            this.setState({
                groupOneEdited: serieZero,
                groupTwoEdited: serieOne,
                popItUp: false,
            })
            resolve()
        });
    }
    hideEmployee = async () => {
        let serieZero = this.state.groupOneEdited
        let serieOne = this.state.groupTwoEdited
        if (this.state.serieIndex == 0) { //If serie 0.
            serieZero = this.state.groupOneEdited.slice(0) // Copy the array with all employees without mutating
            serieZero.splice(this.state.clickedPersonIndex, 1) //Remove the person from the array
        }
        if (this.state.serieIndex == 1) { //If serie 1
            serieOne = this.state.groupTwoEdited.slice(0)
            serieOne.splice(this.state.clickedPersonIndex, 1)
        }
        await this.forceUpdateApexChart(serieZero, serieOne)
        this.autoCompare("edited")
    }
    popUpEditSalarySetting = () => { this.setState({ popUpEditSalarySetting: true }) }

    editSalary = async (excluded) => {
        let serieZero = this.state.groupOneEdited
        let serieOne = this.state.groupTwoEdited
        if (this.state.serieIndex == 0) { //If serie 0
            serieZero = this.state.groupOneEdited.slice(0) // Copy the array with all men without mutating
            //Below is bugfixes to rerun chart, basically it removes the person and then ads it again
            let thePerson = serieZero[this.state.clickedPersonIndex]
            if (excluded === "exclude") { //if changed salary excluding extra 
                thePerson.salNoExtra = this.state.newSalary
            } else { //if changed salary including extra 
                thePerson.salBeforeAddOn = this.state.newSalary //Create new person with updated salary
                thePerson.salNoExtra = undefined
            }
            serieZero.splice(this.state.clickedPersonIndex, 1) //Remove the person
            if (serieZero.length === 0) { //If there is only one person in the list, push a copy
                // await this.forceUpdateApexChart(serieZero, serieOne);
            }
            if (serieZero.length != this.state.clickedPersonIndex) {//Push the person to the end if it is not in the end
                serieZero.push(thePerson)
            } else { //Else push at the start instead
                serieZero.unshift(thePerson)
            }
        }
        if (this.state.serieIndex == 1) { //If womens group
            serieOne = this.state.groupTwoEdited.slice(0) // Copy the array with all men without mutating
            //Below is bugfixes to rerun chart, basically it removes the person and then ads it again
            let thePerson = serieOne[this.state.clickedPersonIndex]
            if (excluded === "exclude") { //if changed salary excluding extra 
                thePerson.salNoExtra = this.state.newSalary //Create new person with updated salary that is not including extra
            }
            else { //if changed salary including extra 
                thePerson.salBeforeAddOn = this.state.newSalary //Create new person with updated salary
                thePerson.salNoExtra = undefined
            }
            serieOne.splice(this.state.clickedPersonIndex, 1) //Remove the person
            if (serieOne.length === 0) { //If there is only one person in the list, push a copy
                //  await this.forceUpdateApexChart(serieZero, serieOne);
            }
            if (serieOne.length != this.state.clickedPersonIndex) {//Push the person to the end if it is not in the end
                serieOne.push(thePerson)
            } else { //Else push at the start instead
                serieOne.unshift(thePerson)
            }
        }
        this.setState({ popUpEditSalarySetting: false })
        await this.forceUpdateApexChart(serieZero, serieOne)
        this.autoCompare("edited")
    }
    editSalaryExclude = () => { this.editSalary("exclude") }
    openAnalysis = (event) => {
        if (this.state.analysisOpen !== "false") {
            this.setState({ analysisOpen: "false" })
        }
        else {
            window.scroll(0, 0);
            this.setState({ analysisOpen: event.target.dataset.group })
            // console.log( event.target.dataset.group )
            let filteredData = []
            let updateEmployeeData = (groupNr) => {
                const group = groupNr
                filteredData = this.state.dataByGroup[group].flatMap(group => // this.state.dataByGroup[0][0].groups . First arraye shows what equivalentGroup, Second array  shows what equal group. "groups" shows all employees in the group.
                    group.groups.map(employee => ({
                        ...employee,
                        x: employee.experience,
                        y: employee.totalSalary
                    })))
                //  console.log("filteredData")
                //  console.log(filteredData)
            }
            updateEmployeeData(event.target.dataset.group)
            this.setState({ filteredData: filteredData })
            // Group employees by groupNameArbVardering
            const groupedData = filteredData.reduce((groups, employee) => {
                const groupName = employee.groupNameArbVardering;
                if (!groups[groupName]) {
                    groups[groupName] = [];
                }
                groups[groupName].push(employee);
                return groups;
            }, {});
            // Generate colors for each group (will start from index 0 and work upwards)
            const colors = ['#50e7a6', '#40a0fc', '#d450e7', '#7650e7', '#e7508c', '#e79e50', '#60FF33', '#3360FF', '#FFB833', '#33FFB8', '#B833FF', '#FF3366', '#66FF33', '#3366FF', '#FF33B5', '#B5FF33', '#33B5FF', '#FF3387', '#87FF33', '#3387FF', '#FF33C4', '#C4FF33', '#33C4FF', '#FF33E0', '#E0FF33', '#33E0FF', '#FFBB33', '#33FFBB', '#BB33FF', '#FF8C33', '#33FF8C', '#8C33FF', '#FF5B33', '#33FF5B', '#5B33FF', '#FF33F0', '#F0FF33', '#33F0FF', '#FFA833', '#33FFA8', '#A833FF', '#FF33D3', '#D3FF33', '#33D3FF', '#FF333F', '#3FFF33', '#333FFF'];
            //Calculate biggest xAxis
            let allExperiences = filteredData.map(person => person.experience)
            let longestExp = Math.max(...allExperiences)
            if (longestExp < 10) longestExp = 10
            if (longestExp > 10 && longestExp < 16) longestExp = 16

            // Update seriesBig and optionsBig in the state
            let seriesBig = Object.entries(groupedData).map(([groupName, employees], index) => ({
                name: groupName,
                data: employees,
                color: colors[index % colors.length],
            }));
            // New array to store the modified objects
            const annotationsList = [];
            // Iterate through each array in seriesBig
            for (const seriesItem of seriesBig) {
                // Iterate through each object in the "data" array
                for (const originalObject of seriesItem.data) {
                    // Create a new object with properties copied from the original object
                    const newObject = { ...originalObject };

                    // Add the "marker" property based on the "gender" property
                    newObject.marker = {
                        cssClass: originalObject.gender === 'man' ? 'annotationChartRhombus' : 'annotationChart',
                        size: originalObject.gender === 'man' ? 6 : 5.5,
                        radius: 0,
                        fillColor: seriesItem.color, // Use the color from the same array
                        strokeWidth: originalObject.gender === 'man' ? 0 : 0.5,
                        strokeColor: "#fff",
                    };
                    /*newObject.label = {
                        text: "M", //0x2642 is male, 0x2640 is female, use like this: String.fromCharCode(0x2640)
                        offsetY: 16.5,
                        borderWidth: 0,
                        style: {
                            background: 'transparent',
                            color: "#fff",
                            fontSize: '8px',
                            fontWeight: 600,
                        }
                    };*/
                    // Push the new object to the annotationsList array
                    annotationsList.push(newObject);
                }
            }
            // Now, annotationsList contains the modified objects with the "marker" property
            this.setState({
                seriesBig: seriesBig,
                optionsBig: {
                    ...this.state.optionsBig,
                    annotations: {
                        points: annotationsList
                    },
                    xaxis: {
                        ...this.state.optionsBig.xaxis,
                        max: longestExp,
                        min: 0,
                        labels: {
                            formatter: function (val) { return parseFloat(val).toFixed(0) }
                        },
                    },
                    /*fill: { // <-- Add to make the series themself the group color
                        colors: seriesBig.map(group => group.color),
                    },*/
                },
            });
        }
    }
    eGroupBox = (group) => {
        return (
            <>
                <div className={this.state.analysisOpen == group ? "bigDetailsBox analysisOpenWait" : "hide"}>
                    <div className="regressionContainerResult">
                        <div style={{ textAlign: "center" }}>
                            <div className="statisticsContainerBox">
                                <div className="statisticSide">
                                    <div className="statisticSideTitle" style={{ marginBottom: 6 }}>Antal anställda</div>
                                    <table style={{ display: "inline", margin: "auto" }}>
                                        <tr style={{ fontSize: 12 }}>
                                            <th>Män</th>
                                            <th>Kvinnor</th>
                                            <th>Totalt</th>
                                        </tr>
                                        <tr style={{ fontSize: 14 }}>
                                            <td style={{ paddingTop: 4 }}>{this.state.filteredData.filter(obj => obj.gender === "man").length}</td>
                                            <td style={{ paddingTop: 4 }}>{this.state.filteredData.filter(obj => obj.gender === "woman").length}</td>
                                            <td style={{ paddingTop: 4 }}>{this.state.filteredData.length}</td>
                                        </tr>

                                    </table>
                                </div>
                                <div className="statisticSide">
                                    <div className="statisticSideTitle" style={{ marginLeft: 0 }}>Medellön</div>
                                    <tr style={{ fontSize: 12 }}>
                                        <th>Män</th>
                                        <th>Kvinnor</th>
                                        <th>Totalt</th>
                                        <th>Lönegap</th>
                                    </tr>
                                    <tr style={{ fontSize: 14 }}>
                                        <td>{Math.round(this.state.filteredData.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.y, 0) / this.state.filteredData.filter(obj => obj.gender === "man").length).toLocaleString()}</td>
                                        <td>{Math.round(this.state.filteredData.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.y, 0) / this.state.filteredData.filter(obj => obj.gender === "woman").length).toLocaleString()}</td>
                                        <td>{Math.round(this.state.filteredData.reduce((sum, obj) => sum + obj.y, 0) / this.state.filteredData.length).toLocaleString()}</td>
                                        <td style={{ cursor: "pointer" }} onClick={this.gapPercent}>{this.state.gapPercent ? (((this.state.filteredData.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.y, 0) / this.state.filteredData.filter(obj => obj.gender === "woman").length) / (this.state.filteredData.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.y, 0) / this.state.filteredData.filter(obj => obj.gender === "man").length) - 1) * 100).toFixed(1) + "%"
                                            : Math.round((this.state.filteredData.filter(obj => obj.gender === "woman").reduce((sum, obj) => sum + obj.y, 0) / this.state.filteredData.filter(obj => obj.gender === "woman").length) - (this.state.filteredData.filter(obj => obj.gender === "man").reduce((sum, obj) => sum + obj.y, 0) / this.state.filteredData.filter(obj => obj.gender === "man").length)).toLocaleString()}</td>
                                    </tr>
                                </div>
                                <div className="statisticSide" style={{ borderRight: 0, marginLeft: 10, marginRight: 0, marginTop: 17.8 }}>
                                    <span className="statisticSideTitleBtnT">Spridning</span>
                                    <img onClick={this.spridningPopUp} src={arrowDown} className={this.state.spridningPopUp ? "spridningArrowUp statisticSideTitleBtn" : "statisticSideTitleBtn"} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Filterable chart here */}
                    <div className="equivalentChart">
                        <Chart
                            options={this.state.optionsBig}
                            series={this.state.seriesBig}
                            type="scatter"
                            width={500}
                            height={270}
                        />
                    </div>
                </div>
            </>
        )
    }
    eyeClick = (event) => {
        event.stopPropagation(); // Makes sure handleClick is not also running
        //KANSKE SKA JAG ÄNDRA seriesBig också eller bara?

        //FilteredData contains all individuals that should be shown, 
        //FilteredOut contains all individuals that should be hidden,
        const group = event.target.dataset.egroup;

        // DO IT FOR INDIVIDUALS ---
        let filtered = this.state.filteredData.slice()
        let filteredOut = this.state.filteredOut.slice()

        //Check if group is included in filtered
        const groupIncluded = filtered.some(item => item.groupNameArbVardering === group);

        if (groupIncluded) {
            //Move it to filteredOut
            filteredOut.push(...filtered.filter(obj => obj.groupNameArbVardering === group));
            //Remove from filtered
            filtered = filtered.filter(item => item.groupNameArbVardering !== group);
        } else {
            //Move it to filtered
            filtered.push(...filteredOut.filter(obj => obj.groupNameArbVardering === group));
            //Remove from filtered
            filteredOut = filteredOut.filter(item => item.groupNameArbVardering !== group);
        }
        this.setState({ filteredOut: filteredOut, filteredData: filtered })
        // --- END FOR THE INDIVIDUALS

        // DO IT FOR seriesBig ---
        let seriesBig = this.state.seriesBig.slice()
        let seriesBigFilteredOut = this.state.seriesBigFilteredOut.slice()

        const groupFound = seriesBig.some(element => element.name.includes(group));
        if (groupFound) {
            // Iterate through the main array
            for (let i = 0; i < seriesBig.length; i++) {
                const element = seriesBig[i];
                if (element.name === group) {
                    // Copy the array to seriesBigFilteredOut
                    seriesBigFilteredOut.push({ ...element });
                    // Remove the array from seriesBig
                    seriesBig.splice(i, 1);
                    break; // Break the loop after the first occurrence
                }
            }
        } else {
            for (let i = 0; i < seriesBigFilteredOut.length; i++) {
                const element = seriesBigFilteredOut[i];
                if (element.name === group) {
                    // Copy the "data" array to the new array
                    seriesBig.push({ ...element });
                    seriesBigFilteredOut.splice(i, 1);
                    break;
                }
            }
        }
        this.setState({ seriesBig: seriesBig, seriesBigFilteredOut: seriesBigFilteredOut })

        // -- End for seriesBig

        // For annotations
        let annotations = this.state.optionsBig.annotations.points.slice()
        let annotationsFilteredOut = this.state.annotationsFilteredOut.slice()

        //OVAN FUNKAR MEN VI MÅSTE RENSA ANNOTATIONS MED!
        //Check if group is included in filtered
        const groupIncludedA = annotations.some(item => item.groupNameArbVardering === group);
        if (groupIncludedA) {
            //Move it to filteredOut
            annotationsFilteredOut.push(...annotations.filter(obj => obj.groupNameArbVardering === group));
            //Remove from filtered
            annotations = annotations.filter(item => item.groupNameArbVardering !== group);
        } else {
            //Move it to filtered
            annotations.push(...annotationsFilteredOut.filter(obj => obj.groupNameArbVardering === group));
            //Remove from filtered
            annotationsFilteredOut = annotationsFilteredOut.filter(item => item.groupNameArbVardering !== group);
        }
        this.setState({
            annotationsFilteredOut: annotationsFilteredOut,
            optionsBig: {
                ...this.state.optionsBig,
                annotations: {
                    points: annotations
                },
            }
        })
    }

    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        {/* Adding groups */ }
        let equivalentGroup = []

        if (this.state.dataByGroup.length > 0) {
            equivalentGroup = this.state.dataByGroup
        } //[1,2,3] //here we add all the groups, they are then rendered at row 2519

        {/* For comments */ }

        let comments = language === "sv" ? "Inga kommentarer för senaste året." : lang[language].noCommentsFromThisYear

        if (this.state.comments.length > 0) {
            comments = this.state.comments.map((comment) => {
                if (this.state.analysis === "all") { //This is the filter
                    return <tr className="trStyleLine" id={comment.commentId_PK} key={comment.commentId_PK}>
                        <td className="thpadding"><TextareaAutosize onBlur={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange vad som är noterbart" : lang[language].notable} name="notable" id={comment.commentId_PK} defaultValue={comment.notable} /></td>
                        <td className="thpadding"><TextareaAutosize onBlur={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange orsak" : lang[language].reason} name="reason" id={comment.commentId_PK} defaultValue={comment.reason} /></td>
                        <td className="thpadding"><TextareaAutosize onBlur={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange åtgärd (vid behov)" : lang[language].action} name="todo" id={comment.commentId_PK} defaultValue={comment.todo} /></td>
                        <td className="thpadding"><input type="date" value={comment.deadline} onChange={this.editComment} name="deadline" id={comment.commentId_PK} /></td>
                        <td className="thpadding" style={{ width: "8%" }}><TextareaAutosize className="autoHeightText" placeholder="0" defaultValue={comment.cost} onBlur={this.editComment} name="cost" id={comment.commentId_PK} /></td>
                        <td className="thpadding"><TextareaAutosize onBlur={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange ansvarig" : lang[language].responsible} name="responsible" id={comment.commentId_PK} defaultValue={comment.responsible} /></td>
                        <td className="thpadding remove"><img className="checkFinish" src={remove} alt="" style={{ width: 20, marginTop: -20, filter: 'grayscale(100%)', opacity: 0.75 }} id={comment.commentId_PK} onClick={this.deleteComment} /><span className="analysisIcon">{comment.analysis.charAt(0).toUpperCase()}</span></td>
                    </tr>
                } else if (comment.analysis === this.state.analysis) { //This is the filter
                    return <tr className="trStyleLine" id={comment.commentId_PK} key={comment.commentId_PK}>
                        <td className="thpadding"><TextareaAutosize onBlur={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange vad som är noterbart" : lang[language].notable} name="notable" id={comment.commentId_PK} defaultValue={comment.notable} /></td>
                        <td className="thpadding"><TextareaAutosize onBlur={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange orsak" : lang[language].reason} name="reason" id={comment.commentId_PK} defaultValue={comment.reason} /></td>
                        <td className="thpadding"><TextareaAutosize onBlur={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange åtgärd (vid behov)" : lang[language].action} name="todo" id={comment.commentId_PK} defaultValue={comment.todo} /></td>
                        <td className="thpadding"><input type="date" value={comment.deadline} onChange={this.editComment} name="deadline" id={comment.commentId_PK} /></td>
                        <td className="thpadding" style={{ width: "8%" }}><TextareaAutosize className="autoHeightText" placeholder="0" defaultValue={comment.cost} onBlur={this.editComment} name="cost" id={comment.commentId_PK} /></td>
                        <td className="thpadding"><TextareaAutosize onBlur={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange ansvarig" : lang[language].responsible} name="responsible" id={comment.commentId_PK} defaultValue={comment.responsible} /></td>
                        <td className="thpadding remove"><img className="checkFinish" src={remove} alt="" style={{ width: 20, marginTop: -20, filter: 'grayscale(100%)', opacity: 0.75 }} id={comment.commentId_PK} onClick={this.deleteComment} /></td>
                    </tr>
                }
            });
        }

       // let pastComments = language === "sv" ? "Inga kommentarer från tidigare år." : lang[language].noCommentsPastYear
       let pastComments = ""
        if (this.state.commentsLastYear.length > 0) { //<--- FILTER PAST YEARS COMMENTS!!
            pastComments = this.state.commentsLastYear.map((comment) => {
                if (this.state.analysis === "all") { //This is the filter
                    return <tr className="trStyleLine" id={comment.commentId_PK} key={comment.commentId_PK}>
                        <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="notable" id={comment.commentId_PK} value={comment.notable} /></td>
                        <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="reason" id={comment.commentId_PK} value={comment.reason} /></td>
                        <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="todo" id={comment.commentId_PK} value={comment.todo} readOnly/></td>
                        <td className="thpadding">{comment.deadline == null ? "" : <input type="date" value={comment.deadline} name="deadline" id={comment.commentId_PK} />}</td>
                        <td className="thpadding" style={{ width: "8%" }}>{comment.cost == null ? "" : <TextareaAutosize className="autoHeightText" placeholder="" value={comment.cost} name="cost" id={comment.commentId_PK} />}{comment.cost == null ? "" : ""}</td>
                        <td className="thpadding">{comment.todo != null && comment.todo.length > 0 ? <span className="noReuse">{language === "sv" ? "Utvärderas" : lang[language].evaluates}</span> : this.state.comments.some(e => e.reuseId === comment.commentId_PK) ? <><span className="noReuse">{language === "sv" ? "Återanvänd" : lang[language].reuse}</span><span style={{ display: "block", marginTop: 15, color: "#3f9985", textDecoration: "underline", cursor: "pointer" }} onClick={this.reuseComment} id={comment.commentId_PK}>{language === "sv" ? "Återanvänd igen" : lang[language].reuseAgain}</span></> : <span id={comment.commentId_PK} onClick={this.reuseComment} className="lastYearButton">{language === "sv" ? "Återanvänd" : lang[language].reuse}</span>}</td>
                        <td className="thpadding remove"></td>
                    </tr>
                } else if (comment.analysis === this.state.analysis) { //This is the filter
                    return <tr className="trStyleLine" id={comment.commentId_PK} key={comment.commentId_PK}>
                        <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="notable" id={comment.commentId_PK} value={comment.notable} /></td>
                        <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="reason" id={comment.commentId_PK} value={comment.reason} /></td>
                        <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="todo" id={comment.commentId_PK} value={comment.todo} readOnly/></td>
                        <td className="thpadding">{comment.deadline == null ? "" : <input type="date" value={comment.deadline} name="deadline" id={comment.commentId_PK} />}</td>
                        <td className="thpadding" style={{ width: "8%" }}>{comment.cost == null ? "" : <TextareaAutosize className="autoHeightText" placeholder="" value={comment.cost} name="cost" id={comment.commentId_PK} />}{comment.cost == null ? "" : ""}</td>
                        <td className="thpadding">{comment.todo != null && comment.todo.length > 0 ? <span className="noReuse">{language === "sv" ? "Utvärderas" : lang[language].evaluates}</span> : this.state.comments.some(e => e.reuseId === comment.commentId_PK) ? <><span className="noReuse">{language === "sv" ? "Återanvänd" : lang[language].reuse}</span><span style={{ display: "block", marginTop: 15, color: "#3f9985", textDecoration: "underline", cursor: "pointer" }} onClick={this.reuseComment} id={comment.commentId_PK}>{language === "sv" ? "Återanvänd igen" : lang[language].reuseAgain}</span></> : <span id={comment.commentId_PK} onClick={this.reuseComment} className="lastYearButton">{language === "sv" ? "Återanvänd" : lang[language].reuse}</span>}</td>
                        <td className="thpadding remove"></td>
                    </tr>
                }
            });
        }

        return (
            <>
                <Prompt
                    when={this.state.saveButtonStatus === "Spara" && this.state.showChangesPrompt !== "No"}
                    message={language === "sv" ? "Du har gjort ändringar utan att spara. Är du säker på att du vill fortsätta?" : lang[language].changesMadeSave}
                />
                <TopNav />
                <Menu />
                <div className="container">
                    <Information step="analysLikvardiga" />

                    <button className={this.state.saveButtonStatus === "Spara" ? "saveFixedButton" : "saveFixedButton savedFixedButtonSparat"} onClick={this.state.saveButtonStatus === "Spara" ? this.saveButton : ""}>{language === "sv" ? this.state.saveButtonStatus : (this.state.saveButtonStatus === "Spara" ? lang[language].save : lang[language].saved)}</button>

                    {/* PopUp Questionmark */}
                    <div className={this.state.popUp ? "popUp active-popUp" : "popUp"} style={{ height: "auto", maxWidth: 700, marginTop: -300, fontSize: 14.6 }}>
                        <img alt="" src={exitImg} class="hide-box" onClick={this.togglePop} />
                        <h4 style={{ fontSize: 15.6 }}>{language === "sv" ? "Detaljerad och automatisk analys" : lang[language].detailedAnalysis}</h4>
                        {language === "sv" ? "Här kan du utföra en detaljerad analys mellan två grupper. Se mer detaljer, visualisera individuella skillnader samt få en automatisk analys." : lang[language].detailedAnalysisInfo}  <div style={{ padding: 20 }}>
                            <p style={{ textAlign: "left" }}><p style={{ marginBottom: 5, marginTop: 20 }}><b>{language === "sv" ? "Välj grupper" : lang[language].chooseGroups}</b></p>{language === "sv" ? `Du väljer grupp att analysera genom att först klicka på en av rutorna där det står "Välj grupp". Texten kommer nu ändras till "Klicka på grupp". Klicka på den första gruppen du vill se. Gör nu likadant för den andra rutan där det står "Välj grupp".` : lang[language].chooseGroupInfo}</p>
                            <p style={{ textAlign: "left" }}><p style={{ marginBottom: 5, marginTop: 20 }}><b>{language === "sv" ? "Byt ut grupper" : lang[language].changeGroups}</b></p>{language === "sv" ? `För att byta ut en grupp klickar du helt enkelt i den ruta där det tidigare stod "Välj grupp" (nu står gruppnamnet). Texten kommer nu ändras till "Klicka på grupp". Klicka på den grupp du vill se istället.` : lang[language].changeGroupInfo}</p>
                        </div>
                    </div>
                    <div className={this.state.popUp ? "popUpFader" : "hide"}></div>
                    {/* PopUp infoblock */}
                    <div style={{ height: "auto" }} className={this.state.popUpInfo ? "popUp active-popUp" : "popUp"}>
                        <img alt="" src={exitImg} class="hide-box" onClick={this.popUpInfo} />
                        <div style={{ padding: 20, textAlign: "left", fontSize: 15.6 }}>
                            <span><b>{language === "sv" ? "Analysen tar hänsyn till flera faktorer såsom: " : lang[language].aiAnalysisInfo}</b></span>
                            {language === "sv" ? <ul style={{ listStyleType: "circle", paddingLeft: 20, padding: "inherit" }}>
                                <li>Lönenivåer</li>
                                <li>Könsfördelning</li>
                                <li>Ålder</li>
                                <li>Erfarenhet</li>
                                <li>Gruppstorlek</li>
                                <li>Placeringsort</li>
                                <li>Arbetets krav</li>
                                <li style={{ display: "block" }}>... och mer</li>
                            </ul> : lang[language].aiAnalysisUl}

                            <span>{language === "sv" ? "Verkligheten kan dock vara mer komplex och andra faktorer kan spela in, såsom marknaden, bibehållna löner, prestationer etc, varav du alltid bör göra en egen bedömning. Eventuella felaktigheter reserveras." : lang[language].aiAnalyisInfoTwo}</span>
                        </div>
                    </div>
                    <div className={this.state.popUpInfo ? "popUpFader" : "hide"}></div>


                    {/* Auto analysis */}
                    <div className="equivalentContainer">
                        <div className="autoAnalysisContainer">
                            <div className={this.state.divOpened === "first" ? "topAnalysis hideBoxShadowInitial" : this.state.divOpened === "yes" ? "topAnalysis showBoxShadow" : "topAnalysis hideBoxShadow"}>
                                <span onClick={this.selectGroup1} className={this.state.analyseGroup1 === "Välj grupp" || this.state.analyseGroup1 === "Klicka på grupp" ? "groupAnalysis selectGroup" : "groupAnalysis"} >{language === "sv" ? this.state.analyseGroup1 : (this.state.analyseGroup1 === "Välj grupp" ? lang[language].selectGroup : this.state.analyseGroup1 === "Klicka på grupp" ? lang[language].clickGroup : this.state.analyseGroup1)}</span>
                                <span style={{ fontSize: 16, display: "inline-block", fontWeight: 600 }}> &amp;</span>
                                <span onClick={this.selectGroup2} className={this.state.analyseGroup2 === "Välj grupp" || this.state.analyseGroup2 === "Klicka på grupp" ? "groupAnalysis selectGroup" : "groupAnalysis"} >{language === "sv" ? this.state.analyseGroup2 : (this.state.analyseGroup2 === "Välj grupp" ? lang[language].selectGroup : this.state.analyseGroup2 === "Klicka på grupp" ? lang[language].clickGroup : this.state.analyseGroup2)}</span>
                                <div onClick={this.autoCompare} className="compareButton">{language === "sv" ? "Jämför" : lang[language].compare}</div>
                                <span onClick={this.toggleDiv} className="minusAnalysis">-</span>
                                <span className="questionMark" onClick={this.togglePop}>?</span>
                            </div>

                            <div className={this.state.divOpened === "first" ? "hideAnalysisInitial" : this.state.divOpened === "yes" ? "showAnalysis" : "hideAnalysis"}>
                                <div style={{ display: "inline-block", verticalAlign: "top", width: 380, fontSize: 13.6, marginTop: -5 }}>
                                    <div class="cssGrid">
                                        <div class="gridLine"> </div>
                                        <div class="gridLine autoTitleGroup" style={{ paddingRight: 2 }}>{language === "sv" ? this.state.analyseGroup1 : (this.state.analyseGroup1 === "Välj grupp" ? lang[language].selectGroup : this.state.analyseGroup1 === "Klicka på grupp" ? lang[language].clickGroup : this.state.analyseGroup1)}</div>
                                        <div class="gridLine autoTitleGroup" style={{ paddingLeft: 2 }}>{language === "sv" ? this.state.analyseGroup2 : (this.state.analyseGroup2 === "Välj grupp" ? lang[language].selectGroup : this.state.analyseGroup2 === "Klicka på grupp" ? lang[language].clickGroup : this.state.analyseGroup2)}</div>
                                        <div class="gridLine autoTitleGroup">{language === "sv" ? "Differens" : lang[language].difference}</div>
                                        <div class="autoTitel">{language === "sv" ? "Krav" : lang[language].requirements}</div>
                                        <div class="gridLine">{this.state.tableInfo[0].oneEqualentGroup}</div>
                                        <div class="gridLine">{this.state.tableInfo[0].twoEqualentGroup}</div>
                                        <div class="gridLine"> {isNaN(this.state.tableInfo[0].oneEqualentGroup - this.state.tableInfo[0].twoEqualentGroup) ? "" : this.state.tableInfo[0].oneEqualentGroup - this.state.tableInfo[0].twoEqualentGroup}</div>
                                        <div class="autoTitel">{language === "sv" ? "Kvinnor" : lang[language].women}</div>
                                        <div class="gridLine">{isNaN(this.state.tableInfo[0].oneWomen) ? "" : this.state.tableInfo[0].oneWomen + "%"} </div>
                                        <div class="gridLine">{isNaN(this.state.tableInfo[0].twoWomen) ? "" : this.state.tableInfo[0].twoWomen + "%"} </div>
                                        <div class="gridLine"> {isNaN(this.state.tableInfo[0].diffWomen) ? "" : this.state.tableInfo[0].diffWomen + "%"} </div>
                                        <div class="autoTitel"> {language === "sv" ? "Anställda" : lang[language].employees}</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].oneNrOfEmp}</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].twoNrOfEmp}</div>
                                        <div class="gridLine"> {isNaN(this.state.tableInfo[0].oneNrOfEmp - this.state.tableInfo[0].twoNrOfEmp) ? "" : this.state.tableInfo[0].oneNrOfEmp - this.state.tableInfo[0].twoNrOfEmp}</div>
                                        <div class="autoTitel"> {this.state.hideExtra === "onlyExtra" ? language === "sv" ? "M. tillägg" : lang[language].avgBen : language === "sv" ? "M. lön" : lang[language].avgSal}</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].oneAvgSalary === undefined ? "" : this.state.tableInfo[0].oneAvgSalary.toLocaleString()}</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].twoAvgSalary === undefined ? "" : this.state.tableInfo[0].twoAvgSalary.toLocaleString()}</div>
                                        <div class="gridLine" style={{ cursor: "pointer" }} onClick={this.showDiffPercent}> {isNaN(this.state.tableInfo[0].oneAvgSalary - this.state.tableInfo[0].twoAvgSalary) ? "" : !this.state.showDiffPercent ? (this.state.tableInfo[0].oneAvgSalary - this.state.tableInfo[0].twoAvgSalary).toLocaleString() : (Math.round((100 - ((this.state.tableInfo[0].twoAvgSalary / this.state.tableInfo[0].oneAvgSalary) * 100)) * 10) / 10) + "%"}</div>
                                        <div class="autoTitel"> {language === "sv" ? "M. ålder" : lang[language].avgAge}</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].oneAvgAge}</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].twoAvgAge}</div>
                                        <div class="gridLine"> {isNaN(this.state.tableInfo[0].oneAvgAge - this.state.tableInfo[0].twoAvgAge) ? "" : this.state.tableInfo[0].oneAvgAge - this.state.tableInfo[0].twoAvgAge}</div>
                                        <div class="autoTitel"> {language === "sv" ? "M. erfarenhet" : lang[language].avgExp}</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].oneAvgExp}</div>
                                        <div class="gridLine"> {this.state.tableInfo[0].twoAvgExp}</div>
                                        <div class="gridLine"> {isNaN(this.state.tableInfo[0].oneAvgExp - this.state.tableInfo[0].twoAvgExp) ? "" : this.state.tableInfo[0].oneAvgExp - this.state.tableInfo[0].twoAvgExp}</div>
                                        <div class="autoTitel">{language === "sv" ? "Lönespridning" : lang[language].salarySpread}</div>
                                        <div class="gridLine" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> {this.state.tableInfo[0].spridningskvotG1}</div>
                                        <div class="gridLine" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> {this.state.tableInfo[0].spridningskvotG2}</div>
                                        <div class="gridLine" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> {this.state.tableInfo[0].spridningDiff}</div>
                                        <div class="autoTitel"> {language === "sv" ? "M. ort" : lang[language].avgLocation}</div>
                                        <div class="gridLine" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> {this.state.tableInfo[0].oneAvgCity}</div>
                                        <div class="gridLine" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}> {this.state.tableInfo[0].twoAvgCity}</div>
                                        <div class="gridLine"> </div>
                                    </div>
                                </div>

                                <div className="analysisInText">
                                    <div style={{ marginTop: -5 }}>
                                        <h4 style={{ margin: 0, fontWeight: 800, fontSize: 15.6 }}>{language === "sv" ? "Analys" : lang[language].aiAnalysisTitle}<span className="infoIcon" onClick={this.popUpInfo}>i</span></h4>
                                        <span className="analyseText">
                                            {this.state.analysisText}
                                            <span
                                                data-tip={language === "sv" ? "Ålder i sig är inte en saklig orsak, det behöver vara relevant tidigare erfarenhet." : lang[language].aboutAge}
                                                className={this.state.showInfoIcon ? "infoIconInText" : "hide"}
                                                data-background-color="white"
                                                data-text-color="#333333"
                                            >i</span>
                                            <ReactTooltip />
                                        </span>
                                    </div>
                                    {/*<div className="addAsReasonButton">Ange som orsak</div> */}
                                    <div onClick={this.AIComment} className={this.state.divOpened === "yes" ? "addAsReasonButton" : "hideAddAsReasonButton"}>{language === "sv" ? "Använd som kommentar" : lang[language].useAsComment}</div>
                                    <div className={this.state.showAIComment ? "AIComment" : "hide"}>
                                        <img style={{ position: "absolute", right: 8, top: 8 }} alt="" src={exitImg} class="hide-box" onClick={this.AIComment} />
                                        <div className="AICommentTextField AICommentTitles">{language === "sv" ? "Noterbart" : lang[language].notable}</div>
                                        <div className="AICommentTextField AICommentTitles">{language === "sv" ? "Orsak" : lang[language].reason}</div>
                                        <TextareaAutosize id="AISelComment" onChange={this.changeTextAINotable} style={{ marginRight: 7 }} className="autoHeightText AICommentTextField" placeholder="" value={this.state.AInotable} name="AINotable" />
                                        <TextareaAutosize id="AISelReason" onChange={this.changeTextAIReason} style={{ marginLeft: 7 }} className="autoHeightText AICommentTextField" placeholder="" value={this.state.AIreason} name="AIReason" />
                                        <div className="addAsReasonButton AIAcceptButton" onClick={this.saveAIComment}>{language === "sv" ? "Ange som kommentar" : lang[language].useAsComment}</div>
                                    </div>
                                    <div className={this.state.showAIComment ? "popUpFader popUpFaderFixFader" : "hide"}></div>
                                </div>

                                <div class={this.state.apexPopUp !== "" ? "apexPopUpGreen" : "chartContainerLikvardiga"}>
                                    <div style={{ zIndex: 13, position: "absolute", maxWidth: 145, boxShadow: "0 2px 10px 0 rgb(128 128 128 / 40%)", height: "auto", paddingBottom: 0, top: 260, color: "#333333" }} className={this.state.popItUp ? "popUp active-popUp" : "hide"}>
                                        <span className="exitVideo" onClick={this.hideEditBox} style={{ marginTop: 0, float: "right", textAlign: "center", cursor: "pointer", fontSize: 14.6, fontWeight: 600, height: 22, width: 22 }}>x</span>
                                        <span style={{ fontWeight: 800, display: "block", paddingBottom: 16, marginTop: -20, float: "left", fontSize: 13.6 }}>{language === "sv" ? "Justera" : lang[language].adjust}</span>
                                        <div className={this.state.popUpEditSalarySetting ? "hide" : ""}>
                                            <span style={{ display: "block", marginTop: 35, marginBottom: 6, fontSize: 13.6, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", letterSpacing: -0.2 }}>{!this.state.hideName ? this.state.clickedPerson.fullName : ""}</span>
                                            <div className={this.state.showPercent ? "hide" : ""}><input type="number" placeholder="Lön" style={{ width: 75, border: "1px solid #d5d4d4", display: "inlinge-block", padding: 0, fontWeight: "normal", height: 30, margin: 0, verticalAlign: "top", borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: "none", fontSize: 13.6 }} value={this.state.addEditSalary} onChange={this.addEditSalary} /><div style={{ display: "inline-block", border: "1px solid #d5d4d4", height: 30, verticalAlign: "top", lineHeight: "30px", paddingLeft: 7, paddingRight: 7, borderTopRightRadius: 5, borderBottomRightRadius: 5, cursor: "pointer", fontSize: 13.6, background: "#d7d7d7" }} onClick={this.state.clickedPerson.addOn > 0 ? this.popUpEditSalarySetting : this.editSalary}>Ny lön</div></div>
                                            <div onClick={this.hideEmployee} style={{ height: 30, lineHeight: "30px", width: 133, margin: "auto", marginTop: 7, borderRadius: 5, fontSize: 13.6, cursor: "pointer", background: "#d7d7d7" }}>Dölj anställd</div>
                                            <p style={{ fontSize: 11.6, borderTop: "1px solid #cacaca", paddingTop: 2, marginBottom: 6, marginTop: 6 }}>{language === "sv" ? "Klicka på jämför för att återställa justeringen." : lang[language].resetChange}</p>
                                        </div>
                                        <div className={this.state.popUpEditSalarySetting ? "" : "hide"}>
                                            <span style={{ display: "block", fontSize: 12.6, marginTop: 35 }}>{language === "sv" ? "Personen har ett tillägg på " : lang[language].personHasBenefit} {this.state.clickedPerson.addOn}{language === "sv" ? "kr. " : ". "}<p>{language === "sv" ? "Ska tillägget inkluderas eller adderas till summan?" : lang[language].personBenefitQuestion}</p></span>
                                            <div style={{ display: "inline-block", fontSize: 13.6, padding: 4, background: "#d7d7d7", borderRadius: 5, marginRight: 4, cursor: "pointer" }} onClick={this.editSalary}>{language === "sv" ? "Inkludera" : lang[language].include}</div><div style={{ display: "inline-block", fontSize: 13.6, padding: 4, background: "#d7d7d7", borderRadius: 5, marginLeft: 4, cursor: "pointer", marginBottom: 15 }} onClick={this.editSalaryExclude}>{language === "sv" ? "Addera" : lang[language].personBenefitAdd}</div>
                                        </div>
                                    </div>
                                    <Chart
                                        options={this.state.options}
                                        series={this.state.series}
                                        type="scatter"
                                        width={this.state.chartWidth}
                                        height={this.state.chartHeight}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* End of auto analysis container */}


                    <div className="equivalentContainerExt" >
                        {/* Here we add the groups */}
                        {equivalentGroup.map((group, i) => {
                            const payG = this.state.showWhat === "EU" ? this.state.dataByGroup[i][0].payGap : ""
                            //const payG = ""
                            return (
                                <div className={this.state.analysisOpen != "false" && this.state.analysisOpen != i ? "hide" : this.state.analysisOpen == i ? "equivalentGroupOpen" : (this.state.showAIComment ? "equivalentGroup equivalentGroupOnAI" : "equivalentGroup")}>
                                    <div className="infoBoxEq">
                                        {this.state.showWhat === "EU" &&
                                            <div className={this.state.analysisOpen == i ? "moreDetailsBtn moreDetailsBtnClose" : "moreDetailsBtn"}>
                                                <div className="moreDetailsBtnLeft">
                                                    <span className="payGapText">Lönegap: </span><span> {payG} %</span>
                                                </div>
                                                <div data-group={i} className="moreDetailsBtnRight" onClick={this.openAnalysis}>Analysera</div>
                                            </div>
                                        }
                                        <div className="fillertitleEq"><h4 data-group={i} onClick={this.state.showWhat === "EU" ? this.openAnalysis : ""} className={this.state.bigTextIcon ? "groupNamesH4" : "groupNamesH4A"}>{group[0].g !== null ? (language === "sv" ? "Grupp " : lang[language].group) + group[0].g : (language === "sv" ? "Saknar grupp" : lang[language].groupMissing)}</h4><img src={bigText} className={this.state.bigTextIcon ? "bigTextIcon" : "hide"} id={group[0].g} onClick={this.makeTextbig} /></div>
                                        <div className="titleEq flag"></div> {/* for flag */}
                                        <div className="titleEq">{language === "sv" ? "Kvinnor" : lang[language].women}</div>
                                        <div className="titleEq">{language === "sv" ? "Ans." : lang[language].empMinimal}</div>
                                        <div className="titleEq">{language === "sv" ? "M. lön" : lang[language].avgSalMinimal}</div>
                                        <div style={{ width: 55 }} className={this.state.analysisOpen == i ? "titleEq" : "hide"}>Lönegap</div>
                                        {/* Here is overall statistics for the group */}
                                        <div className={this.state.analysisOpen == i ? "equivalentGroupOverview analysisOpenWait" : "hide analysisOpenHide"}>
                                            <div><img src={arrowDown} className="bigequalentbackBtn" onClick={this.openAnalysis} /></div>
                                            <div className="equivalentGroupOverviewItem"><div className="insideTitleEquivalent">Lönegap</div><div style={{ fontWeight: 600 }}>{payG} %</div></div>
                                            {/* <div className="equivalentGroupOverviewItem"><div className="insideTitleEquivalent">Åtgärdat</div><div>1 %</div></div>
                            <div className="equivalentGroupOverviewItem"><div className="insideTitleEquivalent">Förklarat</div><div>8.5 % <span onMouseEnter={this.infoBoxEU} onMouseLeave={this.infoBoxEU} className="infoIcon" style={{ cursor: "help", color: "black", borderColor: "black", background: "white", marginLeft: 5 }}>i</span></div><div className={this.state.infoBoxEU ? "infoBoxEU" : "hide"}>Vid klarmarkering kommer återstående procent som inte hamnat under åtgärdat att hamna under förklarat. </div></div>
                    <div className="equivalentGroupOverviewItem"><div className="notFinished">Klarmarkera</div></div>*/}
                                        </div>
                                    </div>
                                    <ul style={{ fontSize: 15.6, letterSpacing: -0.3 }} className={this.state.analysisOpen == i ? "ulListBigStyle" : ""}>
                                        {group.map((item, index) => (
                                            // <li className="infoBoxEq infoBoxList" 
                                            <li style={{ opacity: this.state.annotationsFilteredOut.some(itemB => itemB.groupNameArbVardering === item.groupNameArbVardering) ? 0.5 : 1 }} className={item.groupNameArbVardering === this.state.clickedGroup ? "infoBoxEq infoBoxList listSelected" :
                                                //  If clicked group is women dominated, this marks male and equal groups with lower demands but higher salary
                                                (this.state.womenPercent !== null && this.state.womenPercent > 59 && item.percentageOfWomen < 60 && item.averageSalary > this.state.avgSalary && item.g < this.state.demands) ? "infoBoxEq infoBoxList markList"
                                                    // If clicked group is not women dominated, this marks women groups with higher demands but lower salary
                                                    : (this.state.womenPercent !== null && this.state.womenPercent < 60 && item.percentageOfWomen > 59 && item.averageSalary < this.state.avgSalary && item.g > this.state.demands) ? "infoBoxEq infoBoxList markList"
                                                        //    If clicked group is women dominated, this marks male and equal groups with same demands but higher salary
                                                        : (this.state.womenPercent !== null && this.state.womenPercent > 59 && item.percentageOfWomen < 60 && item.averageSalary > this.state.avgSalary && item.g === this.state.demands) ? "infoBoxEq infoBoxList markList"
                                                            //      If clicked group is male dominated, this marks women groups with same demands but lower salary
                                                            : (this.state.womenPercent !== null && this.state.womenPercent < 60 && item.percentageOfWomen > 59 && item.averageSalary < this.state.avgSalary && item.g === this.state.demands) ? "infoBoxEq infoBoxList markList"
                                                                //Else, dont mark
                                                                : "infoBoxEq infoBoxList default"
                                            }
                                                key={item.positionTitle}
                                                id={
                                                    `{"group": "${item.groupNameArbVardering}", 
                                         "demands": ${item.g},
                                         "womenPercent": ${item.percentageOfWomen},
                                         "avgSalary": ${item.averageSalary} }`
                                                }
                                                onClick={this.handleClick}>
                                                <div className={!this.state.textBig.includes(item.g) ? "fillertitleEq" : "fillertitleEqBig"}>{item.groupNameArbVardering}</div>
                                                <div className="titleEq flag">{item.percentageOfWomen > 59 ? <img alt="" style={{ width: 14, paddingBottom: 3 }} src={yellowFlag} /> : item.percentageOfWomen < 41 ? <img alt="" style={{ width: 14, paddingBottom: 3 }} src={blueFlag} /> : ""}</div>
                                                <div className="titleEq noBold">{Math.round(item.percentageOfWomen)}%</div>
                                                <div className="titleEq noBold">{item.numberOfPersons}</div>
                                                <div className="titleEq noBold">{Math.round(item.averageSalary)}</div>
                                                <div className={this.state.analysisOpen == i ? "titleEq noBold" : "hide"} style={{ width: 55 }}>5 %</div>
                                                <img data-egroup={item.groupNameArbVardering} src={this.state.annotationsFilteredOut.some(itemA => itemA.groupNameArbVardering === item.groupNameArbVardering) ? eyeOff : eyeOn} onClick={this.eyeClick} className="eye" style={{ visibility: this.state.analysisOpen == i ? "visible" : "hidden" }} />
                                                <div className="colorListItem" style={{ background: this.state.seriesBig.find(itemA => itemA.name === item.groupNameArbVardering)?.color || "#fff", /*boxShadow: `-1px 0px 4px ${this.state.seriesBig.find(item => item.name === elem.group)?.color || "#fff"}`*/ }} />
                                            </li>
                                        ))}
                                    </ul>
                                    {(this.state.showWhat === "EU" && this.state.analysisOpen == i) && this.eGroupBox(`${i}`)}
                                </div>
                            )
                        }
                        )}

                    </div>

                    <div className="commentContainer likvCommentCont">
                        <h3 style={{ marginTop: 0, fontWeight: 800 }}>{language === "sv" ? "Anteckna" : lang[language].note}</h3>
                        <div style={{ marginBottom: 15 }}>
                            <div className={this.state.addAnalysis === "likvärdiga" ? "comment-select-left comment-select-this" : "comment-select-left"} onClick={this.addAnalysisEqualent}>{language === "sv" ? "Likvärdig" : lang[language].equivalent}</div>
                            <div className={this.state.addAnalysis === "hierarkisk" ? "comment-select-right comment-select-this" : "comment-select-right"} onClick={this.addAnalysisHierarchy}>{language === "sv" ? "Hierarkisk" : lang[language].hierarchical}</div>
                        </div>
                        <textarea id="curSelComment" className="comment" placeholder={language === "sv" ? "Noterbart" : lang[language].notable} style={{ minHeight: 48 }} onBlur={this.handleComment} defaultValue={this.state.addComment} ref={el => this.curSelComment = el} />
                        <div className="fieldsB" onClick={this.toggleFields}>{language === "sv" ? "Fler fält " : lang[language].moreFields}<img src={arrowDown} style={{ width: 9, marginLeft: 5 }} className={this.state.moreFields ? "arrowUp" : ""} /> </div>
                        <div className={this.state.moreFields ? "" : "hideFields"} style={{ width: 630, margin: "auto" }}>
                            <textarea id="curSelReason" className="comment reason" placeholder={language === "sv" ? "Orsak" : lang[language].reason} style={{ minHeight: 48 }} onBlur={this.handleReason} defaultValue={this.state.addReason} ref={el => this.curSelReason = el} />
                            <textarea id="curSelTodo" className="comment todo" placeholder={language === "sv" ? "Åtgärd" : lang[language].action} style={{ minHeight: 48 }} onBlur={this.handleTodo} defaultValue={this.state.addTodo} ref={el => this.curSelTodo = el} />
                            <input style={{ background: "white", textAlign: "center", }} type="date" id="deadline" name="deadline" className="dateBox" onChange={this.handleDeadline} value={this.state.addDeadline} />
                            <input id="curSelCost" type="text" className="comment cost" placeholder={language === "sv" ? "Kostnad" : lang[language].cost} onBlur={this.handleCost} defaultValue={this.state.addCost} ref={el => this.curSelCost = el} />
                            <input id="curSelResp" type="text" className="comment responsible" placeholder={language === "sv" ? "Ansvarig" : lang[language].responsible} onBlur={this.handleResponsible} defaultValue={this.state.addResponsible} ref={el => this.curSelResp = el} />
                            <span className="dateBoxTitle">Deadline</span>
                        </div>
                        <div
                            className="commentButton"
                            style={{ width: 120, display: "block", margin: "auto", marginTop: 20, fontSize: 15.6 }}
                            onClick={this.saveNewComment}>{language === "sv" ? "Spara" : lang[language].save}</div>
                    </div>

                    <div className="allCommentsContainer">
                        <div>
                            <h3 style={{ paddingTop: 30, paddingBottom: 5, fontSize: 23.6, fontWeight: 900 }}>{language === "sv" ? "Noteringar" : lang[language].notes}</h3>
                            <div className={this.state.analysis === "all" ? "filterButtonLika activeFilter" : "filterButtonLika"} onClick={this.filterAll}>{language === "sv" ? "Alla" : lang[language].all}</div>
                            <div className={this.state.analysis === "likvärdiga" ? "filterButtonLika activeFilter" : "filterButtonLika"} onClick={this.filterEqualent}>{language === "sv" ? "Likvärdiga" : lang[language].equivalent}</div>
                            <div className={this.state.analysis === "hierarkisk" ? "filterButtonLika activeFilter" : "filterButtonLika"} onClick={this.filterHierarchy}>{language === "sv" ? "Hierarkiska" : lang[language].hierarchical}</div>
                        </div>

                        <table className="commentsTable">
                            <tr className="trStyleLine">
                                <th className="thpadding">{language === "sv" ? "Noterbart" : lang[language].notable}</th>
                                <th className="thpadding">{language === "sv" ? "Orsak" : lang[language].reason}</th>
                                <th className="thpadding">{language === "sv" ? "Åtgärd" : lang[language].action}</th>
                                <th className="thpadding">Deadline</th>
                                <th className="thpadding" style={{ width: "8%" }}>{language === "sv" ? "Kostnad" : lang[language].cost}</th>
                                <th className="thpadding">{language === "sv" ? "Ansvarig" : lang[language].responsible}</th>
                                <th className="thpadding remove" style={{ width: 30 }}></th>
                            </tr>

                            {comments}
                        </table>

                        <div className={this.state.allComments < 1 ? "hide" : ""}>
                            <div style={{ marginTop: 80, fontSize: 16, fontWeight: 900, marginBottom: 20, cursor: "pointer" }}><span onClick={this.toggleOldComments}>{language === "sv" ? "Föregående års kommentarer" : lang[language].commentPast}</span>
                                <img alt="" src={this.state.showOldComments ? down : up} style={{ width: 12, marginLeft: 5, cursor: "pointer" }} onClick={this.toggleOldComments} />
                            </div>
                            <div className={this.state.showOldComments ? "" : "hide"}>
                            <select name="selectionCommentYear" id="selectionCommentYear" onChange={this.changeYearComments} value={this.state.year}>
                                    <option value={this.state.selectedYear - 1}>{this.state.selectedYear - 1}</option>
                                    <option value={this.state.selectedYear - 2}>{this.state.selectedYear - 2}</option>
                                    <option value={this.state.selectedYear - 3}>{this.state.selectedYear - 3}</option>
                                    <option value={this.state.selectedYear - 4}>{this.state.selectedYear - 4}</option>
                                </select> 
                                <span class="lastYearButton" style={{ padding: 7, paddingLeft: 10, paddingRight: 10 }} onClick={this.toggleCommentPop}>{language === "sv" ? "Återanvänd alla" : lang[language].reuseAll}</span>
                                <div style={{ top: 500, width: 450 }} className={this.state.popUpComments ? "popUp active-popUp" : "hide"}>
                                    <img alt="" src={exitImg} class="hide-box" onClick={this.toggleCommentPop} />
                                    <span style={{ marginTop: 60, display: "block", fontWeight: 600, display: "block", paddingLeft: 40, paddingRight: 40, fontWeight: "normal", lineHeight: 1.4 }}>{language === "sv" ? `Vill du återanvända alla kommentarer från ${this.state.year} för alla grupper?` : lang[language].reuseAllPop}</span>
                                    <div style={{ marginTop: 40 }}>
                                        <span class="lastYearButton" style={{ background: "#c99555", marginRight: 10, padding: 10 }} onClick={this.toggleCommentPop}>{language === "sv" ? "Avbryt" : lang[language].cancel}</span>
                                        <span class="lastYearButton" style={{ marginLeft: 10, padding: 10 }} onClick={this.reuseAll}>{language === "sv" ? "Ja, fortsätt" : lang[language].continue}</span>
                                    </div>
                                </div>

                                <div className={this.state.popUpComments ? "popUpFader" : "hide"}></div>
                                <table className="commentsTable lastyearList">

                                    {pastComments}

                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }
}