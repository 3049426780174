import React from 'react';
import './Foraldraledighet.css';
import './Foraldraledighet.css';
import { Menu } from '../Menu.js';
import { TopNav } from '../TopNav.js';
import { Information } from '../Information/Information.js';
import down from '../../assets/images/down.png';
import up from '../../assets/images/up.png';
import yellowAlert from '../../assets/images/yellowAlert.png';
import greenCheck from '../../assets/images/greenCheck.png';
import lang from '../Language/language.js';
import exitImg from '../../assets/images/exit.png';
import arrowDown from '../../assets/images/arrow-down-black.png';
import TextareaAutosize from 'react-textarea-autosize'; //For textareas to autosize
import remove from '../../assets/images/x-mark.png';

export class Foraldraledighet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            parentalLeave: ["hej"],
            allEmps: [],
            openAnalyzeOne: false,
            openAnalyzeTwo: false,
            individualBoxOpen: false,
            whatComment: 1,
            autoNotableOneLower: "Föräldralediga medarbetare har fått en lägre löneökning än ej föräldralediga.",
            autoNotableOneOK: "Inget behöver analyseras. Föräldralediga medarbetare har fått lika stor löneökning.",
            autoNotableTwoLowerMen: "Föräldralediga män har fått en lägre löneökning än föräldralediga kvinnor.",
            autoNotableTwoLowerWomen: "Föräldralediga kvinnor har fått en lägre löneökning än föräldralediga män.",
            autoNotableTwoOK: "Inget behöver analyseras. Kvinnorna och männen har fått lika stor löneökning.",
        }
    }
    openAnalyzeOne = () => { this.state.openAnalyzeOne ? this.setState({ openAnalyzeOne: false }) : this.setState({ openAnalyzeOne: true }) }
    openAnalyzeTwo = () => { this.state.openAnalyzeTwo ? this.setState({ openAnalyzeTwo: false }) : this.setState({ openAnalyzeTwo: true }) }
    toggleIndividualBox = () => { this.state.individualBoxOpen ? this.setState({ individualBoxOpen: false }) : this.setState({ individualBoxOpen: true }) }
    selectAnalysisOne = () => { this.setState({ whatComment: 1 }) }
    selectAnalysisTwo = () => { this.setState({ whatComment: 2 }) }
    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        return (
            <>
                <TopNav />
                <Menu />
                <div className="container">
                    <Information step="foraldraledighet" />
                    <div className={this.state.parentalLeave.length < 1 ? "noNeedPopUp" : "hide"}>
                        <div className="importOK">&#10004;</div>
                        <div className="noNeedPopUpText">Analys behöver ej genomföras. Inga medarbetare har varit föräldralediga.</div>
                    </div>
                    <div className={this.state.parentalLeave.length < 1 ? "hide" : "leftAndRightContainer"}>

                        <div className="leftList">
                            <h3 style={{ marginTop: 6, paddingBottom: 3, fontWeight: 800, paddingTop: 10, fontSize: 18.6 }}>Likvärdiga arbeten</h3>
                            <div style={{ fontSize: 13, marginTop: -10, marginBottom: 10, color: "grey" }}>Visar grupper där föräldraledighet förekommit</div>
                            <ul style={{ overflowY: 'auto', height: 550, fontSize: 15.6, letterSpacing: -0.2 }}>
                                <li className="list2" key="blabla" id="blabla">Grupp 1</li>
                            </ul>
                        </div>

                        <div className="rightContainer" style={{ width: 630 }}>

                            <div className={this.state.whatComment == 1 ? "parentalContainer" : "parentalContainer parentalContainerGrey"}>
                                <div onClick={this.selectAnalysisOne}>
                                    <span className={this.state.whatComment == 1 ? "parentalStepNr" : "parentalStepNr parentalStepNrGrey"}>1</span>  <div className="parentalHeader" style={{ borderBottom: this.state.whatComment == 1 ? "1px solid white" : "1px solid #e2e2e2" }}>Föräldralediga - Ej föräldralediga</div>
                                    {/* <div>Analysera Föräldralediga och ej föräldralediga</div> */}

                                    <div className="parentalRow">
                                        <div className="parentalCellBig"></div>  <div className={this.state.whatComment == 1 ? "parentalCell parentalHeaderWhite" : "parentalCell parentalHeaderGray"}>Löneökning</div>  <div className={this.state.whatComment == 1 ? "parentalCell parentalHeaderWhite" : "parentalCell parentalHeaderGray"}>Antal</div>
                                        <div className="parentalCellBig">Föräldralediga</div>  <div className="parentalCell">3 %</div>  <div className="parentalCell">5</div>
                                        <div className="parentalCellBig">Ej föräldralediga</div>  <div className="parentalCell">2 %</div>  <div className="parentalCell">155</div>
                                    </div>
                                    <div className="parentalRow" style={{fontWeight: 800, marginTop: 10, paddingTop: 5, borderTop: this.state.whatComment == 1 ? "1px solid white" : "1px solid #e2e2e2", color: this.state.whatComment == 1 ? "white" : "#333333"}}>
                                        <div className="parentalCellBig">Differens</div>  <div className="parentalCell">1 %</div>  <div className="parentalCell"></div>
                                    </div>
                                </div>
                                <div className="parentalAnalyzeBox parentalAnalyze">
                                    <div onClick={this.openAnalyzeOne} ><div className="parentalAnalyzeTitle"><img src={yellowAlert} width={20} style={{ verticalAlign: "top", marginRight: 5, marginTop: -1 }} />Analysera </div> <img src={down} width={10} style={{ float: "right", marginTop: 4, marginRight: 4 }} /></div>
                                    <div className={this.state.openAnalyzeOne ? "parentalAnalyzeInfoPop" : "hide"}>
                                        Föräldralediga medarbetare har fått en lägre löneökning än ej föräldralediga. Undersök och kommentera nedan om det finns sakliga orsaker till detta. Om ingen saklig orsak finns bör en åtgärd skapas.
                                    </div>
                                </div>
                                {/*} <div className="parentalShowBtn">Visa individer</div>*/}
                                {/*} <div className="parentalListContainer">
                                        <div className="parentalListDivider">
                                            <div className="parentalWhatTitle">Föräldralediga</div>
                                            <div className="parentalListDiv" style={{ fontStyle: "italic", color: "grey" }}>
                                                <div className="parentalCellList parentalCellListBig">Namn</div>
                                                <div className="parentalCellList parentalCellListBig">Befattning</div>
                                                <div className="parentalCellList">Ny lön</div>
                                                <div className="parentalCellList">Höjning</div>
                                            </div>
                                            <div className="parentalListContainer">
                                                <div className="parentalCellList parentalCellListBig">Martin Svensson</div>
                                                <div className="parentalCellList parentalCellListBig">Montör</div>
                                                <div className="parentalCellList">31 000</div>
                                                <div className="parentalCellList">3%</div>
                                            </div>
                                            <div className="parentalListContainer">
                                                <div className="parentalCellList parentalCellListBig">Josefin Svensson</div>
                                                <div className="parentalCellList parentalCellListBig">Montör</div>
                                                <div className="parentalCellList">33 000</div>
                                                <div className="parentalCellList">2%</div>
                                            </div>
                                        </div>
                                        <div className="parentalListDivider">
                                        <div className="parentalWhatTitle">Ej Föräldralediga</div>
                                            <div className="parentalListDiv" style={{ fontStyle: "italic", color: "grey" }}>
                                                <div className="parentalCellList">Namn</div>
                                                <div className="parentalCellList">Befattning</div>
                                                <div className="parentalCellList">Lön</div>
                                                <div className="parentalCellList">Höjning</div>
                                            </div>
                                            <div className="parentalListContainer">
                                                <div className="parentalCellList">Martin Svensson</div>
                                                <div className="parentalCellList">Montör</div>
                                                <div className="parentalCellList">30 000</div>
                                                <div className="parentalCellList">2%</div>
                                            </div>
                                        </div>
        </div>*/}

                            </div>
                            <div className={this.state.whatComment == 2 ? "parentalContainer" : "parentalContainer parentalContainerGrey"}>
                                <div onClick={this.selectAnalysisTwo}>
                                    <span className={this.state.whatComment == 2 ? "parentalStepNr" : "parentalStepNr parentalStepNrGrey"}>2</span>  <div className="parentalHeader" style={{ borderBottom: this.state.whatComment == 2 ? "1px solid white" : "1px solid #e2e2e2" }}>Män - Kvinnor</div>
                                    {/*   <div>Analysera Föräldralediga kvinnor & män</div>*/}
                                    <div className="parentalRow">
                                        <div className={this.state.whatComment == 2 ? "parentalCellBig parentalHeaderWhite" : "parentalCellBig parentalHeaderGray"}>Föräldralediga</div>  
                                        <div className={this.state.whatComment == 2 ? "parentalCell parentalHeaderWhite" : "parentalCell parentalHeaderGray"}>Löneökning</div>  
                                        <div className={this.state.whatComment == 2 ? "parentalCell parentalHeaderWhite" : "parentalCell parentalHeaderGray"}>Antal</div>
                                    </div>
                                    <div className="parentalRow">
                                        <div className="parentalCellBig">Män</div>  <div className="parentalCell">3 %</div>  <div className="parentalCell">5</div>
                                    </div>
                                    <div className="parentalRow">
                                        <div className="parentalCellBig">Kvinnor</div>  <div className="parentalCell">2 %</div>  <div className="parentalCell">155</div>
                                    </div>
                                    <div className="parentalRow" style={{fontWeight: 800, marginTop: 10, paddingTop: 5, borderTop: this.state.whatComment == 2 ? "1px solid white" : "1px solid #e2e2e2", color: this.state.whatComment == 2 ? "white" : "#333333"}}>
                                        <div className="parentalCellBig">Differens</div>  <div className="parentalCell">1 %</div>  <div className="parentalCell"></div>
                                    </div>
                                </div>
                                {/*<div className="parentalShowBtn">Visa individer</div>*/}
                                <div className="parentalAnalyzeBox parentalOK">
                                    <div onClick={this.openAnalyzeTwo} ><div style={{ fontWeight: "bold", display: "inline-block", color: "#26705f" }}><img src={greenCheck} width={20} style={{ verticalAlign: "top", marginRight: 5, marginTop: -1 }} />Ser bra ut</div><img src={down} width={10} style={{ float: "right", marginTop: 4, marginRight: 4 }} /></div>

                                    <div className={this.state.openAnalyzeTwo ? "parentalAnalyzeInfoPop" : "hide"}>
                                        Inget behöver analyseras. Kvinnorna och männen har fått lika stor löneökning.</div>
                                </div>
                            </div>

                            <div className={this.state.individualBoxOpen ? "parentalShowBtn parentalShowBtnOpen" : "parentalShowBtn"} onClick={this.toggleIndividualBox}>Visa individer</div>
                            <div className={this.state.individualBoxOpen ? "parentalContainerForList" : "hide"}>
                                <div className="parentalSecondCont">
                                    <div className="parentalWhatTitle">Föräldralediga <img src={down} width={10} style={{ marginLeft: 5 }} /></div>
                                    <div className="parentalListDiv" style={{ fontStyle: "italic", color: "grey" }}>
                                        <div className="parentalCellList parentalCellListBig">Namn</div>
                                        <div className="parentalCellList parentalCellListBig">Befattning</div>
                                        <div className="parentalCellList">Ny lön</div>
                                        <div className="parentalCellList">Höjning</div>
                                    </div>
                                    <div className="parentalGenderContainer">
                                        <div className="parentalGenderTitle">Män</div>
                                        <div className="parentalListContainer">
                                            <div className="parentalCellList parentalCellListBig">Martin Svensson</div>
                                            <div className="parentalCellList parentalCellListBig">Montör</div>
                                            <div className="parentalCellList">31 000</div>
                                            <div className="parentalCellList">3%</div>
                                        </div>
                                        <div className="parentalListContainer">
                                            <div className="parentalCellList parentalCellListBig">Johan Eriksson</div>
                                            <div className="parentalCellList parentalCellListBig">Montör</div>
                                            <div className="parentalCellList">31 000</div>
                                            <div className="parentalCellList">3%</div>
                                        </div>
                                        <div className="parentalListContainer">
                                            <div className="parentalCellList parentalCellListBig">Erik Svensson</div>
                                            <div className="parentalCellList parentalCellListBig">Montör</div>
                                            <div className="parentalCellList">31 000</div>
                                            <div className="parentalCellList">3%</div>
                                        </div>
                                    </div>
                                    <div className="parentalGenderContainer parentalGenderContainerWomen">
                                        <div className="parentalGenderTitle parentalGenderTitleWomen">Kvinnor</div>
                                        <div className="parentalListContainer">
                                            <div className="parentalCellList parentalCellListBig">Rebecka Helmersson</div>
                                            <div className="parentalCellList parentalCellListBig">Montör</div>
                                            <div className="parentalCellList">33 000</div>
                                            <div className="parentalCellList">2%</div>
                                        </div>
                                        <div className="parentalListContainer">
                                            <div className="parentalCellList parentalCellListBig">Moa Bengtsson-Svensson</div>
                                            <div className="parentalCellList parentalCellListBig">Montör</div>
                                            <div className="parentalCellList">33 000</div>
                                            <div className="parentalCellList">2%</div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ borderTop: "1px solid lightgrey" }} className="parentalSecondCont">
                                    <div className="parentalWhatTitle">Ej Föräldralediga <img src={down} width={10} style={{ marginLeft: 5 }} /> </div>
                                    <div className="parentalListDiv" style={{ fontStyle: "italic", color: "grey" }}>
                                        <div className="parentalCellList parentalCellListBig">Namn</div>
                                        <div className="parentalCellList parentalCellListBig">Befattning</div>
                                        <div className="parentalCellList">Ny lön</div>
                                        <div className="parentalCellList">Höjning</div>
                                    </div>
                                    <div className="parentalListContainer">
                                        <div className="parentalCellList parentalCellListBig">Martin Svensson</div>
                                        <div className="parentalCellList parentalCellListBig">Montör</div>
                                        <div className="parentalCellList">30 000</div>
                                        <div className="parentalCellList">2%</div>
                                    </div>
                                    <div className="parentalListContainer">
                                        <div className="parentalCellList parentalCellListBig">Erik Olsson</div>
                                        <div className="parentalCellList parentalCellListBig">Montör</div>
                                        <div className="parentalCellList">30 000</div>
                                        <div className="parentalCellList">2%</div>
                                    </div>
                                </div>
                            </div>


                            {/* Comments*/}
                            <div className="commentContainer likvCommentCont" style={{ width: "calc(100% - 40px)", marginBottom: 20 }} >
                                <h3 style={{ marginTop: 0, fontWeight: 800 }}>{language === "sv" ? "Anteckna" : lang[language].note}</h3>
                                <div style={{ marginBottom: 15 }}>
                                    <div className={this.state.whatComment == 1 ? "comment-select-left comment-select-this" : "comment-select-left"} onClick={this.selectAnalysisOne}>{language === "sv" ? "Analys 1" : lang[language].equivalent}</div>
                                    <div className={this.state.whatComment == 2 ? "comment-select-right comment-select-this" : "comment-select-right"} onClick={this.selectAnalysisTwo}>{language === "sv" ? "Analys 2" : lang[language].hierarchical}</div>
                                </div>
                                <textarea id="curSelComment" className="comment" placeholder={language === "sv" ? "Noterbart" : lang[language].notable} style={{ minHeight: 48 }} onBlur={this.handleComment} defaultValue={this.state.autoNotableOneLower} ref={el => this.curSelComment = el} />
                                <textarea id="curSelReason" className="comment reason" placeholder={language === "sv" ? "Orsak" : lang[language].reason} style={{ minHeight: 48 }} onBlur={this.handleReason} defaultValue={this.state.addReason} ref={el => this.curSelReason = el} />
                                <div className="fieldsB" onClick={this.toggleFields}>{language === "sv" ? "Fler fält " : lang[language].moreFields}<img src={arrowDown} style={{ width: 9, marginLeft: 5 }} className={this.state.moreFields ? "arrowUp" : ""} /> </div>
                                <div className={this.state.moreFields ? "" : "hideFields"} style={{ width: 630, margin: "auto" }}>
                                    <textarea id="curSelTodo" className="comment todo" placeholder={language === "sv" ? "Åtgärd" : lang[language].action} style={{ minHeight: 48 }} onBlur={this.handleTodo} defaultValue={this.state.addTodo} ref={el => this.curSelTodo = el} />
                                    <input style={{ background: "white", textAlign: "center", }} type="date" id="deadline" name="deadline" className="dateBox" onChange={this.handleDeadline} value={this.state.addDeadline} />
                                    <input id="curSelCost" type="text" className="comment cost" placeholder={language === "sv" ? "Kostnad" : lang[language].cost} onBlur={this.handleCost} defaultValue={this.state.addCost} ref={el => this.curSelCost = el} />
                                    <input id="curSelResp" type="text" className="comment responsible" placeholder={language === "sv" ? "Ansvarig" : lang[language].responsible} onBlur={this.handleResponsible} defaultValue={this.state.addResponsible} ref={el => this.curSelResp = el} />
                                    <span className="dateBoxTitle">Deadline</span>
                                </div>
                                <div
                                    className="commentButton"
                                    style={{ width: 120, display: "block", margin: "auto", marginTop: 20, fontSize: 15.6 }}
                                    onClick={this.saveNewComment}>{language === "sv" ? "Spara" : lang[language].save}</div>
                            </div>



                        </div>
                    </div>

                    {/* Comments list*/}
                    <div className="allCommentsContainer">
                        <div>
                            <h3 style={{ paddingTop: 30, paddingBottom: 5, fontSize: 23.6, fontWeight: 900 }}>{language === "sv" ? "Noteringar" : lang[language].notes}</h3>
                            <div className={this.state.analysis === "all" ? "filterButtonLika activeFilter" : "filterButtonLika"} onClick={this.filterAll}>{language === "sv" ? "Alla" : lang[language].all}</div>
                            <div className={this.state.analysis === "likvärdiga" ? "filterButtonLika activeFilter" : "filterButtonLika"} onClick={this.filterEqualent}>{language === "sv" ? "Vald grupp" : lang[language].equivalent}</div>
                        </div>

                        <table className="commentsTable">
                            <tr className="trStyleLine">
                                <th className="thpadding">{language === "sv" ? "Noterbart" : lang[language].notable}</th>
                                <th className="thpadding">{language === "sv" ? "Orsak" : lang[language].reason}</th>
                                <th className="thpadding">{language === "sv" ? "Åtgärd" : lang[language].action}</th>
                                <th className="thpadding">Deadline</th>
                                <th className="thpadding" style={{ width: "8%" }}>{language === "sv" ? "Kostnad" : lang[language].cost}</th>
                                <th className="thpadding">{language === "sv" ? "Ansvarig" : lang[language].responsible}</th>
                                <th className="thpadding remove" style={{ width: 30 }}></th>
                            </tr>
                            {/*comments*/}
                            <tr className="trStyleLine">
                                <td className="thpadding">
                                    <span class="equalGroupUtvardera">Analys 1</span>
                                    <TextareaAutosize className="autoHeightText" placeholder={language === "sv" ? "Ange vad som är noterbart" : lang[language].notable} name="notable" /></td>
                                <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder={language === "sv" ? "Ange orsak" : lang[language].reason} name="reason" /></td>
                                <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder={language === "sv" ? "Ange åtgärd (vid behov)" : lang[language].action} name="todo" /></td>
                                <td className="thpadding"><input type="date" name="deadline" /></td>
                                <td className="thpadding" style={{ width: "8%" }}><TextareaAutosize className="autoHeightText" placeholder="0" /></td>
                                <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder={language === "sv" ? "Ange ansvarig" : lang[language].responsible} name="responsible" /></td>
                                <td className="thpadding remove"><img className="checkFinish" src={remove} alt="" style={{ width: 20, marginTop: -20, filter: 'grayscale(100%)', opacity: 0.75 }} /></td>
                            </tr>




                        </table>

                        <div className={this.state.allComments < 1 ? "hide" : ""}>
                            <div style={{ marginTop: 80, fontSize: 16, fontWeight: 900, marginBottom: 20, cursor: "pointer" }}><span onClick={this.toggleOldComments}>{language === "sv" ? "Föregående års kommentarer" : lang[language].commentPast}</span>
                                <img alt="" src={this.state.showOldComments ? down : up} style={{ width: 12, marginLeft: 5, cursor: "pointer" }} onClick={this.toggleOldComments} />
                            </div>

                            <div>Ska ej kunna återanvändas, men ska visas.</div>

                            <div className={this.state.showOldComments ? "" : "hide"}>
                                <select name="selectionCommentYear" id="selectionCommentYear" onChange={this.changeYearComments} value={this.state.year}>
                                    <option value={this.state.selectedYear - 1}>{this.state.selectedYear - 1}</option>
                                    <option value={this.state.selectedYear - 2}>{this.state.selectedYear - 2}</option>
                                    <option value={this.state.selectedYear - 3}>{this.state.selectedYear - 3}</option>
                                    <option value={this.state.selectedYear - 4}>{this.state.selectedYear - 4}</option>
                                </select>
                                <span class="lastYearButton" style={{ padding: 7, paddingLeft: 10, paddingRight: 10 }} onClick={this.toggleCommentPop}>{language === "sv" ? "Återanvänd alla" : lang[language].reuseAll}</span>
                                <div style={{ top: 500, width: 450 }} className={this.state.popUpComments ? "popUp active-popUp" : "hide"}>
                                    <img alt="" src={exitImg} class="hide-box" onClick={this.toggleCommentPop} />
                                    <span style={{ marginTop: 60, display: "block", fontWeight: 600, display: "block", paddingLeft: 40, paddingRight: 40, fontWeight: "normal", lineHeight: 1.4 }}>{language === "sv" ? `Vill du återanvända alla kommentarer från ${this.state.year} för alla grupper?` : lang[language].reuseAllPop}</span>
                                    <div style={{ marginTop: 40 }}>
                                        <span class="lastYearButton" style={{ background: "#c99555", marginRight: 10, padding: 10 }} onClick={this.toggleCommentPop}>{language === "sv" ? "Avbryt" : lang[language].cancel}</span>
                                        <span class="lastYearButton" style={{ marginLeft: 10, padding: 10 }} onClick={this.reuseAll}>{language === "sv" ? "Ja, fortsätt" : lang[language].continue}</span>
                                    </div>
                                </div>
                                    
                                <div className={this.state.popUpComments ? "popUpFader" : "hide"}></div>
                                <table className="commentsTable lastyearList">
                                    {/*pastComments*/}

                                </table>
                            </div>
                        </div>
                    </div>



                </div>
            </>
        )
    }
}