import {
    docx,
    Packer,
    AlignmentType,
    Document,
    HeadingLevel,
    Paragraph,
    TabStopPosition,
    TabStopType,
    TextRun,
    SectionType,
    PageBreak,
    TableCell,
    TableRow,
    Table,
    WidthType,
    TableLayoutType,
    BorderStyle,
    ImageRun,
    ShadingType,
    Run
} from "docx";
import { autoAnalysis } from '../../AnalysLikvardiga/autoAnalysis';
import lang from '../../Language/language.js';
const percentile = require("percentile");

export const LikvardigaCompared = (persData, tableSpace, nrOfEqualentGroups) => {
    let language = localStorage.getItem('language') ?? 'sv';
    //Calculate and compare likvardiga 
    const allEmployees = persData

    //*** data for GROUP 1: ***
    const group1 = allEmployees.filter(person => person.equalentGroup === 1); //Get all works with demands 1
    //get unique groups
    const onlyGroups = group1.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
    const uniqueGroups = onlyGroups.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
    //For each unique group, check:
    //Nr of male and Women, Nr of employees, salary
    const group1Data = [];

    uniqueGroups.forEach(elem => {
        let filteredMale = group1.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
        let filteredWomen = group1.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
        let nrOfMen = filteredMale.length
        let nrOfWomen = filteredWomen.length
        let nrAll = nrOfMen + nrOfWomen
        let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
        let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
        let avgSalary = (totSalary / nrAll).toFixed(0)
        //get averageExperience and avg age
        let filteredGroup = group1.filter(person => (person.groupNameArbVardering === elem))
        let avgExp = Math.round(filteredGroup.reduce((total, person) => total + person.experience, 0) / (filteredGroup.length));
        let avgAge = Math.round(filteredGroup.reduce((total, person) => total + person.age, 0) / (filteredGroup.length));

        //let avgExp = 
        //maybe also median salary...
        let group1data = {
            "group": elem,
            "womenPercent": womenPercent,
            "Men": nrOfMen,
            "Women": nrOfWomen,
            "totEmployees": nrAll,
            "avgSalary": avgSalary,
            "avgExp": avgExp,
            "avgAge": avgAge,
            "demands": 1,
        }
        group1Data.push(group1data)
    });

    //*** data for GROUP 2: ***
    const group2 = allEmployees.filter(person => person.equalentGroup === 2); //Get all works with demands 2
    //get unique groups
    const onlyGroups2 = group2.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
    const uniqueGroups2 = onlyGroups2.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
    //For each unique group, check:
    //Nr of male and Women, Nr of employees, salary
    const group2Data = [];

    uniqueGroups2.forEach(elem => {
        let filteredMale = group2.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
        let filteredWomen = group2.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
        let nrOfMen = filteredMale.length
        let nrOfWomen = filteredWomen.length
        let nrAll = nrOfMen + nrOfWomen
        let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
        let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
        let avgSalary = (totSalary / nrAll).toFixed(0)
        //maybe also median salary...
        let group2data = {
            "group": elem,
            "womenPercent": womenPercent,
            "Men": nrOfMen,
            "Women": nrOfWomen,
            "totEmployees": nrAll,
            "avgSalary": avgSalary,
            "demands": 2,
        }
        group2Data.push(group2data)
    });

    //*** data for GROUP 3: ***
    const group3 = allEmployees.filter(person => person.equalentGroup === 3); //Get all works with demands 3
    //get unique groups
    const onlyGroups3 = group3.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
    const uniqueGroups3 = onlyGroups3.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
    //For each unique group, check:
    //Nr of male and Women, Nr of employees, salary
    const group3Data = [];

    uniqueGroups3.forEach(elem => {
        let filteredMale = group3.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
        let filteredWomen = group3.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
        let nrOfMen = filteredMale.length
        let nrOfWomen = filteredWomen.length
        let nrAll = nrOfMen + nrOfWomen
        let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
        let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
        let avgSalary = (totSalary / nrAll).toFixed(0)
        //maybe also median salary...
        let group3data = {
            "group": elem,
            "womenPercent": womenPercent,
            "Men": nrOfMen,
            "Women": nrOfWomen,
            "totEmployees": nrAll,
            "avgSalary": avgSalary,
            "demands": 3,
        }
        group3Data.push(group3data)
    });

    //*** data for GROUP 4: ***
    const group4 = allEmployees.filter(person => person.equalentGroup === 4); //Get all works with demands 1
    //get unique groups
    const onlyGroups4 = group4.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
    const uniqueGroups4 = onlyGroups4.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
    //For each unique group, check:
    //Nr of male and Women, Nr of employees, salary
    const group4Data = [];

    uniqueGroups4.forEach(elem => {
        let filteredMale = group4.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
        let filteredWomen = group4.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
        let nrOfMen = filteredMale.length
        let nrOfWomen = filteredWomen.length
        let nrAll = nrOfMen + nrOfWomen
        let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
        let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
        let avgSalary = (totSalary / nrAll).toFixed(0)
        //maybe also median salary...
        let group4data = {
            "group": elem,
            "womenPercent": womenPercent,
            "Men": nrOfMen,
            "Women": nrOfWomen,
            "totEmployees": nrAll,
            "avgSalary": avgSalary,
            "demands": 4,
        }
        group4Data.push(group4data)
    });

    //*** data for GROUP 5: ***
    const group5 = allEmployees.filter(person => person.equalentGroup === 5); //Get all works with demands 1
    //get unique groups
    const onlyGroups5 = group5.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
    const uniqueGroups5 = onlyGroups5.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
    //For each unique group, check:
    //Nr of male and Women, Nr of employees, salary
    const group5Data = [];

    uniqueGroups5.forEach(elem => {
        let filteredMale = group5.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
        let filteredWomen = group5.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
        let nrOfMen = filteredMale.length
        let nrOfWomen = filteredWomen.length
        let nrAll = nrOfMen + nrOfWomen
        let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
        let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
        let avgSalary = (totSalary / nrAll).toFixed(0)
        //maybe also median salary...
        let group5data = {
            "group": elem,
            "womenPercent": womenPercent,
            "Men": nrOfMen,
            "Women": nrOfWomen,
            "totEmployees": nrAll,
            "avgSalary": avgSalary,
            "demands": 5,
        }
        group5Data.push(group5data)
    });

    //*** data for GROUP 6: ***
    const group6 = allEmployees.filter(person => person.equalentGroup === 6); //Get all works with demands 1
    //get unique groups
    const onlyGroups6 = group6.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
    const uniqueGroups6 = onlyGroups6.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
    //For each unique group, check:
    //Nr of male and Women, Nr of employees, salary
    const group6Data = [];

    uniqueGroups6.forEach(elem => {
        let filteredMale = group6.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
        let filteredWomen = group6.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
        let nrOfMen = filteredMale.length
        let nrOfWomen = filteredWomen.length
        let nrAll = nrOfMen + nrOfWomen
        let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
        let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
        let avgSalary = (totSalary / nrAll).toFixed(0)
        //maybe also median salary...
        let group6data = {
            "group": elem,
            "womenPercent": womenPercent,
            "Men": nrOfMen,
            "Women": nrOfWomen,
            "totEmployees": nrAll,
            "avgSalary": avgSalary,
            "demands": 6,
        }
        group6Data.push(group6data)
    });

    let group7Data = [];
    let group8Data = [];
    let group9Data = [];
    if (nrOfEqualentGroups > 6) {
        //*** data for GROUP 7: ***
        const group7 = allEmployees.filter(person => person.equalentGroup === 7); //Get all works with demands 1
        //get unique groups
        const onlyGroups7 = group7.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
        const uniqueGroups7 = onlyGroups7.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
        //For each unique group, check:
        //Nr of male and Women, Nr of employees, salary

        uniqueGroups7.forEach(elem => {
            let filteredMale = group7.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
            let filteredWomen = group7.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
            let nrOfMen = filteredMale.length
            let nrOfWomen = filteredWomen.length
            let nrAll = nrOfMen + nrOfWomen
            let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
            let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
            let avgSalary = (totSalary / nrAll).toFixed(0)
            //maybe also median salary...
            let group7data = {
                "group": elem,
                "womenPercent": womenPercent,
                "Men": nrOfMen,
                "Women": nrOfWomen,
                "totEmployees": nrAll,
                "avgSalary": avgSalary,
                "demands": 7,
            }
            group7Data.push(group7data)
        });
        //*** data for GROUP 8: ***
        const group8 = allEmployees.filter(person => person.equalentGroup === 8); //Get all works with demands 1
        //get unique groups
        const onlyGroups8 = group8.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
        const uniqueGroups8 = onlyGroups8.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
        //For each unique group, check:
        //Nr of male and Women, Nr of employees, salary

        uniqueGroups8.forEach(elem => {
            let filteredMale = group8.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
            let filteredWomen = group8.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
            let nrOfMen = filteredMale.length
            let nrOfWomen = filteredWomen.length
            let nrAll = nrOfMen + nrOfWomen
            let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
            let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
            let avgSalary = (totSalary / nrAll).toFixed(0)
            //maybe also median salary...
            let group8data = {
                "group": elem,
                "womenPercent": womenPercent,
                "Men": nrOfMen,
                "Women": nrOfWomen,
                "totEmployees": nrAll,
                "avgSalary": avgSalary,
                "demands": 8,
            }
            group8Data.push(group8data)
        });
        //*** data for GROUP 9: ***
        const group9 = allEmployees.filter(person => person.equalentGroup === 9); //Get all works with demands 1
        //get unique groups
        const onlyGroups9 = group9.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
        const uniqueGroups9 = onlyGroups9.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
        //For each unique group, check:
        //Nr of male and Women, Nr of employees, salary

        uniqueGroups9.forEach(elem => {
            let filteredMale = group9.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
            let filteredWomen = group9.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
            let nrOfMen = filteredMale.length
            let nrOfWomen = filteredWomen.length
            let nrAll = nrOfMen + nrOfWomen
            let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
            let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
            let avgSalary = (totSalary / nrAll).toFixed(0)
            //maybe also median salary...
            let group9data = {
                "group": elem,
                "womenPercent": womenPercent,
                "Men": nrOfMen,
                "Women": nrOfWomen,
                "totEmployees": nrAll,
                "avgSalary": avgSalary,
                "demands": 9,
            }
            group9Data.push(group9data)
        });
    }

    let group10Data = [];
    let group11Data = [];
    let group12Data = [];
    if (nrOfEqualentGroups > 9) {
        //*** data for GROUP 10: ***
        const group10 = allEmployees.filter(person => person.equalentGroup === 10); //Get all works with demands 1
        //get unique groups
        const onlyGroups10 = group10.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
        const uniqueGroups10 = onlyGroups10.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
        //For each unique group, check:
        //Nr of male and Women, Nr of employees, salary

        uniqueGroups10.forEach(elem => {
            let filteredMale = group10.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
            let filteredWomen = group10.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
            let nrOfMen = filteredMale.length
            let nrOfWomen = filteredWomen.length
            let nrAll = nrOfMen + nrOfWomen
            let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
            let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
            let avgSalary = (totSalary / nrAll).toFixed(0)
            //maybe also median salary...
            let group10data = {
                "group": elem,
                "womenPercent": womenPercent,
                "Men": nrOfMen,
                "Women": nrOfWomen,
                "totEmployees": nrAll,
                "avgSalary": avgSalary,
                "demands": 10,
            }
            group10Data.push(group10data)
        });
        //*** data for GROUP 11: ***
        const group11 = allEmployees.filter(person => person.equalentGroup === 11); //Get all works with demands 1
        //get unique groups
        const onlyGroups11 = group11.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
        const uniqueGroups11 = onlyGroups11.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
        //For each unique group, check:
        //Nr of male and Women, Nr of employees, salary

        uniqueGroups11.forEach(elem => {
            let filteredMale = group11.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
            let filteredWomen = group11.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
            let nrOfMen = filteredMale.length
            let nrOfWomen = filteredWomen.length
            let nrAll = nrOfMen + nrOfWomen
            let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
            let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
            let avgSalary = (totSalary / nrAll).toFixed(0)
            //maybe also median salary...
            let group11data = {
                "group": elem,
                "womenPercent": womenPercent,
                "Men": nrOfMen,
                "Women": nrOfWomen,
                "totEmployees": nrAll,
                "avgSalary": avgSalary,
                "demands": 11,
            }
            group11Data.push(group11data)
        });
        //*** data for GROUP 12: ***
        const group12 = allEmployees.filter(person => person.equalentGroup === 12); //Get all works with demands 1
        //get unique groups
        const onlyGroups12 = group12.map(person => person.groupNameArbVardering); //Filter out only the groups to one array (we use this to calculate statistics for each group later)
        const uniqueGroups12 = onlyGroups12.filter((value, index, self) => self.indexOf(value) === index).sort(); //filter the only groups array to only unique items (groups)
        //For each unique group, check:
        //Nr of male and Women, Nr of employees, salary

        uniqueGroups12.forEach(elem => {
            let filteredMale = group12.filter(person => (person.groupNameArbVardering === elem && person.gender === "man"))
            let filteredWomen = group12.filter(person => (person.groupNameArbVardering === elem && person.gender === "woman"))
            let nrOfMen = filteredMale.length
            let nrOfWomen = filteredWomen.length
            let nrAll = nrOfMen + nrOfWomen
            let womenPercent = Math.round((nrOfWomen / nrAll) * 100)
            let totSalary = filteredMale.reduce((acc, person) => acc + person.totalSalary, 0) + filteredWomen.reduce((acc, person) => acc + person.totalSalary, 0)
            let avgSalary = (totSalary / nrAll).toFixed(0)
            //maybe also median salary...
            let group12data = {
                "group": elem,
                "womenPercent": womenPercent,
                "Men": nrOfMen,
                "Women": nrOfWomen,
                "totEmployees": nrAll,
                "avgSalary": avgSalary,
                "demands": 12,
            }
            group12Data.push(group12data)
        });
    }

    let groupData = {
        group1: group1Data,
        group2: group2Data,
        group3: group3Data,
        group4: group4Data,
        group5: group5Data,
        group6: group6Data,
    }
    if (nrOfEqualentGroups > 6 && nrOfEqualentGroups < 10) {
        groupData = {
            group1: group1Data,
            group2: group2Data,
            group3: group3Data,
            group4: group4Data,
            group5: group5Data,
            group6: group6Data,
            group7: group7Data,
            group8: group8Data,
            group9: group9Data,
        }
    }
    if (nrOfEqualentGroups > 9) {
        groupData = {
            group1: group1Data,
            group2: group2Data,
            group3: group3Data,
            group4: group4Data,
            group5: group5Data,
            group6: group6Data,
            group7: group7Data,
            group8: group8Data,
            group9: group9Data,
            group10: group10Data,
            group11: group11Data,
            group12: group12Data,
        }
    }


    ////

    let allGroups = [...groupData.group1, ...groupData.group2, ...groupData.group3, ...groupData.group4, ...groupData.group5, ...groupData.group6]
    if (nrOfEqualentGroups > 6 && nrOfEqualentGroups < 10) { allGroups = [...groupData.group1, ...groupData.group2, ...groupData.group3, ...groupData.group4, ...groupData.group5, ...groupData.group6, ...groupData.group7, ...groupData.group8, ...groupData.group9] }
    if (nrOfEqualentGroups > 9) { allGroups = [...groupData.group1, ...groupData.group2, ...groupData.group3, ...groupData.group4, ...groupData.group5, ...groupData.group6, ...groupData.group7, ...groupData.group8, ...groupData.group9, ...groupData.group10, ...groupData.group11, ...groupData.group12] }

    //List to push results
    let comparisons = 0
    let allComparisons = []
    //Check all against eachother 
    for (let i = 0; i < allGroups.length; i++) {
        for (let j = i + 1; j < allGroups.length; j++) {
            //Here compare them to eachother
            const group1 = allGroups[i].group
            const group2 = allGroups[j].group
            //Get all employees in those groups
            const groupOne = persData.filter(person => person.groupNameArbVardering === group1);
            const groupTwo = persData.filter(person => person.groupNameArbVardering === group2);

            //calculate average values
            //group 1: 
            const oneNrOfEmp = groupOne.length
            const oneWomen = Math.round((groupOne.filter(person => person.gender === "woman").length / oneNrOfEmp) * 100)
            const oneAvgSalary = Math.round(groupOne.reduce((acc, person) => acc + person.totalSalary, 0) / oneNrOfEmp)
            const oneAvgAge = Math.round(groupOne.reduce((acc, person) => acc + person.age, 0) / oneNrOfEmp)
            const oneAvgExp = Math.round(groupOne.reduce((acc, person) => acc + person.experience, 0) / oneNrOfEmp)
            const oneEqualentGroup = groupOne[0].equalentGroup
            const oneCityArray = groupOne.map(person => person.city)
            const oneAverageCity = oneCityArray.sort((a, b) =>
                oneCityArray.filter(v => v === a).length
                - oneCityArray.filter(v => v === b).length
            ).pop();
            //Spridning
            let oneGetOnlySalaries = []
            for (let i = 0; i < groupOne.length; i++) { oneGetOnlySalaries.push(groupOne[i].totalSalary) }
            let oneLowestSalary = Math.min(...oneGetOnlySalaries)
            let oneHighestSalary = Math.max(...oneGetOnlySalaries)
            let oneSpridningskvot = Math.round((oneHighestSalary / oneLowestSalary) * 10) / 10
            //If 20 or more persons in the group
            if (groupOne.length > 19) {
                let salariesInOrder = oneGetOnlySalaries.sort((a, b) => a - b) //Sort lowest to highest
                let more10 = percentile(10, salariesInOrder)
                let more90 = percentile(90, salariesInOrder)
                oneSpridningskvot = Math.round((more90 / more10) * 10) / 10
            }
            //group 2:
            const twoNrOfEmp = groupTwo.length
            const twoWomen = Math.round((groupTwo.filter(person => person.gender === "woman").length / twoNrOfEmp) * 100)
            const twoAvgSalary = Math.round(groupTwo.reduce((acc, person) => acc + person.totalSalary, 0) / twoNrOfEmp)
            const twoAvgAge = Math.round(groupTwo.reduce((acc, person) => acc + person.age, 0) / twoNrOfEmp)
            const twoAvgExp = Math.round(groupTwo.reduce((acc, person) => acc + person.experience, 0) / twoNrOfEmp)
            const twoEqualentGroup = groupTwo[0].equalentGroup
            const twoCityArray = groupTwo.map(person => person.city)
            const twoAverageCity = twoCityArray.sort((a, b) =>
                twoCityArray.filter(v => v === a).length
                - twoCityArray.filter(v => v === b).length
            ).pop();
            //Spridning
            let twoGetOnlySalaries = []
            for (let i = 0; i < groupTwo.length; i++) { twoGetOnlySalaries.push(groupTwo[i].totalSalary) }
            let twoLowestSalary = Math.min(...twoGetOnlySalaries)
            let twoHighestSalary = Math.max(...twoGetOnlySalaries)
            let twoSpridningskvot = Math.round((twoHighestSalary / twoLowestSalary) * 10) / 10
            //If 20 or more persons in the group
            if (groupTwo.length > 19) {
                let salariesInOrder = twoGetOnlySalaries.sort((a, b) => a - b) //Sort lowest to highest
                let more10 = percentile(10, salariesInOrder)
                let more90 = percentile(90, salariesInOrder)
                twoSpridningskvot = Math.round((more90 / more10) * 10) / 10
            }

            //Diffs
            const diffWomen = (oneWomen - twoWomen)

            //prepare data for robot
            let lessWomen = oneWomen //Percentage of women in group, ex 60 = 60% women
            let moreWomen = twoWomen //Percentage of women in the other group, below we switch it so that lessWomen always is the group with the smallest percantage of women.
            let firsAvgSalary = oneAvgSalary //Total average salary for group 1
            let secondAvgSalary = twoAvgSalary //Total average salary for group 2
            let nrOfEmp = oneNrOfEmp //Nr of employee for the women dominated group. If under 4 we do some things
            let nrOfEmpTwo = twoNrOfEmp //Nr of employee for the male dominated group
            let firstGroup = group1 //Employees in group 1
            let secondGroup = group2 //Employees in group 2
            let firstExp = oneAvgExp //Average Experience in group 1
            let secondExp = twoAvgExp //Average Experience in group 2
            let firstAge = oneAvgAge //Average Experience in group 1
            let secondAge = twoAvgAge //Average Experience in group 2
            let firstEqualent = oneEqualentGroup //the equalent group the group belong to, (1-6). The first equalent group will always belong to the women dominated group
            let secondEqualent = twoEqualentGroup
            let firstCity = oneAverageCity
            let secondCity = twoAverageCity
            let firstSpridning = oneSpridningskvot
            let secondSpridning = twoSpridningskvot

            //Only compare if one group is women dominated and the second is not
            if ((oneWomen > 59 && twoWomen < 60) || (twoWomen > 59 && oneWomen < 60)) {
                //Count nr of comparisions
                comparisons = comparisons + 1

                //If it is the second group that is women dominated, that groups should be first. Therefore we swap all data
                if (twoWomen > 59 && oneWomen < 60) {
                    lessWomen = twoWomen
                    moreWomen = oneWomen
                    firsAvgSalary = twoAvgSalary
                    secondAvgSalary = oneAvgSalary
                    nrOfEmp = twoNrOfEmp
                    nrOfEmpTwo = oneNrOfEmp
                    firstGroup = group2
                    secondGroup = group1
                    firstExp = twoAvgExp
                    secondExp = oneAvgExp
                    firstAge = twoAvgAge
                    secondAge = oneAvgAge
                    firstEqualent = twoEqualentGroup
                    secondEqualent = oneEqualentGroup
                    firstCity = twoAverageCity
                    secondCity = oneAverageCity
                    firstSpridning = twoSpridningskvot
                    secondSpridning = oneSpridningskvot
                }

                //Only compare if there is a need for it. If no need to compare the groups, because male group is in higher group or has lower salary, then dont compare group
                if (firstEqualent < secondEqualent || firsAvgSalary > secondAvgSalary) {
                    //Do nothing
                } else {

                    //Run auto analysis (from separate file)
                    //const autoAnalys = autoAnalysis(lessWomen, moreWomen, firsAvgSalary, secondAvgSalary, nrOfEmp, nrOfEmpTwo, firstGroup, secondGroup, firstExp, secondExp, firstAge, secondAge, firstEqualent, secondEqualent, firstCity, secondCity, "Yes");
                    const autoAnalys = autoAnalysis(lessWomen, moreWomen, firsAvgSalary, secondAvgSalary, nrOfEmp, nrOfEmpTwo, firstGroup, secondGroup, firstExp, secondExp, firstAge, secondAge, firstEqualent, secondEqualent, firstCity, secondCity, "No");
                    //console.log(group1, group2, autoAnalys)
                    //create object for analyticList
                    let type = (firstEqualent - secondEqualent) === 0 ? "equalent" : "hierarchical"
                    let group = {
                        "Group1": firstGroup,
                        "avgWomen1": lessWomen,
                        "emp1": nrOfEmp,
                        "avgSal1": firsAvgSalary,
                        "demands1": firstEqualent,
                        "Group2": secondGroup,
                        "avgWomen2": moreWomen,
                        "emp2": nrOfEmpTwo,
                        "avgSal2": secondAvgSalary,
                        "demands2": secondEqualent,
                        "Analys": autoAnalys[0], //<-- Måste skapa så skickar för robot här
                        //"Analys": autoAnalys, // <-- For testing
                        "AllResult": autoAnalys[4], //Used to get all result, example citydiff, agediff etc
                        "TotResult": autoAnalys[4] !== undefined ? autoAnalys[4].total : undefined, //The overall result for all
                        "reason": "",
                        "type": type,
                        "oneSpridningskvot": firstSpridning,
                        "twoSpridningskvot": secondSpridning
                    }
                    if (group.TotResult !== undefined && group.Analys !== "ignore") {
                        allComparisons.push(group)
                    }
                } //End of if statement if need to run function or not
            } //End of if first group is women dominated if statement
        } //End of first for loop
    } //End of second for loop
    //Divide all women dominated groups togheter with the male dominated groups that needs to be looked at

    let nestedGroups = allComparisons.reduce(function (r, a) {
        r[a.Group1] = r[a.Group1] || [];
        r[a.Group1].push(a);
        return r;
    }, Object.create(null));
    let nestedGroupsArray = Object.keys(nestedGroups).map((key) => nestedGroups[key]); //Makes nestedgroups an array


    //------- RENDER -------
    let demandsNr = "6"
    if (nrOfEqualentGroups > 6 && nrOfEqualentGroups < 10) { demandsNr = "9" }
    if (nrOfEqualentGroups > 9) { demandsNr = "12" }

    let loopTables = []
    for (let i = 0; i < nestedGroupsArray.length; i++) {
        let allColumns = []

        //1. first, push the column titles
        allColumns.push(new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: 0,
                            after: 0,
                        },
                        children: [
                            new TextRun({
                                text: language === "sv" ? `Grupp` : lang[language].group,
                                font: "Arial",
                                size: 15,
                                bold: true,
                                italics: true,
                                color: "#333333",
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    },
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: 0,
                            after: 0,
                        },
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Kvinnor" : lang[language].women,
                                font: "Arial",
                                size: 15,
                                bold: true,
                                italics: true,
                                color: "#333333",
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    },
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: 0,
                            after: 0,
                        },
                        children: [
                            new TextRun({
                                text: (language === "sv" ? "Ans" : lang[language].empMinimal) + ".",
                                font: "Arial",
                                size: 15,
                                bold: true,
                                italics: true,
                                color: "#333333",
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    },
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: 0,
                            after: 0,
                        },
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Spridning" : lang[language].spread,
                                font: "Arial",
                                size: 15,
                                bold: true,
                                italics: true,
                                color: "#333333",
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    },
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: 0,
                            after: 0,
                        },
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Krav" : lang[language].requirementsShort,
                                font: "Arial",
                                size: 15,
                                bold: true,
                                italics: true,
                                color: "#333333",
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    },
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: 0,
                            after: 0,
                        },
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Löneskillnad" : lang[language].salDiffShort,
                                font: "Arial",
                                size: 15,
                                bold: true,
                                italics: true,
                                color: "#333333",
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    },
                }),
            ],
        })
        )


        //2. then, push the women dominated group for the title
        allColumns.push(new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: `${nestedGroupsArray[i][0].Group1}`,
                                font: "Arial",
                                bold: true,
                                size: 21
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    },
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: `${nestedGroupsArray[i][0].avgWomen1}%`,
                                font: "Arial",
                                bold: true,
                                size: 21
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    },
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: `${nestedGroupsArray[i][0].emp1}`,
                                font: "Arial",
                                bold: true,
                                size: 21
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    },
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: `${nestedGroupsArray[i][0].oneSpridningskvot}`,
                                font: "Arial",
                                bold: true,
                                size: 21
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    },
                }),
                new TableCell({
                    children: [new Paragraph({
                        spacing: {
                            before: tableSpace,
                            after: tableSpace,
                        },
                        children: [
                            new TextRun({
                                text: `${nestedGroupsArray[i][0].demands1}/${demandsNr}`,
                                font: "Arial",
                                bold: true,
                                size: 21
                            }),
                        ]
                    }),],
                    columnSpan: 1,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    },
                }),
            ],
        })
        )
        //3. Then push all groups that should be compared
        for (let a = 0; a < nestedGroupsArray[i].length; a++) {
            let borderTop = { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" }
            if (a === 0) { borderTop = { style: BorderStyle.SINGLE, size: 2, color: "000000" } }
            allColumns.push(new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({
                            spacing: {
                                before: tableSpace,
                                after: tableSpace,
                            },
                            children: [
                                new TextRun({
                                    text: `${nestedGroupsArray[i][a].Group2}`,
                                    font: "Arial"
                                }),
                            ]
                        }),],
                        columnSpan: 1,
                        borders: {
                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            top: borderTop
                        },
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            spacing: {
                                before: tableSpace,
                                after: tableSpace,
                            },
                            children: [
                                new TextRun({
                                    text: `${nestedGroupsArray[i][a].avgWomen2}%`,
                                    font: "Arial"
                                }),
                            ]
                        }),],
                        columnSpan: 1,
                        borders: {
                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            top: borderTop
                        },
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            spacing: {
                                before: tableSpace,
                                after: tableSpace,
                            },
                            children: [
                                new TextRun({
                                    text: `${nestedGroupsArray[i][a].emp2}`,
                                    font: "Arial"
                                }),
                            ]
                        }),],
                        columnSpan: 1,
                        borders: {
                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            top: borderTop
                        },
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            spacing: {
                                before: tableSpace,
                                after: tableSpace,
                            },
                            children: [
                                new TextRun({
                                    text: `${nestedGroupsArray[i][a].twoSpridningskvot}`,
                                    font: "Arial"
                                }),
                            ]
                        }),],
                        columnSpan: 1,
                        borders: {
                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            top: borderTop
                        },
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            spacing: {
                                before: tableSpace,
                                after: tableSpace,
                            },
                            children: [
                                new TextRun({
                                    text: `${nestedGroupsArray[i][a].demands2}/${demandsNr}`,
                                    font: "Arial"
                                }),
                            ]
                        }),],
                        columnSpan: 1,
                        borders: {
                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            top: borderTop
                        },
                    }),
                    new TableCell({
                        children: [new Paragraph({
                            spacing: {
                                before: tableSpace,
                                after: tableSpace,
                            },
                            children: [
                                new TextRun({
                                    //text: `+${Math.round((((nestedGroupsArray[i][a].avgSal2 / nestedGroupsArray[i][a].avgSal1) * 100) - 100) * 10) / 10}%`,
                                    text: `${(((nestedGroupsArray[i][a].avgSal2 - nestedGroupsArray[i][a].avgSal1) / nestedGroupsArray[i][a].avgSal2) * 100).toFixed(1) }%`,
                                    font: "Arial",
                                    color: "d29934",
                                    bold: true
                                }),
                            ]
                        }),],
                        columnSpan: 1,
                        borders: {
                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                            top: borderTop
                        },
                    }),
                ],
            })
            )
        }
        //4. Push empty row at end to create space
        allColumns.push(new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({
                        children: [
                            new TextRun({
                                text: ``,
                                font: "Arial",
                                size: 15,
                                bold: true,
                                italics: true,
                                color: "#333333",
                            }),
                        ]
                    }),],
                    columnSpan: 6,
                    borders: {
                        right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                        bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                    },
                }),
            ],
        })
        )

        //5. Then create the table and push it to an array which we return
        let table = new Table({
            columns: 6,
            width: 0,
            columnWidths: [4000, 1100, 900, 1100, 1100, 1200],  // total page width is 9638 DXA for A4 portrait
            layout: TableLayoutType.FIXED,
            rows: allColumns
        })
        loopTables.push(table)
    }
    if (nestedGroupsArray.length === 0) {
        return [new Table({
            columns: 6,
            width: 0,
            columnWidths: [4000, 1100, 1100, 1100, 1200, 1100],  // total page width is 9638 DXA for A4 portrait
            layout: TableLayoutType.FIXED,
            rows: [new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({
                            children: [
                                new TextRun({
                                    text: language === "sv" ? "Inga kvinnodominerande arbeten har haft lägre lön än icke kvinnodominerade arbeten med lika eller högre krav." : lang[language].eqvFive,
                                    font: "Arial",
                                    color: "#000000",
                                }),
                            ]
                        }),],
                        columnSpan: 6,
                    }),
                ],
            })
            ]
        })]
    } else {
        return loopTables
    }
}