import React from 'react';
import './TopNav.css';
import arrowDown from '../assets/images/arrow-down.png';
import arrowUp from '../assets/images/arrow-up.png';
import logOut from '../assets/images/logOut.png';
import settings from '../assets/images/Settings.png';
import { fetchAuth } from '../fetch-auth';
import logoGreen from '../assets/images/logo-lonelys.png';
import { NavLink } from 'react-router-dom';
import account from '../assets/images/account.png';
import Switch from "react-switch";
import jwt_decode from "jwt-decode";
import exitImg from '../assets/images/exit.png';
import ReactTooltip from 'react-tooltip';
import searchIcon from '../assets/images/searchIcon.png';
import lang from './Language/language.js';

export class TopNav extends React.Component {
    constructor() {
        super();
        this.state = {
            showDropDown: false,
            settings: false,
            account: false,
            customer: false,
            hideName: false,
            hideSalary: false,
            popUp: false,
            settingsChanged: false,
            r: "",
            popDeleteContent: false,
            aComp: "",
            year: undefined,
            //For search box
            showWhat: "original",
            showSearchBox: false,
            individuals: [],//[{ fullName: "Martin Svensson", positionTitle: "Montör", personID: 199001012211, age: 27 }, { fullName: "Martinius Joelisson-Eriksson", positionTitle: "Senior Frontend lead developer", personID: 199001012211, age: 35 }],
            whatEvaluation: null,
            nrOfEqualentGroups: 6,
            filteredIndividuals: [],
            searchString: "",
            employeeSelected: false,
            infoPopPastYear: false,
            totalAvgSal: "Arbetsvärdering saknas",
            menAvgSal: "-",
            womenAvgSal: "-"
        }
    }

    showDropDown = () => {
        this.state.showDropDown ? this.setState({
            showDropDown: false,
            settings: false,
            account: false,
            customer: false,
            popUp: false
        }) : this.setState({ showDropDown: true })

    }

    logout = () => {
        const fetchIt = async () => {
            let response = await fetchAuth(`/api/r/logout`, 'DELETE');
            const keepName = localStorage.getItem('settingName');
            const keepPercent = localStorage.getItem('settingPercent');
            const keepwarningPrompt = localStorage.getItem('warningPrompt');
            const keepHideSalary = localStorage.getItem('docoHideSalary');
            const keepLanguage = localStorage.getItem('language');
            const keepClickedYear = localStorage.getItem('clickedYear')
            // Save if below is true
            let keepYear = null
            let keepCurrentYearSelected = null
            let keepDateForYearSelected = null
            // *** Fix for popUp and year selection (if should be saved or not) ***
            //If there has been more than 90 days since a previous year was selected, then select current year instead.
            let dateForClickedYear = localStorage.getItem("dateForYearSelected")
            if (dateForClickedYear !== null) {
                const savedDate = new Date(dateForClickedYear);
                const currentDate = new Date();
                const timeDifference = currentDate.getTime() - savedDate.getTime();
                const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

                if (daysDifference < 90) {
                    keepYear = localStorage.getItem('year')
                    keepCurrentYearSelected = localStorage.getItem('currentYearSelected')
                    keepDateForYearSelected = localStorage.getItem('dateForYearSelected')
                }
            }
            localStorage.clear(); // Clear
            localStorage.setItem('settingName', keepName);
            localStorage.setItem('settingPercent', keepPercent);
            localStorage.setItem('warningPrompt', keepwarningPrompt);
            localStorage.setItem('docoHideSalary', keepHideSalary);
            if (keepLanguage !== null) { localStorage.setItem('language', keepLanguage); }
            if (keepClickedYear !== null) { localStorage.setItem('clickedYear', keepClickedYear); }
            if (keepYear !== null) { localStorage.setItem('year', keepYear); }
            if (keepCurrentYearSelected !== null) { localStorage.setItem('currentYearSelected', keepCurrentYearSelected); }
            if (keepDateForYearSelected !== null) { localStorage.setItem('dateForYearSelected', keepDateForYearSelected); }
            window.location.href = '/login';
            return;
        }
        fetchIt();
    }

    toggleAccount = () => this.state.account && this.state.popUp ?
        this.setState({
            account: false,
            settings: false,
            customer: false,
            popUp: false
        }) :
        this.setState({
            account: true,
            popUp: true,
            settings: false,
            customer: false
        })

    toggleSettings = () => this.state.settings && this.state.popUp ?
        this.setState({
            account: false,
            settings: false,
            customer: false,
            popUp: false
        }) :
        this.setState({
            account: false,
            popUp: true,
            settings: true,
            customer: false
        })

    switchChangeName = () => this.state.hideName ? this.setState({ hideName: false, settingsChanged: true }) : this.setState({ hideName: true, settingsChanged: true })
    switchChangeSalary = () => this.state.hideSalary ? this.setState({ hideSalary: false, settingsChanged: true }) : this.setState({ hideSalary: true, settingsChanged: true })

    saveSettings = () => {
        let settingName = this.state.hideName
        let settingPercent = this.state.hideSalary

        localStorage.setItem("settingName", settingName);
        localStorage.setItem("settingPercent", settingPercent);
        window.location.reload();
    }

    changeYear = (event) => {
        let year = event.target.value
        localStorage.setItem("year", year);
        //Fix so that we dont calculate wrong year on import if the year switches but last year (which was current) is still saved in local storage
        const thisYear = new Date().getFullYear()
        const today = new Date()
        if (thisYear == year) {
            localStorage.setItem("currentYearSelected", "Yes")
            localStorage.removeItem('dateForYearSelected'); // remove the date for when we set the selected year
        } else {
            localStorage.setItem("currentYearSelected", "No")
            localStorage.setItem("dateForYearSelected", today.toISOString()) // Set when we selected the year. After 3 months it will go back to current year.
        }
        window.location.reload();
    }

    PopdeleteContent = () => {
        if (this.state.popDeleteContent) {
            this.setState({ popDeleteContent: false })
        } else {
            this.setState({ popDeleteContent: true })
        }
    }

    deleteExcel = (event) => {
        let language = localStorage.getItem('language') ?? 'sv';
        const whatDelete = event.target.id
        if (this.state.r === "Bolag") {
            //Fetch for bolag here 
            const excelDelete = async () => {
                let response = await fetchAuth(`/api/deleteExcelBolag`, 'POST');
                if (response.status === 200) {
                    alert(language === "sv" ? "Data raderad!" : lang[language].dataRemoved)
                    this.setState({ popDeleteContent: false })
                } else if (response.status !== 200) {
                    alert(language === "sv" ? "Något gick fel, försök igen" : lang[language].somethingWrong)
                }
            }
            excelDelete();
        } else if (this.state.r === "Koncern") {
            //Fetch for Koncern here, also send "whatDelete to backend"
            const excelDelete = async () => {
                let response = await fetchAuth(`/api/deleteExcelKoncern`, 'POST', JSON.stringify({ data: whatDelete }));
                if (response.status === 200) {
                    alert(language === "sv" ? "Data raderad!" : lang[language].dataRemoved)
                    this.setState({ popDeleteContent: false })
                } else if (response.status !== 200) {
                    alert(language === "sv" ? "Något gick fel, försök igen" : lang[language].somethingWrong)
                }
            }
            excelDelete();
        } else if (this.state.r === "Konsult") {
            //Fetch for Konsult here, also send "whatDelete to backend"
            const excelDelete = async () => {
                let response = await fetchAuth(`/api/deleteExcelKonsult`, 'POST', JSON.stringify({ data: whatDelete })); //<-- EXISTERAR ÄNNU INTE!
                if (response.status === 200) {
                    alert(language === "sv" ? "Data raderad!" : lang[language].dataRemoved)
                    this.setState({ popDeleteContent: false })
                } else if (response.status !== 200) {
                    alert(language === "sv" ? "Något gick fel, försök igen" : lang[language].somethingWrong)
                }
            }
            excelDelete();
        } else if (this.state.r === "Admin") {
            //Fetch for Admin here, also send "whatDelete to backend"
            const excelDelete = async () => {
                let response = await fetchAuth(`/api/deleteExcelAdmin`, 'POST', JSON.stringify({ data: whatDelete })); //<-- EXISTERAR ÄNNU INTE!
                if (response.status === 200) {
                    alert(language === "sv" ? "Data raderad!" : lang[language].dataRemoved)
                    this.setState({ popDeleteContent: false })
                } else if (response.status !== 200) {
                    alert(language === "sv" ? "Något gick fel, försök igen" : lang[language].somethingWrong)
                }
            }
            excelDelete();
        } else {
            alert(language === "sv" ? "Något gick fel, försök igen" : lang[language].somethingWrong)
        }
    }
    showSearchBox = () => {
        if (this.state.showSearchBox) {
            this.setState({ showSearchBox: false, filteredIndividuals: [], searchString: "", employeeSelected: false })
        } else {
            // Load employee data if not already loaded
            if (this.state.individuals.length < 1) {
                const runIt = async () => {
                    let response = await fetchAuth(`/api/loadSearchData`, 'POST');
                    let data = await response.json();
                    console.log(data)
                    let individuals = data[1]
                    let whatEvaluation = data[0][0]
                    let nrOfEqualentGroups = data[0][1] == null ? 6 : data[0][1]
                    if (whatEvaluation === "extLevel") {
                        nrOfEqualentGroups = Math.max(...individuals.map(obj => obj.extEqualentGroup));
                    }
                    this.setState({ whatEvaluation: whatEvaluation, nrOfEqualentGroups: nrOfEqualentGroups, individuals: individuals })
                }
                runIt();
            }
            this.setState({ showSearchBox: true })

        }
    }
    searchChange = (event) => {
        let searchString = event.target.value
        if (searchString.length > 2) {
            let filteredList = this.state.individuals.filter((employee) =>
                employee.fullName.toLowerCase().includes(searchString.toLowerCase())
            );
            this.setState({ searchString: searchString, filteredIndividuals: filteredList })
        } else { this.setState({ searchString: searchString, filteredIndividuals: [] }) }
    }
    selectEmployee = (event) => {
        //  const personId = event.currentTarget.id // <-- Filter by personal id later
        if (this.state.employeeSelected) {
            this.setState({ employeeSelected: false })
        } else {
            // Then show popUp
            // let person = this.state.filteredIndividuals.filter((item) => item.personID == personId) // <-- Filter by personal id later
            const name = event.currentTarget.dataset.name
            const age = event.currentTarget.dataset.age
            const exp = event.currentTarget.dataset.exp
            const title = event.currentTarget.dataset.title
            console.log(name, age, exp, title)
            let person = this.state.filteredIndividuals.filter((item) => item.fullName === name && item.age == age && item.experience == exp && item.positionTitle === title)
            console.log(this.state.filteredIndividuals)
            console.log(person[0])

            // Calculate average salaries for the equivalent group
            let evaluation = this.state.whatEvaluation === "edited" ? "editedEqualentGroup" : this.state.whatEvaluation === "extLevel" ? "extEqualentGroup" : "equalentGroup"
            let evaluationLevel = person[0][evaluation]
            let menAvgSal = "-"
            let womenAvgSal = "-"
            let totalAvgSal = "Arbetsvärdering saknas"
            if (evaluationLevel !== null && evaluationLevel !== undefined) {
                const groupItems = this.state.individuals.filter(item => item.equalentGroup == evaluationLevel);
                let totalSalaryAll = 0;
                let totalCountAll = 0;
                let totalSalaryMen = 0;
                let totalCountMen = 0;
                let totalSalaryWomen = 0;
                let totalCountWomen = 0;
                groupItems.forEach(item => {
                    totalSalaryAll += item.totalSalary;
                    totalCountAll += 1;
                    if (item.gender === "man") {
                        totalSalaryMen += item.totalSalary;
                        totalCountMen += 1;
                    } else if (item.gender === "woman") {
                        totalSalaryWomen += item.totalSalary;
                        totalCountWomen += 1;
                    }
                });
                totalAvgSal = Math.round((totalSalaryAll / totalCountAll || 0));
                menAvgSal = Math.round((totalSalaryMen / totalCountMen || 0));
                womenAvgSal = Math.round((totalSalaryWomen / totalCountWomen || 0));
                console.log('Total salary all:', totalAvgSal, 'Total count all:', totalCountAll);
                console.log('Total salary men:', menAvgSal, 'Total count men:', totalCountMen);
                console.log('Total salary women:', womenAvgSal, 'Total count women:', totalCountWomen);

            }

            this.setState({ employeeSelected: person, totalAvgSal: totalAvgSal, menAvgSal: menAvgSal, womenAvgSal: womenAvgSal })
        }
    }
    indPopUp = () => {
        return <>
            <div className="backgroundBlackHide"></div>
            <div className="individuealPopBox">
                <img alt="" src={exitImg} className="hide-box" style={{ width: 22, marginRight: -10, marginTop: -10 }} onClick={this.showSearchBox} />
                <div style={{ fontSize: 18, fontWeight: 900 }}>{this.state.employeeSelected[0].fullName}</div>
                <div style={{ marginTop: 40 }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ display: "inline-block", width: "50%" }}>
                        <div className="inBoxInline">
                            <div className="inTitleBox">Lön:</div>
                            <div style={{ display: "inline-block", width: 147, marginBottom: 10 }}>{this.state.employeeSelected[0].totalSalary.toLocaleString()} kr</div>
                        </div>
                        <div className="inBoxInline">
                            <div className="inTitleBox">Befattning:</div>
                            <div style={{ display: "inline-block", width: 147, marginBottom: 10 }}>{this.state.employeeSelected[0].positionTitle}</div>
                        </div>
                        <div className="inBoxInline">
                            <div className="inTitleBox">Lika arbete:</div>
                            <div style={{ display: "inline-block", width: 147, marginBottom: 10 }}>{this.state.employeeSelected[0].groupNameArbVardering}</div>
                        </div>
                        <div className="inBoxInline">
                            <div className="inTitleBox" style={{marginBottom: 0}}>Likvärdigt arbete:</div>
                            <div style={{ display: "inline-block", width: 147 }}>Grupp {this.state.whatEvaluation === "edited" ? this.state.employeeSelected[0].editedEqualentGroup : this.state.whatEvaluation === "extLevel" ? this.state.employeeSelected[0].extEqualentGroup : this.state.employeeSelected[0].equalentGroup} <span>(av {this.state.nrOfEqualentGroups})</span></div>
                        </div>
                    </div>
                    <div style={{ display: "inline-block", width: "50%", textAlign: "center", verticalAlign: "top" }}>
                    
                            <div className="salaryBoxContainerTop">
                                <div style={{ fontWeight: 600, paddingBottom: 10, textAlign: "left" }}>Snittlön för likvärdigt arbete</div>
                                <div className="inTitleBoxChild"><span className="inTitleBoxChildTitle">Män: </span>{this.state.womenAvgSal.toLocaleString()} kr</div>
                                <div className="inTitleBoxChild"><span className="inTitleBoxChildTitle">Kvinnor: </span>{this.state.menAvgSal.toLocaleString()} kr</div>
                                <div className="inTitleBoxChild" style={{ marginBottom: 0 }}><span className="inTitleBoxChildTitle">Totalt: </span>{this.state.totalAvgSal.toLocaleString()} kr</div>

                            </div>
                    </div>
                </div>
                <div className="inBoxBottomDiv">
                    <span class="informationMarkImg">i</span>
                    <span>Ovanstående är info som medarbetaren har rätt att begära ut. Lönerna visas inklusive tillägg.</span>
                </div>
                {/* <div style={{ display: "inline-block", width: "50%", textAlign: "center", verticalAlign: "top" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: 72 }}>
                        <div className="salaryBoxContainerTop">
                            <div style={{marginBottom: 5, fontWeight: 600}}>Lön</div>
                                <div style={{ paddingTop: 5 }}>25 000 kr</div>
                            </div>
                        </div>
    </div>*/}
            </div>

            {/* Tror ej att bonus ska vara med.. eller? */}
            {/*} <div className="inBoxBottomDiv">
                     <div style={{ display: "inline-block", width: "50%" }}>
                        <div className="inTitleBox">Medellön för lika arbeten</div>
                        <div className="inTitleBoxChild"><span className="inTitleBoxChildTitle">Män: </span>35 000 kr</div>
                        <div className="inTitleBoxChild"><span className="inTitleBoxChildTitle">Kvinnor: </span>40 000 kr</div>
                        <div className="inTitleBoxChild"><span className="inTitleBoxChildTitle">Totalt: </span>30 000 kr</div>
                    </div>
                    <div style={{ display: "inline-block", width: "50%" }}>
                        <div className="inTitleBox">Medellön för likvärdiga arbeten</div>
                        <div className="inTitleBoxChild"><span className="inTitleBoxChildTitle">Män: </span>35 000 kr</div>
                        <div className="inTitleBoxChild"><span className="inTitleBoxChildTitle">Kvinnor: </span>40 000 kr</div>
                        <div className="inTitleBoxChild"><span className="inTitleBoxChildTitle">Totalt: </span>30 000 kr</div>
    </div>*/}
            {/*  <div style={{ display: "inline-block", width: "50%" }}>
                        <div className="inTitleBox">Snittlön för likvärdiga arbeten</div>
                        <div className="inTitleBoxChild"><span className="inTitleBoxChildTitle">Män: </span>35 000 kr</div>
                        <div className="inTitleBoxChild"><span className="inTitleBoxChildTitle">Kvinnor: </span>40 000 kr</div>
                        <div className="inTitleBoxChild"><span className="inTitleBoxChildTitle">Totalt: </span>30 000 kr</div>
                    </div>
                    <div style={{ display: "inline-block", width: "50%", textAlign: "center", verticalAlign: "top" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: 72 }}>
                        <div className="salaryBoxContainerBottom">
                               <div style={{marginBottom: 5, fontWeight: 600}}>Procent av snittlön</div>
                                <div style={{ paddingTop: 5 }}>20 %</div>
                            </div>
                        </div>
</div>
                </div>*/}

        </div ></>
    }
    enterYearInfo = () => { this.setState({ infoPopPastYear: true }) }
    leaveYearInfo = () => { this.setState({ infoPopPastYear: false }) }
    componentDidMount() {
        const getSettings = async () => {
            let nameStatus = localStorage.getItem("settingName");
            let salaryStatus = localStorage.getItem("settingPercent");
            nameStatus === "true" ? nameStatus = true : nameStatus = false
            salaryStatus === "true" ? salaryStatus = true : salaryStatus = false
            let localT = localStorage.getItem('localT');
            let { role, aComp } = jwt_decode(localT);
            let year = localStorage.getItem("year")
            if (year == undefined) { year = new Date().getFullYear() }
            this.setState({ hideName: nameStatus, hideSalary: salaryStatus, r: role, aComp: aComp, year: year })

            let settingEU = localStorage.getItem('settingEU');
            if (settingEU === "on") { this.setState({ showWhat: "EU" }) }
        }
        getSettings();
    }


    render() {
        let acc = ""
        let r = this.state.r
        if (r === "Bolag") { acc = "/bolagsVy" }
        else if (r === "Koncern") { acc = "/koncernvy" }
        else if (r === "Konsult") { acc = "/konsultvy" }
        else if (r === "Admin") { acc = "/admin" }
        let language = localStorage.getItem('language') ?? 'sv';
        return (
            <>
                <ReactTooltip />
                {/* PopUp delete contents*/}
                <div className={this.state.popDeleteContent ? "popUp active-popUp" : "hide"} style={{ position: "fixed", margin: "auto", zIndex: 16, maxWidth: 650 }}>
                    <img alt="" src={exitImg} className="hide-box" onClick={this.PopdeleteContent} />
                    <span style={{ marginTop: 60, display: "block", fontWeight: 900, fontSize: 18 }}>{language === "sv" ? "Vill du radera individdata?" : lang[language].deleteDataPop}
                        <p style={{ fontWeight: "normal", fontSize: 15, padding: "0px 30px" }}>{language === "sv" ? "Du kan återställa raderad data genom att importera excelfilen på nytt." : lang[language].reImport}</p>
                    </span>
                    <div style={{ marginTop: 40 }}>
                        <span className="lastYearButton" style={{ background: "rgb(201 101 85)", marginRight: 10, padding: "11px 20px" }} onClick={this.PopdeleteContent}>{language === "sv" ? "Avbryt" : lang[language].cancel}</span>
                        {this.state.r === "Bolag" ?
                            <span className="lastYearButton" style={{ marginLeft: 10, padding: "11px 20px" }} id="singleCompBolag" onClick={this.deleteExcel}>{language === "sv" ? "Fortsätt" : lang[language].continue}</span> :
                            this.state.r === "Koncern" || this.state.r === "Konsult" || this.state.r === "Admin" ?
                                <>
                                    <span className="lastYearButton" style={{ marginLeft: 10, padding: "11px 20px" }} id="singleComp" onClick={this.deleteExcel}>{language === "sv" ? "Radera för bolag" : lang[language].deleteForBolag}</span>
                                    <span className="lastYearButton" style={{ marginLeft: 10, padding: "11px 20px" }} id="fullKoncern" onClick={this.deleteExcel}>{language === "sv" ? "Radera för hela koncernen" : lang[language].deleteForKoncern}</span>
                                </>
                                : language === "sv" ? "Något gick fel, prova ladda om sidan" : lang[language].errorTryAgainToLoad}
                    </div>
                </div>
                {/* PopUp Faders */}
                <div style={{ zIndex: 15 }} className={this.state.popDeleteContent ? "popUpFader" : "hide"}></div>

                {/*Menu */}
                <div className="topnav">
                    <a className="logo"><img src={logoGreen} style={{ marginTop: -9, width: 150, marginLeft: -9 }} /></a>
                    <a onClick={this.showDropDown} className="menu-item business">{this.state.aComp} <img alt="" src={this.state.showDropDown === false ? arrowDown : arrowUp} className="arrow-down" /></a>
                    <NavLink exact to="/" className="menu-item introduktion-item">{language === "sv" ? "Introduktion" : lang[language].introduction}</NavLink>
                    {this.state.showWhat === "EU" && <NavLink exact to="/rapportering" className="menu-item introduktion-item">{language === "sv" ? "Rapportering" : lang[language].reporting }</NavLink>}
                    <NavLink exact to="/overview" className="menu-item introduktion-item">{language === "sv" ? "Översikt" : lang[language].overview }</NavLink>
                    <div className={this.state.showWhat === "EU" ? "" : "hide"}>
                        <img onClick={this.showSearchBox} src={searchIcon} className={!this.state.showSearchBox ? "searchIndividualIcon" : "hide"} />
                        <div style={{ float: "right" }}>
                            <div className={this.state.showSearchBox ? "searchBoxIndividual" : "hide"}> <input className="searchBoxIndInput" type="text" placeholder="Sök medarbetare" onChange={this.searchChange} value={this.state.searchString} /><img onClick={this.showSearchBox} src={exitImg} className="searchIndividualIconInside" style={{ opacity: 0.8 }} /></div>
                            <ul className={this.state.showSearchBox && this.state.filteredIndividuals.length > 0 ? "searchIndividualDropdown" : "hide"}>
                                {this.state.filteredIndividuals.map(employee =>
                                    <li className="searchIndividualLi" onClick={this.selectEmployee} data-name={employee.fullName} data-age={employee.age} data-exp={employee.experience} data-title={employee.positionTitle} ><div className="inlineListFatLeft">{employee.fullName} <span style={{ color: "grey", fontSize: 13 }}>| {employee.age} år, {employee.positionTitle}</span></div></li>
                                )}
                            </ul>
                            {this.state.employeeSelected && this.indPopUp()}
                        </div>
                    </div>
                    {/*  {this.state.year == new Date().getFullYear() || this.state.year === undefined ? "" : <span style={{ background: "#eaac3a", marginTop: 12, display: "inline-block", padding: 7, borderRadius: 3, float: "right", marginRight: 35, color: "white", fontWeight: 600, }} data-tip="Gå till inställningar för att ändra till nuvarande år."><b>OBS: </b>Kartläggning utförs för år {this.state.year}</span>} */}
                    {this.state.year == new Date().getFullYear() || this.state.year === undefined ? "" : <span onMouseEnter={this.enterYearInfo} onMouseLeave={this.leaveYearInfo} style={{ background: "#eaac3a", marginTop: 12, display: "inline-block", padding: 7, borderRadius: 3, float: "right", marginRight: 35, color: "white", fontWeight: 600, }}><b>OBS: </b>Kartläggning utförs för år {this.state.year}  <span className={this.state.infoPopPastYear ? "infoPopPastYear" : "hide"}>Gå till inställningar för att ändra till nuvarande år</span></span>}
                </div>

                <div className={this.state.showDropDown ? "dropDown" : "hideDropDown"}>
                    <div className={this.state.showDropDown ? "dropDown-inner" : ""}>
                        <div>
                            <div style={{ fontWeight: 800, fontSize: 17.6, paddingLeft: 20, paddingRight: 20, paddingTop: 38, paddingBottom: 38 }}>{this.state.aComp}</div>
                        </div>
                        {/*<div style={{ borderBottom: "none" }} className="dropDownAccount" onClick={this.toggleAccount}><img alt="" src={account} style={{ width: 16, float: "right" }} /><span>Konto</span></div>*/}
                        <NavLink exact to={acc} style={{ color: "white" }}><div style={{ borderBottom: "none" }} className="dropDownAccount" ><img alt="" src={account} style={{ width: 16, float: "right" }} /><span>{language === "sv" ? "Konto" : lang[language].account}</span></div></NavLink>
                        <div className="dropDownAccount" onClick={this.toggleSettings}><img alt="" src={settings} style={{ width: 16, float: "right" }} /><span>{language === "sv" ? "Inställningar" : lang[language].settings}</span></div>
                        {/*<NavLink exact to="/costumerAccounts" style={{ color: "white" }}><div style={{borderTop: "none"}} className="dropDownAccount" ><img alt="" src={customerAcc} style={{ width: 17, float: "right" }} /><span>Kundkonton</span></div></NavLink>*/}
                        <div className="dropDownLogOut" onClick={this.logout}><img alt="" src={logOut} style={{ width: 16, float: "right" }} /><span>{language === "sv" ? "Logga Ut" : lang[language].logout}</span></div>
                    </div>
                </div>

                {/* PopUp Faders */}
                <div className={this.state.popUp ? "popUpFader" : "hide"}></div>
                {/* Settings */}
                <div style={{ marginTop: 107 }} className={this.state.popUp && this.state.settings ? "active-popUp-TopNav" : "hide"}>
                    <div className={this.state.popUp && this.state.settings ? "active-popUp-TopNav-inner" : "hide"}>
                        <div className="settingsInline" style={{ borderRight: "1px solid #e9e9e9" }}>
                            <div className="kontoText"><span style={{ fontWeight: 800 }}>{language === "sv" ? "Dölj information" : lang[language].hideInfo}</span></div>
                            <div className="kontoText" style={{ paddingTop: 20 }}><span style={{ display: "inline-block", width: language !== "sv" ? 105 : 80, fontSize: 15.6 }}>{language === "sv" ? "Dölj namn: " : lang[language].hideName}</span><Switch onColor='#56cbad' offColor='#b9b9b9' borderRadius={5} className="swiftPosition" activeBoxShadow="none" onChange={this.switchChangeName} checked={this.state.hideName} /></div>
                            <div className="kontoText"><span style={{ display: "inline-block", width: language !== "sv" ? 105 : 80, fontSize: 15.6 }}>{language === "sv" ? "Dölj löner: " : lang[language].hideSalary}</span><Switch onColor='#56cbad' offColor='#b9b9b9' borderRadius={5} className="swiftPosition" activeBoxShadow="none" onChange={this.switchChangeSalary} checked={this.state.hideSalary} /></div>
                            <div style={{ fontSize: 11, position: "fixed", marginLeft: 35, marginTop: -13, borderTop: "1px solid #e9e9e9", width: 73 }}>{language === "sv" ? "Visas i %" : lang[language].showInPercent}</div>
                            {this.state.settingsChanged ? <div style={{ margin: "auto", fontSize: 15.6, position: "absolute", bottom: 0, left: "50%", transform: "translateX(-50%)" }} className="kontoButton" onClick={this.saveSettings}>{language === "sv" ? "Spara" : lang[language].save}</div> : <div style={{ margin: "auto", marginTop: 30 }} className="kontoButton inactive-konto-button">{language === "sv" ? "Sparat" : lang[language].saved}</div>}
                        </div>
                        <div className="settingsInline" style={{ borderRight: "1px solid #e9e9e9" }}>
                            <div className="kontoText"><span style={{ fontWeight: 800 }}>{language === "sv" ? "Välj år" : lang[language].selectYear}</span></div>
                            <div className="kontoText" style={{ fontSize: 14.6 }}>{language === "sv" ? "Justera vilket år lönekartläggningen gäller. Noteringar hämtas och sparas för detta år, likaså dokumentationen." : lang[language].yearInfo}</div>
                            {/* <div className="kontoText" style={{ fontSize: 11, borderTop: "1px solid #e9e9e9", marginLeft: 25, marginRight: 25 }}>Notera att individdata även raderas automatiskt efter 30 dagar av säkerhetsskäl. </div>*/}
                            <select name="theYear" id="theYear" onChange={this.changeYear} value={this.state.year}>
                                <option value={new Date().getFullYear()}>{language === "sv" ? "Nuvarande år" : lang[language].thisYear}</option>
                                <option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option>
                                <option value={new Date().getFullYear() - 2}>{new Date().getFullYear() - 2}</option>
                                <option value={new Date().getFullYear() - 3}>{new Date().getFullYear() - 3}</option>
                                <option value={new Date().getFullYear() - 4}>{new Date().getFullYear() - 4}</option>
                            </select>
                        </div>
                        <div className="settingsInline">
                            <div className="kontoText"><span style={{ fontWeight: 800 }}>{language === "sv" ? "Radera data" : lang[language].deleteData}</span></div>
                            <div className="kontoText" style={{ fontSize: 14.6 }}>{language === "sv" ? "Radera individdata. Notera att endast individdata raderas (data från uppladdad excelfil). Kommentarer etc. finns kvar." : lang[language].deleteInfo}</div>
                            {/* <div className="kontoText" style={{ fontSize: 11, borderTop: "1px solid #e9e9e9", marginLeft: 25, marginRight: 25 }}>Notera att individdata även raderas automatiskt efter 30 dagar av säkerhetsskäl. </div>*/}
                            <div className="kontoButton" style={{ margin: "auto", background: "#cb5656", fontSize: 15.6, position: "absolute", bottom: 0, left: "50%", transform: "translateX(-50%)" }} onClick={this.PopdeleteContent}>{language === "sv" ? "Radera data" : lang[language].deleteData}</div>
                        </div>
                    </div>
                    {/* <div className="moreSettingsBtn">Fler inställningar</div> */}
                    <div className="moreSettingsBackground">
                        <NavLink exact to="/settings">
                            <div className="moreSettingsBtn" ><span><img src={settings} style={{ width: 16, opacity: 0.4, marginRight: 7.5, filter: "invert(100%)", verticalAlign: "top" }} />{language === "sv" ? "Fler inställningar" : lang[language].moreSettings}</span></div>
                        </NavLink>
                    </div>
                </div>
            </>
        );
    }
}