import { saveAs } from 'file-saver';
import {
    docx,
    Packer,
    AlignmentType,
    Document,
    HeadingLevel,
    Paragraph,
    TabStopPosition,
    TabStopType,
    TextRun,
    SectionType,
    PageBreak,
    TableCell,
    TableRow,
    Table,
    WidthType,
    TableLayoutType,
    BorderStyle,
    Footer
} from "docx";
import { viktningTab1 } from './WordFunctions/ViktningTab1.js';
import { likaTabellCompared } from './WordFunctions/LikaTabellCompared.js';
import { likaTabellComparedExtra } from './WordFunctions/LikaTabellComparedExtra.js';
import { LikvardigaCompared } from './WordFunctions/LikvardigaCompared.js';
import { LikvardigaComparedExt } from './WordFunctions/LikvardigaComparedExt.js';
import { LikvardigaTabellNoSalaries } from './WordFunctions/LikvardigaTabellNoSalaries.js';
import { LikvardigaTabellNoSalariesExt } from './WordFunctions/LikvardigaTabellNoSalariesExt.js';
import { analysTabell } from './WordFunctions/AnalysTabell';
import { likvardigaComments } from './WordFunctions/likvardigaComments';
import lang from '../Language/language.js';
export const wordDocumentDefault = async (logoWidth, samverkanText, praxisText, medverkandeText, fritext, fritextTitel, logo, temporaryLogo, totStats, vikt, persData, comments, oldComments, legalComp, theYear, nrOfEqualentGroups, chartImages, chartPercent, chartImagesAge, chartAge, chartExp, showAgeChart, chartImagesExtraExp, chartImagesExtraAge, addOnChart, addOnDetails, whatEvaluation, valuationName, editedFactors, originalFactors, whatLevelDesc) => {
    let language = localStorage.getItem('language') ?? 'sv';
    //Group edited factors
    let knowledge = []
    let responsibility = []
    let effort = []
    for (let i = 0; i < editedFactors.length; i++) {
        if (editedFactors[i].category === "Knowledge") { knowledge.push(editedFactors[i]) }
        if (editedFactors[i].category === "Responsibility") { responsibility.push(editedFactors[i]) }
        if (editedFactors[i].category === "Effort") { effort.push(editedFactors[i]) }
    }
    //Sum weights
    const knowledgeWeight = knowledge.reduce((accumulator, currentObject) => {
        return accumulator + currentObject.weight;
    }, 0);
    const responsibilityWeight = responsibility.reduce((accumulator, currentObject) => {
        return accumulator + currentObject.weight;
    }, 0);
    const effortWeight = effort.reduce((accumulator, currentObject) => {
        return accumulator + currentObject.weight;
    }, 0);
    //Settings
    const fontSize = 23
    const fontTitleSize = 34
    const subTitleSize = 28
    const tableSpace = 80
    const tableSpaceBig = 120
    //For calculating "Inledning"
    let menSalary = ""
    if (totStats.avgSalMen !== undefined) menSalary = parseInt(totStats.avgSalMen).toLocaleString()
    let womenSalary = ""
    if (totStats.avgSalWomen !== undefined) womenSalary = parseInt(totStats.avgSalWomen).toLocaleString()
    let diff = ""
    if (totStats.diffWomen !== undefined) diff = parseInt(totStats.diffWomen).toLocaleString()
    let total = ""
    if (totStats.avgSalTot !== undefined) total = parseInt(totStats.avgSalTot).toLocaleString()
    let diffSalWomenPercent = ""
    if (totStats.diffSalWomenPercent !== undefined && totStats.avgSalWomen !== undefined) { diffSalWomenPercent = totStats.diffSalWomenPercent }
    //End of calculating "Inledning"
    //Check if there are any comments with todos, for old and new
    let newCommentsTodo = comments.filter(com => (com.todo !== null && com.todo !== ""))
    let newCommentsTodoEqual = comments.filter(com => (com.analysis === "lika" && (com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== "")))
    let newCommentsTodoEqualent = comments.filter(com => (com.analysis === "likvärdiga" && (com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== "")))
    let newCommentsTodoHierarchy = comments.filter(com => (com.analysis === "hierarkisk" && (com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== "")))
    let newCommentsTodoGeneral = comments.filter(com => (com.analysis === "general" && (com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== "")))
    let oldCommentsTodo = oldComments.filter(com => (com.todo !== null && (com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== "")))
    let oldCommentsTodoEqual = oldComments.filter(com => (com.analysis === "lika" && (com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== "")))
    let oldCommentsTodoEqualent = oldComments.filter(com => (com.analysis === "likvärdiga" && (com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== "")))
    let oldCommentsTodoHierarchy = oldComments.filter(com => (com.analysis === "hierarkisk" && (com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== "")))
    let oldCommentsTodoGeneral = oldComments.filter(com => (com.analysis === "general" && (com.todo !== null && com.todo !== "" && com.deadline !== null && com.deadline !== "")))
    //Create word document
    const doc = new Document({
        sections: [
            {
                properties: {
                    type: SectionType.NEXT_PAGE,
                },
                footers: {
                    default: new Footer({
                        children: [new Paragraph({
                            heading: HeadingLevel.HEADING_1,
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: legalComp,
                                    bold: true,
                                    color: "ababab",
                                    font: "Arial",
                                    size: subTitleSize
                                })
                            ]
                        }),],
                    }),
                },
                children: [

                    // FIRST PAGE
                    new Paragraph({
                        heading: HeadingLevel.HEADING_1,
                        alignment: AlignmentType.CENTER,
                        //spacing: { before: 400, after: 400 },
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Lönekartläggning" : lang[language].payE,
                                bold: true,
                                underline: {},
                                color: "000000",
                                size: 56,
                                break: 7,
                                font: "Arial"
                            }),
                            new TextRun({
                                text: theYear,
                                break: 1,
                                color: "000000",
                                size: 46,
                                font: "Arial"
                            })]
                    }),

                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new PageBreak()]
                    }),
                    // --------- END OF FIRST PAGE

                    //Innehållsforteckning
                    new Paragraph({
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Innehållsförteckning" : lang[language].tableOfCont,
                                bold: true,
                                color: "000000",
                                size: fontTitleSize,
                                font: "Arial"
                            }),
                        ]
                    }),
                    new Paragraph({
                        heading: HeadingLevel.HEADING_1,
                        children: [
                            new TextRun({
                                text: language === "sv" ? "1. Inledning" : lang[language].tocIntro,
                                bold: true,
                                color: "000000",
                                size: subTitleSize,
                                font: "Arial",
                                break: 3
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: language === "sv" ? "2. Metod" : lang[language].tocMethod,
                                bold: true,
                                color: "000000",
                                size: subTitleSize,
                                font: "Arial",
                                break: 1
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "     " + (language === "sv" ? "2.1 Verktyg" : lang[language].tocSys),
                                color: "000000",
                                size: fontSize,
                                font: "Arial",
                                break: 1
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "     " + (language === "sv" ? "2.2 Viktning & bedömning" : lang[language].tocWeight),
                                color: "000000",
                                size: fontSize,
                                font: "Arial",
                                break: 1
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "     " + (language === "sv" ? "2.3 Arbetsgång" : lang[language].tocProcedure),
                                color: "000000",
                                size: fontSize,
                                font: "Arial",
                                break: 1
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: language === "sv" ? "3. Samverkan" : lang[language].tocCollab,
                                bold: true,
                                color: "000000",
                                size: subTitleSize,
                                font: "Arial",
                                break: 1
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: language === "sv" ? "4. Kartlägggning & analys av lönebestämmelser & praxis" : lang[language].tocAnalyze,
                                bold: true,
                                color: "000000",
                                size: subTitleSize,
                                font: "Arial",
                                break: 1
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: language === "sv" ? "5. Resultat & analys" : lang[language].tocResults,
                                bold: true,
                                color: "000000",
                                size: subTitleSize,
                                font: "Arial",
                                break: 1
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "     " + (language === "sv" ? "5.1 Lika arbete" : lang[language].tocEqualWork),
                                color: "000000",
                                size: fontSize,
                                font: "Arial",
                                break: 1
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "     " + (language === "sv" ? "5.2 Likvärdiga arbeten & hierarkiska skillnader" : lang[language].tocEquivalent),
                                color: "000000",
                                size: fontSize,
                                font: "Arial",
                                break: 1
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: language === "sv" ? "6. Handlingsplan" : lang[language].tocAction,
                                bold: true,
                                color: "000000",
                                size: subTitleSize,
                                font: "Arial",
                                break: 1
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: language === "sv" ? "7. Utvärdering av föregående års åtgärder" : lang[language].tocEvaluation,
                                bold: true,
                                color: "000000",
                                size: subTitleSize,
                                font: "Arial",
                                break: 1
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: `${fritext !== "" && fritextTitel !== "" ? "8. " + fritextTitel : ""}`,
                                bold: true,
                                color: "000000",
                                size: subTitleSize,
                                font: "Arial",
                                break: 1
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new PageBreak()]
                    }),
                    //  ------------ END OF Innehållsforteckning

                    //1. Inledning
                    new Paragraph({
                        heading: HeadingLevel.HEADING_1,
                        children: [
                            new TextRun({
                                text: language === "sv" ? "1. Inledning" : lang[language].tocIntro,
                                bold: true,
                                color: "000000",
                                size: fontTitleSize,
                                font: "Arial",
                            }),
                        ]
                    }),
                    new Paragraph({
                        heading: HeadingLevel.HEADING_1,
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Enligt diskrimineringslagen skall samtliga arbetsgivare genomföra en lönekartläggning varje år. Syftet med lönekartläggningen är att upptäcka, åtgärda och förhindra osakliga löneskillnader mellan kvinnor och män." : lang[language].intOne,
                                color: "000000",
                                font: "Arial",
                                size: fontSize,
                                break: 1
                            }),
                            new TextRun({
                                text: `${totStats.nrWomen + totStats.nrMen}${language === "sv" ? " anställda omfattas totalt av lönekartläggningen. Den totala lönefördelningen visas i tabellen nedan." : lang[language].intTwo}`,
                                color: "000000",
                                break: 2,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new TextRun({
                                text: "",
                                break: 1,
                            }),
                        ]
                    }),

                    new Table({
                        // rows: 5,
                        columns: 4,
                        width: 0, // AUTO
                        columnWidths: [1400, 1400, 1800, 1000], // total page width is 9638 DXA for A4 portrait
                        layout: TableLayoutType.FIXED,
                        rows: [
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: language === "sv" ? "Antal" : lang[language].intEmp,
                                                    bold: true,
                                                    color: "000000",
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                        columnSpan: 2,
                                        borders: {
                                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                        },
                                    }),
                                    new TableCell({
                                        children: [new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: language === "sv" ? "Lönefördelning" : lang[language].intDist,
                                                    bold: true,
                                                    color: "000000",
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                        columnSpan: 2,
                                        borders: {
                                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                        },
                                    }),
                                ],
                            }),
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: language === "sv" ? "Män" : lang[language].men,
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                        borders: {
                                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                        },
                                    }),
                                    new TableCell({
                                        children: [new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: `${totStats.nrMen}`,
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                        borders: {
                                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                        },
                                    }),
                                    new TableCell({
                                        children: [new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: language === "sv" ? "Differens kvinnor" : lang[language].womenDiff,
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                        borders: {
                                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                        },
                                    }),
                                    new TableCell({
                                        children: [new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: `${diffSalWomenPercent}%`,
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                        borders: {
                                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                        },
                                    }),
                                ],
                            }),
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: language === "sv" ? "Kvinnor" : lang[language].women,
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                        borders: {
                                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                        },
                                    }),
                                    new TableCell({
                                        children: [new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: `${totStats.nrWomen}`,
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                        borders: {
                                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                        },
                                    }),
                                    new TableCell({
                                        children: [new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: "",
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                        borders: {
                                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                        },
                                    }),
                                    new TableCell({
                                        children: [new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: "",
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                        borders: {
                                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                        },
                                    }),
                                ],
                            }),
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: language === "sv" ? "Totalt" : lang[language].total,
                                                    bold: true,
                                                    color: "000000",
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                        bold: true,
                                        borders: {
                                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                        },
                                        shading: {
                                            fill: "f3f3f3",
                                        },
                                    }),
                                    new TableCell({
                                        children: [new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: `${totStats.nrTot}`,
                                                    bold: true,
                                                    color: "000000",
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                        bold: true,
                                        borders: {
                                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                        },
                                        shading: {
                                            fill: "f3f3f3",
                                        },
                                    }),
                                    new TableCell({
                                        children: [new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: "",
                                                    bold: true,
                                                    color: "000000",
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                        bold: true,
                                        borders: {
                                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                        },
                                        shading: {
                                            fill: "f3f3f3",
                                        },
                                    }),
                                    new TableCell({
                                        children: [new Paragraph({
                                            spacing: {
                                                before: tableSpace,
                                                after: tableSpace,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: "",
                                                    bold: true,
                                                    color: "000000",
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                        borders: {
                                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                        },
                                        shading: {
                                            fill: "f3f3f3",
                                        },
                                    }),
                                ],
                            }),
                        ],
                    }),

                    new Paragraph({
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Följande personer har ingått i gruppen som genomfört lönekartläggningen:" : lang[language].intPart,
                                color: "000000",
                                break: 2,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new TextRun({
                                text: medverkandeText,
                                color: "000000",
                                break: 2,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new PageBreak()
                        ]
                    }),
                    // End of Inledning

                    //-----------------------

                    // Metod
                    new Paragraph({
                        heading: HeadingLevel.HEADING_1,
                        children: [
                            new TextRun({
                                text: language === "sv" ? "2. Metod" : lang[language].tocMethod,
                                bold: true,
                                color: "000000",
                                size: fontTitleSize,
                                font: "Arial"
                            }),
                            new TextRun({
                                text: language === "sv" ? "2.1 Verktyg" : lang[language].tocSys,
                                bold: true,
                                color: "000000",
                                break: 3,
                                font: "Arial",
                                size: subTitleSize
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: whatEvaluation !== "extLevel" && whatEvaluation !== "extPoint" ? (language === "sv" ? "I arbetet med lönekartläggningen har verktyget Lönelys använts. " +
                                "Detta hanterar kartläggningen av arbetskrav samt ger möjligheten att vikta dessa. " +
                                "Verktyget sammanställer resultatet i diagram och tabeller som möjliggör analys. Detta visar " +
                                "löneförhållanden inom grupper av anställda som utför lika arbete, mellan likvärdiga arbeten samt hierarkiska skillnader." : lang[language].metOne)
                                    : (language === "sv" ? " I arbetet med lönekartläggningen har verktyget Lönelys använts. Verktyget sammanställer resultatet i diagram och tabeller som möjliggör analys. Detta visar löneförhållanden inom grupper av anställda som utför lika arbete, mellan likvärdiga arbeten samt hierarkiska skillnader." : lang[language].metOne),
                                color: "000000",
                                font: "Arial",
                                size: fontSize,
                                break: 1
                            })
                        ]
                    }),
                    new Paragraph({
                        heading: HeadingLevel.HEADING_1,
                        children: [
                            new TextRun({
                                text: language === "sv" ? "2.2 Viktning & bedömning" : lang[language].tocWeight,
                                bold: true,
                                color: "000000",
                                break: 2,
                                font: "Arial",
                                size: subTitleSize
                            })
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: whatEvaluation !== "extLevel" && whatEvaluation !== "extPoint" ? (language === "sv" ? "För att kunna jämföra likvärdiga arbeten har en arbetsvärdering gjorts. Syftet är att göra en så korrekt och rättvis bedömning av arbetenas krav som möjligt. Diskrimineringslagen anger kunskap och färdigheter, ansvar, ansträngning och arbetsförhållanden som exempel på kriterier i bedömningen av ett arbete." : lang[language].metThree) : (language === "sv" ? "För att kunna jämföra likvärdiga arbeten har en arbetsvärdering gjorts. Syftet är att göra en så korrekt och rättvis bedömning av arbetenas krav som möjligt. För arbetsvärdering har systemet " + valuationName + " använts." : lang[language].metTwo + this.props.valuationName + "."),
                                color: "000000",
                                break: 1,
                                font: "Arial",
                                size: fontSize
                            }),
                            new TextRun({
                                text: whatEvaluation !== "extLevel" && whatEvaluation !== "extPoint" ? (language === "sv" ? "Vi har valt att använda nedanstående viktning för dessa kriterier." : lang[language].metFour) : "",
                                color: "000000",
                                break: whatEvaluation !== "extLevel" && whatEvaluation !== "extPoint" ? 2 : 0,
                                font: "Arial",
                                size: fontSize,
                            }),
                        ]
                    }),
                    new Paragraph(""),
                    whatEvaluation !== "extLevel" && whatEvaluation !== "extPoint" ? new Table({
                        // rows: 5,
                        columns: 2,
                        width: 0, // AUTO
                        columnWidths: [3000, 800], // total page width is 9638 DXA for A4 portrait
                        layout: TableLayoutType.FIXED,
                        rows: [
                            new TableRow({
                                children: [
                                    new TableCell({
                                        borders: {
                                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                        },
                                        children: [new Paragraph({
                                            spacing: {
                                                before: tableSpaceBig,
                                                after: tableSpaceBig,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: language === "sv" ? "Kunskaper & Färdigheter" : lang[language].knowledgeAndExp,
                                                    bold: true,
                                                    color: "000000",
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                    }),
                                    new TableCell({
                                        borders: {
                                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                        },
                                        children: [new Paragraph({
                                            spacing: {
                                                before: tableSpaceBig,
                                                after: tableSpaceBig,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: whatEvaluation !== "edited" ? `${vikt.education + vikt.problemsolving + vikt.socialSkills} %` : `${knowledgeWeight} %`,
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                    }),
                                ],
                            }),
                            new TableRow({
                                children: [
                                    new TableCell({
                                        borders: {
                                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                        },
                                        children: [new Paragraph({
                                            spacing: {
                                                before: tableSpaceBig,
                                                after: tableSpaceBig,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: language === "sv" ? "Ansvar" : lang[language].setResp,
                                                    bold: true,
                                                    color: "000000",
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                    }),
                                    new TableCell({
                                        borders: {
                                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            bottom: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                        },
                                        children: [new Paragraph({
                                            spacing: {
                                                before: tableSpaceBig,
                                                after: tableSpaceBig,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: whatEvaluation !== "edited" ? `${vikt.personnel + vikt.operation + vikt.people} %` : `${responsibilityWeight} %`,
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                    }),
                                ],
                            }),
                            new TableRow({
                                children: [
                                    new TableCell({
                                        borders: {
                                            right: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                        },
                                        children: [new Paragraph({
                                            spacing: {
                                                before: tableSpaceBig,
                                                after: tableSpaceBig,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: language === "sv" ? "Ansträngning & Arbetsförhållanden" : lang[language].setEffort,
                                                    bold: true,
                                                    color: "000000",
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                    }),
                                    new TableCell({
                                        borders: {
                                            left: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                            top: { style: BorderStyle.SINGLE, size: 0, color: "FFFFFF" },
                                        },
                                        children: [new Paragraph({
                                            spacing: {
                                                before: tableSpaceBig,
                                                after: tableSpaceBig,
                                            },
                                            children: [
                                                new TextRun({
                                                    text: whatEvaluation !== "edited" ? `${vikt.physical + vikt.psychological} %` : `${effortWeight} %`,
                                                    font: "Arial"
                                                }),
                                            ]
                                        }),],
                                    }),
                                ],
                            }),

                        ],
                    }) : "",
                    whatEvaluation !== "extLevel" && whatEvaluation !== "extPoint" ? new Paragraph({
                        children: [
                            new TextRun({
                                text: whatEvaluation !== "edited" ? (language === "sv" ? `Viktningen ovan är den totala viktningen inom varje område. Varje område är i sin tur uppdelad i faktorer som också är viktade var för sig, vilket utgör den totala. Detta för att gå djupare och kunna utföra en så bred och korrekt bedömning av arbetets krav som möjligt. Nedanstående kriterier och viktning användes och har bedömts med en skala på 1 - 4, där 1 = låga krav, 2 = medelhöga krav, 3 = höga krav och 4 = mycket höga krav. Arbetsvärderingen har enbart fokuserat på arbetets krav.` : lang[language].metFive + lang[language].metSeven)
                                    : (language === "sv" ? "Viktningen ovan är den totala viktningen inom varje område. Varje område är i sin tur uppdelad i faktorer som också är viktade var för sig, vilka tillsammans utgör den totala. Detta för att gå djupare och kunna utföra en så bred och korrekt bedömning av arbetets krav som möjligt. Nedan beskrivs de faktorer och viktning som har används för arbetsvärderingen." : lang[language].metFive + lang[language].metSix),
                                color: "000000",
                                break: 2,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new PageBreak()
                        ]
                    }) : "",

                    whatEvaluation !== "extLevel" && whatEvaluation !== "extPoint" ? viktningTab1(vikt, tableSpace, editedFactors, originalFactors, whatLevelDesc, whatEvaluation, "knowledge") : "",

                    whatEvaluation !== "extLevel" && whatEvaluation !== "extPoint" ? new Paragraph({
                        children: [
                            new PageBreak()
                        ]
                    }) : "",
                    whatEvaluation !== "extLevel" && whatEvaluation !== "extPoint" ? viktningTab1(vikt, tableSpace, editedFactors, originalFactors, whatLevelDesc, whatEvaluation, "responsibility") : "",
                    whatEvaluation !== "extLevel" && whatEvaluation !== "extPoint" ? new Paragraph({
                        children: [
                            new PageBreak()
                        ]
                    }) : "",
                    whatEvaluation !== "extLevel" && whatEvaluation !== "extPoint" ? viktningTab1(vikt, tableSpace, editedFactors, originalFactors, whatLevelDesc, whatEvaluation, "effort") : "",
                    whatEvaluation !== "extLevel" && whatEvaluation !== "extPoint" ? new Paragraph({
                        children: [
                            new PageBreak()
                        ]
                    }) : "",

                    // 2.3 Arbetsgång
                    new Paragraph({
                        heading: HeadingLevel.HEADING_1,
                        children: [
                            new TextRun({
                                text: language === "sv" ? "2.3 Arbetsgång" : lang[language].tocProcedure,
                                bold: true,
                                color: "000000",
                                break: 2,
                                font: "Arial",
                                size: subTitleSize
                            }),
                            new TextRun({
                                text: (language === "sv" ? "Steg " : lang[language].step) + "1:",
                                color: "000000",
                                break: 2,
                                bold: true,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new TextRun({
                                text: language === "sv" ? "Information om organisationens löner har importerats i verktyget." : lang[language].proOne,
                                color: "000000",
                                break: 1,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new TextRun({
                                text: (language === "sv" ? "Steg " : lang[language].step) + "2:",
                                color: "000000",
                                break: 2,
                                bold: true,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new TextRun({
                                text: language === "sv" ? "Befattningarna har grupperats in i grupper för lika arbeten. " +
                                "De arbeten som anses utföra lika eller nästintill lika arbetsuppgifter har blivit tilldelade " +
                                "samma grupp för jämförelse av lika arbete." : lang[language].proTwo,
                                color: "000000",
                                break: 1,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new TextRun({
                                text: (language === "sv" ? "Steg " : lang[language].step) + "3:",
                                color: "000000",
                                break: 2,
                                bold: true,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new TextRun({
                                text: language === "sv" ? "En gruppering av likvärdiga arbeten har gjorts. Med likvärdiga arbeten syftas på arbeten som inte utför samma arbetsuppgifter men som ändå kan anses ha likvärdiga krav. Arbetena har bedömts med hjälp av en arbetsvärdering där de delats in i grupper " +
                                "baserat på kraven i arbetet. Arbetsvärderingens utformning och grund för bedömning beskrivs i " +
                                "detalj ovan under punkt 2.2, " +`"Viktning & bedömning".` : lang[language].proThree,
                                color: "000000",
                                break: 1,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new TextRun({
                                text: (language === "sv" ? "Steg " : lang[language].step) + "4:",
                                color: "000000",
                                break: 2,
                                bold: true,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new TextRun({
                                text: language === "sv" ? "Lönerna har kartlagts och eventuella löneskillnader mellan könen har analyserats för:" : lang[language].proFour,
                                color: "000000",
                                break: 1,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new TextRun({
                                text: "1. " + (language === "sv" ? "Lika arbeten" : lang[language].equalWorks) + ".",
                                color: "000000",
                                break: 1,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new TextRun({
                                text: "2. " + (language === "sv" ? "Likvärdiga arbeten" : lang[language].equivalentWorks) + ".",
                                color: "000000",
                                break: 1,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new TextRun({
                                text: "3. " + (language === "sv" ? "Hierarkiska skillnader. Det vill säga kvinnodominerade arbeten som har högre krav i sitt arbete men lägre lön än icke kvinnodominerade arbeten." : lang[language].proFive),
                                color: "000000",
                                break: 1,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new TextRun({
                                text: language === "sv" ? "Bestämmelser och praxis har också setts över." : lang[language].proSix,
                                color: "000000",
                                break: 2,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new TextRun({
                                text: (language === "sv" ? "Steg " : lang[language].step) + "5:",
                                color: "000000",
                                break: 2,
                                bold: true,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new TextRun({
                                text: language === "sv" ? "Handlingsplan tillsammans med kostnadsberäkning har tagits fram i de fall åtgärd varit nödvändigt. Även föregående års eventuella åtgärder har utvärderats." : lang[language].proSeven,
                                color: "000000",
                                break: 1,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new PageBreak()
                        ]
                    }),
                    //----------------- END OF METOD

                    //3. Samverkan
                    new Paragraph({
                        heading: HeadingLevel.HEADING_1,
                        children: [
                            new TextRun({
                                text: language === "sv" ? "3. Samverkan" : lang[language].tocCollab,
                                color: "000000",
                                bold: true,
                                size: fontTitleSize,
                                font: "Arial"
                            }),
                            new TextRun({
                                text: samverkanText,
                                color: "000000",
                                break: 2,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new PageBreak()
                        ]
                    }),
                    //--------------- END OF SAMVERKAN

                    //4. Lonebestammelser och Praxis
                    new Paragraph({
                        heading: HeadingLevel.HEADING_1,
                        children: [
                            new TextRun({
                                text: language === "sv" ? "4. Kartläggning & analys av lönebestämmelser & praxis" : lang[language].tocAnalyze,
                                color: "000000",
                                bold: true,
                                size: fontTitleSize,
                                font: "Arial"
                            }),
                            new TextRun({
                                text: praxisText,
                                color: "000000",
                                break: 2,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new PageBreak()
                        ]
                    }),
                    //--------------- END OF Lonebestammelser och Praxis

                    //5 Resultat
                    new Paragraph({
                        heading: HeadingLevel.HEADING_1,
                        children: [
                            new TextRun({
                                text: language === "sv" ? "5. Resultat & analys" : lang[language].tocResults,
                                color: "000000",
                                bold: true,
                                size: fontTitleSize,
                                font: "Arial"
                            }),
                            new TextRun({
                                text: language === "sv" ? "5.1 Lika arbete" : lang[language].tocEqualWork,
                                color: "000000",
                                bold: true,
                                break: 3,
                                font: "Arial",
                                size: subTitleSize,
                            }),
                            //1. Inledning
                            new TextRun({
                                text: addOnDetails === "on" ? (language === "sv" ? "Nedan visas resultat och analys för kartläggningen av arbeten med lika arbetsuppgifter. Varje grupp presenteras med totalt antal anställda, könsfördelning, lönespridning, samt skillnaden i lön för kvinnor i procent. Ett negativt procenttal visar hur mycket mindre kvinnorna tjänar jämfört med männens lön, ett positivt procenttal visar hur mycket mer. Löneskillnaden redovisas inklusive tillägg, exklusive tillägg samt differensen för enbart tillägg." : lang[language].equalTen + lang[language].equalEleven) : (language === "sv" ? "Nedan visas resultat och analys för kartläggningen av arbeten med lika arbetsuppgifter. Varje grupp presenteras med totalt antal anställda, könsfördelning, lönespridning, samt skillnaden i lön för kvinnor i procent. Ett negativt procenttal visar hur mycket mindre kvinnorna tjänar jämfört med männens lön, ett positivt procenttal visar hur mycket mer." : lang[language].equalTen),

                                color: "000000",
                                break: 2,
                                font: "Arial",
                                size: fontSize,
                            }),
                            //2. Spridninsgkvot. Always the same
                            new TextRun({
                                text: language === "sv" ? "Spridningskvoten visar hur stor lönespridningen är, " +
                                "där en kvot på 2 innebär att den högsta lönen är dubbelt så hög som den lägsta. I de fall ett kön består av 20 personer eller mer " +
                                "baseras kvoten på percentil 10 och 90, dvs den lönenivå där 10% tjänar mindre och där 10% tjänar mer. Detta för att ge en rättvis bild av spridningen " +
                                "och undvika extremvärden." : lang[language].equalThree, 
                                color: "000000",
                                break: 2,
                                font: "Arial",
                                size: fontSize,
                            }),
                            //3. Information about charts. 
                            /*  1. If no images AND showAgeChart is set as off or adminOff
                                2. If no images AND showAgeChart is set as on or adminOn
                                3. If images AND showAgeChart is set as off or adminOff 
                                4. If images AND showAgeChart is set as on or adminOn AND chartExp is on AND chartAge is off
                                5. If images AND showAgeChart is set as on or adminOn AND chartExp is off AND chartAge is on
                                6. If images AND showAgeChart is set as on or adminOn AND chartExp is on AND chartAge is on */
                            new TextRun({
                                text: chartImages === "none" && chartImagesAge === "none" && (showAgeChart === "off" || showAgeChart === "adminOff") ?
                                    (language === "sv" ? "Vid analysering har förutom nedan redovisade uppgifter även punktdiagram använts för att belysa individuella skillnader. Punktdiagrammen har synliggjort alla anställda i respektive grupp baserat på lön och år i organisationen." : lang[language].equalFour) :
                                    //2. If no images AND showAgeChart is set as on or adminOn
                                    chartImages === "none" && chartImagesAge === "none" && (showAgeChart === "on" || showAgeChart === "adminOn") ?
                                        (language === "sv" ? "Vid analysering har förutom nedan redovisade uppgifter även punktdiagram använts för att belysa individuella skillnader. Punktdiagrammen har synliggjort alla anställda i respektive grupp baserat på lön och år i organisationen samt ålder." : lang[language].equalFourFive) :
                                        //3. If images AND showAgeChart is set as off or adminOff 
                                        (chartImages !== "none" || chartImagesAge !== "none") && (showAgeChart === "off" || showAgeChart === "adminOff") ?
                                            (language === "sv" ? "Vid analysering har även punktdiagram använts för att belysa individuella skillnader. Nedan redovisas punktdiagram baserat på lön och antal år i organisationen, där punkter med blå färg är män och grön färg är kvinnor." : lang[language].equalSix) :
                                            //4. If images AND showAgeChart is set as on or adminOn AND chartExp is on AND chartAge is off
                                            (chartImages !== "none" || chartImagesAge !== "none") && (showAgeChart === "on" || showAgeChart === "adminOn") && chartExp === "on" && chartAge === "off" ?
                                                (language === "sv" ? "Vid analysering har även punktdiagram använts för att belysa individuella skillnader. Nedan redovisas punktdiagram baserat på lön och antal år i organisationen, där punkter med blå färg är män och grön färg är kvinnor." : lang[language].equalSix) :
                                                //5. If images AND showAgeChart is set as on or adminOn AND chartExp is off AND chartAge is on
                                                (chartImages !== "none" || chartImagesAge !== "none") && (showAgeChart === "on" || showAgeChart === "adminOn") && chartExp === "off" && chartAge === "on" ?
                                                    (language === "sv" ? "Vid analysering har även punktdiagram använts för att belysa individuella skillnader. Nedan redovisas punktdiagram baserat på lön och ålder, där punkter med blå färg är män och grön färg är kvinnor." : lang[language].equalSeven) :
                                                    // 6. If images AND showAgeChart is set as on or adminOn AND chartExp is on AND chartAge is on 
                                                    (chartImages !== "none" || chartImagesAge !== "none") && (showAgeChart === "on" || showAgeChart === "adminOn") && chartExp === "on" && chartAge === "on" ?
                                                        (language === "sv" ? "Vid analysering har även punktdiagram använts för att belysa individuella skillnader. Punktdiagrammen har synliggjort alla anställda i respektive grupp baserat på lön och antal år i organisationen samt ålder. Dessa redovisas nedan, där punkter med blå färg är män och grön färg är kvinnor." : lang[language].equalSeven) : "",
                                color: "000000",
                                break: 2,
                                font: "Arial",
                                size: fontSize,
                            }),
                            //Text explaining Y-axis because salaries are hidden on chart
                            new TextRun({
                                text: (chartImages !== "none" || chartImagesAge !== "none") && chartPercent === "on" ? (language === "sv" ? "Y-axeln visar hur stor andel av den totala lönen i gruppen som individerna besitter i procent." : lang[language].equalNine) : "",
                                color: "000000",
                                break: 0,
                                font: "Arial",
                                size: fontSize,
                            }),
                            //Information about equal works. Always the same
                            new TextRun({
                                text: language === "sv" ? "Lika arbeten som har bedömts utföra lika eller nästintill lika arbetsuppgifter har satts ihop i samma grupp för att ge en rättvis och lagenlig kartläggning. De befattningar som ingår i respektive grupp anges under gruppens rubrik." : lang[language].equalSixteen,
                                color: "000000",
                                break: 2,
                                font: "Arial",
                                size: fontSize,
                            }),
                            //Information about actions. Always the same
                            new TextRun({
                                text: language === "sv" ? "I de fall åtgärd har behövts står dessa noteringar även med i handlingsplanen (avsnitt 6)." : lang[language].equalSeventeen,
                                color: "000000",
                                break: 2,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new TextRun({
                                text: "",
                                color: "000000",
                                break: 3,
                                font: "Arial",
                                size: fontSize,
                            }),
                        ]
                    }),
                ]
            },
            //New section here (use for adding full tables):
            {
                properties: {
                    type: SectionType.CONTINUOUS,
                },
                children: addOnDetails !== "on" ? likaTabellCompared(persData, tableSpace, chartImages, comments, chartImagesAge) : likaTabellComparedExtra(persData, tableSpace, chartImages, comments, chartImagesAge, chartImagesExtraAge, chartImagesExtraExp, addOnChart)
            },
            //-------------- END OF SECTION LIKA ARBETE

            //5.2 Resultat likvardiga och hierarkiska skillnader
            {
                properties: {
                    type: SectionType.NEXT_PAGE,
                },
                children: [
                    new Paragraph({
                        heading: HeadingLevel.HEADING_1,
                        children: [
                            new TextRun({
                                text: language === "sv" ? "5.2 Likvärdiga arbeten & Hierarkiska skillnader" : lang[language].tocEquivalent,
                                color: "000000",
                                bold: true,
                                font: "Arial",
                                size: subTitleSize
                            }),
                            new TextRun({
                                text: language === "sv" ? `Nedan visas arbeten med likvärdiga krav. Varje grupp innehåller arbeten med likvärdiga krav baserat på utförd arbetsvärdering. Högre grupp innebär högre krav. Dessa grupper har även varit underlag för analys av hierarkiska skillnader. Bokstaven "K" (gulmarkerad) innebär att arbetet är kvinnodominerat och bokstaven "M" (blåmarkerad) innebär att arbetet är mansdominerat. Om ingen bokstav anses det vara jämställt. Gränsen för överrepresentation är 60%. Spridningen baseras på percentil 10 och 90 i de fall det är 20 eller fler anställda i gruppen, om mindre grupper baseras det på högsta och lägsta lön.` : lang[language].eqvOneWord,
                                color: "000000",
                                break: 2,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new TextRun({
                                text: language === "sv" ? "Nedanför grupperna redovisas vilka kvinnodominerande arbeten som har lägre lön men lika eller högre krav än icke kvinnodominerande arbeten. Procenten visar hur mycket lägre lönen är för det kvinnodominerade arbetet jämfört med det icke kvinnodominerade arbetet. Därefter redovisas analys. I de fall åtgärd har behövts står dessa noteringar även med i handlingsplanen (avsnitt 6)." : lang[language].eqvTwo,
                                color: "000000",
                                break: 2,
                                font: "Arial",
                                size: fontSize,
                            }),
                            new TextRun({
                                text: "",
                                break: 1,
                            }),
                        ]
                    }),
                ]
            },
            //New section here (use for adding full tables):
            whatEvaluation === "extLevel" || whatEvaluation === "extPoint" || whatEvaluation === "edited" ? {
                properties: {
                    type: SectionType.CONTINUOUS,
                },
                children: //[
                    LikvardigaTabellNoSalariesExt(persData, tableSpace, whatEvaluation), //Remove
            }
                :
                {
                    properties: {
                        type: SectionType.CONTINUOUS,
                    },
                    children: [
                        new Table({
                            columns: 4,
                            width: 0, // AUTO
                            columnWidths: [5100, 1200, 1200, 1200], // total page width is 9638 DXA for A4 portrait
                            layout: TableLayoutType.FIXED,
                            rows: LikvardigaTabellNoSalaries(persData, "g1", tableSpace, nrOfEqualentGroups),
                        }),
                        new Paragraph({
                            heading: HeadingLevel.HEADING_1,
                            children: [
                                new TextRun(""),
                            ]
                        }),
                        new Table({
                            columns: 4,
                            width: 0, // AUTO
                            columnWidths: [5100, 1200, 1200, 1200], // total page width is 9638 DXA for A4 portrait
                            layout: TableLayoutType.FIXED,
                            rows: LikvardigaTabellNoSalaries(persData, "g2", tableSpace, nrOfEqualentGroups),

                        }),
                        new Paragraph({
                            heading: HeadingLevel.HEADING_1,
                            children: [
                                new TextRun(""),
                            ]
                        }),
                        new Table({
                            columns: 4,
                            width: 0, // AUTO
                            columnWidths: [5100, 1200, 1200, 1200], // total page width is 9638 DXA for A4 portrait
                            layout: TableLayoutType.FIXED,
                            rows: LikvardigaTabellNoSalaries(persData, "g3", tableSpace, nrOfEqualentGroups),
                        }),
                        new Paragraph({
                            heading: HeadingLevel.HEADING_1,
                            children: [
                                new TextRun(""),
                            ]
                        }),
                        new Table({
                            columns: 4,
                            width: 0, // AUTO
                            columnWidths: [5100, 1200, 1200, 1200], // total page width is 9638 DXA for A4 portrait
                            layout: TableLayoutType.FIXED,
                            rows: LikvardigaTabellNoSalaries(persData, "g4", tableSpace, nrOfEqualentGroups),
                        }),
                        new Paragraph({
                            heading: HeadingLevel.HEADING_1,
                            children: [
                                new TextRun(""),
                            ]
                        }),
                        new Table({
                            columns: 4,
                            width: 0, // AUTO
                            columnWidths: [5100, 1200, 1200, 1200], // total page width is 9638 DXA for A4 portrait
                            layout: TableLayoutType.FIXED,
                            rows: LikvardigaTabellNoSalaries(persData, "g5", tableSpace, nrOfEqualentGroups),
                        }),
                        new Paragraph({
                            heading: HeadingLevel.HEADING_1,
                            children: [
                                new TextRun(""),
                            ]
                        }),
                        new Table({
                            columns: 4,
                            width: 0, // AUTO
                            columnWidths: [5100, 1200, 1200, 1200], // total page width is 9638 DXA for A4 portrait
                            layout: TableLayoutType.FIXED,
                            rows: LikvardigaTabellNoSalaries(persData, "g6", tableSpace, nrOfEqualentGroups)
                        }),
                        new Paragraph({
                            heading: HeadingLevel.HEADING_1,
                            children: [
                                nrOfEqualentGroups == 6 ? new PageBreak() : new TextRun(""),
                            ]
                        }),
                        //If nr of equalgroups is more than 6
                        nrOfEqualentGroups > 6 ? new Table({
                            columns: 4,
                            width: 0, // AUTO
                            columnWidths: [5100, 1200, 1200, 1200], // total page width is 9638 DXA for A4 portrait
                            layout: TableLayoutType.FIXED,
                            rows: LikvardigaTabellNoSalaries(persData, "g7", tableSpace, nrOfEqualentGroups)
                        }) : []
                        ,
                        nrOfEqualentGroups > 6 ? new Paragraph({
                            heading: HeadingLevel.HEADING_1,
                            children: [
                                new TextRun(""),
                            ]
                        }) : []
                        ,
                        nrOfEqualentGroups > 6 ? new Table({
                            columns: 4,
                            width: 0, // AUTO
                            columnWidths: [5100, 1200, 1200, 1200], // total page width is 9638 DXA for A4 portrait
                            layout: TableLayoutType.FIXED,
                            rows: LikvardigaTabellNoSalaries(persData, "g8", tableSpace, nrOfEqualentGroups)
                        }) : []
                        ,
                        nrOfEqualentGroups > 6 ? new Paragraph({
                            heading: HeadingLevel.HEADING_1,
                            children: [
                                new TextRun(""),
                            ]
                        }) : []
                        ,
                        nrOfEqualentGroups > 6 ? new Table({
                            columns: 4,
                            width: 0, // AUTO
                            columnWidths: [5100, 1200, 1200, 1200], // total page width is 9638 DXA for A4 portrait
                            layout: TableLayoutType.FIXED,
                            rows: LikvardigaTabellNoSalaries(persData, "g9", tableSpace, nrOfEqualentGroups)
                        }) : []
                        ,
                        nrOfEqualentGroups > 6 ? new Paragraph({
                            heading: HeadingLevel.HEADING_1,
                            children: [
                                nrOfEqualentGroups == 9 ? new PageBreak() : new TextRun(""),
                            ]
                        }) : []
                        ,
                        //If nr of equalGroups is more than 9
                        nrOfEqualentGroups > 9 ? new Table({
                            columns: 4,
                            width: 0, // AUTO
                            columnWidths: [5100, 1200, 1200, 1200], // total page width is 9638 DXA for A4 portrait
                            layout: TableLayoutType.FIXED,
                            rows: LikvardigaTabellNoSalaries(persData, "g10", tableSpace, nrOfEqualentGroups)
                        }) : []
                        ,
                        nrOfEqualentGroups > 9 ? new Paragraph({
                            heading: HeadingLevel.HEADING_1,
                            children: [
                                new TextRun(""),
                            ]
                        }) : []
                        ,
                        nrOfEqualentGroups > 9 ? new Table({
                            columns: 4,
                            width: 0, // AUTO
                            columnWidths: [5100, 1200, 1200, 1200], // total page width is 9638 DXA for A4 portrait
                            layout: TableLayoutType.FIXED,
                            rows: LikvardigaTabellNoSalaries(persData, "g11", tableSpace, nrOfEqualentGroups)
                        }) : []
                        ,
                        nrOfEqualentGroups > 9 ? new Paragraph({
                            heading: HeadingLevel.HEADING_1,
                            children: [
                                new TextRun(""),
                            ]
                        }) : []
                        ,
                        nrOfEqualentGroups > 9 ? new Table({
                            columns: 4,
                            width: 0, // AUTO
                            columnWidths: [5100, 1200, 1200, 1200], // total page width is 9638 DXA for A4 portrait
                            layout: TableLayoutType.FIXED,
                            rows: LikvardigaTabellNoSalaries(persData, "g12", tableSpace, nrOfEqualentGroups)
                        }) : []
                        ,
                        nrOfEqualentGroups > 9 ? new Paragraph({
                            heading: HeadingLevel.HEADING_1,
                            children: [
                                nrOfEqualentGroups == 12 ? new PageBreak() : new TextRun(""),
                            ]
                        }) : []
                        ,

                        new Paragraph({
                            heading: HeadingLevel.HEADING_1,
                            children: [
                                new TextRun({
                                    text: language === "sv" ? "Nedan redovisas de kvinnodominerande arbeten som har lägre lön men lika eller högre krav än icke kvinnodominerande arbeten. Procenten visar hur mycket lägre lönen är för det kvinnodominerade arbetet jämfört med det icke kvinnodominerade arbetet." : lang[language].eqvFour,
                                    color: "000000",
                                    break: 2,
                                    font: "Arial",
                                    size: fontSize,
                                }),
                                new TextRun({
                                    text: "",
                                    break: 1,
                                }),
                            ]
                        }),
                    ]
                },
            //Here we loop all women dominated groups that should be compared
            {
                properties: {
                    type: SectionType.CONTINUOUS,
                },
                //children: LikvardigaCompared(persData, tableSpace, nrOfEqualentGroups)
                children: whatEvaluation !== "extLevel" && whatEvaluation !== "extPoint" && whatEvaluation !== "edited" ? LikvardigaCompared(persData, tableSpace, nrOfEqualentGroups) : LikvardigaComparedExt(persData, tableSpace, nrOfEqualentGroups, whatEvaluation)
            },
            //Here we add analysis comments
            {
                properties: {
                    type: SectionType.CONTINUOUS,
                },
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: language === "sv" ? "Analys" : lang[language].aiAnalysisTitle,
                                color: "000000",
                                bold: true,
                                font: "Arial",
                                size: 27,
                            }),
                            new TextRun({
                                text: "",
                                break: 1,
                                size: 27,
                            }),
                        ]
                    }),
                    new Table({
                        columns: 3,
                        width: 0, // AUTO
                        columnWidths: [3000, 3000, 3000], // total page width is 9638 DXA for A4 portrait
                        layout: TableLayoutType.FIXED,
                        rows: likvardigaComments(comments, "equalent", tableSpace)
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "",
                                size: 27,
                            }),
                        ]
                    }),
                    new Table({
                        columns: 3,
                        width: 0, // AUTO
                        columnWidths: [3000, 3000, 3000], // total page width is 9638 DXA for A4 portrait
                        layout: TableLayoutType.FIXED,
                        rows: likvardigaComments(comments, "hierarchical", tableSpace)
                    }),
                ]
            },
            {
                properties: {
                    type: SectionType.NEXT_PAGE,
                },
                children: [
                    //--------------- END OF LIKVARDIGA

                    //6. Handlingsplan
                    new Paragraph({
                        heading: HeadingLevel.HEADING_1,
                        children: [
                            new TextRun({
                                text: language === "sv" ? "6. Handlingsplan" : lang[language].tocAction,
                                color: "000000",
                                bold: true,
                                font: "Arial",
                                size: fontTitleSize,
                            }),
                            new TextRun({
                                text: newCommentsTodo.length < 1 ? (language === "sv" ? "En kartläggning och analys har genomförts. I kartläggningen kunde inga osakliga löneskillnader eller villkor hittas som haft direkt eller indirekt samband med kön. Detta gäller både på individuell samt gruppnivå. Inga åtgärder har därför bedömts nödvändiga varav ingen handlingsplan tagits fram för detta år." : lang[language].actTwo) : (language === "sv" ? "Nedan handlingsplan redovisar de åtgärder som behöver genomföras för att åtgärda löneskillnader som har direkt eller indirekt samband med kön baserat på resultaten och analyserna i denna lönekartläggning. Åtgärderna redovisas tillsammans med deadline och kostnadsberäkning." : lang[language].actOne),
                                color: "000000",
                                break: 2,
                                font: "Arial",
                                size: fontSize,
                            }),
                            //Lika arbeten
                            new TextRun({
                                text: newCommentsTodoEqual.length < 1 ? "" : (language === "sv" ? "Lika arbeten" : lang[language].equalWorks),
                                color: "000000",
                                bold: true,
                                break: 3,
                                font: "Arial",
                                size: subTitleSize
                            }),
                            ,
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun(""),
                        ]
                    }),
                    new Table({
                        columns: 5,
                        width: 0, // AUTO
                        columnWidths: [2480, 2450, 2100, 1300, 1300], // total page width is 9638 DXA for A4 portrait
                        layout: TableLayoutType.FIXED,
                        rows: newCommentsTodoEqual.length < 1 ? [] : analysTabell(comments, oldComments, "equal", tableSpace),
                    }),
                    //Likvärdigt arbete
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: newCommentsTodoEqualent.length < 1 ? "" : (language === "sv" ? "Likvärdiga arbeten" : lang[language].eqvWorks),
                                color: "000000",
                                bold: true,
                                break: 2,
                                font: "Arial",
                                size: subTitleSize
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun(""),
                        ]
                    }),
                    new Table({
                        columns: 5,
                        width: 0, // AUTO
                        columnWidths: [2480, 2450, 2100, 1300, 1300], // total page width is 9638 DXA for A4 portrait
                        layout: TableLayoutType.FIXED,
                        rows: newCommentsTodoEqualent.length < 1 ? [] : analysTabell(comments, oldComments, "equalent", tableSpace),
                    }),
                    //Hierarkiska skillnader
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: newCommentsTodoHierarchy.length < 1 ? "" : (language === "sv" ? "Hierarkiska skillnader" : lang[language].eqvHie),
                                color: "000000",
                                bold: true,
                                break: 2,
                                font: "Arial",
                                size: subTitleSize
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun(""),
                        ]
                    }),
                    new Table({
                        columns: 5,
                        width: 0, // AUTO
                        columnWidths: [2480, 2450, 2100, 1300, 1300], // total page width is 9638 DXA for A4 portrait
                        layout: TableLayoutType.FIXED,
                        rows: newCommentsTodoHierarchy.length < 1 ? [] : analysTabell(comments, oldComments, "hierarchical", tableSpace),
                    }),
                    //General actions
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: newCommentsTodoGeneral.length < 1 ? "" : (language === "sv" ? "Övriga åtgärder" : lang[language].generalActions),
                                color: "000000",
                                bold: true,
                                break: 2,
                                font: "Arial",
                                size: subTitleSize
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun(""),
                        ]
                    }),
                    new Table({
                        columns: 5,
                        width: 0, // AUTO
                        columnWidths: [2480, 2450, 2100, 1300, 1300], // total page width is 9638 DXA for A4 portrait
                        layout: TableLayoutType.FIXED,
                        rows: newCommentsTodoGeneral.length < 1 ? [] : analysTabell(comments, oldComments, "general", tableSpace),
                    }),
                    new Paragraph({
                        children: [
                            new TextRun(""),
                            new PageBreak()
                        ]
                    }),
                    //7 Utvärdering av föregående års åtgärder
                    new Paragraph({
                        heading: HeadingLevel.HEADING_1,
                        children: [
                            new TextRun({
                                text: (language === "sv" ? "7. Utvärdering av föregående års åtgärder" : lang[language].tocEvaluation),
                                color: "000000",
                                bold: true,
                                size: fontTitleSize,
                                font: "Arial"
                            }),
                            new TextRun({
                                text: oldCommentsTodo.length < 1 ? (language === "sv" ? "Inga planerade åtgärder från föregående år." : lang[language].noActionsPastYear) : (language === "sv" ? "Nedan redovisas föregående års åtgärder tillsammans med " +
                                "en utvärdering. Syftet är att säkerställa att diskrimineringslagen följs, samt att " +
                                "säkerställa att åtgärderna utförs." : lang[language].actThree),
                                color: "000000",
                                break: 2,
                                font: "Arial",
                                size: fontSize,
                            }),
                        ],
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: oldCommentsTodoEqual.length < 1 ? "" : (language === "sv" ? "Lika arbeten" : lang[language].equalWorks),
                                color: "000000",
                                bold: true,
                                break: 2,
                                font: "Arial",
                                size: subTitleSize
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun(""),
                        ]
                    }),
                    new Table({
                        columns: 7,
                        width: 0, // AUTO
                        columnWidths: [1800, 1700, 1430, 1200, 1000, 900, 1600], // total page width is 9638 DXA for A4 portrait
                        layout: TableLayoutType.FIXED,
                        rows: oldCommentsTodoEqual.length < 1 ? [] : analysTabell(comments, oldComments, "oldEqual", tableSpace),
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: oldCommentsTodoEqualent.length < 1 ? "" : (language === "sv" ? "Likvärdiga arbeten" : lang[language].eqvWorks),
                                color: "000000",
                                bold: true,
                                break: 2,
                                font: "Arial",
                                size: subTitleSize
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun(""),
                        ]
                    }),
                    new Table({
                        columns: 7,
                        width: 0, // AUTO
                        columnWidths: [1800, 1700, 1430, 1200, 1000, 900, 1600], // total page width is 9638 DXA for A4 portrait
                        layout: TableLayoutType.FIXED,
                        rows: oldCommentsTodoEqualent.length < 1 ? [] : analysTabell(comments, oldComments, "oldEqualent", tableSpace),
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: oldCommentsTodoHierarchy.length < 1 ? "" : (language === "sv" ? "Hierarkiska skillnader" : lang[language].eqvHie),
                                color: "000000",
                                bold: true,
                                break: 2,
                                font: "Arial",
                                size: subTitleSize
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun(""),
                        ]
                    }),
                    new Table({
                        columns: 7,
                        width: 0, // AUTO
                        columnWidths: [1800, 1700, 1430, 1200, 1000, 900, 1600], // total page width is 9638 DXA for A4 portrait
                        layout: TableLayoutType.FIXED,
                        rows: oldCommentsTodoHierarchy.length < 1 ? [] : analysTabell(comments, oldComments, "oldHierarchical", tableSpace),
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: oldCommentsTodoGeneral.length < 1 ? "" : (language === "sv" ? "Övriga åtgärder" : lang[language].generalActions),
                                color: "000000",
                                bold: true,
                                break: 2,
                                font: "Arial",
                                size: subTitleSize
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun(""),
                        ]
                    }),
                    new Table({
                        columns: 7,
                        width: 0, // AUTO
                        columnWidths: [1800, 1700, 1430, 1200, 1000, 900, 1600], // total page width is 9638 DXA for A4 portrait
                        layout: TableLayoutType.FIXED,
                        rows: oldCommentsTodoGeneral.length < 1 ? [] : analysTabell(comments, oldComments, "oldGeneral", tableSpace),
                    }),
                    new Paragraph({
                        children: [
                            new TextRun(""),
                            new PageBreak()
                        ]
                    }),
                    //7 ------------ END OF Utvärdering av föregående års åtgärder
                    //8. Avslutning / Fritext
                    new Paragraph({
                        heading: HeadingLevel.HEADING_1,
                        children: [
                            new TextRun({
                                text: `${fritext !== "" && fritextTitel !== "" ? "8. " + fritextTitel : ""}`,
                                color: "000000",
                                bold: true,
                                size: fontTitleSize,
                                font: "Arial"
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: `${fritext !== "" && fritextTitel !== "" ? fritext : ""}`,
                                color: "000000",
                                break: 1,
                                font: "Arial",
                                size: fontSize,
                            }),
                        ]
                    }),
                    //End of avslutning/Fritext
                ]
            },
        ]
    });
    Packer.toBlob(doc).then((blob) => {
        saveAs(blob, "Lönekartlaggning.docx");
    });
}