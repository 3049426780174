import React from 'react';
import './GrupperaLika.css';
import { Menu } from '../Menu.js';
import { TopNav } from '../TopNav.js';
import { Information } from '../Information/Information.js';
import drag from '../../assets/images/drag.png';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { fetchAuth } from '../../fetch-auth';
import MultiSearchSelect from "react-search-multi-select";
import { PopUp } from '../Arbetsvardera/Popup/PopUp.js';
import { Prompt } from 'react-router'
import arrowDown from '../../assets/images/arrow-down.png';
import exitImg from '../../assets/images/exit.png';
import lang from '../Language/language.js';

/* Följ denna tutorial för drag and drop: https://www.freecodecamp.org/news/how-to-add-drag-and-drop-in-react-with-react-beautiful-dnd/ */
// Se här för flera kolumner: https://dev.to/imjoshellis/codealong-multi-column-drag-and-drop-in-react-3781

let selectedValues = []; //Fix because of bug in MultiSearchSelect

export class GrupperaLika extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uniquePositions: [],
            saveButtonStatus: "Sparat",
            copyOpen: true,
            values: [], //for MultiSearchSelect
            importFrom: "",
            importWhat: "",
            concernComps: [],
            showPopUpWarning: "hide",
            showPopUp: null,
            showChangesPrompt: "Yes",
            addSSYK: "none",
            // For Search
            // ssykWorks: [],
            benchActive: false,
            searchSSYK: [],
            searchTerm: '',
        }
        this.getData = this.getData.bind(this);
        this.timer = null;
        this.indexedData = {};
    }

    componentDidMount() {
        let warningPrompt = localStorage.getItem('warningPrompt');
        if (warningPrompt === "off") { this.setState({ showChangesPrompt: "No" }) }
        this.getData();
        // this.populateIndex();
    }

    componentWillUnmount() {
        this.indexedData = {}; // Clear the data when the component unmounts
    }

    findSSYK = (event, clicked) => {
        let searchTermBeforeTrim = ""
        if (clicked !== undefined) { searchTermBeforeTrim = clicked.toLowerCase() }
        else { searchTermBeforeTrim = event.target.value.toLowerCase(); }
        // const searchTermBeforeTrim = event.target.value.toLowerCase();
        this.setState({ searchTerm: searchTermBeforeTrim });
        const maxWords = 3 // To many makes it to slow to search
        const minWordLength = 2; // Minimum word length. Less is excluded from search
        const maxResults = 80; // Maximum number of results (adjust as needed)

        if (searchTermBeforeTrim.length < 2 || searchTermBeforeTrim === "  ") {
            return;
        }

        // Clear any existing debounce timer
        if (this.timer) {
            clearTimeout(this.timer);
        }

        // Debounce the search function
        this.timer = setTimeout(() => {
            const searchTerm = searchTermBeforeTrim.trim()
            const matchingItems = [];

            // Indexed data structure (this.indexedData) should be populated with lowercase keys for efficient matching.
            // Step 1. Check for exact matches
            if (this.indexedData[searchTerm]) {
                matchingItems.push(...this.indexedData[searchTerm]);
            }
            //  let thisSearch = ""
            // If no exact matches were found, continue with the next steps
            if (matchingItems.length === 0) {
                //const searchWords = searchTerm.split(/\s+/);
                const searchWordsAll = searchTerm.split(/\s+/);
                //const searchWords = searchWordsAll.slice(0, maxWords);
                //  thisSearch = searchWords.join(" ");
                //  if (thisSearch === this.state.lastSearch) { return; }
                const filteredWords = searchWordsAll.filter(word => word.length >= minWordLength);
                const searchWords = filteredWords.slice(0, maxWords);

                // Step 2. Check if both words are included in the work
                for (const key in this.indexedData) {
                    if (matchingItems.length >= maxResults) {
                        break; // Exit the loop once the maximum number of results is reached
                    }
                    const work = key.toLowerCase();
                    if (searchWords.every(word => work.includes(word))) {
                        matchingItems.push(...this.indexedData[key]);
                    }
                }
                // If still no matches, continue with the next step
                if (matchingItems.length === 0) {
                    // Step 3. Check if any of the words are included in the work
                    for (const key in this.indexedData) {
                        if (matchingItems.length >= maxResults) {
                            break; // Exit the loop once the maximum number of results is reached
                        }
                        const work = key.toLowerCase();
                        if (searchWords.some(word => work.includes(word))) {
                            matchingItems.push(...this.indexedData[key]);
                        }
                    }
                }
            }
            // Update the state with matching items
            this.setState({ searchSSYK: matchingItems });
            // this.setState({lastSearch: thisSearch})
            this.timer = null;
        }, 800);
    };

    getData() {
        const getUniquePositions = async () => {
            selectedValues = []
            const response = await fetchAuth(`/api/loadEqualWorks`, 'POST');
            let data = await response.json();
            //filter for use with MultiSearchSelect
            let titles = [];
            if (data[0] !== undefined && data[0] !== null && data[0].length > 0) {
                for (let i = 0; i < data[0].length; i++) {
                    titles.push(data[0][i].positionTitle)
                }
            }
            //Get Bench
            //add show/hide benchmark
            let benchActiveStatus = false
            let showSalBench = data[3]
            if (showSalBench != undefined) {
                if (showSalBench.length > 0) {
                    let benchActive = showSalBench[0].showSalBench
                    if (benchActive === "on" || benchActive === "adminOn") { benchActiveStatus = true }
                }
            }

            //Get all concern companies (own self is not included), for MultiSearchSelect
            let concernComps = []
            if (data[1] !== undefined && data[1] !== null && data[1].length > 0) {
                for (let i = 0; i < data[1].length; i++) {
                    concernComps.push(data[1][i].dotterBolag)
                }
            }
            //Make unique positions in alphabetic order
            let uniquePositionsSorted = data[0].sort((a, b) => a.groupNameArbVardering != null ? a.groupNameArbVardering.localeCompare(b.groupNameArbVardering != null ? b.groupNameArbVardering : "") : "".localeCompare(b.groupNameArbVardering != null ? b.groupNameArbVardering : ""))
            this.setState({ uniquePositions: uniquePositionsSorted, values: titles, concernComps: concernComps, benchActive: benchActiveStatus });
            //Lastly set ssyk works by creating variable and create index for faster filtering:
            const ssykWorksData = data[2]
            for (const item of ssykWorksData) {
                const work = item.work.toLowerCase();
                if (!this.indexedData[work]) {
                    this.indexedData[work] = [];
                }
                this.indexedData[work].push(item);
            }
        };
        getUniquePositions();
    };

    //Run function when changing the textbox (when adding an equal group)
    //More info: https://www.robinwieruch.de/react-state-array-add-update-remove
    handleChange = (event) => {
        const position = event.target.id
        const group = event.target.value
        //Change uniquePositions group
        const stateCopy = [...this.state.uniquePositions]
        var newGroup = stateCopy.map(el => {
            if (el.positionTitle == position)
                return Object.assign({}, el, { groupNameArbVardering: group })
            return el
        });
        //Make unique positions in alphabetic order
        // let uniquePositionsSorted = newGroup.sort((a, b) => a.groupNameArbVardering != null ? a.groupNameArbVardering.localeCompare(b.groupNameArbVardering != null ? b.groupNameArbVardering : "") : "".localeCompare(b.groupNameArbVardering != null ? b.groupNameArbVardering : "") )
        this.setState({ uniquePositions: newGroup });
        this.setState({ saveButtonStatus: "Spara" }); //For save button status
    }

    handleOnDragEnd = (result) => {
        if (!result.destination) return; //Preventing errors from dragging out of bounds
        // console.log(result) //If we check out the console.log in chrome, we can see info about the item we moved. Destination -> index is where in the list we moved it, Source -> index is where it was moved from.

        //Add new group if moved to another group
        if (result.source.droppableId != result.destination.droppableId) {
            // alert('moved ' + result.draggableId + ' to another container!')
            const stateCopy = [...this.state.uniquePositions]
            var newGroup = stateCopy.map(el => {
                if (el.positionTitle == result.draggableId)
                    return Object.assign({}, el, { groupNameArbVardering: result.destination.droppableId })
                return el
            });

            const from = result.source.index
            const to = result.destination.index

            let cutOut = newGroup.splice(from, 1)[0]; // cut the element at index 'from'
            //If statemenst is there to fix index bug. If we move an index back, for example 0 to 3, because we move 0, this will be 1-4. Because of this we add -1 if from is bigger than to.
            if (from > to) {
                newGroup.splice(to, 0, cutOut);
            }
            if (from < to) {
                newGroup.splice(to - 1, 0, cutOut);
            }
            let uniquePositionsSorted = newGroup.sort((a, b) => a.groupNameArbVardering != null ? a.groupNameArbVardering.localeCompare(b.groupNameArbVardering != null ? b.groupNameArbVardering : "") : "".localeCompare(b.groupNameArbVardering != null ? b.groupNameArbVardering : ""))

            this.setState({ uniquePositions: uniquePositionsSorted });
            this.setState({ saveButtonStatus: "Spara" }); //For save button status
        }

        if (result.source.droppableId == result.destination.droppableId) { //<-- maybe change or remove this if statement and function below later on
            // Change index of item
            const uniquePositions = [...this.state.uniquePositions]

            const from = result.source.index
            const to = result.destination.index

            let cutOut = uniquePositions.splice(from, 1)[0]; // cut the element at index 'from'
            uniquePositions.splice(to, 0, cutOut);            // insert it at index 'to'  //Splice works like this: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/splice

            this.setState({ uniquePositions: uniquePositions }); //Set our new state
            this.setState({ saveButtonStatus: "Spara" }); //For save button status
        }
    }

    saveGroups = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        const saveData = async () => {
            const response = await fetchAuth(`/api/saveEqualWorks`, 'POST', JSON.stringify({ data: this.state.uniquePositions }));
            let data = await response.json();
            data === "Saved" ? this.setState({ saveButtonStatus: "Sparat" }) : alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave) //For save button status
        };
        saveData();
    }

    toggleCopy = () => {
        if (this.state.copyOpen) {
            this.setState({ copyOpen: false })
        } else this.setState({ copyOpen: true })
    }

    whatToImport = (event) => {
        this.setState({ importWhat: event.target.value });
    }
    importFrom = (event) => {
        this.setState({ importFrom: event.target.value });
    }

    handleChangeForMultiSelect = (arr) => { //<-- For react-search-multi-select
        if (arr.length > 0) {
            selectedValues = arr
        }
    }

    import = () => {
        let language = localStorage.getItem('language') ?? 'sv';
        const importIt = async () => {
            const importFrom = this.state.importFrom
            const importWhat = this.state.importWhat
            let uniquePositions = this.state.uniquePositions
            let data = ""
            if (importFrom === "" || importWhat === "") { //all, empty, selected
                alert(language === "sv" ? "Du måste välja vad och ifrån vilket bolag du vill importera." : lang[language].selectWhatComp)
                return;
                //Import all
            } else if (importWhat === "all") {
                let positions = [];
                for (let i = 0; i < uniquePositions.length; i++) {
                    positions.push(uniquePositions[i].positionTitle)
                }
                data = { from: importFrom, positions: positions }
                //Import empty ones
            } else if (importWhat === "empty") {
                let emptyPositions = [];
                for (let i = 0; i < uniquePositions.length; i++) {
                    if (uniquePositions[i].groupNameArbVardering === undefined || uniquePositions[i].groupNameArbVardering === "" || uniquePositions[i].groupNameArbVardering === null) {
                        emptyPositions.push(uniquePositions[i].positionTitle)
                    }
                }
                if (emptyPositions.length === 0) {
                    alert(language === "sv" ? "Inga befattningar saknar gruppindelning." : lang[language].noPosMissGroup)
                    return;
                }
                data = { from: importFrom, positions: emptyPositions }
                //import seleced ones
            } else if (importWhat === "selected") {
                if (selectedValues.length > 0) {
                    data = { from: importFrom, positions: selectedValues }
                } else if (selectedValues.length === 0) {
                    alert(language === "sv" ? "Du har inte valt några befattningar." : lang[language].noPosSelected)
                    return;
                }
            }
            //Copy from the server
            const response = await fetchAuth(`/api/importEqualWorks`, 'POST', JSON.stringify({ data: data }));
            if (response.status !== 200) {
                alert(language === "sv" ? "Något gick fel, försök igen." : lang[language].somethingWrong)
                this.setState({ showPopUpWarning: "hide" })
                return;
            }
            let resData = await response.json();
            //Data is copied and saved. Now update the titles with the newly retrieved equal group and set state
            //a. create shallow copy of state first: 
            const dataNow = this.state.uniquePositions.concat()
            //b. Then check which titles are the same and update their equalGroup. Lastly update state
            if (resData.length > 0) {
                for (let i = 0; i < resData.length; i++) {
                    let position = resData[i].positionTitle
                    let eGroup = resData[i].groupNameArbVardering
                    for (let i = 0; i < dataNow.length; i++) {
                        if (dataNow[i].positionTitle === position) {
                            dataNow[i].groupNameArbVardering = eGroup
                        }
                    }
                }
                //Make unique positions in alphabetic order
                let uniquePositionsSorted = dataNow.sort((a, b) => a.groupNameArbVardering != null ? a.groupNameArbVardering.localeCompare(b.groupNameArbVardering != null ? b.groupNameArbVardering : "") : "".localeCompare(b.groupNameArbVardering != null ? b.groupNameArbVardering : ""))

                this.setState({ uniquePositions: uniquePositionsSorted, showPopUpWarning: "finished" })
            } else { alert(language === "sv" ? "Inga motsvarande grupperingar kunde hittas. Säkerställ att du valt rätt bolag att importera ifrån." : lang[language].noPosFound) }
            return;
        }
        importIt();
    }

    toggleWarning = () => (this.state.showPopUpWarning === "start" || this.state.showPopUpWarning === "finished") ? this.setState({ showPopUpWarning: "hide" }) : this.setState({ showPopUpWarning: "start" })

    togglePopUp = (id) => this.setState({ showPopUp: id });
    exitPop = () => this.setState({ showPopUp: null });

    popUpNull = () => this.setState({ showPopUp: null });

    popUpSSYKBox = (event) => {
        if (event.currentTarget.id !== null || event.currentTarget.id !== "") {
            let removeTextInFront = event.currentTarget.id.slice(4)
            this.setState({ addSSYK: removeTextInFront, searchTerm: removeTextInFront }, () => {
                //   this.findSSYKClicked(removeTextInFront)
                this.findSSYK("", removeTextInFront)
            });
        }
    }
    hidepopUpSSYK = () => {
        this.setState({ addSSYK: "none" })
    }

    addSSYKToWork = (event) => {
        let work = this.state.addSSYK
        let ssykWork = event.target.getAttribute('data-work');
        let ssyk = event.target.getAttribute('data-ssyk');
        //Add ssyk to group
        let copyWorks = this.state.uniquePositions.slice()
        const updatedList = copyWorks.map((item) => {
            if (item.positionTitle === work) {
                // If it matches, add or modify ssyk and ssykwork
                return { ...item, ssyk: ssyk, ssykWork: ssykWork };
            } else {
                return item;
            }
        });
        this.setState({ addSSYK: "none", uniquePositions: updatedList, saveButtonStatus: "Spara" })
    }

    render() {
        const uniquePositions = this.state.uniquePositions;
        const uniqueGroups = [...new Set(uniquePositions.map(item => item.groupNameArbVardering))]; //Gets a list of all unique groups, we will use this to generate the group UL:s
        let companySelection = ["Inga bolag hittades"]
        if (this.state.concernComps.length > 0) {
            //Here add list of companies to choose from <-- FOR SELECTED LIST
            companySelection = this.state.concernComps.map(company => {
                return <option value={company}>{company}</option>
            });
        }
        let language = localStorage.getItem('language') ?? 'sv';
        return (
            <>
                <Prompt
                    when={this.state.saveButtonStatus === "Spara" && this.state.showChangesPrompt !== "No"}
                    message={language === "sv" ? "Du har gjort ändringar utan att spara. Är du säker på att du vill fortsätta?" : lang[language].changesMadeSave}
                />
                <TopNav />
                <Menu />
                <div className="container">
                    <Information step={this.state.benchActive ? "grupperaLikaMarknad" : "grupperaLika"} />

                    {/* Info PopUp ?*/}
                    {/* <PopUp showPopUp={this.state.showPopUp} exitAction={this.popUpNull} />*/}
                    <div id="utbildning" className={this.state.showPopUp === "importGroups" ? "popUp active-popUp" : "hide" } style={{ maxWidth: 650, height: "auto" }}>
                        <img alt="" src={exitImg} className="hide-box" onClick={this.exitPop} />
                        <div style={{ padding: 20, textAlign: "left", fontSize: 14.6 }}>
                        {language === "sv" ? "Har ni gjort grupperingar av befattningar i ett annat bolag inom er koncern kan du importera och återanvända dessa grupperingar även för detta bolag. Ändringarna du gör i ett bolag påverkar inte de andra." : lang[language].groupImportInfo}
                            <p></p><b>{language === "sv" ? "Såhär gör du:" : lang[language].howTo}</b>
                            {language === "sv" ? <ul>
                                <li><b>1)</b> Klicka på knappen "Hämta grupperingar"</li>
                                <li><b>2)</b> Välj vilket bolag du vill kopiera/importera ifrån.</li>
                                <li><b>3)</b> Välj vad du vill importera. Allt, endast tomma, eller enskilda befattningar.</li>
                                <li><b>4)</b> Klicka på importera.</li>
                            </ul> : lang[language].howToImportGroup}
                        </div>
                    </div>
                    
                    <div className={this.state.showPopUp === null ? "hide" : "popUpFader"}></div>
                    { /* Warning popUp */}
                    <div className={(this.state.showPopUpWarning === "start" || this.state.showPopUpWarning === "finished") ? "popUp active-popUp" : "popUp"} style={{ padding: 40, width: 400, height: "auto" }}>
                        {this.state.showPopUpWarning === "start" ?
                            <>
                                {language === "sv" ? "Är du säker på att du vill importera?" : lang[language].jobEPop}
                                <div>
                                    <div className="noButton" onClick={this.toggleWarning}>{language === "sv" ? "Avbryt" : lang[language].cancel}</div>
                                    <div className="yesButton" onClick={this.import}>{language === "sv" ? "Fortsätt" : lang[language].continue}</div>
                                </div>
                            </>
                            :
                            <>
                                {language === "sv" ? "Data importerad!" : lang[language].jobEImported}
                                <div>
                                    <div className="yesButton" onClick={this.toggleWarning}>Ok</div>
                                </div>
                            </>
                        }
                    </div>
                    <div className={this.state.showPopUpWarning !== "hide" ? "popUpFader" : "hide"}></div>
                    {/* End of warning popUp */}

                    <button className={this.state.saveButtonStatus === "Spara" ? "saveFixedButton" : "saveFixedButton savedFixedButtonSparat"} onClick={this.state.saveButtonStatus === "Spara" ? this.saveGroups : ""}>{language === "sv" ? this.state.saveButtonStatus : (this.state.saveButtonStatus === "Spara" ? lang[language].save : lang[language].saved)}</button>
                    <div className="leftAndRightWrapperPadding">
                        <div className={this.state.copyOpen ? "copyClosed" : "copyClosed openCopyCont"}>

                            <div style={{ textAlign: "center" }} className={this.state.copyOpen ? "copyButton" : "copyButton openCopyCont"} >
                                <span style={{ paddingTop: 20, paddingBottom: 20, marginLeft: -10, paddingLeft: 10, fontSize: 14.6 }} onClick={this.toggleCopy}>{language === "sv" ? "Hämta grupperingar" : lang[language].groupGet}</span>
                                <select onChange={this.importFrom} className={this.state.copyOpen ? "closedCopySections" : "openCopySections"} name="chooseCompany">
                                    <option value=""> {language === "sv" ? "Importera ifrån.." : lang[language].importFrom}</option>
                                    {companySelection}
                                </select>
                                <select onChange={this.whatToImport} className={this.state.copyOpen ? "closedCopySections" : "openCopySections"} name="chooseWhat" >
                                    <option value=""> {language === "sv" ? "Vad vill du importera?" : lang[language].importWhat}</option>
                                    <option value="all"> {language === "sv" ? "Allt" : lang[language].everything}</option>
                                    <option value="empty"> {language === "sv" ? "Endast tomma" : lang[language].onlyEmpty}</option>
                                    <option value="selected"> {language === "sv" ? "Enskilda befattningar" : lang[language].specificPos}</option>
                                </select>
                                <div className={this.state.importWhat === "selected" ? "showMultiSearch" : "hide"}>
                                    <MultiSearchSelect
                                        searchPlaceholder={language === "sv" ? "Välj befattningar" : lang[language].importChoosePos}
                                        searchable={true}
                                        showTags={false}
                                        multiSelect={true}
                                        width="180px"
                                        onSelect={this.handleChangeForMultiSelect}
                                        options={this.state.values}
                                        className={this.state.copyOpen ? "hide" : "multiSearch"}
                                        primaryColor="white"
                                        secondaryColor="white"
                                        textSecondaryColor="#333333"
                                    />
                                </div>
                                <span onClick={this.toggleWarning} className={this.state.copyOpen ? "hiddenButton" : "showButton"}>{language === "sv" ? "Importera" : lang[language].groupImport}</span>
                                <span onClick={this.toggleCopy} className={this.state.copyOpen ? "hide" : "questionM"} style={{ color: "white", lineHeight: 1, border: "none", fontSize: 16, marginLeft: 30 }}>x</span>
                            </div>
                            <span className="questionM" onClick={() => this.togglePopUp("importGroups")}>?</span>
                        </div>

                        {/*} <div className="popUpSSYK">
                            <span>Välj statistikkod</span>
                            
                    </div>*/}


                        <div className="leftAndRightCont">
                            <div className="leftGroups">
                                <h2 style={{ paddingTop: 10, fontSize: 19.6, fontWeight: 900, marginBottom: 27 }}>{language === "sv" ? "Gruppera Befattningar" : lang[language].groupTitle}</h2>
                                {/*<div style={{ fontSize: 13.6, marginTop: -15, paddingBottom: 15}}>Visa statistikkoder</div>*/}
                                <ul>
                                    {this.state.uniquePositions.map(item =>
                                        <li className={this.state.addSSYK === "none" ? "list" : "listNoHover"} style={{ display: "block", overflow: "hidden" }} key={item.positionTitle}>
                                            <div className="ssykAbove">
                                                <div id={item.positionTitle} className="inline positionList" style={{ maxWidth: "50%" }}><span className="spanPositionTitle">{item.positionTitle}</span></div>
                                                <div className="inline" style={{ maxWidth: "50%" }}>
                                                    <input className="equalInput" id={item.positionTitle} placeholder={language === "sv" ? "Ange grupp" : lang[language].addGroup} value={item.groupNameArbVardering} ref={this.input} type="text" onChange={this.handleChange} />
                                                </div>
                                            </div>
                                            <div className={this.state.benchActive ? "ssykBlock" : "hide"} id={"ssyk" + item.positionTitle} onClick={this.popUpSSYKBox}>
                                                <div className={item.ssyk === undefined || item.ssyk === null ? "hide" : "leftDivSSYK"} >{item.ssyk === undefined || item.ssyk === null ? "" : language === "sv" ? ("Kod: " + item.ssyk) : (lang[language].groupCode + item.ssyk) }</div>
                                                <div className={item.ssyk === undefined || item.ssyk === null ? "fullDivSSYK" : "rightDivSSYK"}>
                                                    <div><span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", display: "block" }}>{item.ssyk === undefined || item.ssyk === null ? language === "sv" ? "Välj statistikkod" : lang[language].groupStatistic : item.ssykWork}</span></div>
                                                </div>
                                                <img src={arrowDown} style={{ width: 10, position: "absolute", marginTop: 4, filter: "invert(35%)" }} />
                                            </div>
                                            <div className={this.state.addSSYK === item.positionTitle ? "popUpSSYK" : "hide"}>
                                                <input type="text" placeholder="Sök på ett arbete" className="searchSSYK" value={this.state.searchTerm} onChange={this.findSSYK} />

                                                <img className="hide-box" style={{ width: 15 }} src={exitImg} onClick={this.hidepopUpSSYK} />
                                                {/*<div onClick={this.findSSYK}>Sök</div>*/}
                                                <div>
                                                    {this.state.searchSSYK.map((work, index) => {
                                                        return (<div className="ssykWorkList" data-work={work.work} data-ssyk={work.ssyk} onClick={this.addSSYKToWork}>{work.work}</div>)
                                                    })}
                                                </div>

                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>

                            <div className="rightGroups">
                                <h2 style={{ textAlign: "center", paddingTop: 10, fontSize: 19.6, fontWeight: 900 }}>{language === "sv" ? "Alla grupper" : lang[language].groupAll}</h2>
                                <div className="rightGroupsColumns">
                                    <DragDropContext onDragEnd={this.handleOnDragEnd}> {/* onDragEnd lets us fire a function whenever someone stops dragging an item */}

                                        {uniqueGroups.map((groupUl, index) => {
                                            return (
                                                <Droppable droppableId={groupUl}>
                                                    {(provided) => (
                                                        <ul className="group-box" id={groupUl} {...provided.droppableProps} ref={provided.innerRef}>
                                                            <b className="groupBoxTitle">{groupUl}</b>
                                                            {this.state.uniquePositions.map(({ positionTitle, groupNameArbVardering }, index) => {

                                                                //If the group is the same as the groupUl
                                                                if (groupNameArbVardering === groupUl) {
                                                                    return (
                                                                        <Draggable key={positionTitle} draggableId={positionTitle} index={index}>
                                                                            {(provided) => (
                                                                                <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="groupListItem"><span className="movableList">{positionTitle}</span> <img className="dragIcon" src={drag} alt="drag" /></li>
                                                                            )}
                                                                        </Draggable>
                                                                    )
                                                                } //For if statement
                                                            })}
                                                            {provided.placeholder} {/* This is the placeholder that should prevent the ul to rezise when we move our item. We may need to move this if things look weird when moving stuff */}
                                                        </ul>
                                                    )}
                                                </Droppable>
                                            )
                                        })}
                                    </DragDropContext>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}