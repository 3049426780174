import React from 'react';
import '../Overview/Overview.css';
import './Reporting.css';
import { Menu } from '../Menu.js';
import { TopNav } from '../TopNav.js';
import Chart from "react-apexcharts";
import { BarChart } from "../Overview/BarChart";
import { PercentageBar } from "../Overview/PercentageBar";
import employees from '../../assets/images/card-person.png';
import money from '../../assets/images/card-money-2.png';
import time from '../../assets/images/card-time.png';
import age from '../../assets/images/card-age.png';
import { fetchAuth } from '../../fetch-auth';
import down from '../../assets/images/down.png';
import up from '../../assets/images/up.png';
import lang from '../Language/language.js';
import cloud from '../../assets/images/cloud.png';
import uploadIcon from '../../assets/images/upload-icon.png';
import TextareaAutosize from 'react-textarea-autosize'; //For textareas to autosize
import { Information } from '../Information/Information.js';

export class Reporting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            missingEqualGroups: false,
            maleDominated: [],
            womenDominated: [],
            notDominated: [],
            noData: false,
            nrOfEmp: 0,
            avgSalary: "",
            avgSalaryWomen: "",
            avgSalaryMen: "",
            avgAge: 0,
            avgAgeWomen: 0,
            avgAgeMen: 0,
            expAge: 0,
            expAgeWomen: 0,
            expAgeMen: 0,
            //For EU data
            payGapEqualentGroups: [{ group: 1, payGap: 15 }, { group: 2, payGap: 12 }, { group: 3, payGap: 35 }, { group: 4, payGap: 7 }, { group: 5, payGap: 3 }, { group: 6, payGap: 5 }],
            showWorkCategories: false,
            showWhat: "extern", //original, all, intern, extern. Change this to "all" when ready to deploy this function, if not ready, change to "original"
            salarySetting: "totalSalary", // Change to "salary" or "totalSalary" depending on what shouöd be used in the calculations
            quartiles: false,
            avgSalDiffPercent: 0,
            medianSalDiffPercent: 0,
            avgBenefitDiffPercent: 0,
            medianBenefitDiffPercent: 0,
            menWithBonusPercent: 0,
            womenWithBonusPercent: 0,
            step: "two",
            dataImported: true,
            showWhyInfo: false,
            showUpload: false,
            //For donut chart
            series: [50, 50],
            options: {
                labels: ['Män', 'Kvinnor'],
                colors: ['#54c7b6', '#55c2ca'],
                plotOptions: {
                    pie: {
                        donut: {
                            size: '52%',
                            /* labels: {
                                 show: true,
                             }*/
                        }
                    }
                }
            }
        }
    }

    componentDidMount() {
        let language = localStorage.getItem('language') ?? 'sv';
        if (language !== "sv") {
            this.setState(prevState => ({
                options: {
                    ...prevState.options,
                    labels: [lang[language].men, lang[language].women],
                }
            }));
        }

        const getData = async () => {
            try {
                const response = await fetchAuth(`/api/loadOverview`, 'POST');
                let data = await response.json();
                let employees = []
                if (data[0].length < 1 || data[0] === undefined) {
                    this.setState({ noData: true })
                    return;
                } else {
                    let calcSal = this.state.salarySetting //Change to "totalSalary" (with addOn) or "salary" (without addOn) // Note, this changes everything except "medellon i kronor intill medarbetare"

                    employees = data[0][0]
                    let equalentGroupSetting = data[1][0][0].whatEvaluation
                    if (equalentGroupSetting === undefined || equalentGroupSetting === null || equalentGroupSetting === "off") {
                        equalentGroupSetting = "original"
                    }

                    //Calculate for statistics
                    //1. Nr of Employers
                    const nrOfEmp = employees.length
                    //2. Genders
                    const womenList = employees.filter(person => person.gender === "woman")
                    const menList = employees.filter(person => person.gender === "man")
                    const nrOfWomen = womenList.length
                    const nrOfMen = menList.length
                    //const womenPercent = Math.round((nrOfWomen / (nrOfWomen + nrOfMen)) * 100)
                    //3. Average salary
                    const salaryTotalSum = employees.reduce((acc, num) => acc + num[calcSal], 0)
                    const avgSalary = Math.round(salaryTotalSum / nrOfEmp)
                    const totalSumWomen = womenList.reduce((acc, num) => acc + num[calcSal], 0)
                    const avgSalaryWomen = Math.round(totalSumWomen / nrOfWomen)
                    const totalSumMen = menList.reduce((acc, num) => acc + num[calcSal], 0)
                    const avgSalaryMen = Math.round(totalSumMen / nrOfMen)
                    //4. Average Age
                    const ageTotalSum = employees.reduce((acc, num) => acc + num.age, 0)
                    const avgAge = Math.round(ageTotalSum / nrOfEmp)
                    const ageTotalSumWomen = womenList.reduce((acc, num) => acc + num.age, 0)
                    const ageAvgWomen = Math.round(ageTotalSumWomen / nrOfWomen)
                    const ageTotalSumMen = menList.reduce((acc, num) => acc + num.age, 0)
                    const ageAvgMen = Math.round(ageTotalSumMen / nrOfMen)
                    //4. Average Experience
                    const expTotalSum = employees.reduce((acc, num) => acc + num.experience, 0)
                    const avgExp = Math.round(expTotalSum / nrOfEmp)
                    const expTotalSumWomen = womenList.reduce((acc, num) => acc + num.experience, 0)
                    const expAvgWomen = Math.round(expTotalSumWomen / nrOfWomen)
                    const expTotalSumMen = menList.reduce((acc, num) => acc + num.experience, 0)
                    const expAvgMen = Math.round(expTotalSumMen / nrOfMen)

                    //1-4. Create thousand separator:
                    const avgSalarySeparated = avgSalary.toLocaleString()
                    const avgSalarySeparatedWomen = avgSalaryWomen.toLocaleString()
                    const avgSalarySeparatedMen = avgSalaryMen.toLocaleString()

                    //5. Gender dominated groups
                    //5a. divide all groups into array based on equalGroup (groupNameArbVardering)
                    let groups = {};
                    for (let i = 0; i < employees.length; i++) {
                        let groupName = employees[i].groupNameArbVardering;
                        if (!groups[groupName]) {
                            groups[groupName] = [];
                        }
                        groups[groupName].push(employees[i].gender);
                    }
                    let myArray = [];
                    for (let groupName in groups) {
                        myArray.push({ group: groupName, gender: groups[groupName] });
                    }
                    //5b. Count how many genders in each group and add what its dominated by
                    for (let i = 0; i < myArray.length; i++) {
                        let totalEmps = myArray[i].gender.length
                        let nrOfMen = []
                        let nrOfWomen = []
                        for (let a = 0; a < myArray[i].gender.length; a++) {
                            if (myArray[i].gender[a] === "man") nrOfMen.push(myArray[i].gender[a])
                            if (myArray[i].gender[a] === "woman") nrOfWomen.push(myArray[i].gender[a])
                        }
                        if (nrOfMen.length / (nrOfWomen.length + nrOfMen.length) > 0.59) { myArray[i].dominatedBy = "men" }
                        else if (nrOfWomen.length / (nrOfWomen.length + nrOfMen.length) > 0.59) { myArray[i].dominatedBy = "women" }
                        else { myArray[i].dominatedBy = "equal" }
                    }

                    //5c. Count how many of each dominated group
                    const maleDominated = myArray.filter(g => g.dominatedBy === "men").length
                    const womenDominated = myArray.filter(g => g.dominatedBy === "women").length
                    const noDominiation = myArray.filter(g => g.dominatedBy === "equal").length
                    const maleDominatedPercent = Math.round((maleDominated / (maleDominated + womenDominated + noDominiation)) * 100)
                    const womenDominatedPercent = Math.round((womenDominated / (maleDominated + womenDominated + noDominiation)) * 100)
                    const noDominiationPercent = Math.round((noDominiation / (maleDominated + womenDominated + noDominiation)) * 100)

                    //5d. Check if any group is nulled, then show message about this. It means there is groups that are not set in group Lika
                    const equalNotGrouped = myArray.filter(g => g.group === "null").length
                    if (equalNotGrouped > 0) {
                        this.setState({ missingEqualGroups: true })
                    }

                    //When all is calculated, set state
                    this.setState({
                        maleDominated: [maleDominated, maleDominatedPercent],
                        womenDominated: [womenDominated, womenDominatedPercent],
                        notDominated: [noDominiation, noDominiationPercent],
                        nrOfEmp: nrOfEmp,
                        avgSalary: avgSalarySeparated,
                        avgSalaryWomen: avgSalarySeparatedWomen,
                        avgSalaryMen: avgSalarySeparatedMen,
                        avgAge: avgAge,
                        avgAgeWomen: ageAvgWomen,
                        avgAgeMen: ageAvgMen,
                        expAge: avgExp,
                        expAgeWomen: expAvgWomen,
                        expAgeMen: expAvgMen,
                        series: [nrOfMen, nrOfWomen]
                    })

                    // Here calculate data for EU directive ---

                    if (this.state.showWhat !== "original") {
                        // -- Calculate quartiles --
                        // Extract the totalSalary values from the objects and sort them in ascending order
                        const salaries = employees.map(obj => obj[calcSal]);
                        salaries.sort((a, b) => a - b);
                        // Calculate Q1, Q2 (median), Q3, and Q4
                        const q1 = calculateQuartile(salaries, 0.25);
                        const q2 = calculateQuartile(salaries, 0.5);
                        const q3 = calculateQuartile(salaries, 0.75);
                        // Function to calculate quartiles using linear interpolation
                        function calculateQuartile(sortedArray, quartile) {
                            const position = (sortedArray.length - 1) * quartile;
                            const lowerIndex = Math.floor(position);
                            const upperIndex = Math.ceil(position);
                            const weight = position - lowerIndex;
                            if (lowerIndex === upperIndex) {
                                return sortedArray[lowerIndex];
                            } else {
                                return sortedArray[lowerIndex] + weight * (sortedArray[upperIndex] - sortedArray[lowerIndex]);
                            }
                        }
                        // Initialize arrays for each quartile
                        const quartile1 = [];
                        const quartile2 = [];
                        const quartile3 = [];
                        const quartile4 = [];
                        // Group objects based on their salary
                        for (const obj of employees) {
                            if (obj[calcSal] <= q1) {
                                quartile1.push(obj);
                            } else if (obj[calcSal] <= q2) {
                                quartile2.push(obj);
                            } else if (obj[calcSal] <= q3) {
                                quartile3.push(obj);
                            } else {
                                quartile4.push(obj);
                            }
                        }
                        // Now calculate the gender percentage inside each quartile
                        function calculatePercentage(data) {
                            let menCount = 0;
                            let womenCount = 0;
                            for (const person of data) {
                                if (person.gender === 'man') {
                                    menCount++;
                                } else if (person.gender === 'woman') {
                                    womenCount++;
                                }
                            }
                            const totalPeople = data.length;
                            const menPercentage = Math.round((menCount / totalPeople) * 100);
                            const womenPercentage = Math.round((womenCount / totalPeople) * 100);
                            return ({ menP: menPercentage, womP: womenPercentage })
                        }
                        let quartile1P = calculatePercentage(quartile1)
                        let quartile2P = calculatePercentage(quartile2)
                        let quartile3P = calculatePercentage(quartile3)
                        let quartile4P = calculatePercentage(quartile4)
                        let quartileStats = { quartile1: quartile1P, quartile2: quartile2P, quartile3: quartile3P, quartile4: quartile4P }
                        // --- End of quartiles --

                        // Calculate gender difference in median and average salaries 
                        function calculateMedian(data, propertyName) {
                            data.sort((a, b) => a[propertyName] - b[propertyName]);
                            const middle = Math.floor(data.length / 2);
                            return data.length % 2 === 0
                                ? (data[middle - 1][propertyName] + data[middle][propertyName]) / 2
                                : data[middle][propertyName];
                        }

                        const totalSumWomenEU = womenList.reduce((acc, num) => acc + num[calcSal], 0)
                        const avgSalaryWomenEU = Math.round(totalSumWomenEU / nrOfWomen)
                        const totalSumMenEU = menList.reduce((acc, num) => acc + num[calcSal], 0)
                        const avgSalaryMenEU = Math.round(totalSumMenEU / nrOfMen)

                        let avgSalaryDifferencePercent = Math.round(((avgSalaryMenEU - avgSalaryWomenEU) / avgSalaryMenEU) * 100)
                        let medianSalaryMen = calculateMedian(menList, [calcSal]);
                        let medianSalaryWomen = calculateMedian(womenList, [calcSal]);
                        let medianSalaryDifferencePercent = Math.round(((medianSalaryMen - medianSalaryWomen) / medianSalaryMen) * 100)

                        //Calculate gender difference in addOn and benefits
                        const totalSumMenBenefit = menList.reduce((acc, num) => acc + num.addOn, 0)
                        const avgBenefitMen = Math.round(totalSumMenBenefit / nrOfMen)
                        const totalSumWomenBenefit = womenList.reduce((acc, num) => acc + num.addOn, 0)
                        const avgBenefitWomen = Math.round(totalSumWomenBenefit / nrOfWomen)
                        let avgBenefitDifferencePercent = Math.round(((avgBenefitMen - avgBenefitWomen) / avgBenefitMen) * 100)
                        let medianBenefitMen = calculateMedian(menList, "addOn");
                        let medianBenefitWomen = calculateMedian(womenList, "addOn");
                        let medianBenefitDifferencePercent = Math.round(((medianBenefitMen - medianBenefitWomen) / medianBenefitMen) * 100)
                        if (isNaN(avgBenefitDifferencePercent) || avgBenefitDifferencePercent !== avgBenefitDifferencePercent) { avgBenefitDifferencePercent = 0 }
                        if (isNaN(medianBenefitDifferencePercent) || medianBenefitDifferencePercent !== medianBenefitDifferencePercent) { medianBenefitDifferencePercent = 0 }

                        //Nr of gender that bonus and addOn
                        let menWithBenefits = 0
                        let menWithoutBenifits = 0
                        let womenWithBenefits = 0
                        let womenWithoutBenifits = 0
                        for (const obj of menList) {
                            if (obj.hasOwnProperty("addOn")) {
                                if (obj.addOn === 0 || obj.addOn === null) {
                                    menWithoutBenifits++;
                                } else {
                                    menWithBenefits++;
                                }
                            }
                        }
                        for (const obj of womenList) {
                            if (obj.hasOwnProperty("addOn")) {
                                if (obj.addOn === 0 || obj.addOn === null) {
                                    womenWithoutBenifits++;
                                } else {
                                    womenWithBenefits++;
                                }
                            }
                        }
                        let menWithBonusPercent = Math.round((menWithBenefits / (menWithoutBenifits + menWithBenefits)) * 100)
                        let womenWithBonusPercent = Math.round((womenWithBenefits / (womenWithoutBenifits + womenWithBenefits)) * 100)

                        // Calculate salary differences for each work category
                        let equalentGroup = "equalentGroup"
                        if (equalentGroupSetting === "edited") { equalentGroup = "editedEqualentGroup" }
                        if (equalentGroupSetting === "extLevel") { equalentGroup = "extEqualentGroup" }
                        // Calculate median and average salaries for men and women in each "equalentGroup"
                        const groupedData = employees.reduce((result, obj) => {
                            const groupKey = obj[equalentGroup]
                            if (!result[groupKey]) {
                                result[groupKey] = {
                                    equalentGroup: groupKey,
                                    menSalaries: [],
                                    womenSalaries: [],
                                };
                            }

                            if (obj.gender === 'man') {
                                result[groupKey].menSalaries.push(obj[calcSal]);
                            } else if (obj.gender === 'woman') {
                                result[groupKey].womenSalaries.push(obj[calcSal]);
                            }

                            return result;
                        }, {});
                        // Calculate median salaries and other statistics
                        const resultArray = Object.values(groupedData).map((group) => {
                            const menMedian = calculateMedianG(group.menSalaries);
                            const womenMedian = calculateMedianG(group.womenSalaries);
                            const menAverage = calculateAverage(group.menSalaries);
                            const womenAverage = calculateAverage(group.womenSalaries);
                            const medianDiff = calculatePercentageDifference(menMedian, womenMedian);
                            const averageDiff = calculatePercentageDifference(menAverage, womenAverage);
                            return {
                                equalentGroup: group.equalentGroup,
                                menMedian,
                                womenMedian,
                                menAverage,
                                womenAverage,
                                medianDiff,
                                averageDiff,
                            };
                        });
                        // Function to calculate the median of an array
                        function calculateMedianG(arr) {
                            if (arr.length === 0) return 0;
                            const sorted = [...arr].sort((a, b) => a - b);
                            const middle = Math.floor(sorted.length / 2);
                            if (sorted.length % 2 === 0) {
                                const lower = sorted[middle - 1];
                                const upper = sorted[middle];
                                return (lower + upper) / 2;
                            } else {
                                return sorted[middle];
                            }
                        }
                        // Function to calculate the average of an array
                        function calculateAverage(arr) {
                            if (arr.length === 0) return 0;

                            const total = arr.reduce((sum, value) => sum + value, 0);
                            return total / arr.length;
                        }
                        // Function to calculate the percentage difference between two values
                        function calculatePercentageDifference(value1, value2) {
                            if (value1 === 0) return 0;
                            // return Math.round((((value1 - value2) / value1) * 100));
                            return (((value1 - value2) / value1) * 100).toFixed(1)
                        }
                        // Save to state
                        this.setState({
                            quartiles: quartileStats,
                            avgSalDiffPercent: avgSalaryDifferencePercent,
                            medianSalDiffPercent: medianSalaryDifferencePercent,
                            avgBenefitDiffPercent: avgBenefitDifferencePercent,
                            medianBenefitDiffPercent: medianBenefitDifferencePercent,
                            menWithBonusPercent: menWithBonusPercent,
                            womenWithBonusPercent: womenWithBonusPercent,
                            payGapEqualentGroups: resultArray
                        })
                        return;
                    }
                }
            } catch (err) {
                //   console.log(err)
                return;
            }
        }
        getData();
    }

    toggleWorkCategories = () => { this.state.showWorkCategories ? this.setState({ showWorkCategories: false }) : this.setState({ showWorkCategories: true }) }
    showWhat = (event) => { let showWhat = event.target.value; showWhat === "extern" ? this.setState({ showWhat: showWhat, showWorkCategories: true }) : this.setState({ showWhat: showWhat, showWorkCategories: false }) }
    selectStepOne = () => { this.setState({ step: "one" }) }
    selectStepTwo = () => {
        if (this.state.showUpload === true && this.state.dataImported === true) { this.setState({ step: "two", showUpload: false }) }
        else { this.setState({ step: "two" }) }
    }
    showWhyInfo = () => { this.state.showWhyInfo ? this.setState({ showWhyInfo: false }) : this.setState({ showWhyInfo: true }) }
    showUpload = () => { this.setState({ showUpload: true }) }

    render() {
        let language = localStorage.getItem('language') ?? 'sv';
        // For dominated groups
        const maleD = Number(this.state.maleDominated[0])
        const WomenD = Number(this.state.womenDominated[0])
        const notD = Number(this.state.notDominated[0])

        const data = [{
            data: [maleD, WomenD, notD]
        }]
        const workCategoriesPayGap = this.state.payGapEqualentGroups.map(group =>
            <div className="overview-list-row">
                <div style={{borderBottom: "1px solid #eaeaea", paddingBottom: 5}}>
                <div className="overview-list-title">{group.equalentGroup === null ? "Saknar grupp" : "Grupp " + group.equalentGroup}</div>
                <div className="overview-list-payGap">{group.averageDiff} %</div>
                </div>
                <TextareaAutosize className="explainerBoxText" placeholder="Ingen orsak angiven." name="reason" id={group.equalentGroup} />  
            </div>
        )

        return (
            <>
                <TopNav />
                <Menu />
                <div className="container buggFixContainer">
                    <div style={{ maxWidth: 1250, margin: "auto" }}>
                        {/*<h1 style={{ color: "#333333", paddingBottom: 40, paddingTop: 40 }}>{language === "sv" ? "Rapportering" : lang[language].overviewOrg}</h1>*/}
                        <Information step="rapportera" />

                        <div style={{ marginBottom: 40 }}>
                            <div className="reportingSelectTitle">Välj år</div>
                            <select id="showWhat" className="overview-selection" onChange={this.showWhat} style={{ width: 100, marginRight: 10 }}>
                                <option value="all">{language === "sv" ? "2024" : lang[language].showAllStatistics}</option>
                            </select>
                        </div>
                        <div>
                            <div className="reportingStepContainer" onClick={this.selectStepOne}>
                                <span style={{ marginRight: 0 }} className={this.state.step === "one" ? "num reportingActive" : "num"}>1</span>
                                <div style={{ fontWeight: this.state.step === "one" ? "bold" : "normal" }} className="reportingStepTitle">Imporera data</div>
                            </div>
                            <div className="reportingStepContainer" onClick={this.selectStepTwo}>
                                <span style={{ marginRight: 0 }} className={this.state.step === "two" ? "num reportingActive" : "num"}>2</span>
                                <div style={{ fontWeight: this.state.step === "two" ? "bold" : "normal" }} className="reportingStepTitle">Visa statistik</div>
                            </div>
                        </div>
                        <div className="allCommentsContainer" style={{ marginBottom: 140, minHeight: 420 }}>

                            {/* Import container */}
                            <div className={this.state.step === "one" ? "" : "hide"}>
                                <div>
                                    <div className={this.state.showUpload ? "" : "hide"}>
                                        <img className="logoUpload-default" src={uploadIcon} style={{ marginTop: 65 }} />
                                        <label htmlFor="files" className="upload-button upload-button-smallScreen" style={{ display: "block", width: 150, margin: "auto", fontSize: 16, padding: 15 }}>{language === "sv" ? "IMPORTERA" : lang[language].import}</label>
                                    </div>
                                </div>

                                <div className={!this.state.showUpload ? "success" : "hide"} style={{ paddingTop: 80 }}>
                                    <div className="importOK" style={{ width: 100, height: 100, lineHeight: "100px", opacity: 0.5, boxShadow: "none" }}>&#10004;</div>
                                    <span className="spanOK">Datan är importerad</span>
                                    <span className="popUpSuccess" style={{ display: "block", marginBottom: 80 }}>Data för 2024 laddades upp 2025-01-05.
                                    </span>
                                    <div style={{ color: "#35b292", background: "#56cbad", color: "white", padding: 12, borderRadius: 8, fontWeight: 900, cursor: "pointer", width: "fit-content", margin: "auto", marginTop: -40 }} onClick={this.showUpload}>Ersätt med ny import</div>
                                </div>
                            </div>

                            {/* Nothing imported container */}
                            <div style={{ paddingTop: 40, maxWidth: 600, margin: "auto" }} className={(!this.state.dataImported && this.state.step === "two") ? "" : "hide"}>
                                <img src={cloud} style={{ marginBottom: 10, marginTop: 40, width: 100, opacity: 0.5 }} />
                                <div style={{ fontWeight: "bold", fontSize: 20 }}>Ingen data importerad</div>
                                <p style={{ fontSize: 16 }}>Klicka på "Importera data" ovan.</p>
                                <div className="showSettingBtn" style={{ width: "fit-content", marginTop: 60 }} onClick={this.showWhyInfo}>Varför en till import?</div>

                                <div className={this.state.showWhyInfo ? "chartPercentInfoBox" : "hide"}>Enligt lag ska rapporteringen innehålla den totala årslönen och timlönen för föregående år. Detta överensstämmer inte med lönekartläggningen där den aktuella månadslönen används.
                                    Detta till följd av krav från EU:s lönetransparensdirektiv i syfte att rapporteringen skall gå att jämföra mellan alla länder.
                                </div>
                            </div>
                            {/* Statistics container */}
                            <div className={(this.state.dataImported && this.state.step === "two") ? "" : "hide"}>

                                {this.state.noData ? <div className="needData">{language === "sv" ? "Du behöver importera data innan du kan visa någon översikt. Detta gör du under steg 1 i menyn åt vänster." : lang[language].youNeedToImport}</div> : <>
                                    {/* <div className={this.state.showWhat === "original" ? "hide" : ""} style={{ marginTop: 0, marginBottom: 15, paddingTop: 40 }}>
                                        <select id="showWhat" className="overview-selection" onChange={this.showWhat}>
                                            <option value="all">{language === "sv" ? "Visa all statistik" : lang[language].showAllStatistics}</option>
                                            <option value="intern">{language === "sv" ? "Rapport intern - EU direktiv" : lang[language].internalReport}</option>
                                            <option value="extern">{language === "sv" ? "Rapport till myndigheter - EU direktiv" : lang[language].authReport}</option>
                                        </select>
                                    </div> */}

                                    {(this.state.showWhat === "all" || this.state.showWhat === "original") && <>
                                        <div className="card_overview">
                                            <div className="card_padding">
                                                <span>{language === "sv" ? "Medarbetare" : lang[language].employees}</span>
                                                <span className="card_header">{this.state.nrOfEmp}</span>
                                                <img src={employees} className="card_image" style={{ transform: "scaleX(-1)" }} />
                                            </div>
                                        </div>

                                        <div className="card_overview">
                                            <div className="card_padding">
                                                <img src="" />
                                                <span>{language === "sv" ? "Medellön" : lang[language].averageSal}</span>
                                                <span className="card_header">{this.state.avgSalary} {language === "sv" ? "kr" : ""}</span>
                                                <span style={{ display: "block", fontSize: 14, paddingTop: 35 }}>{language === "sv" ? "Män" : lang[language].men}: {this.state.avgSalaryMen}</span>
                                                <span style={{ display: "block", fontSize: 14 }}>{language === "sv" ? "Kvinnor" : lang[language].women}: {this.state.avgSalaryWomen}</span>
                                                <img src={money} className="card_image" style={{ marginRight: -40, marginBottom: -30 }} />
                                            </div>
                                        </div>

                                        <div className="card_overview">
                                            <div className="card_padding">
                                                <img src="" />
                                                <span>{language === "sv" ? "Medelanställningstid" : lang[language].avgTimeInOrg}</span>
                                                <span className="card_header">{this.state.expAge} {language === "sv" ? "år" : lang[language].years}</span>
                                                <span style={{ display: "block", fontSize: 14, paddingTop: 35 }}>{language === "sv" ? "Män" : lang[language].men}: {this.state.expAgeMen}</span>
                                                <span style={{ display: "block", fontSize: 14 }}>{language === "sv" ? "Kvinnor" : lang[language].women}: {this.state.expAgeWomen}</span>
                                                <img src={time} className="card_image" style={{ marginRight: -40, marginBottom: -30 }} />
                                            </div>
                                        </div>

                                        <div className="card_overview">
                                            <div className="card_padding">
                                                <img src="" />
                                                <span>{language === "sv" ? "Medelålder" : lang[language].avgAgeLong}</span>
                                                <span className="card_header">{this.state.avgAge} {language === "sv" ? "år" : lang[language].years}</span>
                                                <span style={{ display: "block", fontSize: 14, paddingTop: 35 }}>{language === "sv" ? "Män" : lang[language].men}: {this.state.avgAgeMen}</span>
                                                <span style={{ display: "block", fontSize: 14 }}>{language === "sv" ? "Kvinnor" : lang[language].women}: {this.state.avgAgeWomen}</span>
                                                <img src={age} className="card_image" style={{ marginRight: -40, marginBottom: -30 }} />
                                            </div>
                                        </div>
                                    </>
                                    }
                                    {/*  <div className="card_overview" style={{ background: "white", color: "#333333", height: 255, width: "45.5%" }}>
                                        <div className="card_padding">
                                            <span style={{ display: "block", margin: "auto", textAlign: "center", paddingBottom: 5 }} className={this.state.showWhat !== "original" ? "euTitle" : ""}>{language === "sv" ? "Könsfördelning" : lang[language].genderDis}</span>
                                            <div style={{ marginTop: 10 }}>
                                                <Chart options={this.state.options} series={this.state.series} type="donut" width="320" />
                                            </div>
                                        </div>
                                    </div>*/}


                                    {this.state.showWhat === "original" &&
                                        <>
                                            <div className="card_overview" style={{ background: "white", color: "#333333", height: 255, width: "45.5%" }}>
                                                <div className="card_padding">
                                                    <span style={{ display: "block", margin: "auto", textAlign: "center", paddingBottom: 5 }}>{language === "sv" ? "Könsdominerande grupper" : lang[language].genderDominant}</span>
                                                    {this.state.missingEqualGroups ? <div className="card_message">{language === "sv" ? "Alla lika arbeten måste grupperas innan denna statistik kan visas. Detta görs i steg 2.1" : lang[language].beforeStatShow}</div>
                                                        :
                                                        <>
                                                            <div style={{ width: "70%", display: "inline-block", marginTop: -20 }}>
                                                                <BarChart chartData={data} />
                                                            </div>
                                                            <div style={{ width: "25%", display: "inline-block", marginTop: 0, verticalAlign: "top" }}>

                                                                <span className="card_header gradient_text" style={{ textAlign: "center", marginTop: 20 }}>{this.state.maleDominated[1]} %</span>
                                                                <span className="card_header gradient_text" style={{ textAlign: "center", marginTop: 25 }}>{this.state.womenDominated[1]} %</span>
                                                                <span className="card_header gradient_text" style={{ textAlign: "center", marginTop: 25 }}>{this.state.notDominated[1]} %</span>
                                                            </div>
                                                        </>}
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {this.state.showWhat !== "original" &&
                                        <>
                                            <div className="card_overview" style={{ background: "white", color: "#333333", height: 270, width: "45.5%", marginTop: 17 }}>
                                                <div className="card_padding">
                                                    <span style={{ marginBottom: 30 }} className="euTitle">{language === "sv" ? "Andel kön per lönekvartil" : lang[language].proportionOfGender}</span>
                                                    {/*}      <div className="columnsOverviewMedium" >Lönekvartil</div>
                                <div className="columnsOverview" >Män</div>
                                <div className="columnsOverview" >Kvinnor</div>
                                <div className="columnsOverviewBig" ></div>

                                <div className="columnsOverviewMedium" >Lägre kvartilen</div>
                                <div className="columnsOverview">75%</div>
                                <div className="columnsOverview" >25%</div>
                                <div className="columnsOverviewBig" > <PercentageBar percentage={75} /></div>
                                        
                                          <div className="columnsOverviewMedium" >Lägre mittkvartilen</div>
                                <div className="columnsOverview">75%</div>
                                <div className="columnsOverview" >25%</div>
        <div className="columnsOverviewBig" > <PercentageBar percentage={75} /></div>*/}

                                                    <div className="percentageBars">
                                                        <div className="columnsOverviewMedium" >{language === "sv" ? "Lägre kvartil" : lang[language].lowerQ}</div>
                                                        {/*<div className="columnsOverview">75% | 25%</div>*/}
                                                        <div className="columnsOverviewBig" > <PercentageBar percentage={this.state.quartiles ? this.state.quartiles.quartile1.menP : 50} /></div>
                                                    </div>
                                                    <div className="percentageBars">
                                                        <div className="columnsOverviewMedium" >{language === "sv" ? "Lägre mittkvartil" : lang[language].lowMidQ}</div>
                                                        <div className="columnsOverviewBig" > <PercentageBar percentage={this.state.quartiles ? this.state.quartiles.quartile2.menP : 50} /></div>
                                                    </div>
                                                    <div className="percentageBars">
                                                        <div className="columnsOverviewMedium" >{language === "sv" ? "Övre mittkvartil" : lang[language].higMidQ}</div>
                                                        <div className="columnsOverviewBig" > <PercentageBar percentage={this.state.quartiles ? this.state.quartiles.quartile3.menP : 50} /></div>
                                                    </div>
                                                    <div>
                                                        <div className="columnsOverviewMedium" >{language === "sv" ? "Övre kvartil" : lang[language].highQ}</div>
                                                        <div className="columnsOverviewBig" > <PercentageBar percentage={this.state.quartiles ? this.state.quartiles.quartile4.menP : 50} /></div>
                                                    </div>


                                                </div>
                                            </div>
                                        </>
                                    }
                                    {/*} <div className="card_overview" style={{ background: "white", color: "#333333", height: 255, width: "45.5%" }}>
                            <div className="card_padding">
                                <span style={{ display: "block", margin: "auto", textAlign: "center", paddingBottom: 5 }}>Andel kön per lönekvartil</span>
                                {this.state.missingEqualGroups ? <div className="card_message">Alla lika arbeten måste grupperas innan denna statistik kan visas. Detta görs i steg 2.1</div> 
                                :
                                    <>
                                        <div style={{ width: "70%", display: "inline-block", marginTop: -20 }}>
                                            <BarChart chartData={data} />
                                        </div>
                                        <div style={{ width: "25%", display: "inline-block", marginTop: 0, verticalAlign: "top" }}>

                                            <span className="card_header gradient_text" style={{ textAlign: "center", marginTop: 20 }}>{this.state.maleDominated[1]} %</span>
                                            <span className="card_header gradient_text" style={{ textAlign: "center", marginTop: 25 }}>{this.state.womenDominated[1]} %</span>
                                            <span className="card_header gradient_text" style={{ textAlign: "center", marginTop: 25 }}>{this.state.notDominated[1]} %</span>
                                        </div>
                                    </> }
                            </div>
        </div>*/}
                                    {this.state.showWhat !== "original" &&
                                        <>
                                            <div className={this.state.showWhat === "all" ? "card_overview card-overview-white" : "card_overview"} style={{ width: "45.5%", height: 270, background: "white", color: "#333333" }}>
                                                <div className="card_padding">
                                                    <div className="euTitle">{language === "sv" ? "Löneskillnad" : lang[language].payG}</div>
                                                    <div style={{ display: "inline-block", width: "47.5%", marginRight: "5%", textAlign: "center", boxSizing: "border-box", background: "#f7f7f7", borderRadius: 7, padding: 15, marginTop: -5, paddingBottom: 30 }}>
                                                        <div style={{ borderBottom: "1px solid #eaeaea", paddingBottom: 10, color: "grey", fontSize: 14.6 }}>Årsinkomst</div>
                                                        <div style={{ marginTop: 10 }}>
                                                            <span style={{ display: "inline-block", marginRight: "10%", width: "45%", fontSize: 14.6 }}>{language === "sv" ? "Medel" : lang[language].average}</span>
                                                            <span style={{ display: "inline-block", fontSize: 20.6, width: "45%" }} className={this.state.showWhat === "all" ? "card_header card_header-white" : "card_header"}>{this.state.avgSalDiffPercent} %</span>
                                                        </div>
                                                        <div style={{ marginTop: 5 }}>
                                                            <span style={{ display: "inline-block", marginRight: "10%", width: "45%", fontSize: 14.6 }}>{language === "sv" ? "Median" : lang[language].median}</span>
                                                            <span style={{ display: "inline-block", fontSize: 20.6, width: "45%" }} className={this.state.showWhat === "all" ? "card_header card_header-white" : "card_header"}>{this.state.medianSalDiffPercent} %</span>
                                                        </div>
                                                    </div>
                                                    
                                                    <div style={{ display: "inline-block", width: "47.5%", textAlign: "center", boxSizing: "border-box", background: "#f7f7f7", borderRadius: 7, padding: 15, marginTop: -5, paddingBottom: 30 }}>
                                                        <div style={{ borderBottom: "1px solid #eaeaea", paddingBottom: 10, color: "grey", fontSize: 14.6 }}>Timlön</div>
                                                        <div style={{ marginTop: 10 }}>
                                                            <span style={{ display: "inline-block", marginRight: "10%", width: "45%", fontSize: 14.6 }}>{language === "sv" ? "Medel" : lang[language].average}</span>
                                                            <span style={{ display: "inline-block", fontSize: 20.6, width: "45%" }} className={this.state.showWhat === "all" ? "card_header card_header-white" : "card_header"}>{this.state.avgSalDiffPercent} %</span>
                                                        </div>
                                                        <div style={{ marginTop: 5 }}>
                                                            <span style={{ display: "inline-block", marginRight: "10%", width: "45%", fontSize: 14.6 }}>{language === "sv" ? "Median" : lang[language].median}</span>
                                                            <span style={{ display: "inline-block", fontSize: 20.6, width: "45%" }} className={this.state.showWhat === "all" ? "card_header card_header-white" : "card_header"}>{this.state.medianSalDiffPercent} %</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                            <div className={this.state.showWhat === "all" ? "card_overview card-overview-white" : "card_overview"} style={{ width: "45.5%", height: 270, background: "white", color: "#333333" }}>
                                                <div className="card_padding">
                                                    <div className="euTitle">{language === "sv" ? "Tillägg skillnad" : lang[language].benefitDiff}</div>
                                                    <div style={{ display: "inline-block", width: "47.5%", marginRight: "5%", textAlign: "center", boxSizing: "border-box", background: "#f7f7f7", borderRadius: 7, padding: 15, marginTop: -5, paddingBottom: 30 }}>
                                                        <div style={{ borderBottom: "1px solid #eaeaea", paddingBottom: 10, color: "grey", fontSize: 14.6 }}>Årsinkomst</div>
                                                        <div style={{ marginTop: 10 }}>
                                                            <span style={{ display: "inline-block", marginRight: "10%", width: "45%", fontSize: 14.6 }}>{language === "sv" ? "Medel" : lang[language].average}</span>
                                                            <span style={{ display: "inline-block", fontSize: 20.6, width: "45%" }} className={this.state.showWhat === "all" ? "card_header card_header-white" : "card_header"}>{this.state.avgSalDiffPercent} %</span>
                                                        </div>
                                                        <div style={{ marginTop: 5 }}>
                                                            <span style={{ display: "inline-block", marginRight: "10%", width: "45%", fontSize: 14.6 }}>{language === "sv" ? "Median" : lang[language].median}</span>
                                                            <span style={{ display: "inline-block", fontSize: 20.6, width: "45%" }} className={this.state.showWhat === "all" ? "card_header card_header-white" : "card_header"}>{this.state.medianSalDiffPercent} %</span>
                                                        </div>
                                                    </div>
                                                    
                                                    <div style={{ display: "inline-block", width: "47.5%", textAlign: "center", boxSizing: "border-box", background: "#f7f7f7", borderRadius: 7, padding: 15, marginTop: -5, paddingBottom: 30 }}>
                                                        <div style={{ borderBottom: "1px solid #eaeaea", paddingBottom: 10, color: "grey", fontSize: 14.6 }}>Timlön</div>
                                                        <div style={{ marginTop: 10 }}>
                                                            <span style={{ display: "inline-block", marginRight: "10%", width: "45%", fontSize: 14.6 }}>{language === "sv" ? "Medel" : lang[language].average}</span>
                                                            <span style={{ display: "inline-block", fontSize: 20.6, width: "45%" }} className={this.state.showWhat === "all" ? "card_header card_header-white" : "card_header"}>{this.state.avgSalDiffPercent} %</span>
                                                        </div>
                                                        <div style={{ marginTop: 5 }}>
                                                            <span style={{ display: "inline-block", marginRight: "10%", width: "45%", fontSize: 14.6 }}>{language === "sv" ? "Median" : lang[language].median}</span>
                                                            <span style={{ display: "inline-block", fontSize: 20.6, width: "45%" }} className={this.state.showWhat === "all" ? "card_header card_header-white" : "card_header"}>{this.state.medianSalDiffPercent} %</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                            <div className={this.state.showWhat === "all" ? "card_overview card-overview-white" : "card_overview"} style={{ width: "45.5%", height: 270, background: "white", color: "#333333" }}>
                                                <div className="card_padding">
                                                    <div className="euTitle">{language === "sv" ? "Innehar tillägg" : lang[language].hasBenefits}</div>
                                                    {/* div>Skillnaden mellan kvinnor och män avseende lönetillägg  rörliga ersättningar</div> */}
                                                    <div style={{ display: "inline-block", width: "47.5%", marginRight: "5%", textAlign: "center", boxSizing: "border-box", background: "#f7f7f7", borderRadius: 7, padding: 15, marginTop: -5, paddingBottom: 30 }}>
                                                        <div style={{ borderBottom: "1px solid #eaeaea", paddingBottom: 10, color: "grey", fontSize: 14.6 }}>Årsinkomst</div>
                                                        <div style={{ marginTop: 10 }}>
                                                            <span style={{ display: "inline-block", marginRight: "10%", width: "45%", fontSize: 14.6 }}>{language === "sv" ? "Medel" : lang[language].average}</span>
                                                            <span style={{ display: "inline-block", fontSize: 20.6, width: "45%" }} className={this.state.showWhat === "all" ? "card_header card_header-white" : "card_header"}>{this.state.avgSalDiffPercent} %</span>
                                                        </div>
                                                        <div style={{ marginTop: 5 }}>
                                                            <span style={{ display: "inline-block", marginRight: "10%", width: "45%", fontSize: 14.6 }}>{language === "sv" ? "Median" : lang[language].median}</span>
                                                            <span style={{ display: "inline-block", fontSize: 20.6, width: "45%" }} className={this.state.showWhat === "all" ? "card_header card_header-white" : "card_header"}>{this.state.medianSalDiffPercent} %</span>
                                                        </div>
                                                    </div>
                                                    
                                                    <div style={{ display: "inline-block", width: "47.5%", textAlign: "center", boxSizing: "border-box", background: "#f7f7f7", borderRadius: 7, padding: 15, marginTop: -5, paddingBottom: 30 }}>
                                                        <div style={{ borderBottom: "1px solid #eaeaea", paddingBottom: 10, color: "grey", fontSize: 14.6 }}>Timlön</div>
                                                        <div style={{ marginTop: 10 }}>
                                                            <span style={{ display: "inline-block", marginRight: "10%", width: "45%", fontSize: 14.6 }}>{language === "sv" ? "Medel" : lang[language].average}</span>
                                                            <span style={{ display: "inline-block", fontSize: 20.6, width: "45%" }} className={this.state.showWhat === "all" ? "card_header card_header-white" : "card_header"}>{this.state.avgSalDiffPercent} %</span>
                                                        </div>
                                                        <div style={{ marginTop: 5 }}>
                                                            <span style={{ display: "inline-block", marginRight: "10%", width: "45%", fontSize: 14.6 }}>{language === "sv" ? "Median" : lang[language].median}</span>
                                                            <span style={{ display: "inline-block", fontSize: 20.6, width: "45%" }} className={this.state.showWhat === "all" ? "card_header card_header-white" : "card_header"}>{this.state.medianSalDiffPercent} %</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>






                                        </>
                                    }
                                    {(this.state.showWhat !== "original" && this.state.showWhat !== "intern") &&
                                        <>
                                           {/* <div style={{ marginTop: 40, cursor: "pointer" }} className="euTitle" onClick={this.toggleWorkCategories}>{language === "sv" ? "Visa löneskillnad per arbetstagarkategori" : lang[language].payGapCategory}<img style={{ marginLeft: 5, verticalAlign: "middle" }} src={this.state.showWorkCategories ? up : down} width={15} /></div>*/}
                       
                                           {/* <div className={this.state.showWorkCategories ? "reportingListContainer" : "hide"}>*/}
                                            <div className="reportingListContainer">
                                            <div className="euTitle">Löneskillnad per arbetstagarkategori</div>
                                                <div style={{ margin: "auto", color: "#333333", width: "45.5%", textAlign: "center", boxShadow: "none", paddingBottom: 40, height: "auto", minWidth: 450, background: "#f7f7f7", borderRadius: 7, padding: 20 }}>
                                                    {workCategoriesPayGap}
                                                </div>
                                               {/* <div style={{ paddingBottom: 5, fontSize: 12, fontStyle: "italic", marginBottom: 20 }}>{language === "sv" ? "Ovan visar löneskillnad för arbeten med likvärdiga krav. Detaljerad analys kan göras i systemet." : lang[language].detailedAnaInfo}</div> */}
                                          </div>
                                        </>
                                    }
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </>);
    }
}


