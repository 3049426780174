import React from 'react';
import './Lika.css';
import check from '../../assets/images/check-mark.png';
import remove from '../../assets/images/x-mark.png';
import { Menu } from '../Menu.js';
import { TopNav } from '../TopNav.js';
import { Information } from '../Information/Information.js';
import Chart from "react-apexcharts";
import arrowDown from '../../assets/images/arrow-down-black.png';
import TextareaAutosize from 'react-textarea-autosize'; //For textareas to autosize
import { fetchAuth } from '../../fetch-auth';
import exitImg from '../../assets/images/exit.png';
import down from '../../assets/images/down.png';
import up from '../../assets/images/up.png';
import fullscreen from '../../assets/images/fullscreen.png';
import zoomOut from '../../assets/images/zoom-out.png';
import { jitter } from './Jitter';
import { Prompt } from 'react-router';
import percentile from "percentile";
import extraOn from '../../assets/images/extraOff.png';
import extraOff from '../../assets/images/extraOffKryss.png';
import menuAnalysisIcon from '../../assets/images/menuAnalysis.png';
import menuAnalysisIconGreen from '../../assets/images/menuAnalysisGreen.png';
import uncheckImg from '../../assets/images/uncheckImg.png';
import checkedImg from '../../assets/images/checkedImg.png';
import radioGreen from '../../assets/images/radioGreen.png';
import radioBlack from '../../assets/images/radioBlack.png';
import benchIcon from '../../assets/images/benchIcon.png';
import benchIconGreen from '../../assets/images/benchIconGreen.png';
import lang from '../Language/language.js';

export class Lika extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: '',
      saveButtonStatus: "Sparat",
      moreFields: false,
      groupTitle: 'Ingen grupp vald',
      nrOfMen: 0,
      nrOfWomen: 0,
      nrTotal: 0,
      avSalMen: 0,
      avSalWomen: 0,
      avSalTotal: 0,
      diffWomen: 0,
      diffWomenPercent: 0,
      showDiffPercent: false,
      comments: [],
      commentsLastYear: [],
      popUp: false,
      showOldComments: true,
      deletedComments: [],
      allComments: [],
      selectedYear: 0,
      year: 0,
      filter: 'all', //all or group
      maleEmployees: [],
      womenEmployees: [],
      popUpInfo: false,
      clickedNewGroup: true,
      longestExp: "",
      longestAge: "",
      //Below is for adding completely new comment
      addComment: "",
      addReason: "",
      addTodo: "",
      addDeadline: "",
      addCost: "",
      addResponsible: "",
      //below is for showing salaries as percent and show/hide name
      showPercent: false,
      hideName: false,
      //Below is for editing a persons salary or remove the person
      clickedPerson: "",
      clickedPersonIndex: "",
      getMen: [],
      getWomen: [],
      serieIndex: "",
      popItUp: false,
      newSalary: 0,
      showChangesPrompt: "Yes", //<-- For changes PopUp
      popUpEditSalarySetting: false,
      //Below is for spridning
      lowestSalaryMen: "-",
      highestSalaryMen: "-",
      spridningskvotMen: "-",
      lowestSalaryWomen: "-",
      highestSalaryWomen: "-",
      spridningskvotWomen: "-",
      spridningDiff: "-",
      spridningPopUp: false,
      moreThan20: "",
      moreMen10: "-",
      moreMen90: "-",
      moreMenKvot: "-",
      moreWomen10: "-",
      moreWomen90: "-",
      moreWomenKvot: "-",
      //Below is for extra vs salary
      hideExtra: "includeExtra", //"includeExtra", "hideExtra", "onlyExtra"
      showHideExtraIcon: "off",
      //Below is for filterMenu
      showHideFilterIcon: "off",
      openDetails: false,
      filterTitles: [],
      filterCities: [],
      calculationSeries: [],
      showFilter: false,
      filterTitlesToRender: [],
      filterCitiesToRender: [],
      //
      benchActive: false,
      showBench: false,
      ssykBenchData: [],
      whatSector: "all",
      whatPercentiles: "p10", //  p10, p25, all, hide
      ssykBenchDataForGroup: [],
      average: 0,
      median: 0,
      p10: 0,
      p25: 0,
      p75: 0,
      p90: 0,
      averageO: 0,
      medianO: 0,
      p10O: 0,
      p25O: 0,
      p75O: 0,
      p90O: 0,
      averageP: 0,
      medianP: 0,
      p10P: 0,
      p25P: 0,
      p75P: 0,
      p90P: 0,
      benchInfoPop: false,
      //Below is state for chart, above is for leftlist and other stats
      xAxisType: "experience",
      xSetting: false,
      chartWidth: "500",
      series: [{
        name: "Män",
        data: []
      },
      {
        name: "Kvinnor",
        data: []
      }
      ],
      options: {
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            return `<ul>
                    <li><b>Namn</b>: ${data.fullName}</li>
                    <li><b>Lön</b>: ${data.y} kr</li>
                    <li><b>År i org</b>: ${data.expNoJitter}</li>
                    <li><b>Ålder</b>: ${data.age}</li>
                    <li><b>Ort</b>: ${data.city}</li>
                    </ul>`
          },
        },
        chart: {
          height: 350,
          type: 'scatter',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false
          },
          animations: {
            enabled: true
          },
        },
        /*markers: { //REMOVE THIS
         strokeWidth: 3,
         strokeColors: ["#000", "#cf1af2"],
         //size: 5,  
       },*/
        //colors:["#000", "#cbb958"],

        xaxis: {
          tickAmount: 5,
          type: 'numeric',
          labels: {
            formatter: function (val) {
              return parseFloat(val).toFixed(0)
            }
          }
        },
        yaxis: {
          tickAmount: 0,
          labels: {
            formatter: function (val) {
              return parseFloat(val).toFixed(0)
            }
          }
        },
      },

    } //For state

  } //For constructor

  componentDidMount() {
    let warningPrompt = localStorage.getItem('warningPrompt');
    if (warningPrompt === "off") { this.setState({ showChangesPrompt: "No" }) }
    this.getData();
  }
  // This is added because styles need to change back to standard otherwise icons will be wrong in analys likvardiga
  componentWillUnmount() {
    document.documentElement.style.setProperty('--widthExtraSalIcon', '20px')
    document.documentElement.style.setProperty('--margRightExtraSalIcon', '0px')
  }
  //

  getData() {
    let language = localStorage.getItem('language') ?? 'sv';
    const getDataAndComments = async () => {
      const response = await fetchAuth(`/api/loadEqual`, 'POST');
      let data = await response.json();

      //set percent status to state
      let percentStatus = localStorage.getItem("settingPercent");
      percentStatus === "true" ? percentStatus = true : percentStatus = false
      this.setState({ showPercent: percentStatus })

      //set name status to state
      let hideName = localStorage.getItem("settingName");
      hideName === "true" ? hideName = true : hideName = false
      this.setState({ hideName: hideName })

      //set benchmark salaries
      let ssykBenchData = data[6]
      this.setState({ ssykBenchData: ssykBenchData })

      //add show/hide benchmark
      let showSalBench = data[7]
      if (showSalBench != undefined) {
        if (showSalBench.length > 0) {
          let benchActive = showSalBench[0].showSalBench
          if (benchActive === "on" || benchActive === "adminOn") { this.setState({ benchActive: true }) }
        }
      }

      //set age status to state
      const ageChart = data[3]
      if (ageChart != undefined) {
        if (ageChart.length > 0) {
          let showAgeChart = ageChart[0].showAgeChart
          if (showAgeChart === "on" || showAgeChart === "adminOn") { this.setState({ xSetting: true }) }
        }
      }
      //set showExtraSetting to state
      const extraSetting = data[4]
      if (extraSetting != undefined) {
        if (extraSetting.length > 0) {
          let showExtraSetting = extraSetting[0].showExtraSetting
          if (showExtraSetting === "on" || showExtraSetting === "adminOn") { this.setState({ showHideExtraIcon: "on" }) }
        }
      }
      //set showFilterSetting to state
      const filterSetting = data[5]
      if (filterSetting != undefined) {
        if (filterSetting.length > 0) {
          let showFilterSetting = filterSetting[0].showFilterSetting
          if (showFilterSetting === "on" || showFilterSetting === "adminOn") { this.setState({ showHideFilterIcon: "on" }) }
        }
      }

      //Then do the rest
      let employees = data[0]
      const comments = data[1]
      const commentsLastYear = data[2]
      const women = employees.filter(emp => emp.gender === "woman")
      const men = employees.filter(emp => emp.gender === "man")
      //Sort comments by descending date
      const commentsByDate = comments.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)
      const commentsByDateLastYear = commentsLastYear.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)

      //Set state for leftList and comments
      this.setState({
        maleEmployees: men,
        womenEmployees: women,
        comments: commentsByDate,
        commentsLastYear: commentsByDateLastYear
      })

      //Set state for all comments (remove years for this year or after the selected year)
      let selectedYear = localStorage.getItem('year')
      if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
      if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
      const allComments = data[8]
      const allCommentsExceptNewer = allComments.filter(obj => { const objYear = new Date(obj.dateAdded).getFullYear(); return objYear < selectedYear; });
     // const allYears = [...new Set(allCommentsExceptNewer.map(obj => new Date(obj.dateAdded).getFullYear()))].sort((a, b) => a - b); 
      this.setState({
        allComments: allCommentsExceptNewer,
        selectedYear: selectedYear,
        year: selectedYear-1
      })

      //Set state for chart
      this.setState({
        series: [{
          name: language === "sv" ? "Män" : lang[language].men,
          data: []
        },
        {
          name: language === "sv" ? "Kvinnor" : lang[language].women,
          data: []
        }],

      })

    };
    getDataAndComments();
  };

  spridningPopUp = () => { this.state.spridningPopUp ? this.setState({ spridningPopUp: false }) : this.setState({ spridningPopUp: true }) }
  hideEditBox = () => { this.setState({ popItUp: false, popUpEditSalarySetting: false }) }
  popUpEditSalarySetting = () => { this.setState({ popUpEditSalarySetting: true }) }
  addEditSalary = (event) => {
    let salary = Number(event.target.value)
    this.setState({ newSalary: salary })
  }
  hideExtra = () => {
    this.state.hideExtra === "includeExtra" ? this.setState({ hideExtra: "hideExtra" }) : this.state.hideExtra === "hideExtra" ? this.setState({ hideExtra: "onlyExtra" }) : this.setState({ hideExtra: "includeExtra" })
    this.state.hideExtra === "includeExtra" ? document.documentElement.style.setProperty('--widthExtraSalIcon', '20px') : document.documentElement.style.setProperty('--widthExtraSalIcon', '86px')
    this.state.hideExtra === "includeExtra" ? document.documentElement.style.setProperty('--margRightExtraSalIcon', '0px') : document.documentElement.style.setProperty('--margRightExtraSalIcon', '-5px')
  }
  forceUpdateApexChart = () => {
    let language = localStorage.getItem('language') ?? 'sv';
    return new Promise(resolve => {
      this.setState({
        series: [{
          name: language === "sv" ? "Män" : lang[language].men,
          data: [] //filteredMale
        },
        {
          name: language === "sv" ? "Kvinnor" : lang[language].women,
          data: [] //filteredWoman
        }],
      })
      resolve()
    });
  }

  showDiffPercent = () => { this.state.showDiffPercent ? this.setState({ showDiffPercent: false }) : this.setState({ showDiffPercent: true }) }

  changeYearComments = (event) => {
    let year = Number(event.target.value)
    let allComments = this.state.allComments
    let commentsForYear = allComments.filter(obj => new Date(obj.dateAdded).getFullYear() === year);
    //Below is probably wrong, borde tas bort från allcomments listan också.
    this.setState({ commentsLastYear: commentsForYear, year: year })
  }

  editSalary = async (excluded) => {
    let language = localStorage.getItem('language') ?? 'sv';
    const waitForState = async () => {
      return new Promise((resolve, reject) => {
        let getMen = this.state.series[0].data.slice(0)
        let getWomen = this.state.series[1].data.slice()
        let womenCalcArray = this.state.calculationSeries[1].data.slice()
        let menCalcArray = this.state.calculationSeries[0].data.slice()

        if (this.state.serieIndex == 0) { //If mens group
          //First, make cahnges for calculations array
          //Also make the changes for the calculation series
          let clickedExperience = ""
          let clickedName = ""
          let clickedAge = ""
          clickedExperience = getMen[this.state.clickedPersonIndex].expNoJitter
          clickedName = getMen[this.state.clickedPersonIndex].fullName
          clickedAge = getMen[this.state.clickedPersonIndex].age
          for (let i = 0; i < menCalcArray.length; i++) {
            if (menCalcArray[i].expNoJitter === clickedExperience && menCalcArray[i].fullName === clickedName && menCalcArray[i].age === clickedAge) {
              if (excluded === "exclude") {
                menCalcArray[i].salNoExtra = this.state.newSalary
              } else {
                menCalcArray[i].y = this.state.newSalary
                menCalcArray[i].salBeforeAddOn = this.state.newSalary
                menCalcArray[i].salNoExtra = undefined
              }
            }
          }
          //then make changes for chart
          let thePerson = getMen[this.state.clickedPersonIndex]
          if (excluded === "exclude") { //if changed salary excluding extra 
            thePerson.salNoExtra = this.state.newSalary
          } else { //if changed salary including extra 
            thePerson.salNoExtra = undefined
            thePerson.y = this.state.newSalary
            thePerson.salBeforeAddOn = this.state.newSalary
          }
          getMen.splice(this.state.clickedPersonIndex, 1) //Remove the person
          if (getMen.length === 0) { //If there is only one person in the list, push a copy
            this.forceUpdateApexChart();
          }
          if (getMen.length != this.state.clickedPersonIndex) {//Push the person to the end if it is not in the end
            getMen.push(thePerson)
          } else { //Else push at the start instead
            getMen.unshift(thePerson)
          }
        }
        if (this.state.serieIndex == 1) { //If womens group
          //First make the changes for the calculation series
          let clickedExperience = ""
          let clickedName = ""
          let clickedAge = ""
          clickedExperience = getWomen[this.state.clickedPersonIndex].expNoJitter
          clickedName = getWomen[this.state.clickedPersonIndex].fullName
          clickedAge = getWomen[this.state.clickedPersonIndex].age
          for (let i = 0; i < womenCalcArray.length; i++) {
            if (womenCalcArray[i].expNoJitter === clickedExperience && womenCalcArray[i].fullName === clickedName && womenCalcArray[i].age === clickedAge) {
              if (excluded === "exclude") {
                womenCalcArray[i].salNoExtra = this.state.newSalary
              } else {
                womenCalcArray[i].y = this.state.newSalary
                womenCalcArray[i].salBeforeAddOn = this.state.newSalary
                womenCalcArray[i].salNoExtra = undefined
              }
            }
          }
          //Then make changes for chart
          let thePerson = getWomen[this.state.clickedPersonIndex]
          if (excluded === "exclude") { //if changed salary excluding extra 
            thePerson.salNoExtra = this.state.newSalary
          } else { //if changed salary including extra 
            thePerson.salNoExtra = undefined
            thePerson.y = this.state.newSalary
            thePerson.salBeforeAddOn = this.state.newSalary
          }
          getWomen.splice(this.state.clickedPersonIndex, 1) //Remove the person
          if (getWomen.length === 0) { //If there is only one person in the list, push a copy
            this.forceUpdateApexChart();
          }
          if (getWomen.length != this.state.clickedPersonIndex) {//Push the person to the end if it is not in the end
            getWomen.push(thePerson)
          } else { //Else push at the start instead
            getWomen.unshift(thePerson)
          }
        }
        //setState
        this.setState({
          popItUp: false,
          //For chart 
          series: [{
            name: language === "sv" ? "Män" : lang[language].men,
            data: getMen
          },
          {
            name: language === "sv" ? "Kvinnor" : lang[language].women,
            data: getWomen
          }],
          //For calculation array
          calculationSeries: [{
            name: language === "sv" ? "Män" : lang[language].men,
            data: menCalcArray
          },
          {
            name: language === "sv" ? "Kvinnor" : lang[language].women,
            data: womenCalcArray
          }],
        })
        resolve();
      })
    }
    await waitForState()
    this.handleClick()
  }

  editSalaryExclude = () => { this.editSalary("exclude") }
  benchInfoPop = () => { this.state.benchInfoPop ? this.setState({ benchInfoPop: false }) : this.setState({ benchInfoPop: true }) }
  chooseSector = (event) => { this.setState({ whatSector: event.target.value }, () => { this.handleClick() }) }
  whatPercentiles = (event) => { this.setState({ whatPercentiles: event.target.value }, () => { this.handleClick() }) }

  hideEmployee = async () => {
    let language = localStorage.getItem('language') ?? 'sv';
    const waitForState = () => {
      return new Promise((resolve, reject) => {
        // Remove the employee from the calculation array
        let womenCalcArray = this.state.calculationSeries[1].data
        let menCalcArray = this.state.calculationSeries[0].data
        let clickedExperience = ""
        let clickedName = ""
        let clickedAge = ""
        if (this.state.serieIndex == 0) { //If mens group
          clickedExperience = this.state.series[0].data[this.state.clickedPersonIndex].expNoJitter
          clickedName = this.state.series[0].data[this.state.clickedPersonIndex].fullName
          clickedAge = this.state.series[0].data[this.state.clickedPersonIndex].age
          let copyOfCalculationsArray = menCalcArray.slice()
          menCalcArray = copyOfCalculationsArray.filter(person => person.expNoJitter !== clickedExperience || person.fullName !== clickedName || person.age !== clickedAge)
        }
        if (this.state.serieIndex == 1) { //If womens group
          clickedExperience = this.state.series[1].data[this.state.clickedPersonIndex].expNoJitter
          clickedName = this.state.series[1].data[this.state.clickedPersonIndex].fullName
          clickedAge = this.state.series[1].data[this.state.clickedPersonIndex].age
          let copyOfCalculationsArray = womenCalcArray.slice()
          womenCalcArray = copyOfCalculationsArray.filter(person => person.expNoJitter !== clickedExperience || person.fullName !== clickedName || person.age !== clickedAge)
        }
        //Remove the employee from the chart array
        let getMen = this.state.series[0].data
        let getWomen = this.state.series[1].data
        if (this.state.serieIndex == 0) { //If mens group
          getMen = this.state.series[0].data.slice(0) // Copy the array with all men without mutating
          getMen.splice(this.state.clickedPersonIndex, 1) //Remove the person from the array
        }
        if (this.state.serieIndex == 1) { //If womens group
          getWomen = this.state.series[1].data.slice(0)
          getWomen.splice(this.state.clickedPersonIndex, 1)
        }
        //save arrays to state
        this.setState({
          series: [{
            name: language === "sv" ? "Män" : lang[language].men,
            data: getMen //filteredMale
          },
          {
            name: language === "sv" ? "Kvinnor" : lang[language].women,
            data: getWomen //filteredWoman
          }],
          //For calculations array and to close popUp
          calculationSeries: [{
            name: language === "sv" ? "Män" : lang[language].men,
            data: menCalcArray //filteredMale
          },
          {
            name: language === "sv" ? "Kvinnor" : lang[language].women,
            data: womenCalcArray //filteredWoman
          }],
          popItUp: false,
        })
        resolve();
      })
    }
    await waitForState() //wait for state to be set
    this.handleClick() //then run handleClick to recalculate all data.
  }

  handleClick = async (event) => { //Change chart onClick
    let language = localStorage.getItem('language') ?? 'sv';
    let eventId = this.state.activeItem
    let checkClickedNewGroup = () => {
      return new Promise((resolve, reject) => {
        // eventId = event.target.id
        this.setState({ clickedNewGroup: false })
        resolve();
      });
    }
    if (event != undefined) {
      if (event.target.id != undefined) {
        eventId = event.target.id
        checkClickedNewGroup = () => {
          return new Promise((resolve, reject) => {
            // eventId = event.target.id
            this.setState({ clickedNewGroup: true })
            resolve();
          });
        }
      }
    }
    await checkClickedNewGroup()
    let hideExtraBefore = ""
    let runHandleClick = () => {
      return new Promise((resolve, reject) => {
        let hideExtra = this.state.hideExtra //For showing extra/tillagg
        let animationSetting = this.state.clickedNewGroup ? true : false
        let listMen = []
        let listWomen = []
        let listAll = []
        let filteredMale = []
        let filteredWoman = []
        if (this.state.clickedNewGroup) { // If new group clicked
          //alert("newGroup clicked!")
          hideExtraBefore = this.state.hideExtra.slice() // This is used for mutate fix in the end of runHandleClick function
          // alert(hideExtraBefore)
          hideExtra = "includeExtra" //Salaryfilter fix. This makes sure our salaryfilter is not mutaded. Not great but works
          this.setState({ hideExtra: "includeExtra" }) //Salaryfilter fix. This makes sure our salaryfilter is not mutaded. Not great but works
          document.documentElement.style.setProperty('--widthExtraSalIcon', '20px') //fixes icon due to salaryFilter fix above.
          document.documentElement.style.setProperty('--margRightExtraSalIcon', '0px') //fixes icon due to salaryFilter fix above.

          listMen = this.state.maleEmployees
          listWomen = this.state.womenEmployees
          listAll = listMen.concat(listWomen);
          filteredMale = listAll.filter(person => (person.groupNameArbVardering === eventId && person.gender === "man"))
          filteredWoman = listAll.filter(person => (person.groupNameArbVardering === eventId && person.gender === "woman"))
          // Also set state for filter titles that we need to render the filter function
          let filterEqualGroup = listAll.filter(person => person.groupNameArbVardering === eventId);
          let positionsInGroup = []
          let cityInGroup = []
          for (let i = 0; i < filterEqualGroup.length; i++) {
            positionsInGroup.push(filterEqualGroup[i].positionTitle)
            cityInGroup.push(filterEqualGroup[i].city)
          }
          let uniqueFilterTitlesToRender = positionsInGroup.filter((value, index, self) => self.indexOf(value) === index).sort();
          let uniqueFilterCitiesToRender = cityInGroup.filter((value, index, self) => self.indexOf(value) === index).sort();
          this.setState({ filterTitlesToRender: uniqueFilterTitlesToRender, filterCitiesToRender: uniqueFilterCitiesToRender })
        }
        if (!this.state.clickedNewGroup) { //If same group
          //alert("not new group")
          listAll = listMen.concat(listWomen);
          filteredMale = this.state.calculationSeries[0].data
          filteredWoman = this.state.calculationSeries[1].data
          listAll = filteredMale.concat(filteredWoman);
        }
        //Calculate nr of employees
        const nrOfMen = filteredMale.length
        const nrOfWomen = filteredWoman.length
        const nrTotal = nrOfMen + nrOfWomen

        //Depending on the series we use the properties differ
        let salary = this.state.clickedNewGroup ? "totalSalary" : !this.state.clickedNewGroup && !this.state.showPercent ? "y" : "salInKr"
        let exp = this.state.clickedNewGroup ? "experience" : "expNoJitter"
        //Calculate average salaries
        let avSalMen = 0
        if (hideExtra === "hideExtra") {
          avSalMen = ((filteredMale.reduce((sum, cur) => sum + (cur.salNoExtra === undefined ? (cur[salary] - cur.addOn) : cur.salNoExtra), 0)) / nrOfMen).toFixed(0)
          if (avSalMen === "NaN") { avSalMen = 0 }
        }
        if (hideExtra === "onlyExtra") {
          avSalMen = ((filteredMale.reduce((sum, cur) => sum + (cur.addOn), 0)) / nrOfMen).toFixed(0)
          if (avSalMen === "NaN") { avSalMen = 0 }
        }
        if (hideExtra === "includeExtra") {
          avSalMen = ((filteredMale.reduce((sum, cur) => sum + (cur.salNoExtra === undefined ? cur[salary] : (cur.salNoExtra + cur.addOn)), 0)) / nrOfMen).toFixed(0)
          //ORIGINAL: avSalMen = ((filteredMale.reduce((sum, cur) => sum + cur[salary], 0)) / nrOfMen).toFixed(0)
          if (avSalMen === "NaN") { avSalMen = 0 }
        }
        let avSalWomen = 0
        if (hideExtra === "hideExtra") {
          avSalWomen = ((filteredWoman.reduce((sum, cur) => sum + (cur.salNoExtra === undefined ? (cur[salary] - cur.addOn) : cur.salNoExtra), 0)) / nrOfWomen).toFixed(0)
          // Original: avSalWomen = ((filteredWoman.reduce((sum, cur) => sum + (cur[salary] - cur.addOn), 0)) / nrOfWomen).toFixed(0)
          if (avSalWomen === "NaN") { avSalWomen = 0 }
        }
        if (hideExtra === "onlyExtra") {
          avSalWomen = ((filteredWoman.reduce((sum, cur) => sum + (cur.addOn), 0)) / nrOfWomen).toFixed(0)
          if (avSalWomen === "NaN") { avSalWomen = 0 }
        }
        if (hideExtra === "includeExtra") {
          avSalWomen = ((filteredWoman.reduce((sum, cur) => sum + (cur.salNoExtra === undefined ? cur[salary] : (cur.salNoExtra + cur.addOn)), 0)) / nrOfWomen).toFixed(0)
          // ORIGINAL: avSalWomen = ((filteredWoman.reduce((sum, cur) => sum + cur[salary], 0)) / nrOfWomen).toFixed(0)
          if (avSalWomen === "NaN") { avSalWomen = 0 }
        }

        let allInGroup = this.state.clickedNewGroup ? listAll.filter(person => (person.groupNameArbVardering === eventId)) : listAll

        let salaryTotal = 0
        if (hideExtra === "hideExtra") {
          salaryTotal = allInGroup.reduce((sum, cur) => sum + (cur.salNoExtra === undefined ? (cur[salary] - cur.addOn) : cur.salNoExtra), 0)
          //ORIGINAL:  salaryTotal = allInGroup.reduce((sum, cur) => sum + (cur[salary] - cur.addOn), 0)
        }
        if (hideExtra === "onlyExtra") {
          salaryTotal = allInGroup.reduce((sum, cur) => sum + (cur.addOn), 0)
        }
        if (hideExtra === "includeExtra") {
          salaryTotal = allInGroup.reduce((sum, cur) => sum + (cur.salNoExtra === undefined ? cur[salary] : (cur.salNoExtra + cur.addOn)), 0)
          //Original: salaryTotal = allInGroup.reduce((sum, cur) => sum + cur[salary], 0)
        }
        let avSalTotal = (salaryTotal / nrTotal.toFixed(0)).toFixed(0)
        if (avSalTotal === "NaN") { avSalTotal = 0 }

        let diffWomen = avSalWomen - avSalMen
        if (diffWomen == avSalMen || nrOfWomen === 0 || nrOfMen === 0) { diffWomen = '-' }
        //let diffWomenPercent = Math.round((100 - ((avSalMen / avSalWomen) * 100)) * 10) / 10
        //console.log("diffWomenPercent" + diffWomenPercent)
        let diffWomenPercent = ((diffWomen / avSalMen) * 100).toFixed(1)
  
        if (nrOfWomen === 0 || nrOfMen === 0) { diffWomenPercent = '-' }

        //Get lowest and highest salary, also calculate spridningskvot (highest/lowest)
        let getOnlySalariesMen = []
        if (hideExtra === "hideExtra") { for (let i = 0; i < filteredMale.length; i++) { filteredMale[i].salNoExtra === undefined ? getOnlySalariesMen.push(filteredMale[i][salary] - filteredMale[i].addOn) : getOnlySalariesMen.push(filteredMale[i].salNoExtra) } }
        if (hideExtra === "onlyExtra") { for (let i = 0; i < filteredMale.length; i++) { getOnlySalariesMen.push(filteredMale[i].addOn) } }
        if (hideExtra === "includeExtra") { for (let i = 0; i < filteredMale.length; i++) { filteredMale[i].salNoExtra === undefined ? getOnlySalariesMen.push(filteredMale[i][salary]) : getOnlySalariesMen.push(filteredMale[i].salNoExtra + filteredMale[i].addOn) } }
        let lowestSalaryMen = Math.min(...getOnlySalariesMen)
        let highestSalaryMen = Math.max(...getOnlySalariesMen)
        let spridningskvotMen = Math.round((highestSalaryMen / lowestSalaryMen) * 10) / 10

        let getOnlySalariesWomen = []
        if (hideExtra === "hideExtra") { for (let i = 0; i < filteredWoman.length; i++) { filteredWoman[i].salNoExtra === undefined ? getOnlySalariesWomen.push(filteredWoman[i][salary] - filteredWoman[i].addOn) : getOnlySalariesWomen.push(filteredWoman[i].salNoExtra) } }
        if (hideExtra === "onlyExtra") { for (let i = 0; i < filteredWoman.length; i++) { getOnlySalariesWomen.push(filteredWoman[i].addOn) } }
        if (hideExtra === "includeExtra") { for (let i = 0; i < filteredWoman.length; i++) { filteredWoman[i].salNoExtra === undefined ? getOnlySalariesWomen.push(filteredWoman[i][salary]) : getOnlySalariesWomen.push(filteredWoman[i].salNoExtra + filteredWoman[i].addOn) } }
        let lowestSalaryWomen = Math.min(...getOnlySalariesWomen)
        let highestSalaryWomen = Math.max(...getOnlySalariesWomen)
        let spridningskvotWomen = Math.round((highestSalaryWomen / lowestSalaryWomen) * 10) / 10

        let spridningDiff = Math.round((spridningskvotMen - spridningskvotWomen) * 10) / 10
        //Remove "NaN" and "Infinity" errors
        spridningskvotMen = spridningskvotMen ? spridningskvotMen : "-";
        spridningskvotWomen = spridningskvotWomen ? spridningskvotWomen : "-";
        spridningDiff = spridningDiff ? spridningDiff : "-";
        lowestSalaryWomen = isFinite(lowestSalaryWomen) ? lowestSalaryWomen : "-";
        highestSalaryWomen = isFinite(highestSalaryWomen) ? highestSalaryWomen : "-";
        lowestSalaryMen = isFinite(lowestSalaryMen) ? lowestSalaryMen : "-";
        highestSalaryMen = isFinite(highestSalaryMen) ? highestSalaryMen : "-";

        //Calculate 10 and 90 percentiles if needed (if more than 20 employees)
        let moreThan20 = ""
        let moreMen10 = "-"
        let moreMen90 = "-"
        let moreMenKvot = "-"
        let moreWomen10 = "-"
        let moreWomen90 = "-"
        let moreWomenKvot = "-"

        if (nrOfMen > 19 || nrOfWomen > 19) {
          //If men bigger but not women
          if (nrOfMen > 19 && nrOfWomen < 20) {
            let menSalariesInOrder = getOnlySalariesMen.sort((a, b) => a - b) //Sort lowest to highest
            moreMen10 = percentile(10, menSalariesInOrder)
            moreMen90 = percentile(90, menSalariesInOrder)
            moreMenKvot = Math.round((moreMen90 / moreMen10) * 10) / 10
            moreThan20 = "men"
          }
          //If women bigger but not men
          if (nrOfWomen > 19 && nrOfMen < 20) {
            let womenSalariesInOrder = getOnlySalariesWomen.sort((a, b) => a - b) //Sort lowest to highest
            moreWomen10 = percentile(10, womenSalariesInOrder)
            moreWomen90 = percentile(90, womenSalariesInOrder)
            moreWomenKvot = Math.round((moreWomen90 / moreWomen10) * 10) / 10
            moreThan20 = "women"
          }
          //If both bigger
          if (nrOfWomen > 19 && nrOfMen > 19) {
            let menSalariesInOrder = getOnlySalariesMen.sort((a, b) => a - b) //Sort lowest to highest
            moreMen10 = percentile(10, menSalariesInOrder)
            moreMen90 = percentile(90, menSalariesInOrder)
            moreMenKvot = Math.round((moreMen90 / moreMen10) * 10) / 10
            let womenSalariesInOrder = getOnlySalariesWomen.sort((a, b) => a - b) //Sort lowest to highest
            moreWomen10 = percentile(10, womenSalariesInOrder)
            moreWomen90 = percentile(90, womenSalariesInOrder)
            moreWomenKvot = Math.round((moreWomen90 / moreWomen10) * 10) / 10
            moreThan20 = "both"
          }
        }

        //Add benchmark
        let average = 0
        let median = 0
        let p10 = 0
        let p25 = 0
        let p75 = 0
        let p90 = 0
        let averageO = 0
        let medianO = 0
        let p10O = 0
        let p25O = 0
        let p75O = 0
        let p90O = 0
        let averageP = 0
        let medianP = 0
        let p10P = 0
        let p25P = 0
        let p75P = 0
        let p90P = 0
        if (this.state.showBench) {
          let ssykList = [...new Set(allInGroup.map(item => item.ssyk))].filter(value => value !== null); //Create an array with all unique ssyk codes
          //let ssykWoman = [...new Set(filteredWoman.map(item => item.ssyk))].filter(value => value !== null); //Create an array with all unique ssyk codes
          //Now get data for the ssyk codes
          if (ssykList.length === 1) { // If only one ssyk code for the group
            let ssykBenchData = this.state.ssykBenchData.filter(item => item.ssyk == ssykList[0])
            if (ssykBenchData.length > 0) {
              average = ssykBenchData[0].average
              median = ssykBenchData[0].median
              p10 = ssykBenchData[0].percentile10
              p25 = ssykBenchData[0].percentile25
              p75 = ssykBenchData[0].percentile75
              p90 = ssykBenchData[0].percentile90
              averageO = ssykBenchData[0].averageOffentlig
              medianO = ssykBenchData[0].medianOffentlig
              p10O = ssykBenchData[0].percentile10Offentlig
              p25O = ssykBenchData[0].percentile25Offentlig
              p75O = ssykBenchData[0].percentile75Offentlig
              p90O = ssykBenchData[0].percentile90Offentlig
              averageP = ssykBenchData[0].averagePrivat
              medianP = ssykBenchData[0].medianPrivat
              p10P = ssykBenchData[0].percentile10Privat
              p25P = ssykBenchData[0].percentile25Privat
              p75P = ssykBenchData[0].percentile75Privat
              p90P = ssykBenchData[0].percentile90Privat
            }
          } else if (ssykList.length > 1) { // If there is more than one ssyk code for the group (should usually not be the case but should be able to handle it)
            // 1. Object with p10-p90 o,p,all for each ssyk is saved in this.state.ssykBenchDataForGroup, so we can recalculate if using filter.
            let ssykBenchData = this.state.ssykBenchData.filter(item => ssykList.some(ssyk => ssyk === item.ssyk));
            this.setState({ ssykBenchDataForGroup: ssykBenchData })
            // 2. Set the intial p10-p90 o,p,all to an average of all ssyk
            average = ssykBenchData.reduce((sum, item) => item.average !== 0 ? sum + item.average : sum, 0) / ssykBenchData.filter(item => item.average !== 0).length;
            median = ssykBenchData.reduce((sum, item) => item.median !== 0 ? sum + item.median : sum, 0) / ssykBenchData.filter(item => item.median !== 0).length;
            p10 = ssykBenchData.reduce((sum, item) => item.percentile10 !== 0 ? sum + item.percentile10 : sum, 0) / ssykBenchData.filter(item => item.percentile10 !== 0).length;
            p25 = ssykBenchData.reduce((sum, item) => item.percentile25 !== 0 ? sum + item.percentile25 : sum, 0) / ssykBenchData.filter(item => item.percentile25 !== 0).length;
            p75 = ssykBenchData.reduce((sum, item) => item.percentile75 !== 0 ? sum + item.percentile75 : sum, 0) / ssykBenchData.filter(item => item.percentile75 !== 0).length;
            p90 = ssykBenchData.reduce((sum, item) => item.percentile90 !== 0 ? sum + item.percentile90 : sum, 0) / ssykBenchData.filter(item => item.percentile90 !== 0).length;
            averageO = ssykBenchData.reduce((sum, item) => item.averageOffentlig !== 0 ? sum + item.averageOffentlig : sum, 0) / ssykBenchData.filter(item => item.averageOffentlig !== 0).length;
            medianO = ssykBenchData.reduce((sum, item) => item.medianOffentlig !== 0 ? sum + item.medianOffentlig : sum, 0) / ssykBenchData.filter(item => item.medianOffentlig !== 0).length;
            p10O = ssykBenchData.reduce((sum, item) => item.percentile10Offentlig !== 0 ? sum + item.percentile10Offentlig : sum, 0) / ssykBenchData.filter(item => item.percentile10Offentlig !== 0).length;
            p25O = ssykBenchData.reduce((sum, item) => item.percentile25Offentlig !== 0 ? sum + item.percentile25Offentlig : sum, 0) / ssykBenchData.filter(item => item.percentile25Offentlig !== 0).length;
            p75O = ssykBenchData.reduce((sum, item) => item.percentile75Offentlig !== 0 ? sum + item.percentile75Offentlig : sum, 0) / ssykBenchData.filter(item => item.percentile75Offentlig !== 0).length;
            p90O = ssykBenchData.reduce((sum, item) => item.percentile90Offentlig !== 0 ? sum + item.percentile90Offentlig : sum, 0) / ssykBenchData.filter(item => item.percentile90Offentlig !== 0).length;
            averageP = ssykBenchData.reduce((sum, item) => item.averagePrivat !== 0 ? sum + item.averagePrivat : sum, 0) / ssykBenchData.filter(item => item.averagePrivat !== 0).length;
            medianP = ssykBenchData.reduce((sum, item) => item.medianPrivat !== 0 ? sum + item.medianPrivat : sum, 0) / ssykBenchData.filter(item => item.medianPrivat !== 0).length;
            p10P = ssykBenchData.reduce((sum, item) => item.percentile10Privat !== 0 ? sum + item.percentile10Privat : sum, 0) / ssykBenchData.filter(item => item.percentile10Privat !== 0).length;
            p25P = ssykBenchData.reduce((sum, item) => item.percentile25Privat !== 0 ? sum + item.percentile25Privat : sum, 0) / ssykBenchData.filter(item => item.percentile25Privat !== 0).length;
            p75P = ssykBenchData.reduce((sum, item) => item.percentile75Privat !== 0 ? sum + item.percentile75Privat : sum, 0) / ssykBenchData.filter(item => item.percentile75Privat !== 0).length;
            p90P = ssykBenchData.reduce((sum, item) => item.percentile90Privat !== 0 ? sum + item.percentile90Privat : sum, 0) / ssykBenchData.filter(item => item.percentile90Privat !== 0).length;
          }
          this.setState({
            average: average,
            median: median,
            p10: p10,
            p25: p25,
            p75: p75,
            p90: p90,
            averageO: averageO,
            medianO: medianO,
            p10O: p10O,
            p25O: p25O,
            p75O: p75O,
            p90O: p90O,
            averageP: averageP,
            medianP: medianP,
            p10P: p10P,
            p25P: p25P,
            p75P: p75P,
            p90P: p90P,
          })
        }

        //Set state
        this.setState({
          nrOfMen: nrOfMen,
          nrOfWomen: nrOfWomen,
          nrTotal: nrTotal,
          groupTitle: eventId, //used for h2 title
          avSalMen: avSalMen,
          avSalWomen: avSalWomen,
          avSalTotal: avSalTotal,
          diffWomen: diffWomen,
          diffWomenPercent: diffWomenPercent,
          activeItem: eventId, //used for styling
          //For spridning
          lowestSalaryMen: lowestSalaryMen,
          highestSalaryMen: highestSalaryMen,
          spridningskvotMen: spridningskvotMen,
          lowestSalaryWomen: lowestSalaryWomen,
          highestSalaryWomen: highestSalaryWomen,
          spridningskvotWomen: spridningskvotWomen,
          spridningDiff: spridningDiff,
          moreThan20: moreThan20,
          moreMen10: moreMen10,
          moreMen90: moreMen90,
          moreMenKvot: moreMenKvot,
          moreWomen10: moreWomen10,
          moreWomen90: moreWomen90,
          moreWomenKvot: moreWomenKvot
        })

        //Above makes changes for calculations, below make changes to chart
        let filteredMaleChart = filteredMale
        let filteredWomanChart = filteredWoman
        if (!this.state.clickedNewGroup) {
          filteredMaleChart = this.state.series[0].data
          filteredWomanChart = this.state.series[1].data
        }
        let xAxisType = this.state.xAxisType
        let clickedNewGroup = this.state.clickedNewGroup
        //If should show in kr 
        if (!this.state.showPercent) {
          let chartMen = []
          let chartWomen = []
          chartMen = filteredMaleChart.map(function (el) {
            let xAxisValue = xAxisType === "experience" ? el[exp] : el.age //If should show age or experience in xAxis
            let theSalary = 0 //If should hide extra/tillagg or not
            //!hideExtra ? theSalary = el[salary] : theSalary = el[salary] - el.addOn
            let salBeforeAddOn = clickedNewGroup ? el[salary] : el.salBeforeAddOn // This saves the original salary to each employee
            if (hideExtra === "hideExtra" && !clickedNewGroup) { theSalary = el.salNoExtra === undefined ? (el.salBeforeAddOn - el.addOn) : el.salNoExtra }
            else if (hideExtra === "hideExtra" && clickedNewGroup) { theSalary = el[salary] - el.addOn }
            else if (hideExtra === "onlyExtra" && !clickedNewGroup) { theSalary = el.addOn }
            else if (hideExtra === "onlyExtra" && clickedNewGroup) { theSalary = el.addOn }
            else if (hideExtra === "includeExtra" && clickedNewGroup) { theSalary = el[salary] }
            else if (hideExtra === "includeExtra" && !clickedNewGroup) { theSalary = el.salNoExtra === undefined ? el.salBeforeAddOn : el.salNoExtra + el.addOn }
            let fillColor = el.fillColor === undefined || el.fillColor === null ? "" : el.fillColor
            return { y: theSalary, x: xAxisValue, salNoExtra: el.salNoExtra, salBeforeAddOn: salBeforeAddOn, expNoJitter: el[exp], positionTitle: el.positionTitle, gender: el.gender, fullName: el.fullName, persNr: el.persNr, groupNameArbVardering: el.groupNameArbVardering, age: el.age, city: el.city, additional: el.additional, addOn: el.addOn, fillColor: fillColor, ssyk: el.ssyk }
          })
          chartWomen = filteredWomanChart.map(function (el) {
            let xAxisValue = xAxisType === "experience" ? el[exp] : el.age //If should show age or experience in xAxis
            let theSalary = 0
            let salBeforeAddOn = clickedNewGroup ? el[salary] : el.salBeforeAddOn // This saves the original salary to each employee
            if (hideExtra === "hideExtra" && !clickedNewGroup) { theSalary = el.salNoExtra === undefined ? (el.salBeforeAddOn - el.addOn) : el.salNoExtra }
            else if (hideExtra === "hideExtra" && clickedNewGroup) { theSalary = el[salary] - el.addOn }
            else if (hideExtra === "onlyExtra" && !clickedNewGroup) { theSalary = el.addOn }
            else if (hideExtra === "onlyExtra" && clickedNewGroup) { theSalary = el.addOn }
            else if (hideExtra === "includeExtra" && clickedNewGroup) { theSalary = el[salary] }
            else if (hideExtra === "includeExtra" && !clickedNewGroup) { theSalary = el.salNoExtra === undefined ? el.salBeforeAddOn : el.salNoExtra + el.addOn }
            let fillColor = el.fillColor === undefined || el.fillColor === null ? "" : el.fillColor
            return { y: theSalary, x: xAxisValue, salNoExtra: el.salNoExtra, salBeforeAddOn: salBeforeAddOn, expNoJitter: el[exp], positionTitle: el.positionTitle, gender: el.gender, fullName: el.fullName, persNr: el.persNr, groupNameArbVardering: el.groupNameArbVardering, age: el.age, city: el.city, additional: el.additional, addOn: el.addOn, fillColor: fillColor, ssyk: el.ssyk }
          })

          //Create jitter if years and experience are exactly the same
          jitter(chartMen, chartWomen, 180)

          //Show or hide name
          const nameStatus = this.state.hideName ? "display: none" : "";

          //Get data to use with settings for chart x-axis
          let allExperiences = []
          let longestExp = 0
          if (xAxisType === "experience") {
            allExperiences = allInGroup.map(person => person[exp])
            longestExp = Math.max(...allExperiences)
            if (longestExp < 10) longestExp = 10
            if (longestExp > 10 && longestExp < 16) longestExp = 16
          }
          if (xAxisType === "age") {
            allExperiences = allInGroup.map(person => person.age)
            longestExp = Math.max(...allExperiences)
          }
          //If not a new clicked group use the old max experience
          if (this.state.clickedNewGroup) {
            //save highest exp for all employees even filtered
            let allExp = allInGroup.map(person => person[exp])
            let longestExperience = Math.max(...allExp)
            if (longestExperience < 10) longestExperience = 10
            if (longestExperience > 10 && longestExperience < 16) longestExperience = 16
            //save highest age for all employees even filtered
            let allAges = allInGroup.map(person => person.age)
            let longestAge = Math.max(...allAges)
            this.setState({
              longestExp: longestExperience,
              longestAge: longestAge
            })
          }
          if (!this.state.clickedNewGroup && xAxisType === "experience") { longestExp = this.state.longestExp }
          if (!this.state.clickedNewGroup && xAxisType === "age") { longestExp = this.state.longestAge }
          //Buggfix, if cero and only one person the chart will show a very small dot that is not hoverable. This fixes it.
          /* if (chartMen.length === 1 && chartWomen.length === 0) {
             if (chartMen[0].x === 0) {
               let getChartMenAgain = filteredMaleChart.map(function (el) {
                 let fillColor = el.fillColor === undefined || el.fillColor === null ? "" : el.fillColor
                 return { y: el[salary], x: 0.001, expNoJitter: el[exp], positionTitle: el.positionTitle, gender: el.gender, fullName: el.fullName, persNr: el.persNr, groupNameArbVardering: el.groupNameArbVardering, age: el.age, city: el.city, additional: el.additional, fillColor: fillColor }
               })
               chartMen = chartMen.concat(getChartMenAgain)
             }
           } if (chartWomen.length === 1 && chartMen.length === 0) {
             if (chartWomen[0].x === 0) {
               let getChartWomenAgain = filteredWomanChart.map(function (el) {
                 let fillColor = el.fillColor === undefined || el.fillColor === null ? "" : el.fillColor
                 return { y: el[salary], x: 0.001, expNoJitter: el[exp], positionTitle: el.positionTitle, gender: el.gender, fullName: el.fullName, persNr: el.persNr, groupNameArbVardering: el.groupNameArbVardering, age: el.age, city: el.city, additional: el.additional, fillColor: fillColor }
               })
               chartWomen = chartWomen.concat(getChartWomenAgain);
             }
           }*/
          let yMax = 0
          if (this.state.showBench) {
            // let allSal = allInGroup.map(person => (person.totalSalary !== undefined ? person.totalSalary : person.y ?? 0));
            // let highestSalary = Math.max(...allSal)
            let menHighestSalary = this.state.highestSalaryMen
            let womenHighestSalary = this.state.highestSalaryWomen
            if (isNaN(menHighestSalary) || menHighestSalary === null || menHighestSalary === undefined || menHighestSalary === "-") {
              menHighestSalary = 0; // Set a default value, e.g., 0
            }
            if (isNaN(womenHighestSalary) || womenHighestSalary === null || womenHighestSalary === undefined || womenHighestSalary === "-") {
              womenHighestSalary = 0; // Set a default value, e.g., 0
            }
            let highestSalary = Math.max(menHighestSalary, womenHighestSalary);
            if (this.state.whatSector === "all") {
              if (this.state.p90 > highestSalary) yMax = Math.round(this.state.p90 + 2000)
            } if (this.state.whatSector === "privat") {
              if (this.state.p90P > highestSalary) yMax = this.state.p90P > this.state.p90 ? Math.round(this.state.p90P + 2000) : Math.round(this.state.p90 + 2000)
            } if (this.state.whatSector === "offentlig") {
              if (this.state.p90O > highestSalary) yMax = this.state.p90O > this.state.p90 ? Math.round(this.state.p90O + 2000) : Math.round(this.state.p90 + 2000)
            }
          }
          let maxY = this.state.showBench && yMax > 0 ? {
            max: yMax,
            forceNiceScale: true,
            // type: 'numeric',
            //tickAmount: 5,// <-- justerad
            /* labels: { // <-- Verkar inte funkar?
               formatter: function (val) {
                 return Math.round(parseFloat(val).toFixed(0))
               }
             }*/
          } : {
            tickAmount: 0,
            labels: {
              formatter: function (val) {
                return parseFloat(val).toFixed(0)
              }
            }
          }

          //Set state for chart
          this.setState({
            options: {
              tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                  var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                  if (data.additional === null) { data.additional = "" }
                  let showAdditional = ""
                  data.additional === "" ? showAdditional = "" : showAdditional = `<li><b>${language === "sv" ? "Övrigt" : lang[language].other}</b>: ${data.additional}</li>`
                  if (data.addOn === 0 || data.addOn === null || data.addOn === undefined) { data.addOn = "" }
                  let showAddOn = ""
                  data.addOn === "" ? showAddOn = "" : hideExtra === "includeExtra" ? showAddOn = ` | <i style="font-size: 12px">${language === "sv" ? "Varav tillägg" : lang[language].inclBen}: ${data.addOn}</i>` : hideExtra === "hideExtra" ? showAddOn = ` | <i style="font-size: 12px">${language === "sv" ? (`Tillägg: ${data.addOn} (exkluderat)</li>`) : (`${lang[language].benefits}: ${data.addOn} ${lang[language].excluded}`)}</i>` : showAddOn = ``
                  let salaryName = hideExtra === "onlyExtra" ? (language === "sv" ? "Tillägg" : lang[language].benefits) : (language === "sv" ? "Lön" : lang[language].salary)
                  return `<ul>
                    <li style="${nameStatus}"><b>${language === "sv" ? "Namn" : lang[language].name}</b>: ${data.fullName}</li>
                    <li><b>${salaryName}</b>: ${data.y} ${language === "sv" ? "kr" : ""}${showAddOn}</li>
                    <li><b>${language === "sv" ? "År i org" : lang[language].yInOrg}</b>: ${data.expNoJitter}</li>
                    <li><b>${language === "sv" ? "Ålder" : lang[language].age}</b>: ${data.age}</li>
                    <li><b>${language === "sv" ? "Ort" : lang[language].city}</b>: ${data.city}</li>
                    <li style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;overflow-wrap: break-word;max-width: 320px"><b>${language === "sv" ? "Bef" : lang[language].positionShort}</b>: ${data.positionTitle}</li>
                    ${showAdditional}
                    </ul>`
                },
              },
              states: { active: { filter: { type: "none" } } },
              chart: {
                height: 350,
                type: 'scatter',
                animations: {
                  enabled: animationSetting
                },
                zoom: {
                  enabled: true,
                  type: 'x',
                  zoomedArea: {
                    fill: {
                      color: '#54c7b9',
                    },
                    stroke: {
                      color: '#54c7b9',
                      opacity: 0.4,
                      width: 1,
                    }
                  }
                },
                toolbar: {
                  show: true,
                  tools: {
                    download: false,
                    zoom: true,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: [



                      {
                        icon: `<div>${language === "sv" ? "Ålder" : lang[language].age}</div>`,
                        index: 5,
                        title: `${language === "sv" ? "Sätt x-axel till ålder" : lang[language].ageXinfo}`,
                        class: this.state.xSetting ? (this.state.xAxisType === "age" ? 'custom-icon-div custom-icon-age custom-icon-div-green' : 'custom-icon-div custom-icon-age') : 'hide',
                        click: function (chart, options, e) {
                          let activeItem = this.state.activeItem
                          this.setState({ xAxisType: "age" })
                          this.handleClick();
                        }.bind(this)
                      },
                      {



                        //  icon: hideExtra === "includeExtra" ? `<img width="16px" src=${extraOn} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px">` : hideExtra === "hideExtra" ?
                        icon: hideExtra === "includeExtra" ? `
                    <img width="16px" src=${extraOn} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px">` : hideExtra === "hideExtra" ?
                          `
                      <img width="16px" src=${extraOff} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px; display: inline-block; margin-right: -6px;">
                      <div style="
                      font-size: 11px;
    line-height: 8px;
    width: 60px;
    text-align: center;
    padding: 2px;
    border-radius: 4px;
    margin-top: 4px;
    display: inline-block;
    padding-right: 0px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    vertical-align: top;
    border-bottom: 1.5px solid #a09e9e;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px;
    padding-left: 2px;
    font-weight: 500;
    color: #9b9999;
    font-style: italic;
    ">${language === "sv" ? "Exkl tillägg" : lang[language].exclBenVeryShort}</div>
                      ` :
                          `
                      <img width="16px" src=${extraOff} style="margin-left: 5px;margin-top: 1.8px; padding-left: 2px; display: inline-block; margin-right: -6px;">
                      <div style="
                      font-size: 11px;
    line-height: 8px;
    width: 60px;
    text-align: center;
    padding: 2px;
    border-radius: 4px;
    margin-top: 4px;
    display: inline-block;
    padding-right: 0px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    vertical-align: top;
    border-bottom: 1.5px solid #a09e9e;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px;
    padding-left: 2px;
    font-weight: 500;
    color: #9b9999;
    font-style: italic;
    ">${language === "sv" ? "Bara tillägg" : lang[language].onlyBenVeryShort}</div>
                      `
                        ,
                        index: 2,
                        title: hideExtra === "includeExtra" ? (language === "sv" ? 'Visar lön inklusive tillägg' : lang[language].iconInclSal) : hideExtra === "hideExtra" ? (language === "sv" ? 'Visar lön exklusive tillägg' : lang[language].iconExclSal) : (language === "sv" ? "Visar tillägg exklusive lön" : lang[language].iconOnlyBen),
                        class: this.state.showHideExtraIcon === "on" || this.state.showHideExtraIcon === "adminOn" ? 'custom-icon extraSalIcon' : "hide",
                        click: function (chart, options, e) {
                          this.hideExtra();
                          this.handleClick();
                        }.bind(this)
                      },
                      {
                        icon: `<img src=${zoomOut} width="15px" style="margin-left: 6px;">`,
                        index: 1,
                        title: language === "sv" ? "Zooma ut" : lang[language].zoomOut,
                        class: 'custom-icon',
                        click: function (chart, options, e) {
                          if (this.state.chartWidth === "500") {
                            this.setState({ chartWidth: "500.1" })
                          }
                          if (this.state.chartWidth === "92%") {
                            this.setState({ chartWidth: "92.01%" })
                          }
                          if (this.state.chartWidth === "92.01%") {
                            this.setState({ chartWidth: "92%" })
                          }
                          else {
                            this.setState({ chartWidth: "500" })
                          }

                        }.bind(this)
                      },
                      {
                        icon: `<div>${language === "sv" ? "År i org." : lang[language].yearInOrgShorten}</div>`,
                        index: 6,
                        title: `${language === "sv" ? "Sätt x-axel till år i organisationen" : lang[language].yearInOrgXinfo}`,
                        class: this.state.xSetting ? (this.state.xAxisType === "age" ? 'custom-icon-div custom-icon-exp custom-icon-div-mright' : 'custom-icon-div custom-icon-exp custom-icon-div-mright custom-icon-div-green') : 'hide',
                        click: function (chart, options, e) {
                          this.setState({ xAxisType: "experience" })
                          this.handleClick();
                        }.bind(this)
                      },
                      {
                        icon: `<img src=${menuAnalysisIcon} width="13.5px"></img>`,
                        index: 3,
                        title: "Filter",
                        class: this.state.showHideFilterIcon === "on" || this.state.showHideFilterIcon === "adminOn" ? "custom-icon-div custom-icon-exp custom-icon-div-mright moreAnalysisBtn" : "hide",
                        click: function (chart, options, e) {
                          this.state.showFilter ? this.setState({ showFilter: false }) : this.setState({ showFilter: true });
                        }.bind(this)
                      },
                      {
                        icon: `<img src=${fullscreen} width="15px">`,
                        index: 4,
                        title: language === "sv" ? "Fullskärm" : lang[language].fullscreen,
                        class: 'custom-icon custom-icon-zoom',
                        click: function (chart, options, e) {
                          if (this.state.chartWidth === "500") {
                            this.setState({ chartWidth: "92%", apexPopUp: "apexPopUp" })
                          }
                          else {
                            this.setState({ chartWidth: "500", apexPopUp: "" })
                          }
                        }.bind(this)
                      },
                      {
                        icon: this.state.showBench ? `<img src=${benchIconGreen} width="16.5px" style="margin-left: 5.5px"></img>` : `<img src=${benchIcon} width="16.5px" style="opacity: 0.41; margin-left: 5.5px"></img>`,
                        index: 2,
                        title: language === "sv" ? "Marknadslöner" : lang[language].marketSalaries,
                        class: this.state.benchActive ? "" : "hide",
                        click: function (chart, options, e) {
                          this.state.showBench ? this.setState({ showBench: false, hideExtra: "onlyExtra" }) : this.setState({ showBench: true, hideExtra: "includeExtra" });
                          this.hideExtra();
                          this.handleClick();
                        }.bind(this)
                      },
                    ]
                  },
                },
                events: {
                  beforeZoom: (e, { xaxis }) => {
                    return {
                      xaxis: {
                        tickAmount: 1,
                        max: xaxis.max,
                        min: xaxis.min
                      }
                    }
                  },
                  dataPointSelection: (event, chartContext, config) => {
                    let getMen = this.state.series[0].data.slice(0)
                    let getWomen = this.state.series[1].data.slice(0)
                    let clickedPerson = ""
                    if (config.seriesIndex == 0) { //If mens group
                      clickedPerson = getMen[config.dataPointIndex]
                    }
                    if (config.seriesIndex == 1) { //If womens group
                      clickedPerson = getWomen[config.dataPointIndex]
                    }

                    this.setState({
                      clickedPerson: clickedPerson,
                      clickedPersonIndex: config.dataPointIndex,
                      getMen: getMen,
                      getWomen: getWomen,
                      serieIndex: config.seriesIndex,
                      popItUp: true,
                    })
                  }
                },
              },
              xaxis: {
                tickAmount: 5,
                type: 'numeric',
                max: longestExp,
                min: 0,
                ticks: {
                  includeBounds: true,
                },
                labels: {
                  formatter: function (val) {
                    return parseFloat(val).toFixed(0)
                  }
                }
              },
              yaxis: maxY,
              //This is for benchark
              annotations: {
                yaxis: this.state.whatPercentiles === "hide" || !this.state.showBench ? [] : [
                  //P90-P75
                  this.state.whatPercentiles !== "p25" && {
                    y: this.state.showBench && this.state.whatSector === "all" ? this.state.p90 : this.state.showBench && this.state.whatSector === "privat" ? this.state.p90P : this.state.showBench && this.state.whatSector === "offentlig" ? this.state.p90O : 0,
                    y2: this.state.showBench && this.state.whatSector === "all" ? this.state.p75 : this.state.showBench && this.state.whatSector === "privat" ? this.state.p75P : this.state.showBench && this.state.whatSector === "offentlig" ? this.state.p75O : 0,
                    borderColor: 'none',
                    fillColor: '#f2d2b7',
                  },
                  //P75-P50
                  {
                    y: this.state.showBench && this.state.whatSector === "all" ? this.state.p75 : this.state.showBench && this.state.whatSector === "privat" ? this.state.p75P : this.state.showBench && this.state.whatSector === "offentlig" ? this.state.p75O : 0,
                    y2: this.state.showBench && this.state.whatSector === "all" ? this.state.median : this.state.showBench && this.state.whatSector === "privat" ? this.state.medianP : this.state.showBench && this.state.whatSector === "offentlig" ? this.state.medianO : 0,
                    borderColor: 'none',
                    fillColor: '#f2d2b7',
                  },
                  //P25-P50
                  {
                    y: this.state.showBench && this.state.whatSector === "all" ? this.state.p25 : this.state.showBench && this.state.whatSector === "privat" ? this.state.p25P : this.state.showBench && this.state.whatSector === "offentlig" ? this.state.p25O : 0,
                    y2: this.state.showBench && this.state.whatSector === "all" ? this.state.median : this.state.showBench && this.state.whatSector === "privat" ? this.state.medianP : this.state.showBench && this.state.whatSector === "offentlig" ? this.state.medianO : 0,
                    borderColor: 'none',
                    fillColor: '#ccf6e4',
                    /*label: {
                      text: '50',
                      style: {
                        color: '#000',
                        background: '#f2d2b7'
                      },
                    }*/
                  },
                  //P10-P25
                  this.state.whatPercentiles !== "p25" && {
                    y: this.state.showBench && this.state.whatSector === "all" ? this.state.p10 : this.state.showBench && this.state.whatSector === "privat" ? this.state.p10P : this.state.showBench && this.state.whatSector === "offentlig" ? this.state.p10O : 0,
                    y2: this.state.showBench && this.state.whatSector === "all" ? this.state.p25 : this.state.showBench && this.state.whatSector === "privat" ? this.state.p25P : this.state.showBench && this.state.whatSector === "offentlig" ? this.state.p25O : 0,
                    borderColor: 'none',
                    fillColor: '#ccf6e4',
                  },
                  ((this.state.whatPercentiles === "p10" || this.state.whatPercentiles === "all") && this.state.showBench) && {
                    y: this.state.showBench && this.state.whatSector === "all" ? this.state.p10 : this.state.showBench && this.state.whatSector === "privat" ? this.state.p10P : this.state.showBench && this.state.whatSector === "offentlig" ? this.state.p10O : 0,
                    borderColor: 'gray',
                    label: {
                      borderColor: false,
                      style: {
                        color: '#000',
                        background: '#fff0'
                      },
                      text: 'P10'
                    }
                  },
                  ((this.state.whatPercentiles === "p25" || this.state.whatPercentiles === "all") && this.state.showBench) && {
                    y: this.state.showBench && this.state.whatSector === "all" ? this.state.p25 : this.state.showBench && this.state.whatSector === "privat" ? this.state.p25P : this.state.showBench && this.state.whatSector === "offentlig" ? this.state.p25O : 0,
                    borderColor: 'gray',
                    label: {
                      borderColor: false,
                      style: {
                        color: '#000',
                        background: '#fff0'
                      },
                      text: 'P25'
                    }
                  },
                  ((this.state.whatPercentiles === "p25" || this.state.whatPercentiles === "all") && this.state.showBench) && {
                    y: this.state.showBench && this.state.whatSector === "all" ? this.state.p75 : this.state.showBench && this.state.whatSector === "privat" ? this.state.p75P : this.state.showBench && this.state.whatSector === "offentlig" ? this.state.p75O : 0,
                    borderColor: 'gray',
                    label: {
                      borderColor: false,
                      style: {
                        color: '#000',
                        background: '#fff0'
                      },
                      text: 'P75'
                    }
                  },
                  this.state.showBench && {
                    y: this.state.showBench && this.state.whatSector === "all" ? this.state.median : this.state.showBench && this.state.whatSector === "privat" ? this.state.medianP : this.state.showBench && this.state.whatSector === "offentlig" ? this.state.medianO : 0,
                    borderColor: 'gray',
                    label: {
                      borderColor: false,
                      style: {
                        color: '#000',
                        background: '#fff0'
                      },
                      text: 'P50'
                    }
                  },
                  ((this.state.whatPercentiles === "p10" || this.state.whatPercentiles === "all") && this.state.showBench) && {
                    y: this.state.showBench && this.state.whatSector === "all" ? this.state.p90 : this.state.showBench && this.state.whatSector === "privat" ? this.state.p90P : this.state.showBench && this.state.whatSector === "offentlig" ? this.state.p90O : 0,
                    borderColor: 'gray',
                    label: {
                      borderColor: '#fff',
                      style: {
                        color: '#000',
                        background: '#fff'
                      },
                      text: 'P90'
                    }
                  },
                ]
              },
            },
            series: [{
              name: language === "sv" ? "Män" : lang[language].men,
              data: chartMen //filteredMale
            },
            {
              name: language === "sv" ? "Kvinnor" : lang[language].women,
              data: chartWomen //filteredWoman
            }],
          })
          if (this.state.clickedNewGroup) {
            this.setState({
              calculationSeries: [{
                name: language === "sv" ? "Män" : lang[language].men,
                data: chartMen //filteredMale
              },
              {
                name: language === "sv" ? "Kvinnor" : lang[language].women,
                data: chartWomen //filteredWoman
              }],
              filterTitles: [], //Resets filters
              filterCities: []
            })
          }
        }

        //If should show percent but not Hide name
        else if (this.state.showPercent) {

          let chartMenPercent = []
          let chartWomenPercent = []
          chartMenPercent = filteredMaleChart.map(function (el) {
            let xAxisValue = xAxisType === "experience" ? el[exp] : el.age //If should show age or experience in xAxis
            let theSalary = 0
            //  !hideExtra ? theSalary = el[salary] : theSalary = el[salary] - el.addOn
            let salBeforeAddOn = clickedNewGroup ? el[salary] : el.salBeforeAddOn // This saves the original salary to each employee
            if (hideExtra === "hideExtra" && !clickedNewGroup) { theSalary = el.salNoExtra === undefined ? (el.salBeforeAddOn - el.addOn) : el.salNoExtra }
            else if (hideExtra === "hideExtra" && clickedNewGroup) { theSalary = el[salary] - el.addOn }
            else if (hideExtra === "onlyExtra" && !clickedNewGroup) { theSalary = el.addOn }
            else if (hideExtra === "onlyExtra" && clickedNewGroup) { theSalary = el.addOn }
            else if (hideExtra === "includeExtra" && clickedNewGroup) { theSalary = el[salary] }
            else if (hideExtra === "includeExtra" && !clickedNewGroup) { theSalary = el.salNoExtra === undefined ? el.salBeforeAddOn : el.salNoExtra + el.addOn }
            let fillColor = el.fillColor === undefined || el.fillColor === null ? "" : el.fillColor
            return { y: ((theSalary / salaryTotal) * 100).toFixed(1), x: xAxisValue, salNoExtra: el.salNoExtra, salBeforeAddOn: salBeforeAddOn, expNoJitter: el[exp], positionTitle: el.positionTitle, gender: el.gender, fullName: el.fullName, persNr: el.persNr, groupNameArbVardering: el.groupNameArbVardering, age: el.age, city: el.city, salInKr: el[salary], additional: el.additional, addOn: el.addOn, fillColor: fillColor }
          })

          chartWomenPercent = filteredWomanChart.map(function (el) {
            let xAxisValue = xAxisType === "experience" ? el[exp] : el.age //If should show age or experience in xAxis
            let theSalary = 0
            // !hideExtra ? theSalary = el[salary] : theSalary = el[salary] - el.addOn
            let salBeforeAddOn = clickedNewGroup ? el[salary] : el.salBeforeAddOn // This saves the original salary to each employee
            if (hideExtra === "hideExtra" && !clickedNewGroup) { theSalary = el.salNoExtra === undefined ? (el.salBeforeAddOn - el.addOn) : el.salNoExtra }
            else if (hideExtra === "hideExtra" && clickedNewGroup) { theSalary = el[salary] - el.addOn }
            else if (hideExtra === "onlyExtra" && !clickedNewGroup) { theSalary = el.addOn }
            else if (hideExtra === "onlyExtra" && clickedNewGroup) { theSalary = el.addOn }
            else if (hideExtra === "includeExtra" && clickedNewGroup) { theSalary = el[salary] }
            else if (hideExtra === "includeExtra" && !clickedNewGroup) { theSalary = el.salNoExtra === undefined ? el.salBeforeAddOn : el.salNoExtra + el.addOn }
            let fillColor = el.fillColor === undefined || el.fillColor === null ? "" : el.fillColor
            return { y: ((theSalary / salaryTotal) * 100).toFixed(1), x: xAxisValue, salNoExtra: el.salNoExtra, salBeforeAddOn: salBeforeAddOn, expNoJitter: el[exp], positionTitle: el.positionTitle, gender: el.gender, fullName: el.fullName, persNr: el.persNr, groupNameArbVardering: el.groupNameArbVardering, age: el.age, city: el.city, salInKr: el[salary], additional: el.additional, addOn: el.addOn, fillColor: fillColor }
          })

          //Create jitter if years and experience are exactly the same
          jitter(chartWomenPercent, chartMenPercent, 180)  //IMPORTANT!! Jitter function creates a SHALLOW(!) copy. This means that it will automatically update values in chartMenPercent and chartWomenPercent.. So we can not use these for something else

          //Show or hide name
          const nameStatus = this.state.hideName ? "display: none" : "";

          //Get data to use with settings for chart x-axis, but do not change if it is not new group
          let allExperiences = []
          let longestExp = 0
          if (xAxisType === "experience") {
            allExperiences = allInGroup.map(person => person[exp])
            longestExp = Math.max(...allExperiences)
            if (longestExp < 10) longestExp = 10
            if (longestExp > 10 && longestExp < 16) longestExp = 16
          }
          if (xAxisType === "age") {
            allExperiences = allInGroup.map(person => person.age)
            longestExp = Math.max(...allExperiences)
          }
          //If not a new clicked group use the old max experience
          if (this.state.clickedNewGroup) {
            //save highest exp for all employees even filtered
            let allExp = allInGroup.map(person => person[exp])
            let longestExperience = Math.max(...allExp)
            if (longestExperience < 10) longestExperience = 10
            if (longestExperience > 10 && longestExperience < 16) longestExperience = 16
            //save highest age for all employees even filtered
            let allAges = allInGroup.map(person => person.age)
            let longestAge = Math.max(...allAges)
            this.setState({
              longestExp: longestExperience,
              longestAge: longestAge
            })
          }
          if (!clickedNewGroup && xAxisType === "experience") { longestExp = this.state.longestExp }
          if (!clickedNewGroup && xAxisType === "age") { longestExp = this.state.longestAge }

          //Buggfix, if cero and only one person the chart will show a very small dot that is not hoverable. This fixes it.
          /* if (chartMenPercent.length === 1 && chartWomenPercent.length === 0) {
             if (chartMenPercent[0].x === 0) {
               let getChartMenAgain = filteredMaleChart.map(function (el) {
                 let fillColor = el.fillColor === undefined || el.fillColor === null ? "" : el.fillColor
                 return { y: ((el[salary] / salaryTotal) * 100).toFixed(1), x: 0.001, expNoJitter: el[exp], positionTitle: el.positionTitle, gender: el.gender, fullName: el.fullName, persNr: el.persNr, groupNameArbVardering: el.groupNameArbVardering, age: el.age, city: el.city, salInKr: el[salary], additional: el.additional, fillColor: fillColor }
               })
               chartMenPercent = chartMenPercent.concat(getChartMenAgain)
             }
           } if (chartWomenPercent.length === 1 && chartMenPercent.length === 0) {
             if (chartWomenPercent[0].x === 0) {
               let getChartWomenAgain = filteredWomanChart.map(function (el) {
                 let fillColor = el.fillColor === undefined || el.fillColor === null ? "" : el.fillColor
                 return { y: ((el[salary] / salaryTotal) * 100).toFixed(1), x: 0.001, expNoJitter: el[exp], positionTitle: el.positionTitle, gender: el.gender, fullName: el.fullName, persNr: el.persNr, groupNameArbVardering: el.groupNameArbVardering, age: el.age, city: el.city, salInKr: el[salary], additional: el.additional, fillColor: fillColor }
               })
               chartWomenPercent = chartWomenPercent.concat(getChartWomenAgain);
             }
           }*/

          //Set state for chart
          this.setState({
            options: {
              tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                  var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                  if (data.additional === null) { data.additional = "" }
                  let showAdditional = ""
                  data.additional === "" ? showAdditional = "" : showAdditional = `<li><b>${language === "sv" ? "Övrigt" : lang[language].other}</b>: ${data.additional}</li>`
                  let salaryPercent = data.fillColor === "#d5e9ff" || data.fillColor === "#d8f6e7" ? "Bortfiltrerad" : data.y + " %"
                  let salaryName = hideExtra === "onlyExtra" ? (language === "sv" ? "Tillägg" : lang[language].benefits) : (language === "sv" ? "Lön" : lang[language].salary)
                  return `<ul>
                    <li style="${nameStatus}"><b>${language === "sv" ? "Namn" : lang[language].name}</b>: ${data.fullName}</li>
                    <li><b>${salaryName}</b>: ${salaryPercent}</li>
                    <li><b>${language === "sv" ? "År i org" : lang[language].yInOrg}</b>: ${data.expNoJitter}</li>
                    <li><b>${language === "sv" ? "Ålder" : lang[language].age}</b>: ${data.age}</li>
                    <li><b>${language === "sv" ? "Ort" : lang[language].city}</b>: ${data.city}</li>
                    <li style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;overflow-wrap: break-word;max-width: 320px"><b>${language === "sv" ? "Bef" : lang[language].positionShort}</b>: ${data.positionTitle}</li>
                    ${showAdditional}
                    </ul>`
                },
              },
              states: { active: { filter: { type: "none" } } },
              chart: {
                height: 350,
                type: 'scatter',
                animations: {
                  enabled: animationSetting
                },
                zoom: {
                  enabled: true,
                  type: 'x',
                  zoomedArea: {
                    fill: {
                      color: '#54c7b9',
                    },
                    stroke: {
                      color: '#54c7b9',
                      opacity: 0.4,
                      width: 1
                    }
                  }
                },
                toolbar: {
                  show: true,
                  tools: {
                    download: false,
                    zoom: true,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                    customIcons: [{
                      icon: `<div>${language === "sv" ? "Ålder" : lang[language].age}</div>`,
                      index: 5,
                      title: `${language === "sv" ? "Sätt x-axel till ålder" : lang[language].ageXinfo}`,
                      class: this.state.xSetting ? (this.state.xAxisType === "age" ? 'custom-icon-div custom-icon-age custom-icon-div-green' : 'custom-icon-div custom-icon-age') : 'hide',
                      click: function (chart, options, e) {
                        let activeItem = this.state.activeItem
                        this.setState({ xAxisType: "age" })
                        this.handleClick();
                      }.bind(this)
                    }, {
                      icon: hideExtra === "includeExtra" ? `<img width="16px" src=${extraOn} style="margin-left: 5px;margin-top: 2px; padding-left: 2px">` : hideExtra === "hideExtra" ?
                        `
                    <img width="16px" src=${extraOff} style="margin-left: 5px;margin-top: 2px; padding-left: 2px; display: inline-block; margin-right: -6px;">
                    <div style="
                    font-size: 11px;
  line-height: 8px;
  width: 60px;
  text-align: center;
  padding: 2px;
  border-radius: 4px;
  margin-top: 4px;
  display: inline-block;
  padding-right: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  vertical-align: top;
  border-bottom: 1.5px solid #a09e9e;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
  padding-left: 2px;
  font-weight: 500;
  color: #9b9999;
  font-style: italic;
  ">${language === "sv" ? "Exkl tillägg" : lang[language].exclBenVeryShort}</div>
                    ` :
                        `
                    <img width="16px" src=${extraOff} style="margin-left: 5px;margin-top: 2px; padding-left: 2px; display: inline-block; margin-right: -6px;">
                    <div style="
                    font-size: 11px;
  line-height: 8px;
  width: 60px;
  text-align: center;
  padding: 2px;
  border-radius: 4px;
  margin-top: 4px;
  display: inline-block;
  padding-right: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  vertical-align: top;
  border-bottom: 1.5px solid #a09e9e;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
  padding-left: 2px;
  font-weight: 500;
  color: #9b9999;
  font-style: italic;
  ">${language === "sv" ? "Bara tillägg" : lang[language].onlyBenVeryShort}</div>
                    `

                      ,
                      index: 2,
                      title: hideExtra === "includeExtra" ? (language === "sv" ? 'Visar lön inklusive tillägg' : lang[language].iconInclSal) : hideExtra === "hideExtra" ? (language === "sv" ? 'Visar lön exklusive tillägg' : lang[language].iconExclSal) : (language === "sv" ? "Visar tillägg exklusive lön" : lang[language].iconOnlyBen),
                      class: this.state.showHideExtraIcon === "on" || this.state.showHideExtraIcon === "adminOn" ? 'custom-icon extraSalIcon' : "hide",
                      click: function (chart, options, e) {
                        this.hideExtra();
                        this.handleClick();
                      }.bind(this)
                    },
                    {
                      icon: `<img src=${zoomOut} width="15px" style="margin-left: 6px;">`,
                      index: 1,
                      title: language === "sv" ? "Zooma ut" : lang[language].zoomOut,
                      class: 'custom-icon',
                      click: function (chart, options, e) {
                        if (this.state.chartWidth === "500") {
                          this.setState({ chartWidth: "500.1" })
                        }
                        if (this.state.chartWidth === "92%") {
                          this.setState({ chartWidth: "92.01%" })
                        }
                        if (this.state.chartWidth === "92.01%") {
                          this.setState({ chartWidth: "92%" })
                        }
                        else {
                          this.setState({ chartWidth: "500" })
                        }

                      }.bind(this)
                    },
                    {
                      icon: `<div>${language === "sv" ? "År i org." : lang[language].yearInOrgShorten}</div>`,
                      index: 6,
                      title: `${language === "sv" ? "Sätt x-axel till år i organisationen" : lang[language].yearInOrgXinfo}`,
                      class: this.state.xSetting ? (this.state.xAxisType === "age" ? 'custom-icon-div custom-icon-exp custom-icon-div-mright' : 'custom-icon-div custom-icon-exp custom-icon-div-mright custom-icon-div-green') : 'hide',
                      click: function (chart, options, e) {
                        this.setState({ xAxisType: "experience" })
                        this.handleClick();
                      }.bind(this)
                    }, {
                      icon: `<img src=${menuAnalysisIcon} width="13.5px"></img>`,
                      index: 3,
                      title: "Filter",
                      class: this.state.showHideFilterIcon === "on" || this.state.showHideFilterIcon === "adminOn" ? "custom-icon-div custom-icon-exp custom-icon-div-mright moreAnalysisBtn" : "hide",
                      click: function (chart, options, e) {
                        this.state.showFilter ? this.setState({ showFilter: false }) : this.setState({ showFilter: true })
                      }.bind(this)
                    }, {
                      icon: `<img src=${fullscreen} width="15px">`,
                      index: 4,
                      title: language === "sv" ? "Fullskärm" : lang[language].fullscreen,
                      class: 'custom-icon custom-icon-zoom',
                      click: function (chart, options, e) {
                        if (this.state.chartWidth === "500") {
                          this.setState({ chartWidth: "92%", apexPopUp: "apexPopUp" })
                        }
                        else {
                          this.setState({ chartWidth: "500", apexPopUp: "" })
                        }
                      }.bind(this)
                    },
                    ]
                  },
                },
                events: {
                  beforeZoom: (e, { xaxis }) => {
                    return {
                      xaxis: {
                        tickAmount: 1,
                        max: xaxis.max,
                        min: xaxis.min
                      }
                    }
                  },
                  dataPointSelection: (event, chartContext, config) => {
                    let getMen = this.state.series[0].data.slice(0)
                    let getWomen = this.state.series[1].data.slice(0)
                    let clickedPerson = ""
                    if (config.seriesIndex == 0) { //If mens group
                      clickedPerson = getMen[config.dataPointIndex]
                    }
                    if (config.seriesIndex == 1) { //If womens group
                      clickedPerson = getWomen[config.dataPointIndex]
                    }

                    this.setState({
                      clickedPerson: clickedPerson,
                      clickedPersonIndex: config.dataPointIndex,
                      getMen: getMen,
                      getWomen: getWomen,
                      serieIndex: config.seriesIndex,
                      popItUp: true,
                    })
                  }
                },
              },
              xaxis: {
                tickAmount: 5,
                type: 'numeric',
                max: longestExp,
                min: 0,
                ticks: {
                  includeBounds: true,
                },
                labels: {
                  formatter: function (val) {
                    return parseFloat(val).toFixed(0)
                  }
                }
              },
              yaxis: {
                tickAmount: 0,
                labels: {
                  formatter: function (val) {
                    return parseFloat(val).toFixed(0)
                  }
                }
              },
            }
          })
          this.setState({
            series: [{
              name: language === "sv" ? "Män" : lang[language].men,
              data: chartMenPercent //filteredMale
            },
            {
              name: language === "sv" ? "Kvinnor" : lang[language].women,
              data: chartWomenPercent //filteredWoman
            }]
          })
          if (this.state.clickedNewGroup) {
            this.setState({
              calculationSeries: [{
                name: language === "sv" ? "Män" : lang[language].men,
                data: chartMenPercent //filteredMale
              },
              {
                name: language === "sv" ? "Kvinnor" : lang[language].women,
                data: chartWomenPercent //filteredWoman
              }],
              filterTitles: [],
              filterCities: []
            })
          }
        }
        resolve();
      });
    }
    await runHandleClick();
    // --- Fix for making sure excluded, included or only addons is selected without mutating array (not the best fix but it works)
    if (hideExtraBefore !== "includeExtra" && hideExtraBefore !== "") {
      // hideExtraBefore !== "" = If hideExtraBefore is not "" it means that it was not a new group that was clicked
      // hideExtraBefore !== "includeExtra" = If hideExtraBefore is not "includeExtra" it means that we should rerun the function to make it work with excludeExtra or onlyExtra
      this.setState({ clickedNewGroup: false, hideExtra: hideExtraBefore })
      document.documentElement.style.setProperty('--widthExtraSalIcon', '86px')
      document.documentElement.style.setProperty('--margRightExtraSalIcon', '-5px')
      await runHandleClick(); // <-- Ändra här så den gör om med exclude eller onlyExtra!
    }
    // --- End of fix
    this.setState({ clickedNewGroup: false, popItUp: false, popUpEditSalarySetting: false }) //Make sure above is finished before doing this. make it async
  }

  //Register that it is a new group that was clicked on which we need to know for handleClick function
  clickedNewGroup = async () => {
    const clickedGroupStatus = () => {
      return new Promise((resolve) => {
        this.setState({ clickedNewGroup: true })
        resolve();
      });
    }
    await clickedGroupStatus()
    this.handleClick();
  }
  //toggle more save fields
  toggleFields = () => {
    if (this.state.moreFields) {
      this.setState({
        moreFields: false
      })
    } else {
      this.setState({
        moreFields: true
      })
    }
  }

  //Save new comment
  //First add state for fields:
  handleComment = (event) => {
    this.setState({ addComment: event.target.value })
  }
  handleReason = (event) => {
    this.setState({ addReason: event.target.value })
  }
  handleTodo = (event) => {
    this.setState({ addTodo: event.target.value })
  }
  handleDeadline = (event) => {
    this.setState({ addDeadline: event.target.value })
  }
  handleCost = (event) => {
    this.setState({ addCost: event.target.value })
  }
  handleResponsible = (event) => {
    this.setState({ addResponsible: event.target.value })
  }
  toggleFilter = () => { this.state.showFilter ? this.setState({ showFilter: false }) : this.setState({ showFilter: true }) }
  //Then, save comment if clicking save button
  saveNewComment = () => {
    let language = localStorage.getItem('language') ?? 'sv';
    if (this.state.addComment !== "") {
      const saveData = async () => {
        const commentData = {
          notable: this.state.addComment,
          reason: this.state.addReason,
          todo: this.state.addTodo,
          deadline: this.state.addDeadline,
          cost: this.state.addCost,
          responsible: this.state.addResponsible,
          groupName: this.state.groupTitle
        }

        const response = await fetchAuth(`/api/saveEqualComment`, 'POST', JSON.stringify({ data: commentData }));
        let data = await response.json();
        //add todays date to temporary comment,or a previous date if we are doing it for a previous year
        let selectedYear = localStorage.getItem('year')
        if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
        if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
        let d = new Date()
        let month = d.getMonth();
        let day = d.getDate();
        const todaysDate = new Date(selectedYear, month, day);
        const dateNow = todaysDate.toISOString();

        //When saved, add the new comment to the comments array
        const toState = {
          notable: this.state.addComment,
          reason: this.state.addReason,
          todo: this.state.addTodo,
          deadline: this.state.addDeadline,
          cost: this.state.addCost,
          responsible: this.state.addResponsible,
          equalGroup: this.state.groupTitle,
          commentId_PK: data,
          dateAdded: dateNow
        }
        const commentList = this.state.comments.concat(toState);

        //Sort comments by descending date
        const commentsByDate = commentList.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)
        console.log(commentsByDate)

        //Reset fields
        if (typeof data == 'number') { //it is a number because we returned the id for our last inserted item (the last id is in the data variable)
          this.setState({
            addComment: "",
            addReason: "",
            addTodo: "",
            addDeadline: "",
            addCost: "",
            addResponsible: "",
            comments: commentsByDate //add the new comment to the comments array
          })

        } else { alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave) }
      };
      saveData();
    } else {
      alert(language === "sv" ? 'Du kan inte spara en tom notering. Vänligen fyll i fältet "Noterbart".' : lang[language].noEmptyComment)
    }
  }

  //Set state for edited comments, will be used for saving with saveButton function
  editComment = (event) => {
    const column = event.target.name //what column should be saved
    const commentId_PK = event.target.id //For what comment ID
    const text = event.target.value //what text should be saved
    const editedComments = this.state.comments

    //Look into state, if it is in the array, then merch the comments. If not in array, then add it 
    let updateComments = editedComments.map(el => {
      if (el.commentId_PK == commentId_PK) {
        return Object.assign({}, el, { [column]: text, updated: 'Yes' })
      } //Changes the correct column text. Updated Yes is there to know which comments have been updated when we are saving
      return el
    });

    this.setState({ comments: updateComments })
    //Update save button
    if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }
  }

  //Save changes in comments
  saveButton = () => {
    let language = localStorage.getItem('language') ?? 'sv';
    //send only updatedComments to server for saving (and also the deleted comments)
    const updatedComments = this.state.comments.filter(el => el.updated)
    const deletedComments = this.state.deletedComments

    //Query DB HERE (Use deletedComments and updatedComments)
    const saveData = async () => {
      const commentData = {
        changes: updatedComments,
        deleted: deletedComments
      }

      const response = await fetchAuth(`/api/saveEqualCommentChanges`, 'POST', JSON.stringify({ data: commentData }));
      let data = await response.json();
      if (data == 'Saved') { //it is a number because we returned the id for our last inserted item (the last id is in the data variable)

        this.setState({ saveButtonStatus: "Sparat" })

      } else { alert(language === "sv" ? "Ett problem uppstod vid sparningen. Vänligen prova igen." : lang[language].errorSave) }
    };
    saveData();

    //Remove property updated if saved
    const removeUpdatedProperty = this.state.comments.map(el => {
      if (el.updated) {
        delete el.updated;
        return el;
      }
      return el;
    });
    this.setState({ comments: removeUpdatedProperty })
  }

  deleteComment = (event) => {
    const commentId_PK = event.target.id
    const removedComment = this.state.comments.filter(el => el.commentId_PK == commentId_PK);  //Get the deleted comment
    const listWithoutComment = this.state.comments.filter(el => el.commentId_PK != commentId_PK); //create new list without the deleted comment
    let del = this.state.deletedComments
    if (removedComment[0].reuseComment !== "Yes") { del = this.state.deletedComments.concat(removedComment) } //add removedComment to deletedComments array:

    this.setState({
      comments: listWithoutComment, //set state for new array without the deleted comment.
      deletedComments: del //set state for the comment/s that should be deleted, so it wont be deleted until we hit the save button
    })
    if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }
  }

  filterGroup = () => {
    this.setState({ filter: 'group' })
  }

  filterAll = () => {
    this.setState({ filter: 'all' })
  }

  reuseComment = (event) => {
    const commentId_PK = event.target.id

    //get data for comment from state
    const commentData = this.state.commentsLastYear.filter((comment) => comment.commentId_PK == commentId_PK)
    //Get selected year from user
    let selectedYear = localStorage.getItem('year')
    if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
    if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
    let d = new Date()
    let month = d.getMonth();
    let day = d.getDate();
    const todaysDate = new Date(selectedYear, month, day);
    const dateNow = todaysDate.toISOString(); //add todays date to temporary comment

    //Add the new comment to the comments array
    const toState = {
      notable: commentData[0].notable,
      reason: commentData[0].reason,
      todo: commentData[0].todo,
      deadline: commentData[0].deadline,
      cost: commentData[0].cost,
      responsible: commentData[0].responsible,
      equalGroup: commentData[0].equalGroup,
      commentId_PK: commentId_PK, //We use this for reuseId
      dateAdded: dateNow,
      updated: 'Yes',
      reuseComment: 'Yes',
      analysis: commentData[0].analysis
    }
    const commentList = this.state.comments.concat(toState);

    //Sort comments by descending date
    const commentsByDate = commentList.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)

    //get all comments except this comment (to use for setting new state)
    const pastYearsComments = this.state.commentsLastYear.filter((comment) => comment.commentId_PK != commentId_PK)

    //also remove from allComments
    const allComments = this.state.allComments.filter((comment) => comment.commentId_PK != commentId_PK)

    //Add comment to new state and remove from the past
    this.setState({
      comments: commentsByDate, //add the new comment to the comments array
      commentsLastYear: pastYearsComments,
      allComments: allComments
    })

    if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }
  }

  showReusePopUp = () => {
    if (this.state.popUp === false) {
      this.setState({ popUp: true }) //Shows popUp
    }
  }

  togglePop = () => {
    this.state.popUp ? this.setState({ popUp: false }) : this.setState({ popUp: true })
  }
  togglePopInfo = () => {
    this.state.popUpInfo ? this.setState({ popUpInfo: false }) : this.setState({ popUpInfo: true })
  }

  reuseAll = () => {
    this.setState({ popUp: false }) //Hide popUp

    const comments = this.state.commentsLastYear.filter((comment) => (comment.todo == null || comment.todo == "")) //We filter out the ones with a todo, because these should be evaluated and can not be used for this year
    const commentsWithTodo = this.state.commentsLastYear.filter((comment) => (comment.todo != null && comment.todo !== "")) //We use this to temporary update the commentsLastYearState
    //Also filter out the all comments array and not only
    const commentsShouldBeRemoved = this.state.commentsLastYear.filter((comment) => (comment.todo === null || comment.todo == "")) //1. get what comments to be reused
    const removeFromAllComments = this.state.allComments.filter(obj2 => !commentsShouldBeRemoved.some(obj1 => obj1.commentId_PK === obj2.commentId_PK)); //2. remove those comments from all comments

    //Get selected year from user
    let selectedYear = localStorage.getItem('year')
    if (selectedYear === null || selectedYear === "null" || selectedYear === undefined || selectedYear.length !== 4) { selectedYear = new Date().getFullYear() }
    if (selectedYear !== null && selectedYear !== "null" && selectedYear !== undefined && selectedYear.length === 4) { selectedYear = Number(selectedYear) }
    let d = new Date()
    let month = d.getMonth();
    let day = d.getDate();
    const todaysDate = new Date(selectedYear, month, day);
    const dateNow = todaysDate.toISOString(); //add todays date to temporary comment

    let addedYes = comments.map(el => {
      return Object.assign({}, el, { updated: 'Yes', dateAdded: dateNow, reuseComment: 'Yes' })
    }); //Changes the correct column text. Updated Yes is there to know which comments have been updated when we are saving
    const commentList = this.state.comments.concat(addedYes);
    const commentsByDate = commentList.sort((a, b) => b.dateAdded > a.dateAdded ? 1 : -1)

    this.setState({
      comments: commentsByDate, //add the new comment to the comments array
      commentsLastYear: commentsWithTodo, //Empty last years comments. 
      allComments: removeFromAllComments,
    })

    if (this.state.saveButtonStatus === "Sparat") { this.setState({ saveButtonStatus: "Spara" }) }
  }

  noGroupWarning = () => {
    let language = localStorage.getItem('language') ?? 'sv';
    alert(language === "sv" ? "Ingen grupp vald. Klicka på en grupp i listan som noteringen kan knytas till. Försök sedan igen." : lang[language].noSelectedGroupComment)
  }

  toggleOldComments = () => {
    this.state.showOldComments ? this.setState({ showOldComments: false }) : this.setState({ showOldComments: true })
  }

  filterWorkClick = async (event) => {
    let language = localStorage.getItem('language') ?? 'sv';
    let waitForCalculations = (event) => {
      return new Promise((resolve, reject) => {
        /*
        --- The function works like this ---
        1. We have the following arrays in State: 
        a) .series - All employee data. Used for the chart but not for our calculations. We only make changes to fillColor here
        b) .calculationSeries - All employee data minus filter. Used for calculations but not chart. We here remove and put back employees
        c) .filterTitles - this is only the tiltes of all employees that is filtered out in calculationSeries
        d) .filterCities - this is only the cities of all employees that is filtered out in calculationSeries
    
    Do it like this:
      If first click, remove:
        1. Add the position to .filterTitles
        2. Remove the employees with the same position from the .calculations array
        3. Add fillColor with low opacity to the employees with same position in .series array
     If second click, add:
        1. Remove position from .filterTitles
        2. Get employees from .series array that has same position, Add them to the .calculations array
        3. Add fillColor with high opacity to the employees with same position in .series array
    
      For City we are doing the exact same thing as above, but instead of checking, adding and removing into .filterTitles we are updating .filterCities instead
    
        FOR ORT
        If first click (the city was not in filterCity array), remove:
        1. Add city to .filterCity
        2. Remove the employees with the same city from the .calculations array
    
    
    
        */
        let type = event.currentTarget.id.slice(0, 1) //If city:"C", if workPosition: "W"
        let selectedWork = event.currentTarget.id.slice(1) //We have added "W" or "C" to not interfere the id with other id:s and to let us know if it is city or workposition that should be filtered. This removes the first letter.
        let filterList = this.state.filterTitles.slice(); //copy of array
        let filterCity = this.state.filterCities.slice(); //copy of array
        let filterListNow = []
        let calculationSeries = []
        let men = this.state.series[0].data
        let women = this.state.series[1].data
        let menCalculated = this.state.calculationSeries[0].data
        let womenCalculated = this.state.calculationSeries[1].data

        //If work position should be filtered
        if (type === "W") {
          // IF SECOND CLICK - the position was already in filterList. Therefore add it back to chart and calculations
          if (filterList.includes(selectedWork)) {
            //1. Remove position from .filterTitles
            filterListNow = filterList.filter(item => item !== selectedWork);
            this.setState({ filterTitles: filterListNow })

            //2. Get employees from .series array that has same position, Add them to the .calculations array
            // First check if they are in the .filterCities array, if they are, they should still be filtered so then do not add them back to calculations array and do not change fillColor
            let addMenToCalculations = []
            let addWomenToCalculations = []
            if (filterCity.length > 0) {
              addMenToCalculations = men.filter(person => person.positionTitle === selectedWork && !filterCity.includes(person.city)) //<-- OBS! Funkar inte, för om vi gör detta med fler än en stad så kommer detta bara "Is not city" bara gälla för den stad vi loopar just nu, men nästa stad kommer att funka
              addWomenToCalculations = women.filter(person => person.positionTitle === selectedWork && !filterCity.includes(person.city))
            } else {
              addMenToCalculations = men.filter(person => person.positionTitle === selectedWork)
              addWomenToCalculations = women.filter(person => person.positionTitle === selectedWork)
            }
            let allMen = menCalculated.concat(addMenToCalculations);
            let allWomen = womenCalculated.concat(addWomenToCalculations);
            calculationSeries = [{
              name: language === "sv" ? "Män" : lang[language].men,
              data: allMen
            },
            {
              name: language === "sv" ? "Kvinnor" : lang[language].women,
              data: allWomen
            }]
            this.setState({ calculationSeries: calculationSeries })

            //3. Add fillColor with high opacity to the employees with same position in .series array
            // First check if they are in the .filterCitiesArray, if they are, they should still be filtered meaning they should not be added with high opacity

            for (let i = 0; i < men.length; i++) {
              if (filterCity.length > 0) {
                if (men[i].positionTitle === selectedWork && !filterCity.includes(men[i].city)) {
                  men[i].fillColor = "#218ef7"
                }
              }
              else {
                if (men[i].positionTitle === selectedWork) {
                  men[i].fillColor = "#218ef7"
                }
              }
            }
            for (let i = 0; i < women.length; i++) {
              if (filterCity.length > 0) {
                if (women[i].positionTitle === selectedWork && !filterCity.includes(women[i].city)) {
                  women[i].fillColor = "#38db86"
                }
              }
              else {
                if (women[i].positionTitle === selectedWork) {
                  women[i].fillColor = "#38db86"
                }
              }
            }
            let newSeries = [{
              name: language === "sv" ? "Män" : lang[language].men,
              data: men
            },
            {
              name: language === "sv" ? "Kvinnor" : lang[language].women,
              data: women
            }]
            let chartWidth = this.state.chartWidth === "500" ? "500.1" : this.state.chartWidth === "500.1" ? "500" : this.state.chartWidth === "92%" ? "92.01%" : this.state.chartWidth === "92.01%" ? "92%" : "500" //Forces chart to update
            this.setState({ chartWidth: chartWidth, series: newSeries })
          }
          // IF FIRST CLICK - the position was not in the filterList. Therefore remove it from chart and calculations
          else {
            //1. Add the position to .filterTitles
            filterListNow = filterList.concat(selectedWork);
            this.setState({ filterTitles: filterListNow })

            //2. Remove the employees with the same position from the .calculations array
            let menCalculatedEmpRemoved = menCalculated.filter(person => person.positionTitle !== selectedWork)
            let womenCalculatedEmpRemoved = womenCalculated.filter(person => person.positionTitle !== selectedWork)
            calculationSeries = [{
              name: language === "sv" ? "Män" : lang[language].men,
              data: menCalculatedEmpRemoved
            },
            {
              name: language === "sv" ? "Kvinnor" : lang[language].women,
              data: womenCalculatedEmpRemoved
            }]
            this.setState({ calculationSeries: calculationSeries })

            //3. Add fillColor with low opacity to the employees with same position in .series array
            for (let i = 0; i < men.length; i++) {
              if (men[i].positionTitle === selectedWork) {
                men[i].fillColor = "#d5e9ff"
              }
            }
            for (let i = 0; i < women.length; i++) {
              if (women[i].positionTitle === selectedWork) {
                women[i].fillColor = "#d8f6e7"
              }
            }
            let newSeries = [{
              name: language === "sv" ? "Män" : lang[language].men,
              data: men
            },
            {
              name: language === "sv" ? "Kvinnor" : lang[language].women,
              data: women
            }]
            let chartWidth = this.state.chartWidth === "500" ? "500.1" : this.state.chartWidth === "500.1" ? "500" : this.state.chartWidth === "92%" ? "92.01%" : this.state.chartWidth === "92.01%" ? "92%" : "500" //Forces chart to update
            this.setState({ chartWidth: chartWidth, series: newSeries })
          }
        }

        //If City should be filtered
        if (type === "C") {
          // IF SECOND CLICK - the city was already in filterCities array. Therefore add it back to chart and calculations
          if (filterCity.includes(selectedWork)) {
            //1. Remove position from .filterCities
            filterListNow = filterCity.filter(item => item !== selectedWork);
            this.setState({ filterCities: filterListNow })

            //2. Get employees from .series array that has same city, Add them to the .calculations array
            // First check if they are in the .filterTitles array, if they are, they should still be filtered so then do not add them back to calculations array and do not change fillColor
            let addMenToCalculations = []
            let addWomenToCalculations = []
            if (filterList.length > 0) {
              addMenToCalculations = men.filter(person => person.city === selectedWork && !filterList.includes(person.positionTitle))
              addWomenToCalculations = women.filter(person => person.city === selectedWork && !filterList.includes(person.positionTitle))
            } else {
              addMenToCalculations = men.filter(person => person.city === selectedWork)
              addWomenToCalculations = women.filter(person => person.city === selectedWork)
            }

            let allMen = menCalculated.concat(addMenToCalculations);
            let allWomen = womenCalculated.concat(addWomenToCalculations);
            calculationSeries = [{
              name: language === "sv" ? "Män" : lang[language].men,
              data: allMen
            },
            {
              name: language === "sv" ? "Kvinnor" : lang[language].women,
              data: allWomen
            }]
            this.setState({ calculationSeries: calculationSeries })

            //3. Add fillColor with high opacity to the employees with same position in .series array
            // First check if they are in the .filterCitiesArray, if they are, they should still be filtered meaning they should not be added with high opacity
            for (let i = 0; i < men.length; i++) {
              if (filterList.length > 0) {
                if (men[i].city === selectedWork && !filterList.includes(men[i].positionTitle)) {
                  men[i].fillColor = "#218ef7"
                }
              }
              else {
                if (men[i].city === selectedWork) {
                  men[i].fillColor = "#218ef7"
                }
              }
            }
            for (let i = 0; i < women.length; i++) {
              if (filterList.length > 0) {
                if (women[i].city === selectedWork && !filterList.includes(women[i].positionTitle)) {
                  women[i].fillColor = "#38db86"
                }
              }
              else {
                if (women[i].city === selectedWork) {
                  women[i].fillColor = "#38db86"
                }
              }
            }
            let newSeries = [{
              name: language === "sv" ? "Män" : lang[language].men,
              data: men
            },
            {
              name: language === "sv" ? "Kvinnor" : lang[language].women,
              data: women
            }]
            let chartWidth = this.state.chartWidth === "500" ? "500.1" : this.state.chartWidth === "500.1" ? "500" : this.state.chartWidth === "92%" ? "92.01%" : this.state.chartWidth === "92.01%" ? "92%" : "500" //Forces chart to update
            this.setState({ chartWidth: chartWidth, series: newSeries })
          }
          // IF FIRST CLICK - the position was not in the filterCity. Therefore remove it from chart and calculations
          else {
            //1. Add the position to .filterCity
            filterListNow = filterCity.concat(selectedWork);
            this.setState({ filterCities: filterListNow })

            //2. Remove the employees with the same position from the .calculations array
            let menCalculatedEmpRemoved = menCalculated.filter(person => person.city !== selectedWork)
            let womenCalculatedEmpRemoved = womenCalculated.filter(person => person.city !== selectedWork)
            calculationSeries = [{
              name: language === "sv" ? "Män" : lang[language].men,
              data: menCalculatedEmpRemoved
            },
            {
              name: language === "sv" ? "Kvinnor" : lang[language].women,
              data: womenCalculatedEmpRemoved
            }]
            this.setState({ calculationSeries: calculationSeries })

            //3. Add fillColor with low opacity to the employees with same position in .series array
            for (let i = 0; i < men.length; i++) {
              if (men[i].city === selectedWork) {
                men[i].fillColor = "#d5e9ff"
              }
            }
            for (let i = 0; i < women.length; i++) {
              if (women[i].city === selectedWork) {
                women[i].fillColor = "#d8f6e7"
              }
            }
            let newSeries = [{
              name: language === "sv" ? "Män" : lang[language].men,
              data: men
            },
            {
              name: language === "sv" ? "Kvinnor" : lang[language].women,
              data: women
            }]
            let chartWidth = this.state.chartWidth === "500" ? "500.1" : this.state.chartWidth === "500.1" ? "500" : this.state.chartWidth === "92%" ? "92.01%" : this.state.chartWidth === "92.01%" ? "92%" : "500" //Forces chart to update
            this.setState({ chartWidth: chartWidth, series: newSeries })
          }
        }
        resolve();
      });
    }

    //Run calculations and then update statistics
    await waitForCalculations(event)
    this.handleClick()
  }

  render() {
    let language = localStorage.getItem('language') ?? 'sv';
    const listMen = this.state.maleEmployees
    const listWomen = this.state.womenEmployees
    const listAll = listMen.concat(listWomen); //All people
    const onlyGroups = listAll.map(person => person.groupNameArbVardering);
    const uniqueGroups = onlyGroups.filter((value, index, self) => self.indexOf(value) === index).sort();

    let comments = language === "sv" ? "Inga kommentarer för senaste året." : lang[language].noCommentsFromThisYear

    if (this.state.comments.length > 0) {
      comments = this.state.comments.map((comment) => {
        if (this.state.filter === "all") { //This is the filter
          return <tr className="trStyleLine" id={comment.commentId_PK} key={comment.commentId_PK}>
            <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange vad som är noterbart" : lang[language].notable} name="notable" id={comment.commentId_PK} value={comment.notable} /></td>
            <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange orsak" : lang[language].reason} name="reason" id={comment.commentId_PK} value={comment.reason} /></td>
            <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange åtgärd (vid behov)" : lang[language].action} name="todo" id={comment.commentId_PK} value={comment.todo} /></td>
            <td className="thpadding"><input type="date" value={comment.deadline} onChange={this.editComment} name="deadline" id={comment.commentId_PK} /></td>
            <td className="thpadding" style={{ width: "8%" }}><TextareaAutosize className="autoHeightText" placeholder="0" value={comment.cost} onChange={this.editComment} name="cost" id={comment.commentId_PK} /></td>
            <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange ansvarig" : lang[language].responsible} name="responsible" id={comment.commentId_PK} value={comment.responsible} /></td>
            <td className="thpadding remove"><img className="checkFinish" src={remove} alt="" style={{ width: 20, marginTop: -20, filter: 'grayscale(100%)', opacity: 0.75 }} id={comment.commentId_PK} onClick={this.deleteComment} /></td>
          </tr>
        } else if (comment.equalGroup === this.state.groupTitle) { //This is the filter
          return <tr className="trStyleLine" id={comment.commentId_PK} key={comment.commentId_PK}>
            <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange vad som är noterbart" : lang[language].notable} name="notable" id={comment.commentId_PK} value={comment.notable} /></td>
            <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange orsak" : lang[language].reason} name="reason" id={comment.commentId_PK} value={comment.reason} /></td>
            <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange åtgärd (vid behov)" : lang[language].action} name="todo" id={comment.commentId_PK} value={comment.todo} /></td>
            <td className="thpadding"><input type="date" value={comment.deadline} onChange={this.editComment} name="deadline" id={comment.commentId_PK} /></td>
            <td className="thpadding" style={{ width: "8%" }}><TextareaAutosize className="autoHeightText" placeholder="0" value={comment.cost} onChange={this.editComment} name="cost" id={comment.commentId_PK} /></td>
            <td className="thpadding"><TextareaAutosize onChange={this.editComment} className="autoHeightText" placeholder={language === "sv" ? "Ange ansvarig" : lang[language].responsible} name="responsible" id={comment.commentId_PK} value={comment.responsible} /></td>
            <td className="thpadding remove"><img className="checkFinish" src={remove} alt="" style={{ width: 20, marginTop: -20, filter: 'grayscale(100%)', opacity: 0.75 }} id={comment.commentId_PK} onClick={this.deleteComment} /></td>
          </tr>
        }
      });
    }

   // let pastComments = language === "sv" ? "Inga kommentarer från tidigare år." : lang[language].noCommentsPastYear
   let pastComments = ""
    if (this.state.commentsLastYear.length > 0) { // FILTER PAST YEARS COMMENTS
      pastComments = this.state.commentsLastYear.map((comment) => {
        if (this.state.filter === "all") { //This is the filter
          return <tr className="trStyleLine" id={comment.commentId_PK} key={comment.commentId_PK}>
            <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="notable" id={comment.commentId_PK} value={comment.notable} /></td>
            <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="reason" id={comment.commentId_PK} value={comment.reason} /></td>
            <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="todo" id={comment.commentId_PK} value={comment.todo} readOnly/></td>
            <td className="thpadding">{comment.deadline == null ? "" : <input type="date" value={comment.deadline} name="deadline" id={comment.commentId_PK} />}</td>
            <td className="thpadding" style={{ width: "8%" }}>{comment.cost == null ? "" : <TextareaAutosize className="autoHeightText" placeholder="" value={comment.cost} name="cost" id={comment.commentId_PK} />}{comment.cost == null ? "" : ""}</td>
            <td className="thpadding">{comment.todo != null && comment.todo.length > 0 ? <span className="noReuse">{language === "sv" ? "Utvärderas" : lang[language].evaluates}</span> : this.state.comments.some(e => e.reuseId === comment.commentId_PK) ? <><span className="noReuse">{language === "sv" ? "Återanvänd" : lang[language].reuse}</span><span style={{ display: "block", marginTop: 15, color: "#3f9985", textDecoration: "underline", cursor: "pointer" }} onClick={this.reuseComment} id={comment.commentId_PK}>{language === "sv" ? "Återanvänd igen" : lang[language].reuseAgain}</span></> : <span id={comment.commentId_PK} onClick={this.reuseComment} className="lastYearButton">{language === "sv" ? "Återanvänd" : lang[language].reuse}</span>}</td>
            <td className="thpadding remove"></td>
          </tr>
        } else if (comment.equalGroup === this.state.groupTitle) { //This is the filter
          return <tr className="trStyleLine" id={comment.commentId_PK} key={comment.commentId_PK}>
            <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="notable" id={comment.commentId_PK} value={comment.notable} /></td>
            <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="reason" id={comment.commentId_PK} value={comment.reason} /></td>
            <td className="thpadding"><TextareaAutosize className="autoHeightText" placeholder="" name="todo" id={comment.commentId_PK} value={comment.todo} readOnly/></td>
            <td className="thpadding">{comment.deadline == null ? "" : <input type="date" value={comment.deadline} name="deadline" id={comment.commentId_PK} />}</td>
            <td className="thpadding" style={{ width: "8%" }}>{comment.cost == null ? "" : <TextareaAutosize className="autoHeightText" placeholder="" value={comment.cost} name="cost" id={comment.commentId_PK} />}{comment.cost == null ? "" : ""}</td>
            <td className="thpadding">{comment.todo != null && comment.todo.length > 0 ? <span className="noReuse">{language === "sv" ? "Utvärderas" : lang[language].evaluates}</span> : this.state.comments.some(e => e.reuseId === comment.commentId_PK) ? <><span className="noReuse">{language === "sv" ? "Återanvänd" : lang[language].reuse}</span><span style={{ display: "block", marginTop: 15, color: "#3f9985", textDecoration: "underline", cursor: "pointer" }} onClick={this.reuseComment} id={comment.commentId_PK}>{language === "sv" ? "Återanvänd igen" : lang[language].reuseAgain}</span></> : <span id={comment.commentId_PK} onClick={this.reuseComment} className="lastYearButton">{language === "sv" ? "Återanvänd" : lang[language].reuse}</span>}</td>
            <td className="thpadding remove"></td>
          </tr>
        }
      });
    }
    let filterTitles = this.state.filterTitlesToRender.map((item) => {
      return <li className={this.state.filterTitles.includes(item) ? "analysisItemGrey" : "analysisListItem"} key={"W" + item} id={"W" + item} onClick={this.filterWorkClick}>
        <div className={this.state.filterTitles.includes(item) ? "analysisListWork strikeThrough" : "analysisListWork"} style={{ textAlign: "left" }}>
          <img src={this.state.filterTitles.includes(item) ? uncheckImg : checkedImg} style={{ width: 16, marginRight: 5, verticalAlign: "top" }} />
          <span className="filterTitleName">{item}</span>
        </div>
      </li>
    });
    let filterCities = this.state.filterCitiesToRender.map((item) => {
      return <li className={this.state.filterCities.includes(item) ? "analysisItemGrey" : "analysisListItem"} key={"C" + item} id={"C" + item} onClick={this.filterWorkClick}>
        <div className={this.state.filterCities.includes(item) ? "analysisListWork strikeThrough" : "analysisListWork"} style={{ textAlign: "left" }}>
          <img src={this.state.filterCities.includes(item) ? uncheckImg : checkedImg} style={{ width: 16, marginRight: 5, verticalAlign: "top" }} />
          <span className="filterTitleName">{item}</span></div>
      </li>
    });

    return (
      <>
        <Prompt
          when={this.state.saveButtonStatus === "Spara" && this.state.showChangesPrompt !== "No"}
          message={language === "sv" ? "Du har gjort ändringar utan att spara. Är du säker på att du vill fortsätta?" : lang[language].changesMadeSave}
        />
        <TopNav />
        <Menu />
        <div className="container">
          <Information step={this.state.benchActive ? "analysLikaMarknad" : "analysLika"} />

          <button className={this.state.saveButtonStatus === "Spara" ? "saveFixedButton" : "saveFixedButton savedFixedButtonSparat"} onClick={this.state.saveButtonStatus === "Spara" ? this.saveButton : ""}>{language === "sv" ? this.state.saveButtonStatus : (this.state.saveButtonStatus === "Spara" ? lang[language].save : lang[language].saved)}</button>

          <div className="leftAndRightContainer">
            <div className="leftList">
              <h3 style={{ marginTop: 6, paddingBottom: 3, fontWeight: 800, paddingTop: 10, fontSize: 18.6 }}>{language === "sv" ? "Välj grupp att analysera" : lang[language].equalChooseGroup}</h3>
              <ul style={{ overflowY: 'auto', height: 730, fontSize: 15.6, letterSpacing: -0.2 }}>
                {uniqueGroups.map(group =>
                  <li className={this.state.activeItem === `${group}` ? "list2 list2-active" : "list2"} key={group} id={group} onClick={this.handleClick}>{group}</li>
                )}
              </ul>
            </div>
            <div className="rightContainer">
              <h2 style={{ fontWeight: 900, paddingTop: 10 }}>{this.state.groupTitle}</h2>
              <div className="tableContainer">
                <div className="employees">
                  <span className="tableHeader" style={{ paddingBottom: 10 }}>{language === "sv" ? "Antal anställda" : lang[language].equalNrOfWorkers}</span>
                  <table style={{ display: "inline", margin: "auto" }}>
                    <tr>
                      <th>{language === "sv" ? "Män" : lang[language].men}</th>
                      <th>{language === "sv" ? "Kvinnor" : lang[language].women}</th>
                      <th>{language === "sv" ? "Totalt" : lang[language].total}</th>
                    </tr>
                    <tr>
                      <td>{this.state.nrOfMen}</td>
                      <td>{this.state.nrOfWomen}</td>
                      <td>{this.state.nrTotal}</td>
                    </tr>

                  </table>
                </div>

                <div className="salaries">
                  <span className="tableHeader" style={{ paddingBottom: 10 }}>{this.state.hideExtra === "onlyExtra" ? language === "sv" ? "Tillägg & förmåner" : lang[language].benefits : language === "sv" ? "Medellön" : lang[language].averageSal}</span>
                  <table style={{ display: "inline", margin: "auto" }}>
                    <tr>
                      <th>{language === "sv" ? "Män" : lang[language].men}</th>
                      <th>{language === "sv" ? "Kvinnor" : lang[language].women}</th>
                      <th>{language === "sv" ? "Totalt" : lang[language].total}</th>
                      <th>{language === "sv" ? "Diff. kvinnor" : lang[language].equalDiff}</th>
                    </tr>
                    <tr>
                      <td>{parseInt(this.state.avSalMen).toLocaleString()}</td>
                      <td>{parseInt(this.state.avSalWomen).toLocaleString()}</td>
                      <td>{parseInt(this.state.avSalTotal).toLocaleString()}</td>
                      <td style={{ cursor: "pointer" }} onClick={this.showDiffPercent}>{this.state.showDiffPercent ? this.state.diffWomenPercent + "%" : this.state.diffWomen.toLocaleString()}</td>
                    </tr>
                  </table>
                </div>
                <div className={this.state.spridningPopUp ? "spridningLika spridningLikaBackgroundDark" : "spridningLika"}>
                  <span className="tableHeader" style={{ paddingBottom: 10 }}>{language === "sv" ? "Spridning" : lang[language].spread}</span>
                  <img onClick={this.spridningPopUp} src={arrowDown} className={this.state.spridningPopUp ? "spridningArrowUp" : ""} style={{ width: 13, height: 10.5, cursor: "pointer", padding: 10, verticalAlign: "middle" }} />
                </div>
                <div className={this.state.spridningPopUp ? "popUpMoreInfoLika" : "hide"}>
                  <table style={{ display: "inline", margin: "auto" }}>
                    <tr>
                      <th></th>
                      <th>{this.state.hideExtra === "onlyExtra" ? language === "sv" ? "Lägsta tillägg" : lang[language].lowestBen : language === "sv" ? "Lägsta lön" : lang[language].lowestSaL}</th>
                      <th>{this.state.hideExtra === "onlyExtra" ? language === "sv" ? "Högsta tillägg" : lang[language].highestBen : language === "sv" ? "Högsta lön" : lang[language].highestSal}</th>
                      <th>{language === "sv" ? "Spridningskvot" : lang[language].spreadRatio}</th>
                    </tr>
                    <tr style={{ borderBottom: "1px solid" }}>
                      <td>{language === "sv" ? "Män" : lang[language].men}</td>
                      <td>{this.state.lowestSalaryMen.toLocaleString()}</td>
                      <td>{this.state.highestSalaryMen.toLocaleString()}</td>
                      <td>{this.state.spridningskvotMen}</td>
                    </tr>
                    <tr>
                      <td>{language === "sv" ? "Kvinnor" : lang[language].women}</td>
                      <td>{this.state.lowestSalaryWomen.toLocaleString()}</td>
                      <td>{this.state.highestSalaryWomen.toLocaleString()}</td>
                      <td>{this.state.spridningskvotWomen}</td>
                    </tr>
                  </table>
                  <table style={{ display: "inline", paddingBottom: 10, verticalAlign: "bottom" }} className={this.state.moreThan20 !== "" ? "" : "hideImportant"}>
                    {/* Show percentiles if more than 20 employees in one group */}
                    <tr>
                      <th></th>
                      <th style={{ width: 76 }}>P10</th>
                      <th style={{ width: 76 }}>P90</th>
                      <th>{language === "sv" ? "Spridningskvot" : lang[language].spreadRatio}</th>
                    </tr>
                    <tr style={{ borderBottom: "1px solid" }} className={this.state.moreThan20 === "men" || this.state.moreThan20 === "both" ? "" : "hide"}>
                      <td>{language === "sv" ? "Män" : lang[language].men}</td>
                      <td>{this.state.moreMen10.toLocaleString()}</td>
                      <td>{this.state.moreMen90.toLocaleString()}</td>
                      <td>{this.state.moreMenKvot}</td>
                    </tr>
                    <tr className={this.state.moreThan20 === "women" || this.state.moreThan20 === "both" ? "" : "hide"}>
                      <td>{language === "sv" ? "Kvinnor" : lang[language].women}</td>
                      <td>{this.state.moreWomen10.toLocaleString()}</td>
                      <td>{this.state.moreWomen90.toLocaleString()}</td>
                      <td>{this.state.moreWomenKvot}</td>
                    </tr>
                    {/* End of Show percentiles if more than 20 employees in one group */}
                  </table>
                  <div className={this.state.moreThan20 === "" ? "" : "hideImportant"} style={{ display: "inline-block", verticalAlign: "bottom", marginLeft: 8 }}><div style={{ fontSize: 14, fontWeight: 600, marginBottom: 12 }}>{language === "sv" ? "Kvot diff." : lang[language].ratioDiff}</div><div style={{
                    marginBottom: 11, fontSize: 14.6, textAlign: "center", padding: 3,
                    border: "1px solid",
                    borderRadius: 50
                  }}>{this.state.spridningDiff}</div>
                  </div>
                  <span class={this.state.moreThan20 === "" ? "questionMark2" : "questionMarkMore"} onClick={this.togglePopInfo}>?</span>
                  <div className={this.state.popUpInfo ? "spridningInfoPop" : "hide"}>
                    <img alt="" src={exitImg} class="hide-box" onClick={this.togglePopInfo} />
                    <h4 style={{ fontSize: 15.6 }}>{language === "sv" ? "Lönespridning" : lang[language].salarySpread}</h4>
                    {language === "sv" ? ` Punktdiagram är ett mycket bra sätt att visualisera lönespridning samtidigt som individuella skillnader belyses. För att snabbt visualisera lönespridning kan man även se över skillnaden mellan högsta och lägsta lön.
                    Om spridningen är stor för ett kön men litet för ett annat kan detta indikera på att ett kön har större möjlighet till löneutveckling och då bör det ses över om så är fallet eller vad det beror på.` : lang[language].equalInfo}
                    <p>{language === "sv" ? `Spridningskvoten visar hur stor lönespridningen är mellan den högsta och lägsta lönen. Ju högre  kvot desto större spridning. Ett mått på 2.0 innebär att den högsta lönen är dubbelt så stor som den lägsta.` : lang[language].equalInfoTwo}
                    </p>
                    <p style={{ marginBottom: -10, textDecoration: "underline" }}>{language === "sv" ? "Vid stora grupper" : lang[language].equalBigGroups}</p>
                    <p>{language === "sv" ? `Notera att om ett kön består av 20 personer eller fler så kommer ni även se percentil 10 (P10) och percentil 90 (P90). Detta för att ge en mer rättvis bild av spridningen och undvika extremvärden. Det innebär att istället för högsta och lägsta lön så
                      visar P10 den lönenivå där 10% tjänar mindre och P90 visar den lönenivå där 10% tjänar mer.` : lang[language].equalInfoThree}
                    </p>
                    <p></p>
                  </div>
                </div>
              </div>
              {/* PopUp for analysis menu */}
              <div className={this.state.showFilter && !this.state.apexPopUp ? "analysisMenu" : this.state.showFilter && this.state.apexPopUp ? "analysisMenu analysisMenuBigScreen" : "hide"}>
                <div className="filterTitlePop">Filter  <img alt="" src={exitImg} class="hide-box" style={{ width: 18, marginRight: 9 }} onClick={this.toggleFilter} /></div>
                {/* <div style={{ fontSize: 14.6, textAlign: "left", marginLeft: 16, marginTop: 16 }}>Färgmarkera: Kön | Befattning | Ort</div> */}
                <div>
                  <ul style={{ display: "inline-block", padding: 16, width: 207, verticalAlign: "top" }}>
                    <li className="analysisListItem">
                      <div className="analysisListInline" style={{ textAlign: "left" }}>{language === "sv" ? "Befattning" : lang[language].position}</div>
                    </li>
                    {filterTitles}
                  </ul>
                  <ul style={{ display: "inline-block", padding: 16, width: 205, verticalAlign: "top" }}>
                    <li className="analysisListItem">
                      <div className="analysisListInline" style={{ textAlign: "left" }}>{language === "sv" ? "Ort" : lang[language].location}</div>
                    </li>
                    {filterCities}
                  </ul>
                </div>
              </div>

              {/* Chart container */}
              <div class={this.state.apexPopUp ? "apexPopUp" : "chartContainer"}>
                <div style={{ zIndex: 13, position: "absolute", maxWidth: 170, boxShadow: "0 2px 10px 0 rgb(128 128 128 / 40%)", height: "auto", paddingBottom: 0, top: 300 }} className={this.state.popItUp ? "popUp active-popUp" : "hide"}>
                  <span className="exitVideo" onClick={this.hideEditBox} style={{ marginTop: 0, float: "right", textAlign: "center", cursor: "pointer", fontSize: 15, fontWeight: 600, height: 22, width: 22 }}>x</span>
                  <span style={{ fontWeight: 900, display: "block", paddingBottom: 16, marginTop: -20, float: "left", fontSize: 13.6 }}>{language === "sv" ? "Justera" : lang[language].adjust}</span>
                  <div className={this.state.popUpEditSalarySetting ? "hide" : ""}>
                    <span style={{ display: "block", marginTop: 35, marginBottom: 8, fontSize: 13.6, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{!this.state.hideName ? this.state.clickedPerson.fullName : ""}</span>
                    <div className={this.state.showPercent ? "hide" : ""}><input type="number" placeholder={language === "sv" ? "Lön" : lang[language].salary} style={{ width: 75, border: "1px solid #d5d4d4", display: "inlinge-block", padding: 0, fontWeight: "normal", height: 30, margin: 0, verticalAlign: "top", borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRight: "none", fontSize: 13.6 }} value={this.state.addEditSalary} onChange={this.addEditSalary} /><div style={{ display: "inline-block", border: "1px solid #d5d4d4", height: 30, verticalAlign: "top", lineHeight: "30px", paddingLeft: 7, paddingRight: 7, borderTopRightRadius: 5, borderBottomRightRadius: 5, cursor: "pointer", fontSize: 13.6, background: "#d7d7d7" }} onClick={this.state.clickedPerson.addOn > 0 ? this.popUpEditSalarySetting : this.editSalary}>{language === "sv" ? "Ny lön" : lang[language].newSalary}</div></div>
                    <div onClick={this.hideEmployee} style={{ height: 30, lineHeight: "30px", width: 133, margin: "auto", marginTop: 8, borderRadius: 5, fontSize: 13.6, cursor: "pointer", background: "#d7d7d7" }}>{language === "sv" ? "Dölj anställd" : lang[language].hideEmp}</div>
                    <p style={{ fontSize: 11.6, borderTop: "1px solid #cacaca", paddingTop: 8 }}>{language === "sv" ? "Justeringen är tillfällig och återställs när ni byter grupp." : lang[language].changeGroup}</p>
                  </div>
                  <div className={this.state.popUpEditSalarySetting ? "" : "hide"}>
                    <span style={{ display: "block", fontSize: 12.6, marginTop: 35 }}>{language === "sv" ? "Personen har ett tillägg på " : lang[language].personHasBenefit} {this.state.clickedPerson.addOn}{language === "sv" ? "kr. " : ". "}<p>{language === "sv" ? "Ska tillägget inkluderas eller adderas till summan?" : lang[language].personBenefitQuestion}</p></span>
                    <div style={{ display: "inline-block", fontSize: 13.6, padding: 4, background: "#d7d7d7", borderRadius: 5, marginRight: 4, cursor: "pointer" }} onClick={this.editSalary}>{language === "sv" ? "Inkludera" : lang[language].include}</div><div style={{ display: "inline-block", fontSize: 13.6, padding: 4, background: "#d7d7d7", borderRadius: 5, marginLeft: 4, cursor: "pointer", marginBottom: 15 }} onClick={this.editSalaryExclude}>{language === "sv" ? "Addera" : lang[language].personBenefitAdd}</div>
                  </div>
                </div>
                <Chart
                  options={this.state.options}
                  series={this.state.series}
                  type="scatter"
                  width={this.state.chartWidth}
                />
              </div>

              <div className={this.state.showBench && (this.state.apexPopUp === "" || !this.state.apexPopUp) ? "benchContainer" : this.state.showBench && this.state.apexPopUp === "apexPopUp" ? "benchContainer benchContainerFullscreen" : "hide"}>
                <div className={this.state.benchInfoPop ? "benchInfoPop" : "hide"}>
                  <img className="hide-box" style={{ marginRight: -10, marginTop: -10 }} src={exitImg} onClick={this.benchInfoPop} />
                  <div style={{ fontWeight: 600, fontSize: 16.6, textAlign: "left" }}>{language === "sv" ? "Information om marknadslöner" : lang[language].equalInfoMarket}</div>
                  <div style={{ textAlign: "left", fontSize: 13.6 }}>
                    <div className="titleBenchInfoPop">{language === "sv" ? "Om datan" : lang[language].aboutMarket}</div>
                    <div>{language === "sv" ? `Lönerna är baserade på data från Statistiska centralbyrån (SCB). Alla löner som visas är brutto-månadslöner. Om "0" visas innebär detta att data saknas eller inte är tillräckligt tillförlitlig, alternativt finns ingen statistikkod kopplad till arbetet (anges under steg 2.1 eller vid importen).` : lang[language].aboutMarketInfo}</div>
                    <div className="titleBenchInfoPop">{language === "sv" ? "Vad datan visar" : lang[language].whatMarket}</div>
                    <div>{language === "sv" ? `Datan visar marknadslöner i form av flera "P" värden, vilket står för percentil. Percentil visar hur många procent som har en lägre lön. Om P10 är 30 000 kr så har 10% en lägre lön än 30 000 kr och 90% en högre, om P90 är 50 000 kr så har 90% en lägre lön än 50 000 kr och 10% mer. 80% har alltså en lön mellan 30 000 - 50 000 kr. Detta visar således marknadens lönespann.` : lang[language].whatMarketInfo}
                      <p>{language === "sv" ? `P50 är samma som medianen och innebär att 50% tjänar mindre och 50% tjänar mer. Ska man använda en siffra så är det detta som är "marknadslönen".` : lang[language].whatMarketInfoTwo}</p>
                    </div>
                    <div className="titleBenchInfoPop">{language === "sv" ? "Flera befattningar i en grupp" : lang[language].multiplePositions}</div>
                    <div>{language === "sv" ? `Marknadslönedatan som visas är en sammanställning för alla befattningar i den valda gruppen. Om det finns flera befattningar i gruppen med olika marknadslöner kommer därför ett genomsnitt för dessa att visas. För att visa marknadslöner för en specifik befattning kan ni använda filtret i diagrammet (ikon: ` : lang[language].multiplePositionsOne}<img width={13} style={{ verticalAlign: "bottom", filter: "invert(1)", marginBottom: 1 }} src={menuAnalysisIcon} />){language === "sv" ? " och välja endast den specifika befattningen." : lang[language].multiPositionsTwo}
                    </div>
                    {/*} <div className="titleBenchInfoPop">Hur nyttja datan?</div>
                    <div> Marknadslönedatan visar hur era löner står sig mot marknaden. Man kan även ha olika lönestrategier. Man kan ha som mål att ligga så nära marknadslönen (P50) som möjligt, eller så kan man välja att försöka ligga lägre eller högre än marknaden. 
                    <p>Ligga lägre än marknaden</p>
                    <p>Målet kan till exempel vara att ligga runt P25. Det innebär då att 75% av företagen kommer betala mer än er. Oftast så kombineras denna strategi med något annat, tex att man kan attrahera kandidater med något annat, såsom snabb karriärsutveckling eller  </p>
                </div>*/}
                    {/*} <div className="titleBenchInfoPop">Övrigt att notera</div>
                    <div>Övrigt?</div>*/}
                  </div>
                </div>
                <div className={this.state.benchInfoPop ? "popUpFader" : "hide"}></div>
                <span style={{ fontWeight: 600, display: "block", marginBottom: 10, fontSize: 14.6 }}>{language === "sv" ? "Marknadslöner" : lang[language].marketSalaries}</span>
                <div style={{ borderBottom: "1px solid #e1e1e1", textAlign: "left", marginLeft: 10, marginRight: 10 }}>
                  <div style={{ marginBottom: 10 }}><span className="benchPTitle">P90:</span><span className="benchSalTitle">{this.state.showBench && this.state.whatSector === "all" ? this.state.p90.toLocaleString() : this.state.showBench && this.state.whatSector === "privat" ? this.state.p90P.toLocaleString() : this.state.showBench && this.state.whatSector === "offentlig" ? this.state.p90O.toLocaleString() : 0}</span><span></span></div>
                  <div style={{ marginBottom: 10 }}><span className="benchPTitle">P75:</span><span className="benchSalTitle">{this.state.showBench && this.state.whatSector === "all" ? this.state.p75.toLocaleString() : this.state.showBench && this.state.whatSector === "privat" ? this.state.p75P.toLocaleString() : this.state.showBench && this.state.whatSector === "offentlig" ? this.state.p75O.toLocaleString() : 0}</span> <span></span></div>
                  <div className="p50Div" style={{ marginBottom: 10 }}><span className="benchPTitle">P50:</span><span className="benchSalTitle">{this.state.showBench && this.state.whatSector === "all" ? this.state.median.toLocaleString() : this.state.showBench && this.state.whatSector === "privat" ? this.state.medianP.toLocaleString() : this.state.showBench && this.state.whatSector === "offentlig" ? this.state.medianO.toLocaleString() : 0}</span> <span></span></div>
                  <div style={{ marginBottom: 10 }}><span className="benchPTitle">P25:</span><span className="benchSalTitle">{this.state.showBench && this.state.whatSector === "all" ? this.state.p25.toLocaleString() : this.state.showBench && this.state.whatSector === "privat" ? this.state.p25P.toLocaleString() : this.state.showBench && this.state.whatSector === "offentlig" ? this.state.p25O.toLocaleString() : 0}</span><span></span></div>
                  <div style={{ marginBottom: 5 }}><span className="benchPTitle">P10:</span><span className="benchSalTitle">{this.state.showBench && this.state.whatSector === "all" ? this.state.p10.toLocaleString() : this.state.showBench && this.state.whatSector === "privat" ? this.state.p10P.toLocaleString() : this.state.showBench && this.state.whatSector === "offentlig" ? this.state.p10O.toLocaleString() : 0}</span><span></span></div>

                </div>
                <div className="benchSettingsContainer">
                  <div>{language === "sv" ? "Sektor" : lang[language].sector}</div>
                  <select className="benchSelect" onChange={this.chooseSector}>
                    <option value="all">{language === "sv" ? "Alla" : lang[language].all}</option>
                    <option value="privat">{language === "sv" ? "Privat" : lang[language].private}</option>
                    <option value="offentlig">{language === "sv" ? "Offentlig" : lang[language].public}</option>
                  </select>
                  <div style={{ marginTop: 5 }}>{language === "sv" ? "Visa i diagram" : lang[language].displayInChart}</div>
                  <select className="benchSelect" onChange={this.whatPercentiles}>
                    <option value="p10">P10 - P90</option>
                    <option value="p25">P25 - P75</option>
                    <option value="all">P10 - P90 & P25 - P75</option>
                    <option value="hide">{language === "sv" ? "Dölj" : lang[language].hide}</option>
                  </select>
                  {/*} <div>Bransch</div>
                  <select className="benchSelect" onChange={this.selectFamily}>
                    <option value="Teknik">Alla</option>
                    <option value="Teknik">Teknik</option>
                  </select>
                  <div style={{ marginTop: 5 }}>Anställda</div>
                  <select className="benchSelect" onChange={this.selectFamily}>
                    <option value="Alla">Alla</option>
                    <option value="1-10">1-10</option>
                  </select>
                  <div style={{ marginTop: 5 }}>Tillägg</div>
                  <select className="benchSelect" onChange={this.selectFamily}>
                    <option value="Base">Grundlön</option>
                    <option value="Both">Grundlön + tillägg</option>
                    <option value="Addon">Endart tillägg</option>
                </select>*/}
                </div>
                <div className={this.state.apexPopUp === "apexPopUp" ? "moreInfoBenchFullScreen" : ""} style={{ marginTop: 5, fontSize: 10.6, textDecoration: "underline", cursor: "pointer" }} onClick={this.benchInfoPop}>Information</div>
              </div>

              <p></p>
              <div className="commentContainer" style={{ marginBottom: 0 }}>
                <h3 style={{ marginTop: 0, fontWeight: 800, fontSize: 15.6 }}>{language === "sv" ? "Anteckna" : lang[language].note}</h3>
                <textarea id="curSelComment" className="comment" placeholder={language === "sv" ? "Noterbart" : lang[language].notable} style={{ minHeight: 48 }} onChange={this.handleComment} value={this.state.addComment} />
                <div className="fieldsB" onClick={this.toggleFields}>{language === "sv" ? "Fler fält " : lang[language].moreFields}<img src={arrowDown} style={{ width: 9, marginLeft: 5 }} className={this.state.moreFields ? "arrowUp" : ""} /> </div>
                <div className={this.state.moreFields ? "" : "hideFields"}>
                  <textarea id="curSelReason" className="comment reason" placeholder={language === "sv" ? "Orsak" : lang[language].reason} style={{ minHeight: 48 }} onChange={this.handleReason} value={this.state.addReason} />
                  <textarea id="curSelTodo" className="comment todo" placeholder={language === "sv" ? "Åtgärd" : lang[language].action} style={{ minHeight: 48 }} onChange={this.handleTodo} value={this.state.addTodo} />
                  <input style={{ background: "white", textAlign: "center", }} type="date" id="deadline" name="deadline" className="dateBox" onChange={this.handleDeadline} value={this.state.addDeadline} />
                  <input type="text" className="comment cost" placeholder={language === "sv" ? "Kostnad" : lang[language].cost} onChange={this.handleCost} value={this.state.addCost} />
                  <input type="text" className="comment responsible" placeholder={language === "sv" ? "Ansvarig" : lang[language].responsible} onChange={this.handleResponsible} value={this.state.addResponsible} />
                  <span className="dateBoxTitle">Deadline</span>
                </div>
                <div
                  className="commentButton"
                  style={{ width: 120, display: "block", margin: "auto", marginTop: 10, fontSize: 15.6 }}
                  onClick={this.state.groupTitle === "Ingen grupp vald" ? this.noGroupWarning : this.saveNewComment}>{language === "sv" ? "Spara" : lang[language].save}</div>
              </div>
            </div>
          </div>

          <div className="allCommentsContainer">
            <div>
              <h3 style={{ paddingTop: 30, paddingBottom: 5, fontSize: 23.6, fontWeight: 900 }}>{language === "sv" ? "Noteringar" : lang[language].notes}</h3>
              <div className={this.state.filter === "all" ? "filterButtonLika activeFilter" : "filterButtonLika"} onClick={this.filterAll}>{language === "sv" ? "Alla" : lang[language].all}</div>
              <div className={this.state.filter !== "all" ? "filterButtonLika activeFilter" : "filterButtonLika"} onClick={this.filterGroup}>{language === "sv" ? "Vald grupp" : lang[language].selectedGroup}</div>
            </div>

            <table className="commentsTable">
              <tr className="trStyleLine">
                <th className="thpadding">{language === "sv" ? "Noterbart" : lang[language].notable}</th>
                <th className="thpadding">{language === "sv" ? "Orsak" : lang[language].reason}</th>
                <th className="thpadding">{language === "sv" ? "Åtgärd" : lang[language].action}</th>
                <th className="thpadding">Deadline</th>
                <th className="thpadding" style={{ width: "8%" }}>{language === "sv" ? "Kostnad" : lang[language].cost}</th>
                <th className="thpadding">{language === "sv" ? "Ansvarig" : lang[language].responsible}</th>
                <th className="thpadding remove" style={{ width: 30 }}></th>
              </tr>

              {comments}

            </table>

            <div className={this.state.allComments < 1 ? "hide" : ""}>
              <div style={{ marginTop: 80, fontSize: 16, fontWeight: 800, marginBottom: 20, cursor: "pointer" }}><span onClick={this.toggleOldComments}>{language === "sv" ? "Föregående års kommentarer" : lang[language].commentPast}</span>
                <img alt="" src={this.state.showOldComments ? down : up} style={{ width: 12, marginLeft: 5, cursor: "pointer" }} onClick={this.toggleOldComments} />
              </div>
              <div className={this.state.showOldComments ? "" : "hide"}>
                <select name="selectionCommentYear" id="selectionCommentYear" onChange={this.changeYearComments} value={this.state.year}>
                  <option value={this.state.selectedYear - 1}>{this.state.selectedYear - 1}</option>
                  <option value={this.state.selectedYear - 2}>{this.state.selectedYear - 2}</option>
                  <option value={this.state.selectedYear - 3}>{this.state.selectedYear - 3}</option>
                  <option value={this.state.selectedYear - 4}>{this.state.selectedYear - 4}</option>
                </select>
                <span class="lastYearButton" style={{ padding: 7, paddingLeft: 10, paddingRight: 10 }} onClick={this.showReusePopUp}>{language === "sv" ? "Återanvänd alla" : lang[language].reuseAll}</span>
                <div style={{ top: 500, width: 450 }} className={this.state.popUp ? "popUp active-popUp" : "hide"}>
                  <img alt="" src={exitImg} class="hide-box" onClick={this.togglePop} />
                  <span style={{ marginTop: 60, display: "block", fontWeight: 600, display: "block", paddingLeft: 40, paddingRight: 40, fontWeight: "normal", lineHeight: 1.4 }}>{language === "sv" ? `Vill du återanvända alla kommentarer från ${this.state.year} för alla grupper?` : lang[language].reuseAllPop}</span>
                  <div style={{ marginTop: 40 }}>
                    <span class="lastYearButton" style={{ background: "#c99555", marginRight: 10 }} onClick={this.togglePop}>{language === "sv" ? "Avbryt" : lang[language].cancel}</span>
                    <span class="lastYearButton" style={{ marginLeft: 10 }} onClick={this.reuseAll}>{language === "sv" ? "Ja, fortsätt" : lang[language].continue}</span>
                  </div>
                </div>

                <div className={this.state.popUp ? "popUpFader" : "hide"}></div>
                <table className="commentsTable lastyearList">

                  {pastComments}

                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}